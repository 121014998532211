import { SET_THEMES, IS_LOADING, Theme } from './types';

export const setThemes = (themes: Theme[]) => ({
  type: SET_THEMES,
  themes
});

export const isLoading = (isLoading: boolean) => ({
  type: IS_LOADING,
  isLoading: isLoading,
});