export const lmircf = [
  { value: '14000.00', label: 'R$ 14.000,00' },
  { value: '16000.00', label: 'R$ 16.000,00' },
  { value: '18000.00', label: 'R$ 18.000,00' },
  { value: '20000.00', label: 'R$ 20.000,00' },
  { value: '25000.00', label: 'R$ 25.000,00' },
  { value: '30000.00', label: 'R$ 30.000,00' },
  { value: '40000.00', label: 'R$ 40.000,00' },
  { value: '50000.00', label: 'R$ 50.000,00' },
  { value: '75000.00', label: 'R$ 75.000,00' },
  { value: '100000.00', label: 'R$ 100.000,00' },
  { value: '125000.00', label: 'R$ 125.000,00' },
  { value: '150000.00', label: 'R$ 150.000,00' },
  { value: '175000.00', label: 'R$ 175.000,00' },
  { value: '200000.00', label: 'R$ 200.000,00' },
  { value: '250000.00', label: 'R$ 250.000,00' },
  { value: '300000.00', label: 'R$ 300.000,00' },
  { value: '350000.00', label: 'R$ 350.000,00' },
  { value: '400000.00', label: 'R$ 400.000,00' },
  { value: '450000.00', label: 'R$ 450.000,00' },
  { value: '500000.00', label: 'R$ 500.000,00' },
  { value: '600000.00', label: 'R$ 600.000,00' },
  { value: '700000.00', label: 'R$ 700.000,00' },
  { value: '800000.00', label: 'R$ 800.000,00' },
  { value: '900000.00', label: 'R$ 900.000,00' }
]

export const ppMock = [
  { value: '', label: 'Nenhum' },
  { value: 'C09', label: '7 DIAS MÉDIO / DESCONTO FRANQUIA' },
  { value: 'C10', label: '15 DIAS LUXO / DESCONTO FRANQUIA' },
  { value: 'C11', label: 'INDETERMINADO LUXO / DESCONTO FRANQUIA' },
  { value: 'C12', label: '7 DIAS LUXO / DESCONTO FRANQUIA' },
  { value: 'C13', label: '15 DIAS BÁSICO / DESCONTO FRANQUIA' },
  { value: 'C14', label: 'INDETERMINADO BÁSICO / DESCONTO FRANQUIA' },
  { value: 'C15', label: '7 DIAS BÁSICO / DESCONTO FRANQUIA' },
  { value: 'C28', label: '7 DIAS BÁSICO AR / DESCONTO FRANQUIA' },
  { value: 'C29', label: '15 DIAS BÁSICO AR/ DESCONTO FRANQUIA' }
]

export const iiMock = [
  { value: '', label: 'Nenhum' },
  { value: 'A89', label: '30 DIAS BÁSICO' },
  { value: 'C31', label: '30 DIAS BÁSICO COM AR' },
  { value: 'C16', label: '30 DIAS LUXO' },
  { value: 'C17', label: '30 DIAS MÉDIO' }
]

export const ppiiMock = [
  { value: '', label: 'Nenhum' },
  { value: 'A23', label: '15 DIAS BÁSICO' },
  { value: 'A24', label: '35 DIAS BÁSICO' },
  { value: 'A25', label: '7 DIAS BÁSICO' },
  { value: 'A75', label: '7 DIAS LUXO/EXECUTIVO' },
  { value: 'A76', label: '15 DIAS LUXO/EXECUTIVO' },
  { value: 'A77', label: '35 DIAS LUXO/EXECUTIVO' },
  { value: 'C01', label: '15 DIAS MÉDIO' },
  { value: 'C02', label: '35 DIAS MÉDIO' },
  { value: 'C03', label: '7 DIAS MÉDIO' },
  { value: 'C25', label: '7 DIAS BÁSICO COM AR' },
  { value: 'C26', label: '15 DIAS BÁSICO COM AR' },
  { value: 'C27', label: '35 DIAS BÁSICO COM AR' }
]

export const thirdMock = [
  { value: '', label: 'Nenhum' },
  { value: 'C18', label: '7 DIAS BÁSICO TERCEIRO' },
]

export const assistanceMock = [
  { value: '', label: 'Nenhum' },
  { value: 'C50', label: 'Básico' },
  { value: 'C51', label: 'Intermediário' },
  { value: 'C52', label: 'Superior' }
]

export const mirrorMock = [
  { value: '', label: 'Nenhum' },
  { value: 'A50', label: 'VEÍCULO NACIONAL BÁSICO' },
  { value: 'A51', label: 'VEÍCULO IMPORTADO BÁSICO' },
  { value: 'A69', label: 'VEÍCULO NACIONAL VIP' },
  { value: 'A70', label: 'VEÍCULO IMPORTADO VIP' },
  { value: 'C20', label: 'BLINDADOS BÁSICO NACIONAL' },
  { value: 'C21', label: 'BLINDADO BÁSICO IMPORTADO' },
  { value: 'C22', label: 'BLINDADO VIP NACIONAL' },
  { value: 'C23', label: 'BLINDADO VIP IMPORTADO' }
]

export const dmMock = [
  { value: '', label: 'Nenhum' },
  { value: '5000.00', label: 'R$ 5.000,00' },
  { value: '10000.00', label: 'R$ 10.000,00' },
  { value: '12000.00', label: 'R$ 12.000,00' },
  { value: '14000.00', label: 'R$ 14.000,00' },
  { value: '16000.00', label: 'R$ 16.000,00' },
  { value: '18000.00', label: 'R$ 18.000,00' },
  { value: '20000.00', label: 'R$ 20.000,00' },
  { value: '25000.00', label: 'R$ 25.000,00' },
  { value: '30000.00', label: 'R$ 30.000,00' },
  { value: '40000.00', label: 'R$ 40.000,00' },
  { value: '50000.00', label: 'R$ 50.000,00' },
  { value: '75000.00', label: 'R$ 75.000,00' },
  { value: '100000.00', label: 'R$ 100.000,00' },
  { value: '115000.00', label: 'R$ 115.000,00' },
  { value: '125000.00', label: 'R$ 125.000,00' },
  { value: '150000.00', label: 'R$ 150.000,00' },
  { value: '175000.00', label: 'R$ 175.000,00' },
  { value: '200000.00', label: 'R$ 200.000,00' }
]