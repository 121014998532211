import React from "react";
import { validateVerify } from "../../../utils/validations";
import { fieldNotFilledAlert } from "../../../utils";
import { Box, Button } from "../../../components/ds/SubatomicParticles";
import { H3 } from "../../../components/ds/theme/typography";
import FormSelect from "../../../components/FormSelect";

export default function ResidenceInfo({
  onNext,
  PropertyType,
  setPropertyType,
  ConstructionType,
  setConstructionType,
  OccupanceType,
  setOcupationType,
  RobberyProtectiveSystem,
  setRobberyProtectiveSystem,
  TumbledHomeOwners,
  setTumbledHomeOwners,
  HasAnotherInsurance,
  setHasAnotherInsurance,
  ResidenceHasWoodOutsideWalls,
  setResidenceHasWoodOutsideWalls,
  ResidenceUnoccupiedUnderRenovationOrConstruction,
  setResidenceUnoccupiedUnderRenovationOrConstruction,
  TypeRegionLocation,
  setTypeRegionLocation,
  ResidenceOwnerIsPolicyHolder,
  setResidenceOwnerIsPolicyHolder,
}) {

  const checkFields = () => {
    return PropertyType &&
      ConstructionType &&
      TypeRegionLocation &&
      OccupanceType &&
      (RobberyProtectiveSystem !== "0" && RobberyProtectiveSystem !== 0 && (!!RobberyProtectiveSystem || RobberyProtectiveSystem === "")) &&
      ResidenceHasWoodOutsideWalls &&
      ResidenceUnoccupiedUnderRenovationOrConstruction &&
      HasAnotherInsurance &&
      TumbledHomeOwners &&
      ResidenceOwnerIsPolicyHolder
  }

  const _handleIncomplete = () => {
    if (!PropertyType) fieldNotFilledAlert('Tipo de Imóvel');
    if (!ConstructionType) fieldNotFilledAlert('Construção do Imóvel');
    if (!ResidenceHasWoodOutsideWalls) fieldNotFilledAlert('Imóvel possui madeira nas paredes externas');
    if (!ResidenceUnoccupiedUnderRenovationOrConstruction) fieldNotFilledAlert('Imóvel desocupado, em reforma ou em construção?');
    if (!TypeRegionLocation) fieldNotFilledAlert('Imóvel pertence a qual região de localização?');
    if (!OccupanceType) fieldNotFilledAlert('Tipo de ocupação');
    if (!(RobberyProtectiveSystem !== "0" && RobberyProtectiveSystem !== 0 && (!!RobberyProtectiveSystem || RobberyProtectiveSystem === ""))) fieldNotFilledAlert('Proteção contra roubo');
    if (!OccupanceType) fieldNotFilledAlert('Tipo de ocupação');
    if (!HasAnotherInsurance) fieldNotFilledAlert('Imóvel já possui seguro');
    if (!TumbledHomeOwners) fieldNotFilledAlert('Imóvel tombado pelo patrimônio público');
    if (!ResidenceOwnerIsPolicyHolder) fieldNotFilledAlert("O proprietário do imóvel é segurado");
  }

  const _handleVerify = () => {
    if (checkFields()) {
      onNext({
        ConstructionType: ConstructionType.toString(),
        OccupanceType: OccupanceType.toString(),
        PropertyType: PropertyType.toString(),
        RobberyProtectiveSystem: RobberyProtectiveSystem.toString(),
        ResidenceHasWoodOutsideWalls,
        HasAnotherInsurance,
        TumbledHomeOwners,
        ResidenceUnoccupiedUnderRenovationOrConstruction,
        TypeRegionLocation: TypeRegionLocation.toString(),
        ResidenceOwnerIsPolicyHolder
      });
    } else {
      _handleIncomplete()
    }
  }

  return (
    <>
      <Box
        gridColumn={{
          mobile: "2/span 10",
          tablet: "2/span 10",
          desktop: "4/span 6",
        }}
        styling="column"
      >
        <H3 mb="30px" styling="light">Informações do Imóvel</H3>
        <FormSelect
          data-testid="PropertyType"
          id="PropertyType"
          name="PropertyType"
          onBlur={e => validateVerify(e)}
          onChange={(e) => setPropertyType(e.value)}
          options={[
            { value: '', label: 'Selecione' },
            { value: 1, label: 'Casa' },
            { value: 2, label: 'Apartamento' },
            { value: 3, label: 'Casa em condomínio fechado' },
          ]}
          placeholder="Tipo do Imóvel"
          styling="base"
          defaultValue={PropertyType}
        />
        <FormSelect
          data-testid="ConstructionType"
          id="ConstructionType"
          name="ConstructionType"
          onBlur={e => validateVerify(e)}
          onChange={(e) => setConstructionType(e.value)}
          options={[
            { value: '', label: 'Selecione' },
            { value: 4, label: 'Alvenaria' },
          ]}
          placeholder="Construção do Imóvel"
          styling="base"
          defaultValue={ConstructionType}
        />
        <FormSelect
          data-testid="ResidenceHasWoodOutsideWalls"
          id="ResidenceHasWoodOutsideWalls"
          name="ResidenceHasWoodOutsideWalls"
          onBlur={e => validateVerify(e)}
          onChange={(e) => setResidenceHasWoodOutsideWalls(e.value)}
          options={[
            { value: '', label: 'Selecione' },
            { value: 'F', label: 'Não' },
          ]}
          placeholder="Imóvel possui madeira nas paredes externas"
          styling="base"
          defaultValue={ResidenceHasWoodOutsideWalls}
        />
        <FormSelect
          data-testid="ResidenceUnoccupiedUnderRenovationOrConstruction"
          id="ResidenceUnoccupiedUnderRenovationOrConstruction"
          name="ResidenceUnoccupiedUnderRenovationOrConstruction"
          onBlur={e => validateVerify(e)}
          onChange={(e) => setResidenceUnoccupiedUnderRenovationOrConstruction(e.value)}
          options={[
            { value: '', label: 'Selecione' },
            { value: 'F', label: 'Não' },
            { value: 'T', label: 'Sim' },
          ]}
          placeholder="Imóvel desocupado, em reforma ou em construção?"
          styling="base"
          defaultValue={ResidenceUnoccupiedUnderRenovationOrConstruction}
        />
        <FormSelect
          data-testid="TypeRegionLocation"
          id="TypeRegionLocation"
          name="TypeRegionLocation"
          onBlur={e => validateVerify(e)}
          onChange={(e) => setTypeRegionLocation(e.value)}
          options={[
            { value: '', label: 'Selecione' },
            { value: 1, label: 'Urbana' },
            { value: 2, label: 'Rural' },
          ]}
          placeholder="Imóvel pertence a qual região de localização?"
          styling="base"
          defaultValue={TypeRegionLocation}
        />
        <FormSelect
          data-testid="OccupanceType"
          id="OccupanceType"
          name="OccupanceType"
          onBlur={e => validateVerify(e)}
          onChange={(e) => setOcupationType(e.value)}
          options={[
            { value: '', label: 'Selecione' },
            { value: 1, label: 'Habitual' },
            { value: 2, label: 'Veraneio' },
          ]}
          placeholder="Tipo de ocupação"
          styling="base"
          defaultValue={OccupanceType}
        />
        <FormSelect
          data-testid="ResidenceOwnerIsPolicyHolder"
          id="ResidenceOwnerIsPolicyHolder"
          name="ResidenceOwnerIsPolicyHolder"
          onBlur={e => validateVerify(e)}
          onChange={(e) => setResidenceOwnerIsPolicyHolder(e.value)}
          options={[
            { value: "", label: "Selecione" },
            { value: "T", label: "Sim" },
            { value: "F", label: "Não" },
          ]}
          placeholder="O proprietário do imóvel é beneficiário"
          styling="base"
          defaultValue={ResidenceOwnerIsPolicyHolder}
        />
        <FormSelect
          data-testid="RobberyProtectiveSystem"
          id="RobberyProtectiveSystem"
          name="RobberyProtectiveSystem"
          onBlur={e => validateVerify(e)}
          onChange={(e) => setRobberyProtectiveSystem(e.value)}
          options={[
            { value: 0, label: 'Selecione' },
            { value: '', label: 'Não possui' },
            { value: 4, label: 'Alarme monitorado' },
            { value: 5, label: 'Vigilância particular armada 24 horas' },
            { value: 6, label: 'Vigilância particular armada 24 horas e alarme monitorado' },
          ]}
          placeholder="Proteção contra roubo"
          styling="base"
          defaultValue={RobberyProtectiveSystem}
        />
        <FormSelect
          data-testid="HasAnotherInsurance"
          id="HasAnotherInsurance"
          name="HasAnotherInsurance"
          onBlur={e => validateVerify(e)}
          onChange={(e) => setHasAnotherInsurance(e.value)}
          options={[
            { value: '', label: 'Selecione' },
            { value: 'F', label: 'Não' },
            { value: 'T', label: 'Sim' },
          ]}
          placeholder="Imóvel já possui seguro"
          styling="base"
          defaultValue={HasAnotherInsurance}
        />
        <FormSelect
          data-testid="TumbledHomeOwners"
          id="TumbledHomeOwners"
          name="TumbledHomeOwners"
          onBlur={e => validateVerify(e)}
          onChange={(e) => setTumbledHomeOwners(e.value)}
          options={[
            { value: '', label: 'Selecione' },
            { value: 'F', label: 'Não' },
            { value: 'T', label: 'Sim' },
          ]}
          placeholder="Imóvel tombado pelo patrimônio público"
          styling="base"
          defaultValue={TumbledHomeOwners}
        />
        <Button display="block" margin="0 auto" onClick={_handleVerify} styling="primary" width="195px">Próximo</Button>
      </Box>
    </>
  );
}
