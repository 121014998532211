/* eslint react-hooks/rules-of-hooks: off */
import React from "react";
import { Box, Button } from "../../../components/ds/SubatomicParticles";
import { H3 } from "../../../components/ds/theme/typography";
import FormSelect from "../../../components/FormSelect";
import { validateVerify } from "../../../utils/validations";
import {
  useFormInput,
  useFormSelect,
  fieldNotFilledAlert,
} from "../../../utils";
import FormInput from "../../../components/FormInput";

export default function Step2(props) {
  const { quantidade, onNext, pets } = props;

  const arrPets = [];

  const orderLabels = ["primeiro", "segundo", "terceiro", "quarto", "quinto"];

  for (let index = 0; index < quantidade; index++) {
    arrPets.push({
      nome: useFormInput(
        pets[index]?.nome ? pets[index].nome : "",
        `Nome do ${orderLabels[index]} Pet`,
      ),
      sexo: useFormSelect(pets[index]?.sexo ? pets[index]?.sexo : null),
    });
  }

  const _handleOnNext = () => {
    let isIncomplete = false;
    for (let index = 0; index < arrPets.length; index++) {
      if (!arrPets[index].nome.value)
        fieldNotFilledAlert(`Nome do ${orderLabels[index]} Pet`);
      if (!arrPets[index].sexo.value)
        fieldNotFilledAlert(`Sexo do ${orderLabels[index]} Pet`);
      if (
        !isIncomplete &&
        (!arrPets[index].nome.value || !arrPets[index].sexo.value)
      ) {
        isIncomplete = true;
      }
    }

    if (!isIncomplete) {
      const arrayToReturn = arrPets.map((itemPet) => ({
        nome: itemPet.nome.value,
        sexo: itemPet.sexo.value,
      }));
      onNext(arrayToReturn);
    }
  };

  return (
    <>
      <Box
        gridColumn={{
          mobile: "2/span 10",
          tablet: "2/span 10",
          desktop: "4/span 6",
        }}
        styling="column"
        pb={"30rem"}
      >
        <H3 mb="30px" styling="light">
          Nos conte mais sobre seus pets
        </H3>
        {arrPets.map((petFormItem, i) => (
          <Box display="flex" width={"100%"} key={`pet${i}`}>
            <Box mr={"3rem"} flexGrow={2}>
              <FormInput
                data-testid="petName"
                id="petName"
                name="petName"
                {...petFormItem.nome}
                placeholder={`Nome do ${orderLabels[i]} pet`}
                onBlur={(e) => validateVerify(e)}
              />
            </Box>
            <Box flexGrow={1} mt={"2px"}>
              <FormSelect
                data-testid="petGender"
                id="petGender"
                name="petGender"
                options={[
                  {
                    value: "femea",
                    label: "Fêmea",
                  },
                  {
                    value: "macho",
                    label: "Macho",
                  },
                ]}
                defaultValue={petFormItem.sexo.value}
                onChange={(e) => {
                  e.target = { value: e.value };
                  petFormItem.sexo.onChange(e);
                }}
                onBlur={(e) => validateVerify(e)}
                placeholder="Sexo"
                styling="base"
              />
            </Box>
          </Box>
        ))}
        <Button styling="primary" onClick={_handleOnNext} width="296px" mt={25}>
          Próximo
        </Button>
      </Box>
    </>
  );
}
