/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import * as cotacaoService from "../../state/context/cotacao/services";
import { connect } from "react-redux";
import { formatter } from "../../utils/validations";
import { Box } from "../../components/ds/SubatomicParticles";
import { H3, P } from "../../components/ds/theme/typography";

const InfoMyQuotes = ({ location, user, BuscaInfoCotacoes }) => {
  const [infos, setInfos] = useState([]);

  useEffect(() => {
    BuscaInfoCotacoes(location.state.item.documento);
  }, []);

  useEffect(() => {
    setInfos(user.quoteInfo);
  }, [user.quoteInfo]);

  return (
    <Box
      gridRowGap="1.5rem"
      gridTemplateColumns="repeat(12, 1fr)"
      minHeight="100vh"
      position="relative"
      styling="grid"
      width="100%"
    >
      <Box
        alignItems="flex-start"
        backgroundColor="background.default"
        borderRadius="12px"
        boxShadow="10px 10px 28px 1px rgba(0,0,0,.1)"
        gridColumn={{
          mobile: "2/span 10",
          tablet: "2/span 10",
          desktop: "4/span 6",
        }}
        justifyContent="flex-start"
        padding="20px"
        styling="column"
      >
        <H3 mb="30px" styling="light">
          {location.state.item.itemSegurado}
        </H3>
        <P styling="regular">Nome da seguradora</P>
        <P mb="10px" styling="base">
          {location.state.item.nomeSeguradora}
        </P>
        <P styling="regular">Nome do produto</P>
        <P mb="10px" styling="base">
          {location.state.item.nomeProduto}
        </P>
        <P styling="regular">Número do documento</P>
        <P mb="10px" styling="base">
          {location.state.item.documento}
        </P>

        {!user.quoteInfoLoading && (
          <>
            <H3 mb="30px" mt="20px" styling="light">
              Coberturas
            </H3>
            {infos.length > 0 &&
              infos.map((e, i) => {
                const value = parseInt(e.impSegurada);
                return (
                  <React.Fragment key={`g${i}`}>
                    <P styling="regular">{e.descricao}</P>
                    <P mb="10px" styling="base">{`${
                      e.impSegurada === "0"
                        ? "Incluso"
                        : formatter.format(value)
                    }`}</P>
                  </React.Fragment>
                );
              })}
          </>
        )}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  BuscaInfoCotacoes: cotacaoService.BuscaInfoCotacoes,
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoMyQuotes);
