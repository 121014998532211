import React, { useState, useEffect } from "react";
import { useFormInput } from "../../../../utils";
import { validateVerify } from "../../../../utils/validations";
import { Box, Button } from "../../../../components/ds/SubatomicParticles";
import { H3, P } from "../../../../components/ds/theme/typography";
import FormInput from "../../../../components/FormInput";
import { ReactComponent as IconShield } from "../../../../assets/svg/shield.svg";


const ModalPhone = ({ onSend, infos, phone }) => {
  const telefone = useFormInput("", "Telefone", "text");
  const [otherPhone, setoTherPhone] = useState(false)
  const [currentPhone, setCurrentPhone] = useState(phone)
  const [isEmptyPhone, setIsEmptyPhone] = useState(false)

  useEffect(() => {
    setIsEmptyPhone(false)
  }, [telefone.value])

  return (
    <Box
      backgroundColor="background.default"
      borderRadius="12px"
      maxWidth="415px"
      padding="2rem"
      position="fixed"
      styling="cloumn"
      width="90%"
      zIndex="2"
    >
      <Box styling="row">
        <IconShield />
      </Box>
      <H3 styling="light">
        Você está prestes a solicitar um sinistro.
      </H3>
      {otherPhone ? (
        <Box styling="column">
          <P my="30px" styling="base">Informe o número que deseja contato.</P>
          <FormInput
            {...telefone}
            data-testid="telefone"
            mask="(99) 99999-9999"
            name="telefone"
            onBlur={e => validateVerify(e)}
            placeholder="Telefone"
            type="text"
          />
          <Button
            display="block"
            margin="0 auto"
            onClick={() => {
              if (telefone.value.length >= 11) {
                setCurrentPhone(telefone.value);
                setoTherPhone(false)
              } else {
                setIsEmptyPhone(true)
              }
            }}
            styling="primary"
            width="90%"
          >
            Salvar
              </Button>
          {isEmptyPhone &&
            <P fontSize="12px" mt="15px" styling="error">Telefone inválido</P>
          }
        </Box>
      ) : (
          <>
            <P my="30px" styling="base">{`Número de contato **** ${currentPhone.substr(-4)}`}</P>
            <Button
              display="block"
              margin="0 auto"
              onClick={() => onSend(infos, currentPhone.split(' ')[0], currentPhone.split(' ')[1])}
              styling="primary"
              width="90%"
            >
              Enviar
          </Button>
            <Button display="block" margin="15px auto 0" onClick={() => setoTherPhone(true)} styling="primary" width="90%">Informar outro número</Button>
          </>
        )}
    </Box>
  );
};

export default ModalPhone;
