import React from 'react';
import { css } from '@styled-system/css';
import { Box, Button } from '../ds/SubatomicParticles';
import { ReactComponent as IconMenu } from '../../assets/svg/icon_menu.svg';
import { ReactComponent as IconX } from '../../assets/svg/icon_x.svg';
import Icon from '../ds/atoms/Icon';

type Props = {
  handleMenu?: () => void;
  open: boolean;
  children?: React.ReactNode;
};

const Sidebar: React.FC<Props> = (props: Props) => (
  <>
    <Box
      styling="column"
      position="fixed"
      width={props.open ? '163px' : '58px'}
      backgroundColor="#fff"
      left="0"
      top="15px"
      bottom="15px"
      borderTopRightRadius={7}
      borderBottomRightRadius={7}
      alignItems="flex-start"
      justifyContent="flex-start"
      transition="all ease-in 0.25s"
      css={css({
        boxShadow: '0 0 5px 5px rgba(0,0,0,.05)',
        zIndex: '99999',
        a: {
          borderRadius: 0,
          justifyContent: 'flex-start',
          overflow: 'hidden',
          p: 0,
          width: `${props.open ? '100%' : '30px'}`,
          '& > p': {
            pl: `${props.open ? '4px' : '0'}`,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            transition: 'all ease-in 0.25s',
            whiteSpace: 'nowrap',
            width: `${props.open ? '100%' : '0'}`,
          },
        },
      })}
    >
      <Button
        styling="underlined"
        type="button"
        width="58px"
        py="xs"
        px={19}
        m={0}
        mt="xxs"
        justifyContent="flex-start"
        backgroundColor="transparent"
        onClick={props?.handleMenu}
        css={css({
          '&:focus': {
            outline: 'none'
          },
        })}
      >
        <Icon
          styling="admin"
          title={props.open ? 'Fechar menu' : 'Abrir menu'}
        >
          {props.open && <IconX height={20} width={20} />}
          {!props.open && <IconMenu height={20} width={20} />}
        </Icon>
      </Button>
      {props.children}
    </Box>
    {props.open && <Box styling="overlay" onClick={props?.handleMenu} />}
  </>
);

export default Sidebar;
