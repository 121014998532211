import React from 'react'
import { Box, Button } from "../../components/ds/SubatomicParticles";
import { P } from "../../components/ds/theme/typography";
import Icon from "../../components/ds/atoms/Icon";

const InfoBorderBottomCard = ({ onClick, icon, title, status, onCancel, cancelText }) => (
  <Box
    justifyContent="space-between"
    onClick={onClick}
    py="20px"
    styling="row"
    width="100%"
    bg="#f5f5f5"
  >
    <Icon styling="base" height="40px" width="40px" flex='1'>
      {icon}
    </Icon>
    <Box
      alignItems="flex-start"
      flexDirection="column"
      width="calc(100% - 100px)"
      ml='48px'
    >
      <P fontSize="14px" mb="10px" styling="base">{title}</P>
      <P fontSize="12px" styling="base">{status}</P>
    </Box>
    <Box
      alignItems="flex-end"
      styling={{mobile: "column", desktop:"row", tablet:"column"}}
      width="30%"
    >
      {onCancel && 
      <Button styling="outline" 
              mr={{desktop:'12px', mobile: '0px', tablet:'0px'}} 
              mb='4px' 
              height={{desktop:'38px', mobile: '46px', tablet:'46px'}} 
              width={{desktop:'156px', mobile:'116px', tablet:'116px'}}
              onClick={onCancel}
              display="flex">
        {cancelText}
      </Button>}
      <Button styling="primary" mb='4px' height='38px' width='116px' display="flex">
        Visualizar
      </Button>
    </Box>
  </Box>
)

export default InfoBorderBottomCard