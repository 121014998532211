import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import moment from 'moment'

export function validateEmail(email) {
  const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(email);
}

export const validateVerify = (e) => {
  if (e.target.value !== "") {
    e.target.style.borderBottomColor = "#c2c5cc";
  }
};

export function validInputBorder(id) {
  var x = document.getElementById(id);
  x.style.borderBottomColor = "#d7d5d4";
}

export function keepOnlyNumbers(string) {
  return string.replace(/[^0-9]/g, '')
}

export const getRawPhone = phone => phone.replace(/[^Z0-9_]/g, "");

export const formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 2
})

export const autoCorrectedDatePipe = (value, isFutureEnabled = false, isPastEnabled = true) => {
  const correctDate = createAutoCorrectedDatePipe('dd/mm/yyyy')
  const pipe = correctDate(value)
  const date = moment(pipe.value, 'DD/MM/YYYY', true);
  const rawValue = value.replace(/[^Z0-9]/g, "")
  if (pipe) {
    if (date.isValid() && !isFutureEnabled) {
      pipe.value = moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY').isSameOrAfter(date) ? pipe.value : ''
    }
    if (date.isValid() && isFutureEnabled && !isPastEnabled) {
      pipe.value = date.isBefore(moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY')) ? '' : pipe.value
    }
    if (rawValue.length === 8 && !date.isValid()) {
      pipe.value = ''
    }
    return pipe
  }
  return pipe
}

export const autoCorrectYearDatePipe = (value, dateLimit) => {
  const correctDate = createAutoCorrectedDatePipe('yyyy')
  const pipe = correctDate(value)
  const date = moment(pipe.value, 'YYYY', true);
  const rawValue = value.replace(/[^Z0-9]/g, "")
  if (pipe) {
    if (date.isValid()) {
      if (parseInt(dateLimit) > parseInt(moment().add('year', 1).format('YYYY'))) {
        pipe.value = moment(moment(), 'YYYY').isSameOrAfter(date) ? pipe.value : ''
      } else {
        pipe.value = moment(dateLimit, 'YYYY').isSameOrAfter(date) ? pipe.value : ''
      }
    }
    if (rawValue.length === 4 && !date.isValid()) {
      pipe.value = ''
    }
    return pipe
  }
  return pipe
}

export const checkLengthOfDate = date => date && date.replace(/[/&_]/g, "").length