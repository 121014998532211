import React, { memo, useEffect, useMemo, useState } from 'react';
import { connect } from "react-redux";
import css from '@styled-system/css';

import { ReactComponent as IconFilter } from '../../../assets/svg/filter.svg';
import { Box, Button } from '../../../components/ds/SubatomicParticles';
import { Table } from '../../../components/ds/atoms/Table';
import Icon from '../../../components/ds/atoms/Icon';
import { getUsers, setAdmin } from '../../../state/context/users/services';
import { Props, State } from './types';
import Breadcrumbs from '../../../components/ds/atoms/Breadcrumbs';
import Badge from '../../../components/ds/atoms/Badge';
import Loading from '../../../components/SmallLoading';
import SwitchButton from '../../../components/ds/atoms/SwitchButton';

const steps = [
  {
    path: '/admin/users',
    name: 'Usuários',
  },
];

const List: React.FC<Props> = props => {
  const { getUsers, setAdmin, users } = props;
  const [showFilter, toggleFilter] = useState<boolean>(false);
  const handleFilter = () => toggleFilter(prevState => !prevState);
  const [countFilters, setCountFilters] = useState<number>(0);

  const columns = [
    {
      Header: 'Nome ',
      accessor: 'nome',
      filter: 'fuzzyText',
      placeholder: 'Filtrar por Nome',
    },
    {
      Header: 'E-mail',
      accessor: 'email',
      filter: 'fuzzyText',
      placeholder: 'Filtrar por E-mail',
    },
    {
      Header: 'Admin',
      accessor: 'admin',
      disableFilters: true
    },
  ];

  useEffect(() => { getUsers(); }, [getUsers]);

  const usersList = useMemo(() =>
    users.map(user => ({
      ...user,
      admin: (
        <Box styling="row">
          <SwitchButton
            labelFontSize="0.80rem"
            maxWidth="185px"
            id={`status${user.id}`}
            name={`status${user.id}`}
            label="status"
            value={user.admin || false}
            onChange={value => user.id &&
              setAdmin(
                user.id,
                value
                  ? { admin: true }
                  : { admin: false }
              )
            }
          />
        </Box>
      )
    })), [users]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      styling="column"
      width="100%"
      height="100%"
      overflow="hidden"
      position="relative"
    >
      <Box
        styling="row"
        width="calc(100% - 60px)"
        position="absolute"
        top="30px"
        left="30px"
      >
        <Breadcrumbs styling="base" steps={steps} />
      </Box>
      <Box
        styling="row"
        width="calc(100% - 60px)"
        position="absolute"
        top="85px"
        left="30px"
        justifyContent="flex-end"
      >
        <Button
          type="button"
          styling="underlined"
          css={css({
            color: `${countFilters > 0 ? '#3f51b5' : '#1B2B37'}`,
            fontFamily: 'OpenSans Semibold',
            border: 'none',
            fontSize: 2,
            display: 'flex',
            m: 0,
            outline: 'none',
            position: 'relative',
            '&:hover': {
              color: '#3f51b5',
              'svg': {
                stroke: '#3f51b5',
                fill: '#3f51b5',
              },
            },
          })}
          onClick={handleFilter}
        >
          {countFilters > 0 && (
            <Badge
              styling="base"
              top="-6px"
              right="0px"
              bg="#3f51b5"
              color="#fff"
              borderColor="#3f51b5"
              border="1px solid"
            >
              {countFilters}
            </Badge>
          )}
          <Icon styling={countFilters > 0 ? 'adminActive' : 'admin'} title="Filtrar" mr="10px">
            <IconFilter />
          </Icon>
          Filtros
        </Button>
      </Box>
      <Box
        bottom="0"
        position="absolute"
        styling="row"
        top="135px"
        width="calc(100% - 60px)"
      >
        <Table<any>
          name="list-users"
          styling="base"
          data={usersList}
          columns={columns}
          showFilter={showFilter}
          onCloseFilter={handleFilter}
          countFilter={setCountFilters}
        />
      </Box>
      {props.isLoading && (
        <Box
          styling="column"
          height="100vh"
           width="100%"
          zIndex={99999}
          bg="rgba(240, 241, 242, 0.62)"
          position="absolute"
        >
          <Loading />
        </Box>
      )}
    </Box>
  )
};

const mapStateToProps = (state: State) => ({
  users: state.users.users,
  isLoading: state.users.isLoading
});

const mapDispatchToProps = {
  getUsers,
  setAdmin
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(List as any));