import React, { useState, useEffect } from "react";
import ModalLogin from "./modalLogin";
import ModalContact from "./modalContact";
import ModalCreateSuccess from "./modalSuccess";
import ModalSinistro from "./modalSinistro";
import ModalPhone from "./modalPhone";
import ModalSuccessSinistro from './modalSuccessSinistro'
import ModalConfirmEndQuotation from "./modalConfirmEndQuotation";
import ModalSuccessPaymentQuotation from './modalSuccessPaymentQuotation'
import ModalEditar from "./modalEditar"
import ModalMessage from "./modalMessage"
import * as userService from "../../../state/context/user/services";
import * as formService from "../../../state/context/form/services";
import { showToast } from "../../../utils/index";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Box } from "../../../components/ds/SubatomicParticles";

function Modal({ modal, showModal, signUserIn, authUser, history, user, createSinister, callMe, editarDados, editarCredenciais }) {
  const [successContact, setSuccessContact] = useState(false)
  const [canClose, setCanClose] = useState(true);
  const [password, setPassword] = useState("")
  const { type, visible, infos, data, nome, cpfCnpj, telefoneSplit, sexo, dataNascimento, newEmail, newEmailConfirmation, newPassword, newPasswordConfirmation } = modal;
  if (user.email) { sessionStorage.setItem("email", user.email); }

  useEffect(() => {
    if (type === "modalMessage") {
      setCanClose(false)
    }
  }, [type])

  const _handleCloseModal = () => {
    setSuccessContact(false)
    showModal({ visible: false, type: "" });
  };

  const _handleLogin = async data => {
    await signUserIn({ ...data })
      .then(async user => {
        await authUser(user.uid, user.ma);
        sessionStorage.setItem("uid", user.uid);
        sessionStorage.setItem("tokenId", user.ma);
        sessionStorage.setItem("refreshToken", user.refreshToken);
        showToast("success", "Sucesso !", "Usuário logado com sucesso.");
        showModal({ visible: false, type: "" });
        history.push("/minhaconta");
      })
      .catch(err => {
        if (err.code === "auth/user-not-found" || err.code === 'auth/wrong-password') {
          showToast("warning", "Alerta", "E-mail e/ou senha inválida.");
        }
      });
  };

  const _handleRedirectUserTo = (route = "") => {
    showModal({ visible: false, type: "" });
    history.push("/" + route);
  };

  const handleRedirectUser = () => {
    showModal({ visible: false, type: "" });
    if (user && Object.keys(user).length !== 0) {
      return history.push("/minhaconta");
    }
    return history.push("/");
  }

  const _handleRedirectUserSuccess = (route = "") => {
    showModal({ visible: false, type: "" });
    history.push("/minhaconta");
  };

  const _handleEndCotacaoFlow = () => {
    showModal({ visible: false, type: "" });
  };

  const _createSinister = async (data, ddd, phone) => {
    const obj = {
      ...data,
      ddd,
      telefone: phone
    }
    await createSinister(obj).then(res => {
      showModal({ visible: true, type: "succcessSinistro" });
    }).catch(err => {
      showToast("error", "Erro", "Não foi possível acionar o sinistro. Tente novamente mais tarde.");
    })
  }

  const handleCallMe = data => {
    callMe(data)
      .then((resp) => {
        if (resp === 200) {
          return setSuccessContact(true)
        } else {
          setSuccessContact(false)
          showToast(
            "error", "Erro",
            "Não foi possível enviar os dados de contato no momento."
          );
        }
      }).catch(() => { });
  };

  const handleVerifyModalEditar = () => {
    if (
      sexo !== "" &&
      nome !== "" &&
      cpfCnpj && cpfCnpj.length === 11
    ) {
      const resultado = telefoneSplit.split(" ");
      const ddd = resultado[0]
      const telefone = resultado[1]
      const content = { nome, sexo, cpfCnpj, telefone, ddd, dataNascimento }
      editarDados({ email: user.email, password, content }).then(response => {
        if (response && response.status === 204) {
          _handleRedirectUserTo("minhaconta"); setPassword("")
        }
      })
    }

    if (
      newPassword === newPasswordConfirmation &&
      newPassword !== undefined &&
      newPasswordConfirmation !== undefined) {
      let content = { password: { newPassword, newPasswordConfirmation } }
      editarCredenciais({ email: user.email, password, content }).then(response => {
        if (response && response.status === 204) {
          _handleRedirectUserTo("minhaconta"); setPassword("")
        }
      });

    }
    if (newEmail === newEmailConfirmation
      && newEmail !== undefined
      && newEmailConfirmation !== undefined) {
      let content = { email: { newEmail, newEmailConfirmation } }
      editarCredenciais({ email: user.email, password, content }).then(response => {
        if (response && response.status === 204) { _handleRedirectUserTo("minhaconta"); setPassword("") }
      })
    }
  }

  const renderContent = () => {
    switch (type) {
      case "login":
        return (
          <ModalLogin
            callBackData={_handleLogin}
            callBackRedirectModal={_handleRedirectUserTo}
            callBackError={() => {
              showToast("warning", "Alerta", "Verifique os campos");
            }}
          />
        );
      case "contact":
        return (
          <ModalContact
            callBackData={handleCallMe}
            callBackRedirectModal={_handleRedirectUserTo}
            callBackError={() => { }}
            phone={`${user.ddd} ${user.telefone}`}
            name={user.nome}
            onSuccess={successContact}
            onClose={_handleCloseModal}
          />
        );

      case "succcessSinistro":
        return (
          <ModalSuccessSinistro
            name={user.nome}
            onRedirect={_handleRedirectUserSuccess}
          />
        );
      case "editarPerfil":
        return (
          <ModalEditar
            password={password}
            setPassword={setPassword}
            onSuccess={successContact}
            handleVerifyModalEditar={handleVerifyModalEditar}
          />
        );
        case "modalMessage":
        return (
          <ModalMessage
            title={data.title}
            message={data.message}
            onClose={_handleCloseModal}
          />
        );

      case "successPaymentQuotation":
        return (
          <ModalSuccessPaymentQuotation
            name={user.nome}
            onRedirect={_handleRedirectUserSuccess}
          />
        );

      case "confirmEndQuotation":
        return (
          <ModalConfirmEndQuotation
            name={user.nome}
            onRedirect={_handleRedirectUserSuccess}
          />
        );

      case "createSuccess":
        return (
          <ModalCreateSuccess
            name={user.nome}
            onRedirect={handleRedirectUser}
          />
        );

      case "endCotacaoFlowSuccess":
        return (
          <ModalCreateSuccess
            name={user.nome}
            onRedirect={_handleEndCotacaoFlow}
          />
        );

      case "modalSinistro":
        return (
          <ModalSinistro
            onRedirectAuto={() => {
              showModal({ visible: false, type: "" });
              history.push({
                pathname: "/sinistro",
                state: { type: "type", data: "automovel" }
              });
            }}
            onRedirectSinistro={() => {
              showModal({ visible: false, type: "" });
              history.push({
                pathname: "/sinistro",
                state: { type: "type", data: "other" }
              });
            }}
          />
        );

      case "modalPhone":
        return (
          <ModalPhone
            infos={infos}
            phone={user.ddd + ' ' + user.telefone}
            onSend={_createSinister}
          />
        );

      default:
        break;
    }
  };

  return (
    <Box
      display={visible ? 'flex' : 'none'}
      height="100vh"
      left="0"
      position="fixed"
      top="0"
      styling="row"
      width="100vw"
      zIndex="99999999"
    >
      <Box
        backgroundColor="rgba(0, 0, 0, 0.8)"
        height="100vh"
        onClick={() => {if (canClose) { _handleCloseModal() }}}
        position="fixed"
        styling="row"
        width="100vw"
      ></Box>
      {renderContent()}
    </Box>
  );
}

const mapStateToProps = state => ({
  modal: state.user.modal,
  user: state.user.user
});

const mapDispatchToProps = {
  showModal: userService.showModal,
  signUserIn: userService.signUserIn,
  authUser: userService.authUser,
  createSinister: formService.createSinister,
  callMe: formService.callMe,
  editarDados: userService.editarDados,
  editarCredenciais: userService.editarCredenciais
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Modal);
