import { SET_IMAGES, IS_LOADING, Image } from './types';

export const setImages = (images: Image[]) => ({
  type: SET_IMAGES,
  images
});

export const isLoading = (isLoading: boolean) => ({
  type: IS_LOADING,
  isLoading: isLoading,
});