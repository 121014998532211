import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import * as userService from "../../../state/context/user/services";
import { showToast } from "../../../utils/index";
import { State, Props } from "./types";
import Can from "../Can";

const PrivateRouter: React.FC<Props> = ({ component, ...rest }) => {
  const location = useLocation();
  const uuid = sessionStorage.getItem("uid");

  if (uuid) {
    return (
      <Can
        pathname={location.pathname}
        yes={() => <Route {...rest} component={component} />}
        no={() => {
          return (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          );
        }}
      />
    );
  } else {
    showToast(
      "warning",
      "Alerta",
      "Faça o Login para ter acesso a sua conta."
    );
  };

  return (
    <Redirect
      to={{
        pathname: '/',
      }}
    />
  );
}

const mapStateToProps = (state: State) => ({
  user: state.user.user
});

const mapDispatchToProps = {
  authUser: userService.authUser,
  setIsLoading: userService.setLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRouter);
