import React from "react";
import {
  validateVerify,
  autoCorrectedDatePipe,
} from "../../../utils/validations";
import { fieldNotFilledAlert, dateMask } from "../../../utils";
import { Box, Button } from "../../../components/ds/SubatomicParticles";
import { H3 } from "../../../components/ds/theme/typography";
import FormInput from "../../../components/FormInput";
import FormSelect from "../../../components/FormSelect";
import TextMaskFormInput from "../../../components/TextMaskFormInput";
import { currencyMask } from "../../../utils/masks";

function Step1({
  onComplete,
  marcaBike,
  setMarcaBike,
  dataAquisicao,
  setDataAquisicao,
  garagemBike,
  setGaragemBike,
  setBikeUtilizacao,
  bikeUtilizacao,
  valorAquisicao,
  setValorAquisicao,
  setSaberSobreCoberturas,
  saberSobreCoberturas,
}) {
  const _handleIncomplete = () => {
    if (marcaBike === "") fieldNotFilledAlert("Marca da Bike");
    if (valorAquisicao === "") fieldNotFilledAlert("Valor da aquisição");
    if (dataAquisicao === "") fieldNotFilledAlert("Data de Aquisição");
    if (garagemBike === "") fieldNotFilledAlert("Onde a Bike é guardada");
    if (bikeUtilizacao === "")
      fieldNotFilledAlert("A Bike será utilizada para");
    if (saberSobreCoberturas === "")
      fieldNotFilledAlert("Saber sibre coberturas");
  };

  const _handleVerify = () => {
    if (
      marcaBike === "" ||
      valorAquisicao === "" ||
      dataAquisicao === "" ||
      garagemBike === "" ||
      bikeUtilizacao === "" ||
      saberSobreCoberturas === ""
    ) {
      _handleIncomplete();
    } else {
      onComplete({
        marcaBike,
        valorAquisicaoBike: valorAquisicao,
        dataAquisicaoBike: dataAquisicao,
        garagemBike,
        bikeUtilizacao,
        saberSobreCoberturas
      });
    }
  };

  return (
    <>
      <Box
        gridColumn={{
          mobile: "2/span 10",
          tablet: "2/span 10",
          desktop: "4/span 6",
        }}
        styling="column"
      >
        <H3 color="primary.default" fontSize="24px" mb="52px" styling="light">
          Um pouco mais sobre sua Bike
        </H3>
        <FormInput
          placeholder="Marca da Bike"
          value={marcaBike}
          onChange={(e) => setMarcaBike(e.target.value)}
          onBlur={(e) => validateVerify(e)}
          styling="base"
        />
        <TextMaskFormInput
          value={valorAquisicao}
          onChange={(e) => {
            setValorAquisicao(e.target.value);
          }}
          mask={currencyMask}
          placeholder="Valor da Aquisição"
          onBlur={(e) => validateVerify(e)}
        />
        <TextMaskFormInput
          value={dataAquisicao}
          onChange={(e) => {
            setDataAquisicao(e.target.value);
          }}
          mask={dateMask}
          pipe={(date) => autoCorrectedDatePipe(date, false)}
          placeholder="Data de Aquisição"
          onBlur={(e) => validateVerify(e)}
        />
        <FormInput
          placeholder="Onde a bike é guardada"
          value={garagemBike}
          onChange={(e) => setGaragemBike(e.target.value)}
          onBlur={(e) => validateVerify(e)}
          styling="base"
        />

        <FormSelect
          onBlur={(e) => validateVerify(e)}
          onChange={(e) => setBikeUtilizacao(e.value)}
          options={[
            { value: "competicao", label: "Competição" },
            { value: "lazer", label: "Lazer" },
            { value: "transporte", label: "Meio de transporte" },
          ]}
          placeholder="A bike é utilizada para: "
          styling="base"
          defaultValue={bikeUtilizacao}
        />
        <FormSelect
          onBlur={(e) => validateVerify(e)}
          onChange={(e) => setSaberSobreCoberturas(e.value)}
          options={[
            { value: "sim", label: "Sim" },
            { value: "nao", label: "Não" },
          ]}
          placeholder="Quer saber sobre todas as coberturas que são possíveis? "
          styling="base"
          defaultValue={saberSobreCoberturas}
        />

        <Button
          display="block"
          margin="0 auto"
          onClick={_handleVerify}
          styling="primary"
          width="195px"
        >
          Enviar
        </Button>
      </Box>
    </>
  );
}

export default Step1;
