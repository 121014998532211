import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import InfoBorderBottomCard from "../../components/InfoBorderBottomCard";
import { Box, Button } from "../../components/ds/SubatomicParticles";
import { H3 } from "../../components/ds/theme/typography";
import Icon from "../../components/ds/atoms/Icon";
import {showModal} from "../../state/context/user/services";

import { ReactComponent as IconResidencial } from "../../assets/svg/icon_residencia.svg";
import { ReactComponent as IconPortateis } from "../../assets/svg/icon_portateis.svg";
import { ReactComponent as IconAutomovel } from "../../assets/svg/icon_auto.svg";
import { ReactComponent as IconViagem } from "../../assets/svg/icon_viagem.svg";
import { ReactComponent as IconVida } from "../../assets/svg/icon_vida.svg";
import { ReactComponent as IconPet } from "../../assets/svg/icon_pet.svg";
import { ReactComponent as IconBike } from "../../assets/svg/icon_bike.svg";
import { ReactComponent as Logo } from "../../assets/svg/logo_fruta.svg";
import { ReactComponent as Back } from "../../assets/svg/back.svg";
import InjectImages from "../Images";

const InfoMySecures = ({ location, history, images, showModal }) => {
  const [step, setStep] = useState(1);
  let type = location.state.type;
  let data = location.state.data;

  const renderHeaderStepOther = () => {
    return (
      <Box
        styling="row"
        borderBottom="1px solid #e1e1e1"
        px="3rem"
        py="1rem"
        justifyContent="flex-start"
        width="100%"
      >
        <Icon styling="base">
          <Back
            onClick={() => {
              if (step === 1) {
                history.push("/minhaconta");
              }
              setStep(step - 1);
            }}
          />
        </Icon>
        <Box styling="row" ml="15px">
          {images?.logo?.url ? (
            <img src={images.logo.url} width={24} height={24} alt="" />
          ) : (
            <Logo />
          )}
        </Box>
      </Box>
    );
  };

  const renderType = () => {
    switch (type) {
      case "automovel":
        return (
          <Box
            gridColumn={{
              mobile: "2/span 10",
              tablet: "2/span 10",
              desktop: "4/span 6",
            }}
            styling="column"
            py={"40px"}
          >
            <H3
              color="primary.default"
              fontSize="24px"
              mb="30px"
              styling="light"
            >
              Meus automóveis
            </H3>
            {data.map((e, i) => (
              <InfoBorderBottomCard
                key={`i${i}`}
                icon={<IconAutomovel />}
                title={e.itemSegurado}
                status={e.status}
                onClick={() => {
                  history.push({
                    pathname: "/info-cotacoes",
                    state: { item: e },
                  });
                }}
                onCancel={(e) => {
                  e.stopPropagation();
                  showModal({
                    visible: true,
                    type: "modalMessage",
                    data: {
                      message:
                        "Para cancelar o plano, favor entrar em contato através de nosso número 0800.941.4980",
                    },
                  })
                }}
                cancelText="Cancelar plano"
              />
            ))}
            <Button
              display="block"
              margin="20px auto 0"
              onClick={() => {
                history.push({
                  pathname: "/iframe-auto",
                  state: { type: "automovel" },
                });
              }}
              styling="primary"
              width="295px"
            >
              Cotar novo seguro
            </Button>
          </Box>
        );

      case "portateis":
        return (
          <Box
            gridColumn={{
              mobile: "2/span 10",
              tablet: "2/span 10",
              desktop: "4/span 6",
            }}
            styling="column"
            py={"40px"}
          >
            <H3
              color="primary.default"
              fontSize="24px"
              mb="30px"
              styling="light"
            >
              Meus equipamentos
            </H3>
            {data.map((e, i) => (
              <InfoBorderBottomCard
                key={`j${i}`}
                icon={<IconPortateis />}
                title={e.itemSegurado}
                status={e.status}
                onClick={() => {
                  history.push({
                    pathname: "/info-cotacoes",
                    state: { item: e },
                  });
                }}
              />
            ))}
            <Button
              display="block"
              margin="20px auto 0"
              onClick={() => {
                history.push({
                  pathname: "/form",
                  state: { type: "portateis" },
                });
              }}
              styling="primary"
              width="295px"
            >
              Cotar novo seguro
            </Button>
          </Box>
        );

      case "pets":
        return (
          <Box
            gridColumn={{
              mobile: "2/span 10",
              tablet: "2/span 10",
              desktop: "4/span 6",
            }}
            styling="column"
            py={"40px"}
          >
            <H3
              color="primary.default"
              fontSize="24px"
              mb="30px"
              styling="light"
            >
              Meus pets
            </H3>
            {data.map((e, i) => (
              <InfoBorderBottomCard
                key={`k${i}`}
                icon={<IconPet />}
                title={e.itemSegurado}
                status={e.status}
                onClick={() => {
                  history.push({
                    pathname: "/info-cotacoes",
                    state: { item: e },
                  });
                }}
              />
            ))}
            <Button
              display="block"
              margin="20px auto 0"
              onClick={() => {
                history.push({
                  pathname: "/iframe",
                  state: { type: "pets" },
                });
              }}
              styling="primary"
              width="295px"
            >
              Cotar novo seguro
            </Button>
          </Box>
        );

      case "vida":
        return (
          <Box
            gridColumn={{
              mobile: "2/span 10",
              tablet: "2/span 10",
              desktop: "4/span 6",
            }}
            styling="column"
            py={"40px"}
          >
            <H3
              color="primary.default"
              fontSize="24px"
              mb="30px"
              styling="light"
            >
              Vidas asseguradas
            </H3>
            {data.map((e, i) => (
              <InfoBorderBottomCard
                key={`l${i}`}
                icon={<IconVida />}
                title={e.itemSegurado}
                status={e.status}
                onClick={() => {
                  history.push({
                    pathname: "/info-cotacoes",
                    state: { item: e },
                  });
                }}
              />
            ))}
            <Button
              display="block"
              margin="20px auto 0"
              onClick={() => {
                history.push({
                  pathname: "/form",
                  state: { type: "vida" },
                });
              }}
              styling="primary"
              width="295px"
            >
              Cotar novo seguro
            </Button>
          </Box>
        );

      case "residencia":
        return (
          <Box
            gridColumn={{
              mobile: "2/span 10",
              tablet: "2/span 10",
              desktop: "4/span 6",
            }}
            styling="column"
            py={"40px"}
          >
            <H3
              color="primary.default"
              fontSize="24px"
              mb="30px"
              styling="light"
            >
              Meus endereços
            </H3>
            {data.map((e, i) => (
              <InfoBorderBottomCard
                key={`m${i}`}
                icon={<IconResidencial />}
                title={e.itemSegurado}
                status={e.status}
                onClick={() => {
                  history.push({
                    pathname: "/info-cotacoes",
                    state: { item: e },
                  });
                }}
                onCancel={(e) => {
                  e.stopPropagation();
                  showModal({
                    visible: true,
                    type: "modalMessage",
                    data: {
                      message:
                        "Para cancelar o plano, favor entrar em contato através de nosso número 0800.941.4980",
                    },
                  })
                }}
                cancelText="Cancelar plano"
              />
            ))}
            <Button
              display="block"
              margin="20px auto 0"
              onClick={() => {
                history.push({
                  pathname: "/iframe",
                  state: { type: "residencial" },
                });
              }}
              styling="primary"
              width="295px"
            >
              Cotar novo seguro
            </Button>
          </Box>
        );

      case "viagem":
        return (
          <Box
            gridColumn={{
              mobile: "2/span 10",
              tablet: "2/span 10",
              desktop: "4/span 6",
            }}
            styling="column"
            py={"40px"}
          >
            <H3
              color="primary.default"
              fontSize="24px"
              mb="30px"
              styling="light"
            >
              Minhas viagens
            </H3>
            {data.map((e, i) => (
              <InfoBorderBottomCard
                key={`n${i}`}
                icon={<IconViagem />}
                title={e.itemSegurado}
                status={e.status}
                onClick={() => {
                  history.push({
                    pathname: "/info-cotacoes",
                    state: { item: e },
                  });
                }}
              />
            ))}
            <Button
              display="block"
              margin="20px auto 0"
              onClick={() => {
                history.push({
                  pathname: "/form",
                  state: { type: "viagem" },
                });
              }}
              styling="primary"
              width="295px"
            >
              Cotar novo seguro
            </Button>
          </Box>
        );
      case "bike":
        return (
          <Box
            gridColumn={{
              mobile: "2/span 10",
              tablet: "2/span 10",
              desktop: "4/span 6",
            }}
            styling="column"
            py={"40px"}
          >
            <H3
              color="primary.default"
              fontSize="24px"
              mb="30px"
              styling="light"
            >
              Minhas viagens
            </H3>
            {data.map((e, i) => (
              <InfoBorderBottomCard
                key={`n${i}`}
                icon={<IconBike />}
                title={e.itemSegurado}
                status={e.status}
                onClick={() => {
                  history.push({
                    pathname: "/info-cotacoes",
                    state: { item: e },
                  });
                }}
              />
            ))}
            <Button
              display="block"
              margin="20px auto 0"
              onClick={() => {
                history.push({
                  pathname: "/form",
                  state: { type: "viagem" },
                });
              }}
              styling="primary"
              width="295px"
            >
              Cotar novo seguro
            </Button>
          </Box>
        );
      default:
        break;
    }
  };

  return (
    <Box
      alignItems="flex-start"
      minHeight="100vh"
      justifyContent="flex-start"
      styling="column"
      width="100%"
    >
      {renderHeaderStepOther()}
      <Box
        gridRowGap="1.5rem"
        gridTemplateColumns="repeat(12, 1fr)"
        minHeight="100vh"
        position="relative"
        styling="grid"
        width="100%"
      >
        {renderType()}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
  showModal: showModal,
};

export default compose(
  withRouter,
  connect (mapStateToProps, mapDispatchToProps),
)(InjectImages(InfoMySecures));
