import * as Yup from 'yup';

export const Schema = Yup.object().shape({
  name: Yup.string().required("O Cliente é obrigatório."),
  domain: Yup.string().required("O Domínio é obrigatório."),
});


export const SchemaDrawer = Yup.object().shape({
  Modalidade: Yup.string().required("O Modalidade é obrigatório."),
  'Grupo Evento': Yup.string().required("O Grupo Evento é obrigatório."),
  'Tipo Evento': Yup.string().required("O Tipo Evento é obrigatório."),
  Midia: Yup.string().required("O Midia é obrigatório."),
  'Grupo Hierarquico': Yup.string().required("O Grupo Hierarquico é obrigatório."),
});
