import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import * as userService from "../../state/context/user/services";
import { Box, Button } from "../../components/ds/SubatomicParticles";
import {  H3, P } from "../../components/ds/theme/typography";

function Verify({ history, showModal, location }) {
  return (
    <Box
      gridTemplateColumns="repeat(12,1fr)"
      gridRowGap="1.5rem"
      minHeight="100vh"
      styling="grid"
    >
      <Box gridColumn={{ mobile: '2/span 10', tablet: '2/span 10', desktop: '4/span 6' }} styling="column">
      <H3 my="30px" styling="light">Você deseja entrar com seu usuário para fazer a cotação?</H3>
        <P px={{ mobile: '0px', tablet: '0px', desktop: '150px' }} styling="base" textAlign="center">
          Ganhe tempo e agilidade no preenchimento das propostas de seguro, não pediremos novamente os dados que você já informou.
          </P>
        <Box mt="48px" styling="row">
          <Button mr="30px" onClick={() => { history.push({ pathname: "/form", state: { type: location.state.type } }) }} styling="primary" width="132px">Não</Button>
          <Button onClick={() => { showModal({ visible: true, type: "login" }) }} styling="primary" width="132px">Sim</Button>
        </Box>
      </Box>
    </Box>
  );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  showModal: userService.showModal
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Verify);
