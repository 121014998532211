import React, { useState } from "react";
import { Box, Button } from "../../../../components/ds/SubatomicParticles";
import { H3, P } from "../../../../components/ds/theme/typography";
import FormInput from "../../../../components/FormInput";
import { showToast } from "../../../../utils"

const ModalEditar = ({
  onSuccess,
  password,
  setPassword,
  handleVerifyModalEditar
}) => {
  const [isPhoneOpen, setIsPhoneOpen] = useState(false);

  function handlePasswordModal() {
    if (password && password.length > 5) handleVerifyModalEditar()
    else showToast("warning", "Alerta", "Confirmação de senha não preenchida ou senha inválida.")
  }

  return onSuccess ? (
    <Box styling="row"></Box>
  ) : (
    <Box
      backgroundColor="background.default"
      borderRadius="12px"
      maxWidth="415px"
      padding="2rem"
      position="fixed"
      styling="cloumn"
      width="90%"
      zIndex="2"
    >
      <H3 mb="15px" styling="light">
        Para sua segurança, precisamos que você confirme sua senha
      </H3>
      {!isPhoneOpen && (
        <FormInput
          {...password}
          placeholder="Senha"
          onChange={e => setPassword(e.target.value)}
          type="password"
        />
      )}
      <Button display="block" margin="15px auto 0" onClick={handlePasswordModal} styling="primary" width="90%">Enviar</Button>
      <P styling="base" onClick={() => setIsPhoneOpen(!isPhoneOpen)}></P>
    </Box>
  )
};

export default ModalEditar;
