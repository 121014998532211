import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import * as userService from "../../state/context/user/services";
import { IconsMap } from "../../components/iconHome/iconhome";
import { Box, Button } from "../../components/ds/SubatomicParticles";
import { H1, H2, P } from "../../components/ds/theme/typography";
import Link from "../../components/ds/atoms/Link";
import Icon from "../../components/ds/atoms/Icon";
import BgPortateis from "../../assets/images/bg_portateis.png";
import { ReactComponent as Circle } from "../../assets/svg/circle.svg";
import { ReactComponent as DotsRight } from "../../assets/svg/dots_left.svg";
import { ReactComponent as Dotsleft } from "../../assets/svg/dots_right.svg";
import { ReactComponent as IconBrokenCel } from "../../assets/svg/icon_broken_cel.svg";
import { ReactComponent as IconCel } from "../../assets/svg/icon_cel.svg";
import { ReactComponent as IconEarth } from "../../assets/svg/icon_earth.svg";
import { ReactComponent as IconPrice } from "../../assets/svg/icon_price.svg";
import { ReactComponent as IconSecurityCel } from "../../assets/svg/icon_security_cel.svg";
import { ReactComponent as IconSteal } from "../../assets/svg/icon_steal.svg";
import { ReactComponent as IconWater } from "../../assets/svg/icon_water.svg";
import { ReactComponent as PesegoCare } from "../../assets/svg/pessego-care.svg";
import InjectImages from "../Images";
import PreImg from "../../components/PreImg";

function Portateis({ history, showModal, user, images }) {
  return (
    <>
      <Box
        gridTemplateColumns="repeat(12,1fr)"
        gridRowGap="1.5rem"
        paddingY={{ mobile: "px", tablet: "0px", desktop: "3rem" }}
        position="relative"
        styling="grid"
      >
        <Box
          alignItems="flex-start"
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "2/span 4",
          }}
          styling="column"
        >
          <H1 styling="base">A pessego.com quer cuidar do seu portátil também!</H1>
          <Box
            styling="base"
            height="2px"
            width="4vw"
            mt="2rem"
            backgroundColor="primary.default"
          ></Box>
        </Box>

        <Box
          alignItems="flex-start"
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "2/span 4",
          }}
          gridRow="2"
          styling="column"
        >
          <P lineHeight="1.4" textAlign="justify" styling="base">
            Você pode proteger seu smartphone, notebook, tablet, câmeras,
            smartwatch e até equipamentos áudio visuais locados. Cada aparelho
            tem opções específicas de cobertura e estas podem ser adaptadas de
            acordo com a sua rotina e necessidades, evitando prejuízos e
            garantindo indenizações.
          </P>
        </Box>

        <Box
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "2/span 4",
          }}
          gridRow="3"
          my="2rem"
          styling="column"
        >
          <Button
            onClick={() => {
              history.push({
                pathname: "/form",
                state: { type: "portateis" },
              })
            }}
            styling="primary"
            width="350px"
            height="50px"
          >
            <div style={{ fontSize: 18 }}>Cotar agora seu seguro portáteis</div>
          </Button>
          {user && (user.telefone || user.telefoneCelular) && (
            <Button
              my="10px"
              onClick={() => showModal({ visible: true, type: "contact" })}
              styling="primary"
              width="295px"
            >
              Quero falar com alguém, me ligue!
            </Button>
          )}
        </Box>

        <Box
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          gridColumn="5/span 8"
          gridRow="2/span 4"
          height="210px"
          mt="150px"
          styling="column"
        >
          <Icon styling="baseNone" height="100%" width="100%">
            <DotsRight width="100%" height="100%" />
          </Icon>
        </Box>

        <Box
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          gridColumn="8/span 4"
          gridRow="1/span 4"
          ml="30px"
          styling="column"
        >
          <img alt="" height="100%" src={BgPortateis} width="100%" />
        </Box>

        <Box
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          gridColumn="7/span 8"
          gridRow="1/span 4"
          position="absolute"
          styling="column"
          top="50px"
        >
          <Circle />
        </Box>

        <Box
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          gridColumn="10/span 2"
          gridRow="2/span 4"
          position="absolute"
          styling="column"
          top="145px"
          zIndex="-1"
        >
          <Circle />
        </Box>

        <Box
          alignItems="stretch"
          backgroundColor="background.default"
          borderRadius="12px"
          boxShadow="10px 10px 20px rgba(0,0,0,.05)"
          flexWrap="wrap"
          gridColumn="2/span 10"
          gridRow="4"
          justifyContent="space-between"
          margin={{ mobile: "2rem 0", tablet: "2rem 0", desktop: "0 0 5rem" }}
          position="relative"
          styling="row"
          width="100%"
        >
          <Box
            padding="32px"
            styling="column"
            width={{ mobile: "50%", tablet: "33%", desktop: "25%" }}
          >
            <Box
              backgroundColor="background.default"
              borderRadius="50%"
              height="128px"
              styling="column"
              width="128px"
            >
              <Icon styling="base" height="48px" width="48px">
                <IconCel />
              </Icon>
            </Box>
            <P fontSize="14px" my="15px" styling="bold" textAlign="center">
              Danos físicos
            </P>
          </Box>
          <Box
            padding="32px"
            styling="column"
            width={{ mobile: "50%", tablet: "33%", desktop: "25%" }}
          >
            <Box
              backgroundColor="background.default"
              borderRadius="50%"
              height="128px"
              styling="column"
              width="128px"
            >
              <Icon styling="base" height="48px" width="48px">
                <IconBrokenCel />
              </Icon>
            </Box>
            <P fontSize="14px" my="15px" styling="bold" textAlign="center">
              Danos elétricos
            </P>
          </Box>
          <Box
            padding="32px"
            styling="column"
            width={{ mobile: "50%", tablet: "33%", desktop: "25%" }}
          >
            <Box
              backgroundColor="background.default"
              borderRadius="50%"
              height="128px"
              styling="column"
              width="128px"
            >
              <Icon styling="base" height="48px" width="48px">
                <IconEarth />
              </Icon>
            </Box>
            <P fontSize="14px" my="15px" styling="bold" textAlign="center">
              Proteção internacional
            </P>
          </Box>
          <Box
            padding="32px"
            styling="column"
            width={{ mobile: "50%", tablet: "33%", desktop: "25%" }}
          >
            <Box
              backgroundColor="background.default"
              borderRadius="50%"
              height="128px"
              styling="column"
              width="128px"
            >
              <Icon styling="base" height="48px" width="48px">
                <IconPrice />
              </Icon>
            </Box>
            <P fontSize="14px" my="15px" styling="bold" textAlign="center">
              Garantia estendida
            </P>
          </Box>
          <Box
            padding="32px"
            styling="column"
            width={{ mobile: "50%", tablet: "33%", desktop: "25%" }}
          >
            <Box
              backgroundColor="background.default"
              borderRadius="50%"
              height="128px"
              styling="column"
              width="128px"
            >
              <Icon styling="base" height="48px" width="48px">
                <IconSteal />
              </Icon>
            </Box>
            <P fontSize="14px" my="15px" styling="bold" textAlign="center">
              Roubo
            </P>
          </Box>
          <Box
            padding="32px"
            styling="column"
            width={{ mobile: "50%", tablet: "33%", desktop: "25%" }}
          >
            <Box
              backgroundColor="background.default"
              borderRadius="50%"
              height="128px"
              styling="column"
              width="128px"
            >
              <Icon styling="base" height="48px" width="48px">
                <IconWater />
              </Icon>
            </Box>
            <P fontSize="14px" my="15px" styling="bold" textAlign="center">
              Danos por água
            </P>
          </Box>
          <Box
            padding="32px"
            styling="column"
            width={{ mobile: "50%", tablet: "33%", desktop: "25%" }}
          >
            <Box
              backgroundColor="background.default"
              borderRadius="50%"
              height="128px"
              styling="column"
              width="128px"
            >
              <Icon styling="base" height="48px" width="48px">
                <IconSecurityCel />
              </Icon>
            </Box>
            <P fontSize="14px" my="15px" styling="bold" textAlign="center">
              Equipamentos locados*
            </P>
          </Box>

          <Box mb="-5rem" postion="absolute" styling="row" width="100%">
            <P fontSize="14px" styling="base">
              * Garante reembolso ao segurado ou pagamento do valor do aluguel
              ao proprietário do equipamento para danos devidamente cobertos.
            </P>
          </Box>
        </Box>

        <Box
          alignItems="flex-start"
          gridColumn="2/span 10"
          gridRow="5"
          styling="column"
        >
          <H2 styling="base">A pessego.com tranquiliza você e cuida da sua viagem.</H2>
        </Box>

        <Box
          alignItems="flex-start"
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "2/span 6",
          }}
          gridRow="6"
          styling="column"
        >
          <P my="2rem" styling="regular">
            Não sabe exatamente o que procura?
          </P>
          <P styling="base">
            Não tem problema! Nossos consultores são especialistas em seguro e
            vão ajudar você a encontrar exatamente o que você precisa e do jeito
            que você preferir: por telefone, e-mail ou chat.
          </P>
          <P my="2rem" styling="regular">
            Preparamos uma lista de dúvidas mais frequentes pra você.
          </P>
          <P display="block" styling="base">
            Aqui está tudo bem explicadinho, mas se preferir conversar com um de
            nossos promotores é só clicar no botão “
            <Link color="primary.default" to="/contato">
              fale conosco
            </Link>
            ”, ok?
          </P>
        </Box>

        <Box
          backgroundColor="background.default"
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          borderRadius="50%"
          gridColumn="10/span 2"
          gridRow="6"
          height="256px"
          ml="16px"
          styling="column"
          width="256px"
        >
          {images?.generic1?.url ? (
            <PreImg
              src={images.generic1.url}
              style={{
                width: "120px",
                height: "114px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                styling="row"
                width="80%"
                height="80%"
                borderRadius="4px"
                backgroundColor="#ededed"
              />
            </PreImg>
          ) : (
            <PesegoCare height="120px" width="114px" />
          )}
        </Box>

        <Box
          alignItems="flex-start"
          gridColumn="1/span 8"
          gridRow="7"
          height="210px"
          my="30px"
          styling="column"
        >
          <Icon styling="baseNone" height="100%" width="70%">
            <Dotsleft width="100%" height="100%" />
          </Icon>
        </Box>

        <Box gridColumn="2/span 10" gridRow="8" styling="column">
          <H2 mb="2rem" styling="base">Outros seguros</H2>
          <Box
            gridGap="1rem"
            gridTemplateColumns={{
              mobile: "repeat(2,1fr)",
              tablet: "repeat(3,1fr)",
              desktop: "repeat(7,1fr)",
            }}
            styling="grid"
            width="100%"
          >
            <IconsMap history={history} />
          </Box>
        </Box>
      </Box>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = {
  showModal: userService.showModal,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(InjectImages(Portateis));
