import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { PreImgProps, PreImgStyledProps } from "./index";

const PreImg: React.FC<PreImgProps> = ({ style, src, ...props }) => {
  const [loaded, setLoaded] = useState(false);
  const [iSrc, setISrc] = useState("");

  const preLoader = new Image();

  useEffect(() => {
    const initPreLoader = () => {
      preLoader.onload = () => {
        setLoaded(true);
        setISrc(`url(${src})`);
      };
      preLoader.onerror = (e) => {
        console.log(e, "Não foi possivel carregar a imagem");
      };

      preLoader.src = src;
    };
    initPreLoader();
    return () => {
      preLoader.onload = null;
      preLoader.onerror = null;
    };
  });

  return (
    <div style={style} {...props}>
      {!loaded && props.children}
        <div
          style={{
            height: loaded ? "100%" : 0,
            width: loaded ? "100%" : 0,
            backgroundImage: iSrc,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            transition: `opacity ${"300ms"} ${"cubic-bezier(0.215, 0.61, 0.355, 1)"}`,
            opacity: loaded ? 1 : 0,
          }}
        />
    </div>
  );
};

export default styled(PreImg) <PreImgStyledProps>``;
