import { get, post } from "axios";
import headers from "../../../utils/mountFirebaseHeaders";

const { REACT_APP_FIREBASE_FUNCTIONS_URL } = process.env;

const PET_SEARCH_RACES_GET_URL = (type) =>
  `${REACT_APP_FIREBASE_FUNCTIONS_URL}/petSearchRacesGET?tipoPet=${type}`;
const TRIP_SEARCH_TRANSPORT_METHOD_GET_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/tripSearchTransportMethodsGET`;
const TRIP_SEARCH_CONTINENTS_GET_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/tripSearchContinentsGET`;
const PET_GENERATE_LEAD_POST_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/petGenerateLeadPOST`;
const RESIDENCE_GENERATE_LEAD_POST_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/residenceGenerateLeadPOSTV2`;
const TRIP_GENERATE_LEAD_POST_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/tripGenerateLeadPOST`;
const LIFE_GENERATE_LEAD_POST_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/lifeGenerateLeadPOST`;
const BIKE_GENERATE_LEAD_POST_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/bikeGenerateLeadPOST`;
const GADGETS_GENERATE_LEAD_POST_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/gadgetsGenerateLeadPOST`;
const CALL_ME_POST_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/callMePOST`;
const BUY_RESIDENCE_INSURANCE_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/buyResidenceInsurancePOST`;
const BUY_VEHICLE_INSURANCE_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/buyVehicleInsurancePOST`;
const GADGETS_SEARCH_TYPES_GET_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/gadgetsSearchTypesGET`;
const GADGETS_SEARCH_BRANDS_GET_URL = (type) =>
  `${REACT_APP_FIREBASE_FUNCTIONS_URL}/gadgetsSearchBrandsGET?tipo=${type}`;
const GADGETS_SEARCH_MODELS_GET_URL = (type, brand) =>
  `${REACT_APP_FIREBASE_FUNCTIONS_URL}/gadgetsSearchModelsGET?tipo=${type}&marca=${brand}`;
const VEHICLES_SEARCH_BRAND_GET_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/vehiclesSearchBrandsGET`;
const VEHICLES_SEARCH_MODELS_GET_URL = (brand) =>
  `${REACT_APP_FIREBASE_FUNCTIONS_URL}/vehiclesSearchModelsGET?marca=${brand}`;
const VEHICLE_GENERATE_LEAD_POST_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/vehicleGenerateLeadPOSTV2`;
const SEND_CONTACT_MESSAGE = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/sendMessage`;
const INSURANCE_CLAIM_POST_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/insuranceClaimPOST`;
const NEW_LEAD_POST_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/newLead`;
const UPDATE_LEAD_POST_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/updateLead`;
const GET_VEHICLE_QUESTIONNAIRE_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/vehicleQuestionarioGET`;
const GET_RESIDENCE_COVERAGES = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/residenceConstantsCoveragesGET`;
const GET_AUTO_INSURANCE_TYPES = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/libertyAutoInsuranceTypeGET`;
const GET_AUTO_COVERAGES = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/vehicleConstantsCoveragesGET`;
const GET_VEHICLE_CONSTANTS_URL = (type) =>
  `${REACT_APP_FIREBASE_FUNCTIONS_URL}/vehicleConstantsGET?constantKey=${type}`;
const GET_RESIDENCE_CONSTANTS = (type) =>
  `${REACT_APP_FIREBASE_FUNCTIONS_URL}/residenceConstantsGET?constant=${type}`;
const VEHICLE_INFO_SEARCH_GET_URL = (type, value) =>
  `${REACT_APP_FIREBASE_FUNCTIONS_URL}/getVehicleByPlateOrChassis?type=${type}&value=${value}`;
const OPEN_VEHICLE_QUOTATION_POST_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/openVehicleQuotation`;
const OPEN_RESIDENCE_QUOTATION_POST_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/openResidenceQuotation`;
const OPEN_GADGETS_QUOTATION_POST_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/openGadgetsQuotation`;
const OPEN_TRAVEL_QUOTATION_POST_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/openTravelQuotation`;
const OPEN_BIKE_QUOTATION_POST_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/openBikeQuotation`;
const OPEN_PET_QUOTATION_POST_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/openPetQuotation`;
const OPEN_LIFE_QUOTATION_POST_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/openLifeQuotation`;

export const getListRaces = async (type) =>
  get(PET_SEARCH_RACES_GET_URL(type), { headers: await headers() });

export const getResidenceConstants = async (type) =>
  get(GET_RESIDENCE_CONSTANTS(type), { headers: await headers() });

export const getListFormasDeViagem = async () =>
  get(TRIP_SEARCH_TRANSPORT_METHOD_GET_URL, { headers: await headers() });

export const getListContinent = async () =>
  get(TRIP_SEARCH_CONTINENTS_GET_URL, { headers: await headers() });

export const getResidenceCoverages = async () =>
  get(GET_RESIDENCE_COVERAGES, { headers: await headers() });

export const setPets = async (body) => {
  const { status } = await post(PET_GENERATE_LEAD_POST_URL, body, {
    headers: await headers(),
  });
  return status;
};

export const setResidence = async (body) =>
  post(RESIDENCE_GENERATE_LEAD_POST_URL, body, { headers: await headers() });

export const buyResidenceInsurance = async (body) =>
  post(BUY_RESIDENCE_INSURANCE_URL, body, { headers: await headers() });
export const buyVehicleInsurance = async (body) =>
  post(BUY_VEHICLE_INSURANCE_URL, body, { headers: await headers() });

export const createViagem = async (body) => {
  const { status } = await post(TRIP_GENERATE_LEAD_POST_URL, body, {
    headers: await headers(),
  });
  return status;
};

export const createVida = async (body) => {
  const { status } = await post(LIFE_GENERATE_LEAD_POST_URL, body, {
    headers: await headers(),
  });
  return status;
};
export const createBike = async (body) => {
  const { status } = await post(BIKE_GENERATE_LEAD_POST_URL, body, {
    headers: await headers(),
  });
  return status;
};

export const createPortateis = async (body) => {
  const { status } = await post(GADGETS_GENERATE_LEAD_POST_URL, body, {
    headers: await headers(),
  });
  return status;
};

export const callMePost = async (body) => {
  const { status } = await post(CALL_ME_POST_URL, body, {
    headers: await headers(),
  });
  return status;
};

export const getListTiposPortateis = async () =>
  get(GADGETS_SEARCH_TYPES_GET_URL, { headers: await headers() }).then(
    ({ data }) => data,
  );

export const getListMarcasPortateis = async (type) =>
  get(GADGETS_SEARCH_BRANDS_GET_URL(type), { headers: await headers() }).then(
    ({ data }) => data,
  );

export const getListModeloPortateis = async (type, brand) =>
  get(GADGETS_SEARCH_MODELS_GET_URL(type, brand), {
    headers: await headers(),
  }).then(({ data }) => data);

export const getListMarcasAuto = async () =>
  get(VEHICLES_SEARCH_BRAND_GET_URL, { headers: await headers() }).then(
    ({ data }) => data,
  );

export const getListModeloAuto = async (brand) =>
  get(VEHICLES_SEARCH_MODELS_GET_URL(brand), { headers: await headers() }).then(
    ({ data }) => data,
  );

export const createAuto = async (body) =>
  post(VEHICLE_GENERATE_LEAD_POST_URL, body, { headers: await headers() });

export const createSinister = async (body) =>
  post(INSURANCE_CLAIM_POST_URL, body, { headers: await headers() });

export const getVehicleConstants = async (type) =>
  get(GET_VEHICLE_CONSTANTS_URL(type), { headers: await headers() }).then(
    ({ data }) => data,
  );

export const getVehicleConstantsWithNoData = async (questionnaireType) =>
  get(GET_VEHICLE_CONSTANTS_URL(questionnaireType), {
    headers: await headers(),
  });

export const getVehicleQuestionnaireStep1 = async (questionnaireTypes) => {
  let arrPromise = [];

  questionnaireTypes.forEach((questionnaireType) => {
    arrPromise.push(getVehicleConstantsWithNoData(questionnaireType));
  });
  return Promise.all(arrPromise)
    .then((questionnaireStep1Result) => {
      let objToReturn = {};
      questionnaireTypes.forEach((questionnaireType, key) => {
        objToReturn[questionnaireType] = questionnaireStep1Result[key].data;
      });
      return objToReturn;
    })
    .catch((error) => {
      throw error;
    });
};

export const getVehicleQuestionnaireStep3 = async () =>
  get(GET_VEHICLE_QUESTIONNAIRE_URL, { headers: await headers() });

export const getAutoInsuranceTypes = async () =>
  get(GET_AUTO_INSURANCE_TYPES, { headers: await headers() });

export const getVehiclesCoverages = async () =>
  get(GET_AUTO_COVERAGES, { headers: await headers() });

export const newLead = async (body) =>
  post(NEW_LEAD_POST_URL, body, { headers: await headers() });

export const updateLead = async (body) =>
  post(UPDATE_LEAD_POST_URL, body, { headers: await headers() });

export const sendContactMessage = (body) =>
  post(SEND_CONTACT_MESSAGE, body, {
    headers: {
      midia: "1",
    },
  });

export const getVehicleByPlateOrChassis = async (type, value) => 
  get(VEHICLE_INFO_SEARCH_GET_URL(type, value), { headers: await headers() })

export const openVehicleQuotation = async (body) =>
  post(OPEN_VEHICLE_QUOTATION_POST_URL, body, { 
    headers: {
      "Content-Type": "application/json",
      midia: "1"
    }, 
  });

export const openResidenceQuotation = async (body) =>
  post(OPEN_RESIDENCE_QUOTATION_POST_URL, body,  { 
    headers: {
      "Content-Type": "application/json",
      midia: "1"
    }, 
  });

export const openGadgetsQuotation = async (body) =>
  post(OPEN_GADGETS_QUOTATION_POST_URL, body,  { 
      headers: {
        "Content-Type": "application/json",
        midia: "1"
      }, 
  });

export const openTravelQuotation = async (body) =>
  post(OPEN_TRAVEL_QUOTATION_POST_URL, body,  { 
      headers: {
        "Content-Type": "application/json",
        midia: "1"
      }, 
  });

export const openBikeQuotation = async (body) =>
  post(OPEN_BIKE_QUOTATION_POST_URL, body,  { 
      headers: {
        "Content-Type": "application/json",
        midia: "1"
      }, 
  });

export const openPetQuotation = async (body) =>
  post(OPEN_PET_QUOTATION_POST_URL, body,  { 
      headers: {
        "Content-Type": "application/json",
        midia: "1"
      }, 
  });
  
export const openLifeQuotation = async (body) =>
  post(OPEN_LIFE_QUOTATION_POST_URL, body,  { 
      headers: {
        "Content-Type": "application/json",
        midia: "1"
      }, 
  });
  