import React from "react";
import moment from "moment";
import { validateVerify, checkLengthOfDate, autoCorrectedDatePipe } from "../../../utils/validations";
import { showToast, fieldNotFilledAlert, dateMask } from "../../../utils";
import { Box, Button } from "../../../components/ds/SubatomicParticles";
import { H3 } from "../../../components/ds/theme/typography";
import FormSelect from "../../../components/FormSelect";
import TextMaskFormInput from "../../../components/TextMaskFormInput";

export default function ValidityDate({
  onNext,
  onIncomplete,
  startDateResidence,
  setStartDateResidence,
  endDateResidence,
  setEndDateResidence,
  ProfessionalActivityType,
  setProfessionalActivityType,
}) {
  const _handleVerify = () => {
    if (ProfessionalActivityType !== null && startDateResidence && checkLengthOfDate(startDateResidence) === 8 && endDateResidence && checkLengthOfDate(endDateResidence) === 8) {
      const ProfessionalActivityTypeString = ProfessionalActivityType.toString()
      onNext({
        StartDate: moment(startDateResidence, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        EndDate: moment(endDateResidence, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        ResidenceHasProfessionalActivity: ProfessionalActivityTypeString !== '0' ? 'T' : 'F',
        ProfessionalActivityType: ProfessionalActivityTypeString,
      })
    } else {
      _handleIncomplete()
    }
  };

  const _handleIncomplete = () => {
    if (!startDateResidence || checkLengthOfDate(startDateResidence) !== 8) fieldNotFilledAlert('Data de Ida');
    if (!endDateResidence || checkLengthOfDate(endDateResidence) !== 8) fieldNotFilledAlert('Data de volta');
  }

  const checkStartDateIsGTOrETEndDate = (startDate, endDate) =>
    moment(startDate, 'DD/MM/YYYY').format('YYYYMMDD') > moment(endDate, 'DD/MM/YYYY').format('YYYYMMDD')

  function handleDateValidation(ida, volta) {
    if (checkStartDateIsGTOrETEndDate(ida, volta)) {
      showToast("warning", "Alerta", `A data da ida não pode ser maior que a data do retorno.`)
      return setEndDateResidence("")
    }
  }

  const handleValidationOnRange = date => {
    if (moment(startDateResidence, 'DD/MM/YYYY').add('day', 365).format('YYYYMMDD') > moment(date, 'DD/MM/YYYY').format('YYYYMMDD')) {
      showToast("warning", "Alerta", 'Período minimo de vigência: 1 ano.')
      return setEndDateResidence("")
    }
  }

  return (
    <>
      <Box
        gridColumn={{
          mobile: "2/span 10",
          tablet: "2/span 10",
          desktop: "4/span 6",
        }}
        styling="column"
      >
        <H3 mb="30px" styling="light">Informações Gerais</H3>
        <Box justifyContent="space-between" styling="row" width="100%">
          <Box styling="row" width="48%">
            <TextMaskFormInput
              data-testid="startDateResidence"
              id="startDateResidence"
              mask={dateMask}
              name="startDateResidence"
              onBlur={e => validateVerify(e)}
              onChange={date => {
                setStartDateResidence(date.target.value)
                setEndDateResidence(moment(date.target.value, 'DD/MM/YYYY').add('year', 1).format('DD/MM/YYYY'))
              }}
              placeholder="Início da vigência"
              type="text"
              value={startDateResidence}
              pipe={date => autoCorrectedDatePipe(date, true, false)}
            />
          </Box>
          <Box styling="row" width="48%">
            <TextMaskFormInput
              data-testid="endDateResidence"
              id="endDateResidence"
              mask={dateMask}
              name="endDateResidence"
              onBlur={e => validateVerify(e) || handleDateValidation(startDateResidence, endDateResidence) || handleValidationOnRange(e.target.value)}
              onChange={date => {
                setEndDateResidence(date.target.value)
              }}
              placeholder="Fim da vigência"
              type="text"
              value={endDateResidence}
              pipe={date => autoCorrectedDatePipe(date, true, false)}
            />
          </Box>
        </Box>
        <FormSelect
          data-testid="ProfessionalActivityType"
          id="ProfessionalActivityType"
          name="ProfessionalActivityType"
          onBlur={e => validateVerify(e)}
          onChange={(e) => setProfessionalActivityType(e.value)}
          options={[
            { value: 0, label: 'Nenhum' },
            { value: 1, label: 'Escritório' },
            { value: 2, label: 'Costura exceto Confecções' },
            { value: 3, label: 'Outros' },
          ]}
          placeholder="Exerce atividade profissional no Imóvel"
          styling="base"
          defaultValue={ProfessionalActivityType}
        />
        <Button display="block" margin="0 auto" onClick={_handleVerify} styling="primary" width="195px">Próximo</Button>
      </Box>
    </>
  );
}
