import React from "react";
import { Formik, Form, Field } from 'formik';
import * as Yup from "yup";
import { Box, Button } from "../../components/ds/SubatomicParticles";
import { H2 } from "../../components/ds/theme/typography";
import FormInput from "../../components/FormInput";

import { ReactComponent as Circle } from "../../assets/svg/circle.svg";

export const Login = ({ submitLogin }) => (
  <>
    <Box
      alignItems="flex-start"
      gridColumn={{ mobile: '2/span 10', tablet: '2/span 10', desktop: '5/span 4' }}
      gridRow="2"
      gridRowGap="1.5rem"
      styling="column"
    >
      <H2 styling="base">Acesse a área de parceiros</H2>
      <Box styling="base" height="2px" width="4vw" mt="2rem" backgroundColor="primary.default"></Box>
    </Box>

    <Box
      display={{ mobile: 'none', tablet: 'none', desktop: 'flex' }}
      gridColumn="7/span 8"
      gridRow="2/span 4"
      position="absolute"
      styling="column"
      top="50px"
    >
      <Circle />
    </Box>

    <Box
      display={{ mobile: 'none', tablet: 'none', desktop: 'flex' }}
      gridColumn="10/span 2"
      gridRow="3/span 4"
      position="absolute"
      styling="column"
      top="145px"
      zIndex="-1"
    >
      <Circle />
    </Box>

    <Box
      alignItems="stretch"
      backgroundColor="background.default"
      borderRadius="12px"
      boxShadow="10px 10px 20px rgba(0,0,0,.05)"
      flexWrap="wrap"
      gridColumn={{ mobile: '2/span 10', tablet: '2/span 10', desktop: '5/span 4' }}
      gridRow="5"
      padding="3rem"
      position="relative"
      styling="column"
    >
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().required("Preencha o login."),
          password: Yup.string().required("Preencha a senha."),
        })}
        onSubmit={submitLogin}
        render={({ errors, touched }) => (
          <Form translate="no" style={{ width: '100%' }}>
            <Field name="email">
              {({ field }) => (
                <FormInput
                  {...field}
                  data-testid="email"
                  name="email"
                  placeholder="Login"
                  type="text"
                  error={touched.email && errors.email}
                  maxLength={50}
                />
              )}
            </Field>
            <Field name="password">
              {({ field }) => (
                <FormInput
                  {...field}
                  data-testid="password"
                  name="password"
                  placeholder="Senha"
                  type="password"
                  error={touched.password && errors.password}
                  maxLength={50}
                />
              )}
            </Field>
            <Button display="block" margin="0 auto" styling="primary" type="submit" width="295px">Entrar</Button>
          </Form>
        )}
      />
    </Box>
  </>
);