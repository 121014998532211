import { Dispatch } from 'redux';
import * as repository from './repository';
import { setThemes, isLoading } from './actions';
import { Theme } from './types';
import { showToast } from '../../../utils';

export const getThemes = () => async (dispatch: Dispatch) => {
  try {
    dispatch(isLoading(true));
    const response = await repository.getThemes();
    dispatch(setThemes(response));
    dispatch(isLoading(false));
  } catch (error) {
    showToast(
      "error",
      "Ops :(",
      "Algo deu errado"
    );
    dispatch(isLoading(true));
  };
};

export const getTheme = (themeId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(isLoading(true));
    const response = await repository.getTheme(themeId);
    dispatch(isLoading(false));
    return response;
  } catch (error) {
    showToast(
      "error",
      "Ops :(",
      "Algo deu errado"
    );
    dispatch(isLoading(true));
  }
};

export const editTheme = (themeId: string, theme: Theme) => async (dispatch: Dispatch) => {
  try {
    dispatch(isLoading(true));
    const response = await repository.editTheme(themeId, theme);
    showToast(
      "success",
      "Sucesso :)",
      "O registrado foi atualizado !"
    );
    dispatch(isLoading(false));
    return response;
  } catch (error) {
    showToast(
      "error",
      "Ops :(",
      "Algo deu errado"
    );
    dispatch(isLoading(true));
  }
};

export const createTheme = (clientId: string, theme: Theme) => async (dispatch: Dispatch) => {
  try {
    dispatch(isLoading(true));
    const response = await repository.createTheme(clientId, theme);
    showToast(
      "success",
      "Sucesso :)",
      "O registrado foi criado !"
    );
    dispatch(isLoading(false));
    return response;
  } catch (error) {
    showToast(
      "error",
      "Ops :(",
      "Algo deu errado"
    );
    dispatch(isLoading(true));
  }
};

export const deleteTheme = (themeId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(isLoading(true));
    const response = await repository.deleteTheme(themeId);
    showToast(
      "success",
      "Sucesso :)",
      "O registrado foi excluído !"
    );
    dispatch(isLoading(false));
    return response;
  } catch (error) {
    showToast(
      "error",
      "Ops :(",
      "Algo deu errado"
    );
    dispatch(isLoading(true));
  }
};