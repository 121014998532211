import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { IconsMap } from "../../components/iconHome/iconhome";
import { Box } from "../../components/ds/SubatomicParticles";
import { H1, H2, H3, P } from "../../components/ds/theme/typography";
import Icon from "../../components/ds/atoms/Icon";
import { ReactComponent as Dotsleft } from "../../assets/svg/dots_right.svg";
import { ReactComponent as Pessegueira } from "../../assets/svg/pessegueira.svg";
import injectImages from "../Images/injectImages";
import PreImg from "../../components/PreImg";

function Sobre({ images, history }) {
  return (
    <>
      <Box
        gridTemplateColumns="repeat(12,1fr)"
        gridRowGap="1.5rem"
        position="relative"
        styling="grid"
      >
        <Box
          alignItems="flex-start"
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "2/span 3",
          }}
          gridRow="1"
          styling="column"
        >
          <H2 styling="base" fontSize="32px" textAlign="justify">
            A pessego.com é sua corretora de seguros simples, prática e
            eficiente.
          </H2>
          <Box
            styling="base"
            height="2px"
            width="4vw"
            mt="2rem"
            backgroundColor="primary.default"
          ></Box>
        </Box>

        <Box
          alignItems="flex-start"
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "2/span 3",
          }}
          gridRow="2"
          styling="column"
        >
          <P styling="base" textAlign="justify">
            Registrada na SUSEP sob o nº de registro 100147303. Nossa sede
            atende hoje toda a região nacional e é parceira das principais
            seguradoras do país.
          </P>
        </Box>

        <Box
          alignItems="flex-start"
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          gridColumn="8/span 3"
          gridRowStart="1"
          gridRowEnd="3"
          styling="column"
        >
          {images?.about1?.url ? (
            <PreImg
              src={images.about1.url}
              style={{
                width: "300px",
                height: "320px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                styling="row"
                width="80%"
                height="80%"
                borderRadius="4px"
                backgroundColor="#ededed"
              />
            </PreImg>
          ) : (
            <Pessegueira width={300} height={320} />
          )}
        </Box>

        <Box
          gridColumn="2/span 10"
          gridRow="5"
          justifyContent="flex-start"
          styling="row"
          width="100%"
        >
          <H3 fontSize="30px" styling="light" textAlign="left">
            Quer saber porque escolhemos o pessego?
          </H3>
        </Box>

        <Box
          alignIntems="flex-start"
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "2/span 5",
          }}
          gridRow="6"
          styling="column"
          width="100%"
        >
          <P styling="base" textAlign="justify">
            Um dos frutos mais antigos do mundo e com mais de mil variedades, o
            chá da fusão de suas folhas e sementes é utilizado como calmante
            natural. A presença de magnésio na fruta ajuda a prevenir o estresse
            e ansiedade no organismo e a manter o sistema nervoso em calma.
          </P>
          <P styling="base" textAlign="justify">
            A calmaria é sinônimo de uma vida longa e próspera. Estas
            características traduzem nossos compromissos: criar raízes
            auxiliando na escolha do seguro que melhor atende às suas
            necessidades, adequando custos e benefícios e, em paralelo, o pronto
            atendimento de um time especializado e experiente, garantindo
            tranquilidade no dia-a-dia, apoio e prestatividade sempre que formos
            acionados.
          </P>
        </Box>

        <Box
          alignItems="flex-start"
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "8/span 4",
          }}
          gridRow={{ mobile: "7", tablet: "7", desktop: "6" }}
          styling="column"
          width="100%"
        >
          <H3
            color="primary"
            fontSize="24px"
            mb="1rem"
            styling="light"
            textAlign="left"
          >
            Missão
          </H3>
          <P styling="base" textAlign="justify">
            Simplificar a relação das pessoas com o seguro, inspirado em
            inovação, tecnologia e praticidade.
          </P>
          <H3
            color="primary"
            fontSize="24px"
            my="1rem"
            styling="light"
            textAlign="left"
          >
            Valores
          </H3>
          <P styling="base" textAlign="justify">
            Gentileza, Transparência, espírito Empreendedor, Paixão pelo que
            fazemos, Pense fora da caixa.
          </P>
        </Box>

        <Box
          alignItems="flex-start"
          gridColumn="1/span 8"
          gridRow={{ mobile: "8", tablet: "8", desktop: "7" }}
          height="210px"
          my="30px"
          styling="column"
        >
          <Icon styling="baseNone" height="100%" width="70%">
            <Dotsleft width="100%" height="100%" />
          </Icon>
        </Box>

        <Box
          gridColumn="2/span 10"
          gridRow={{ mobile: "9", tablet: "9", desktop: "8" }}
          styling="column"
        >
          <H1 fontSize="2rem" mb="2rem" styling="light">
            Conheça nossos seguros
          </H1>
          <Box
            gridGap="1rem"
            gridTemplateColumns={{
              mobile: "repeat(2,1fr)",
              tablet: "repeat(3,1fr)",
              desktop: "repeat(7,1fr)",
            }}
            styling="grid"
            width="100%"
          >
            <IconsMap history={history} />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default compose(withRouter)(injectImages(Sobre));
