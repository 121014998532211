import firebase from "firebase";
import { Image as CustomImage, Images } from "./types";
import { Images as ImagesObject } from '../config/types'

export const getImages = () =>
  firebase
    .firestore()
    .collection('images')
    .get()
    .then((querySnapshot) => {

      const docs: CustomImage[] = [];
      querySnapshot.forEach(function (doc) {
        docs.push({ ...doc.data(), id: doc.id });
      });

      return docs;
    });

export const getImage = (imageId: string) =>
  firebase
    .firestore()
    .collection('images')
    .doc(imageId)
    .get()
    .then(doc => {
      const data = doc.data() as ImagesObject;

      return data;
    });

export const createImages = (clientId: string, images: Images) =>
  firebase
    .firestore()
    .collection('images')
    .doc(clientId)
    .set(images);

export const editImage = (clientId: string, images: CustomImage[]) =>
  firebase
    .firestore()
    .collection('images')
    .doc(clientId)
    .update(images)
    .then(resp => resp);

export const deleteImageOnBucket = (clientId: string, image: CustomImage) =>
  firebase
    .storage()
    .ref()
    .child(`${clientId}/${image.id}.png`)
    .delete();

export const uploadImage = (clientId: string, image: CustomImage) =>
  firebase
    .storage()
    .ref()
    .child(`${clientId}/${image.id}.png`)
    .put(image.blob || new Blob())
    .then((resp) => resp);

export const deleteBucket = (clientId: string) =>
  firebase
    .storage()
    .ref()
    .child(clientId)
    .listAll()
    .then(list => -list.items.map(image => image.delete()));

export const deleteImages = (imageId: string) =>
  firebase
    .firestore()
    .collection('images')
    .doc(imageId)
    .delete();