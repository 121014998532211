import firebase from "firebase";
import kebabCase from "lodash/kebabCase";
import { Locale } from "./types";

export const getLocales = () =>
  firebase
    .firestore()
    .collection('locale')
    .get()
    .then(function (querySnapshot) {
      const docs: Locale[] = [];
      querySnapshot.forEach(function (doc) {
        docs.push({ ...doc.data() as Locale, id: doc.id });
      });
      return docs;
    });

export const getLocale = (localeId: string) =>
  firebase
    .firestore()
    .collection('locale')
    .doc(localeId)
    .get()
    .then(doc => doc.data());

export const editLocale = (localeId: string, locale: Locale) =>
  firebase
    .firestore()
    .collection('locale')
    .doc(localeId)
    .update(locale);

export const createLocale = (clientId: string, locale: Locale) =>
  firebase
    .firestore()
    .collection('locale')
    .doc(kebabCase(clientId))
    .set(locale);

export const deleteLocale = (localeId: string) =>
  firebase
    .firestore()
    .collection('locale')
    .doc(localeId)
    .delete();