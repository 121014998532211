import React, { memo } from "react";
import styled from "styled-components";
import { variant, VariantArgs } from "styled-system";
import { defaults } from "../../SubatomicParticles";
import { CardStyledProps, CardProps } from "./index";

const variants = variant({
  prop: "styling",
  variants: {
    base: {
      padding: "1rem",
      margin: "0.5rem",
      bg: "background.default",
      border: "1px solid",
      borderColor: "background.default",
      borderRadius: "1rem",
      boxShadow: "10px 10px 28px 1px rgba(0,0,0,.1)",
      cursor: "pointer",
      "&:hover": {
        borderColor: "primary.default",
      },
      "&.active": {
        bg: "primary.default",
        borderColor: "primary.default",
        svg: {
          fill: "text.light",
          stroke: "text.light",
        },
      },
      ".title": {
        fontFamily: "OpenSans Regular",
        fontSize: "18px",
        marginBottom: "8px",
        fontWeight: "400",
        width: " 90%",
      },
      ".description": {
        fontFamily: "OpenSans Light",
        fontWeight: "300",
        textAlign: "left",
        fontSize: "16px",
        marginTop: "0px",
        marginBottom: "0px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        paddingRight: "16px",
        lineHeight: "1.25",
      },
    },
    get disabled() {
      return {
        ...this.base,
        color: (props: any) => `${props.colors.contrast}40`,
        opacity: 0.5,
      };
    },
  },
} as VariantArgs);

const Style: React.FC<CardStyledProps> = styled.div`
  ${variants}
  ${defaults}
`;

const Card: React.FC<CardProps> = (props) => (
  <Style {...props} styling={"base"}>
    {props.children}
  </Style>
);

export default memo(Card);
