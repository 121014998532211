export const LOADING = "LOADING";
export const LOADING_QUOTATION_VALUES = "LOADING_QUOTATION_VALUES";
export const BUYING_QUOTATION = "BUYING_QUOTATION";
export const LOGOUT_USER = "LOGOUT_USER";

export const SUCCESS_GET_CONTINENTS = "SUCCESS_GET_CONTINENTS"
export const LOADING_CONTINENTS = "form/LOADING_CONTINENTS"

export const SUCCESS_GET_TRIP_TYPES = "SUCCESS_GET_TRIP_TYPES"
export const LOADING_TRIP_TYPES = "form/LOADING_TRIP_TYPES"

export const SUCCESS_GET_PORTABLE_TYPES = "SUCCESS_GET_PORTABLE_TYPES"
export const LOADING_PORTABLE_TYPES = "form/LOADING_PORTABLE_TYPES"

export const SUCCESS_GET_ROLES = "SUCCESS_GET_ROLES"
export const LOADING_ROLES_TYPES = "form/LOADING_ROLES_TYPES"

export const SUCCESS_GET_RANGE_PAYMENT = "SUCCESS_GET_RANGE_PAYMENT"
export const LOADING_RANGE_PAYMENT = "form/LOADING_RANGE_PAYMENT"

export const SUCCESS_GET_RESIDENCE_COVERAGE = "SUCCESS_GET_RESIDENCE_COVERAGE"
export const LOADING_RESIDENCE_COVERAGE = "form/LOADING_RESIDENCE_COVERAGE"

export const SUCCESS_GET_AUTO_COVERAGE = "SUCCESS_GET_AUTO_COVERAGE"
export const LOADING_AUTO_COVERAGE = "form/LOADING_AUTO_COVERAGE"

export const SUCCESS_GET_MARITAL_STATE = "SUCCESS_GET_MARITAL_STATE"
export const LOADING_MARITAL_STATE = "form/LOADING_MARITAL_STATE"

export const SUCCESS_GET_GENERAL_REGISTRY_STATE = "SUCCESS_GET_GENERAL_REGISTRY_STATE"
export const LOADING_GENERAL_REGISTRY_STATE = "form/LOADING_GENERAL_REGISTRY_STATE"

export const SUCCESS_GET_AUTO_INSURANCE_TYPES = "SUCCESS_GET_AUTO_INSURANCE_TYPES"
export const LOADING_GET_AUTO_INSURANCE_TYPES = "form/LOADING_GET_AUTO_INSURANCE_TYPES"