import * as types from "./types";

export const authUser = user => ({
  type: types.AUTH_USER,
  user
});

export const logoutUser = () => ({
  type: types.LOGOUT_USER,
  user: {}
});

export const successLogin = user => ({
  type: types.SUCCESS_LOGIN,
  user
});

export const resetPasswordWithEmail = email => ({
  type: types.RESET_PASSWORD,
  email
})

export const isLoading = loading => ({
  type: types.LOADING,
  loading
});

export const quotesLoading = loading => ({
  type: types.QUOTES_LOADING,
  loading,
})

export const insuranceLoading = loading => ({
  type: types.INSURANCE_LOADING,
  loading,
})

export const showToast = toast => ({
  type: types.TOAST,
  toast
});

export const showModal = modal => ({
  type: types.SHOW_MODAL,
  modal
});

export const setMyInsurances = data => ({
  type: types.SET_MY_INSURANCES,
  data,
})

export const setMyQuotes = data => ({
  type: types.SET_MY_QUOTES,
  data,
})

export const setQuoteInfo = data => ({
  type: types.SET_QUOTE_INFO,
  data,
})

export const quoteInfoLoading = loading => ({
  type: types.QUOTE_INFO_LOADING,
  loading,
})

export const successGetResidences = list => ({
  type: types.SUCCESS_GET_RESIDENCES,
  list
})

export const isLoadingResidences = isLoading => ({
  type: types.IS_LOADING_RESIDENCES,
  isLoading
})

export const successGetVehicles = list => ({
  type: types.SUCCESS_GET_VEHICLES,
  list
})

export const isLoadingVehicles = isLoading => ({
  type: types.LOADING_VEHICLES,
  isLoading
})

export const successGetReferenceDate = object => ({
  type: types.SUCCESS_GET_REFERENCE_DATE,
  object
})

export const successGetBrands = list => ({
  type: types.SUCCESS_GET_BRANDS,
  list
})

export const successGetModels = list => ({
  type: types.SUCCESS_GET_MODELS,
  list
})

export const successGetYearModel = list => ({
  type: types.SUCCESS_GET_YEAR_MODEL,
  list
})

export const successGetSelectedVehicle = obj => ({
  type: types.SUCCESS_GET_VEHICLE,
  obj
})