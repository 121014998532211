import { Dispatch } from 'redux';
import * as repository from './repository';
import { setImages, isLoading } from './actions';
import { Image } from './types';
import { showToast } from '../../../utils';


export const getImages = () => async (dispatch: Dispatch) => {
  try {
    dispatch(isLoading(true));
    const response = await repository.getImages();
    dispatch(setImages(response));
    dispatch(isLoading(false));
  } catch (error) {
    showToast(
      "error",
      "Ops :(",
      "Algo deu errado"
    );
    dispatch(isLoading(false));
  };
};

export const getImage = (imageId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(isLoading(true));
    const response = await repository.getImage(imageId);
    dispatch(isLoading(false));
    return response;
  } catch (error) {
    showToast(
      "error",
      "Ops :(",
      "Algo deu errado"
    );
    dispatch(isLoading(false));
  }
};

export const editImage = (clientId: string, images: Image[]) => async (dispatch: Dispatch) => {
  try {
    dispatch(isLoading(true));
    for (let imageKey in images) {
      if (!!images[imageKey].blob) {
        const timestamp = new Date().getTime()
        if(images[imageKey].url){
          await repository.deleteImageOnBucket(clientId, images[imageKey])
        }
        images[imageKey].id = `${imageKey}_${timestamp}`
        const uploadedImage = await repository.uploadImage(clientId, images[imageKey]);
        images[imageKey].url = `https://storage.googleapis.com/${uploadedImage.metadata.bucket}/${uploadedImage.metadata.fullPath}`
        delete images[imageKey].blob
      }
    }
    const editImage = await repository.editImage(clientId, images);
    showToast(
      "success",
      "Sucesso :)",
      "As imagens foram atualizadas!"
    );
    dispatch(isLoading(false));
    return editImage;
  } catch (error) {
    showToast(
      "error",
      "Ops :(",
      "Algo deu errado"
    );
    dispatch(isLoading(false));
  }
};

export const deleteImage = (imageId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(isLoading(true));
    const response = await repository.deleteImages(imageId);
    dispatch(isLoading(false));
    return response;
  } catch (error) {
    dispatch(isLoading(false));
  }
};