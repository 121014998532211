import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Box } from "../../components/ds/SubatomicParticles";
import { H1, H2 } from "../../components/ds/theme/typography";
import CollapsibleCard from "../../components/CollapsibleCard";
import { IconsMap } from "../../components/iconHome/iconhome";

const Suporte = ({ history }) => {
  return (
    <Box
      gridRowGap="1.5rem"
      gridTemplateColumns="repeat(12,1fr)"
      position="relative"
      styling="grid"
    >
      <Box
        alignItems="flex-start"
        gridColumn="3/11"
        justifyContent="flex-end"
        mb="50px"
        mt="70px"
        styling="column"
      >
        <H2 styling="base">
          Preparamos uma lista de dúvidas frequentes com respostas rápidas para
          você
        </H2>
        <Box
          backgroundColor="primary.default"
          height=".2rem"
          mt="1rem"
          width="4vw"
        ></Box>
      </Box>

      {mockInformation.map((item, index) => (
        <React.Fragment key={`support-item-${index}`}>
          <CollapsibleCard title={item.title} content={item.content} />
        </React.Fragment>
      ))}
      <Box gridColumn="2/span 10" styling="column">
        <H1 fontSize="2rem" mb="2rem" styling="light">
          Conheça nossos seguros
        </H1>
      </Box>
      <Box gridColumn="2/span 10" styling="column">
        <Box
          gridGap="1rem"
          gridTemplateColumns={{
            mobile: "repeat(2,1fr)",
            tablet: "repeat(3,1fr)",
            desktop: "repeat(6,1fr)",
          }}
          styling="grid"
          width="100%"
        >
          <IconsMap history={history} />
        </Box>
      </Box>
    </Box>
  );
};

export default compose(withRouter)(Suporte);

const mockInformation = [
  {
    title: "1. Porque eu preciso de seguro?",
    content:
      "Ter seguro é uma forma de proteger seu patrimônio dos imprevistos cotidianos e acidentes naturais ou de risco.",
  },
  {
    title: "2. Consigo contratar seguros direto pelo app pessego.com?",
    content:
      "Sim. O app pessego.com está preparado para fornecer as seguradoras com melhor custo benefício para o seu perfil. Você escolhe, contrata e acompanha sua apólice durante todo o período de vigência. Disponível para contratação de seguros automotivos, residencial, viagem, pet e portáteis para Pessoa Física. Para contratação de seguro de vida, previdência ou qualquer contratação de seguro para Pessoa Jurídica, entre em contato conosco pelo 0800.941.4980.",
  },
  {
    title: "3. A pessego.com é confiável?",
    content:
      "Com certeza sim. Somos uma corretora atuante no mercado securitário a mais de 25 anos com mais de 200.000 seguros vendidos para clientes espalhados por todo Brasil. Time especializado, experiente e preparado para atender você. Entre em contato conosco e faça sua cotação com segurança!",
  },
  {
    title: "4. Quais seguros posso contratar através da pessego.com?",
    content:
      "Na pessego.com você contrata seguros: - Automotivos (carro, moto, caminhão, etc); - Residencial; - Empresarial; - Viagem; - Vida; - Pet; - Portáteis; - Saúde; - Odontológico; - Previdência. Quer contratar algum seguro que não esteja na lista? Entre em contato conosco pelo 0800.941.4980.",
  },
  {
    title: "5. A pessego.com é 100% online?",
    content:
      "Depende de você! É possível fazer a contratação do seu seguro online, mas se preferir falar ao telefone, os promotores da pessego.com estão disponíveis no 0800.941.4980.",
  },
  {
    title: "6. Consigo falar com a pessego.com pelo telefone?",
    content:
      "Consegue sim. Ligue no 0800.941.4980, selecione a opção que deseja e aguarde nosso atendimento.",
  },
  {
    title: "7. Tive um sinistro. Como faço para acionar meu seguro?",
    content:
      "Você pode acionar o seu seguro através do botão “acionar sinistro” no seu app. Se preferir ligue no 0800.941.4980 da pessego.com, selecione a opção “4: sinistro” e aguarde atendimento. A Pessego Corretora está disponível de segunda à sexta, de 8h30 às 18h. Após este horário ou aos finais de semana, você deve acionar o seu seguro através do 0800 da sua seguradora, ok?",
  },
  {
    title: "8. Estou inadimplente. Como devo proceder?",
    content:
      "Entre em contato com a pessego.com pelo 0800.941.4980, selecione a opção “3: regularização” e aguarde atendimento.",
  },
];
