import React, { useState } from "react";
import { useFormInput } from "../../../../utils";
import { Box, Button } from "../../../../components/ds/SubatomicParticles";
import { H3, P } from "../../../../components/ds/theme/typography";
import FormInput from "../../../../components/FormInput";
import { getRawPhone } from "../../../../utils/validations";

const ModalContact = ({ callBackData, onSuccess, phone, onClose, name }) => {
  const telephone = useFormInput(phone, "Telefone Celular");
  const [isPhoneOpen, setIsPhoneOpen] = useState(false);

  const handleSendContact = () => {
    const rawTelephone = getRawPhone(telephone.value);
    let observacao = `Oi, gostaria de conversar sobre cotação ${window.location.href.substring(22)}!`
    if (isPhoneOpen || !phone) {
      callBackData({
        ddd: rawTelephone.substring(0, 2),
        telefone: rawTelephone.substring(2, rawTelephone.length),
        observacao
      })
    } else {
      callBackData({
        ddd: phone.substring(0, 2),
        telefone: phone.substring(3),
        observacao
      })
    }
  };

  return onSuccess ? (
    <Box
      backgroundColor="background.default"
      borderRadius="12px"
      maxWidth="30rem"
      padding="2rem"
      position="fixed"
      styling="cloumn"
      width="90%"
      zIndex="2"
    >
      <H3 styling="light">
        {`${name}, entraremos em contato nos próximos minutos.`}
      </H3>
      <P mt="30px" styling="base">Nosso atendimento é de segunda a sexta de 08:30 às 18:00 horas.</P>
      <P my="30px" styling="base">Nosso número: 0800 941 4980</P>
      <Button display="block" margin="0 auto" onClick={onClose} styling="primary" width="90%">Ok, obrigado(a)!</Button>
    </Box>
  ) : (
    <Box
      backgroundColor="background.default"
      borderRadius="12px"
      maxWidth="30rem"
      padding="2rem"
      position="fixed"
      styling="cloumn"
      width="90%"
      zIndex="2"
    >
      <H3 styling="light">Vamos te ligar agora mesmo!</H3>
      {isPhoneOpen ? (
        <FormInput
          {...telephone}
          mask="(99) 99999-9999"
          placeholder="Telefone"
          type="text"
        />
      ) : (
        <Box my="30px" styling="row">
          <P styling="base">
            Número de contato: ****{phone.substring(8, phone.length)}
          </P>
        </Box>
      )}
      <Button display="block" margin="0 auto" onClick={handleSendContact} styling="primary" width="90%">Enviar</Button>
      <Button display="block" margin="0 auto" onClick={() => setIsPhoneOpen(!isPhoneOpen)} styling="base" width="265px">
        {!isPhoneOpen ? 'Informar outro número' : 'Cancelar mudança de número'}
      </Button>
    </Box>
  )
};

export default ModalContact;
