import createNumberMask from "text-mask-addons/dist/createNumberMask";

export function currencyMask(rawValue) {
  let numberMask = createNumberMask({
    prefix: "R$ ",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ".",
    allowDecimal: true,
    decimalSymbol: ",",
    integerLimit: 12,
    requireDecimal: true,
    allowLeadingZeroes: false
  });
  let mask = numberMask(rawValue);

  let decimalsRegex = /,([0-9]{1,2})/;
  let result = decimalsRegex.exec(rawValue);
  // In case there is only one decimal
  if (result && result[1].length < 2) {
    mask.push('0');
  } else if (!result) {
    mask.push('0');
    mask.push('0');
  }

  return mask;
}

export function withoutThousandPoint(rawValue) {
  let numberMask = createNumberMask({
    prefix: "R$ ",
    includeThousandsSeparator: false,
    // thousandsSeparatorSymbol: ",",
    allowDecimal: true,
    decimalSymbol: ".",
    integerLimit: 12,
    requireDecimal: true,
    allowLeadingZeroes: false,
  });
  let mask = numberMask(rawValue);

  return mask;
}