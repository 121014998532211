import React from "react";
import { Box, Button } from "../../../components/ds/SubatomicParticles";
import { H3 } from "../../../components/ds/theme/typography";
import FormSelect from "../../../components/FormSelect";
import { validateVerify } from "../../../utils/validations";
import { fieldNotFilledAlert } from "../../../utils/index";

export default function Step1({
  onNext,
  quantidadePets,
  setQuantidadePets,
  onIncomplete,
}) {
  const handleNextStep = () => {
    if (quantidadePets !== "") {
      onNext();
    } else {
      fieldNotFilledAlert("Quantidade de pets");
    }
  };

  const getPetsAmount = (amount) => {
    const amoutArray = [];
    for (let i = 1; i <= amount; i++) {
      amoutArray.push({ value: i, label: i });
    }
    return amoutArray;
  };

  return (
    <>
      <Box
        gridColumn={{
          mobile: "2/span 10",
          tablet: "2/span 10",
          desktop: "4/span 6",
        }}
        styling="column"
        pb={"30rem"}
      >
        <H3 mb="30px" styling="light">
          Quantos pets você tem?
        </H3>

        <FormSelect
          data-testid="petsAmount"
          id="petsAmount"
          name="petsAmount"
          options={getPetsAmount(5)}
          defaultValue={quantidadePets}
          onChange={(e) => {
            setQuantidadePets(e.value);
          }}
          onBlur={(e) => validateVerify(e)}
          placeholder="Quantidade de pets"
          styling="base"
        />
        <Button
          styling="primary"
          onClick={handleNextStep}
          width="296px"
          mt={25}
        >
          Próximo
        </Button>
      </Box>
    </>
  );
}
