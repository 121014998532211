import * as types from "./types";

const initialState = {
  contractor: '',
  clients: {},
  themes: {},
  locales: {},
  images: {},
  isLoading: false
};

const reducers = (state: any = initialState, action: { type: string, [key: string]: any }) => {
  switch (action.type) {
    case types.SET_CONTRACTOR:
      return {
        ...state, contractor: action.contractor
      };
    case types.SET_CLIENT:
      return {
        ...state, clients: { ...state.clients, ...action.client }
      };
    case types.SET_THEME:
      return {
        ...state, themes: { ...state.themes, ...action.theme }
      };
    case types.SET_IMAGES:
      return {
        ...state, images: { ...state.images, ...action.images }
      };
    case types.SET_LOCALE:
      return {
        ...state, locales: { ...state.locales, ...action.locale }
      };
    case types.IS_LOADING:
      return {
        ...state, isLoading: action.isLoading
      };
    default:
      return state;
  }
};

export { initialState };
export default reducers;