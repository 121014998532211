/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import moment from "moment";

import {
  validateVerify,
  autoCorrectedDatePipe,
  checkLengthOfDate,
  keepOnlyNumbers,
  getRawPhone
} from "../../../utils/validations";
import { fieldNotFilledAlert, dateMask } from "../../../utils";
import { Box, Button } from "../../../components/ds/SubatomicParticles";
import { H3 } from "../../../components/ds/theme/typography";
import FormSelect from "../../../components/FormSelect";
import FormInput from "../../../components/FormInput";
import TextMaskFormInput from "../../../components/TextMaskFormInput";


const AdditionalInformation = ({
  onComplete,
  onMountBeneficiaryInfo,
  IndividualNumber,
  setIndividualNumber,
  IndNumIssuanceDate,
  setIndNumIssuanceDate,
  IndNumIssuer,
  setIndNumIssuer,
  IndNumIssuerState,
  setIndNumIssuerState,
  OccupationCode,
  setOccupationCode,
  RangeMonthlyIncomeCode,
  setRangeMonthlyIncomeCode,
  roles,
  rangePayments,
  maritalStateList,
  maritalStateLoading,
  generalRegistryList,
  generalRegistryLoading,
  PoliticallyExposedPerson,
  setPoliticallyExposedPerson,
  DriverLicenseNumber,
  setDriverLicenseNumber,
  DriverLicenseType,
  setDriverLicenseType,
  dataHabCondutor,
  setDataHabCondutor,
  showModal,
  Is0KM,
  DealershipName,
  setDealershipName,
  InvoiceNumber,
  setInvoiceNumber,
  CellPhoneNumber,
  setCellPhoneNumber,
}) => {
  useEffect(() => {
    showModal({
        visible: true,
        type: "modalMessage",
        data: {
            message:
            "Para finalizar a contratação e adiquirir a sua apólice de seguro, responda apenas mais essas perguntas.",
        },
    });
    onMountBeneficiaryInfo();
  }, []);

  const handleVerification = () => {
    return (
      IndNumIssuanceDate &&
      checkLengthOfDate(IndNumIssuanceDate) === 8 &&
      IndividualNumber !== "" &&
      IndNumIssuer !== "" &&
      IndNumIssuerState !== "0" &&
      IndNumIssuerState &&
      RangeMonthlyIncomeCode &&
      OccupationCode &&
      OccupationCode !== "0" &&
      PoliticallyExposedPerson &&
      DriverLicenseNumber !== "" &&
      DriverLicenseType &&
      (Is0KM === "F" || (Is0KM === "T" && DealershipName && InvoiceNumber)) &&
      CellPhoneNumber.length === 15
    );
  };

  const _handleIncomplete = () => {
    if (!PoliticallyExposedPerson)
      fieldNotFilledAlert(
        "Declaro ser, ou ter relacionamento com Pessoa Politicamente Exposta Estrangeira?",
      );
    if (!DriverLicenseNumber) fieldNotFilledAlert("Número da CNH");
    if (!DriverLicenseType) fieldNotFilledAlert("Modelo da CNH");
    if (!IndividualNumber) fieldNotFilledAlert("RG");
    if (!IndNumIssuanceDate || checkLengthOfDate(IndNumIssuanceDate) !== 8)
      fieldNotFilledAlert("Data de expedição");
    if (!dataHabCondutor || checkLengthOfDate(dataHabCondutor) !== 8)
      fieldNotFilledAlert("Data de emissão");
    if (!IndNumIssuer) fieldNotFilledAlert("Órgão expedidor");
    if (IndNumIssuerState === "0" || !IndNumIssuerState)
      fieldNotFilledAlert("UF expedidora");
    if (!OccupationCode || OccupationCode === 0)
      fieldNotFilledAlert("Profissão do segurado");
    if (!RangeMonthlyIncomeCode)
      fieldNotFilledAlert("Faixa de renda do segurado");
    if (Is0KM === "T" && DealershipName === "")
      fieldNotFilledAlert("Nome da Concessionária");
    if (Is0KM === "T" && InvoiceNumber === "")
      fieldNotFilledAlert("Número da Nota Fiscal");
    if (!CellPhoneNumber || (CellPhoneNumber && CellPhoneNumber.length !== 15))
      fieldNotFilledAlert("Telefone");
  };

  const _handleVerify = () => {
    if (handleVerification()) {
      const rawTelephone = getRawPhone(CellPhoneNumber);
      onComplete({
        IndividualNumber,
        IndNumIssuanceDate: moment(IndNumIssuanceDate, "DD/MM/YYYY").format(
          "YYYY-MM-DD",
        ),
        dataHabCondutor: moment(dataHabCondutor, "DD/MM/YYYY").format(
          "YYYY-MM-DD",
        ),
        IndNumIssuer,
        IndNumIssuerState,
        RangeMonthlyIncomeCode,
        OccupationCode,
        PoliticallyExposedPerson,
        DriverLicenseNumber,
        DriverLicenseType,
        CellPhoneAreaCode: rawTelephone.substring(0, 2),
        CellPhoneNumber: rawTelephone.substring(2, rawTelephone.length)
      });
    } else {
      _handleIncomplete();
    }
  };


  return (
    <>
      <Box
        gridColumn={{
          mobile: "2/span 10",
          tablet: "2/span 10",
          desktop: "4/span 6",
        }}
        styling="column"
      >
        <H3 mb="30px" styling="light">
          Informações do Segurado
        </H3>
        <Box display="flex" width={"100%"} flexDirection="column">
            <FormSelect
            defaultValue={PoliticallyExposedPerson}
            onChange={(e) => setPoliticallyExposedPerson(e.value)}
            onBlur={(e) => validateVerify(e)}
            placeholder="Declaro ser, ou ter relacionamento com Pessoa Politicamente Exposta
            Estrangeira?"
            options={[
                {
                label: "Selecione",
                value: "",
                },
                {
                label: "Não",
                value: "N",
                },
                {
                label: "Sim",
                value: "S",
                },
            ]}
            />
            <Box display="flex" flexDirection="column" width="100%">
                <Box display="flex" width="100%">
                    <Box mr="3rem" width="100%">
                        <FormInput
                        value={DriverLicenseNumber}
                        placeholder="Número da CNH"
                        type="text"
                        onChange={(e) => setDriverLicenseNumber(e.target.value)}
                        id="cnh"
                        mask="9999999999999"
                        maskChar={null}
                        onBlur={(e) => validateVerify(e)}
                        />
                    </Box>
                    <Box width="100%">
                        <TextMaskFormInput
                        value={dataHabCondutor}
                        onChange={(e) => setDataHabCondutor(e.target.value)}
                        mask={dateMask}
                        pipe={(date) => autoCorrectedDatePipe(date, false)}
                        placeholder="Data de emissão habilitação"
                        onBlur={(e) => validateVerify(e)}
                        />
                    </Box>
                </Box>
                <Box display="flex" width="100%">
                  <Box mr="3rem" width="100%">
                      <FormSelect
                      defaultValue={DriverLicenseType}
                      onChange={(e) => setDriverLicenseType(e.value)}
                      onBlur={(e) => validateVerify(e)}
                      placeholder="Modelo da CNH:"
                      options={[
                          {
                          label: "Selecione",
                          value: "",
                          },
                          {
                          label: "Modelo Novo (com foto)",
                          value: "N",
                          },
                          {
                          label: "Modelo Antigo (sem foto)",
                          value: "A",
                          },
                      ]}
                      />
                  </Box>
                  <Box width="100%">
                      <FormInput
                        value={CellPhoneNumber}
                        placeholder="Telefone celular"
                        type="text"
                        onChange={(e) => setCellPhoneNumber(e.target.value)}
                        id="telefone"
                        mask="(99) 99999-9999"
                        maskChar={null}
                        onBlur={(e) => validateVerify(e)}
                      />
                  </Box>
                </Box>
                <Box display="flex" width="100%">
                    <Box mr="3rem" width="100%">
                        <FormInput
                        value={IndividualNumber}
                        placeholder="RG (Somente números)"
                        type="text"
                        onChange={(e) => setIndividualNumber(e.target.value)}
                        id="rg"
                        mask="99999999"
                        maskChar={null}
                        onBlur={(e) => validateVerify(e)}
                        />
                    </Box>
                    <Box width="100%">
                        <TextMaskFormInput
                        value={IndNumIssuanceDate}
                        onChange={(e) => setIndNumIssuanceDate(e.target.value)}
                        mask={dateMask}
                        pipe={(date) => autoCorrectedDatePipe(date, false)}
                        placeholder="Data de expedição"
                        onBlur={(e) => validateVerify(e)}
                        />
                    </Box>
                </Box>
                <Box display="flex" width="100%">
                    <Box mr="3rem" width="100%">
                        <FormInput
                        value={IndNumIssuer}
                        placeholder="Órgão expedidor"
                        maxLength={5}
                        onChange={(e) => setIndNumIssuer(e.target.value)}
                        onBlur={(e) => validateVerify(e)}
                        />
                    </Box>
                    <Box width="100%">
                        <FormSelect
                            defaultValue={IndNumIssuerState}
                            onChange={(e) => setIndNumIssuerState(e.value)}
                            onBlur={(e) => validateVerify(e)}
                            placeholder={"UF expeditora"}
                            options={
                                (generalRegistryList && Object.keys(generalRegistryList).length !== 0) ||
                                maritalStateLoading
                                ? Object.keys(generalRegistryList).map((item, index) => {
                                    return {
                                        value: item,
                                        label: generalRegistryList[item],
                                    };
                                    })
                                : [{ label: "Carregando...", value: "" }]
                            }
                        />
                    </Box>
                </Box>
                <Box display="flex" width="100%">
                    <Box mr="3rem" width="100%">
                        <FormSelect
                        defaultValue={OccupationCode}
                        onChange={(e) => setOccupationCode(e.value)}
                        onBlur={(e) => validateVerify(e)}
                        options={
                            (roles && Object.keys(roles).length !== 0) || maritalStateLoading
                            ? Object.keys(roles).map((item, index) => {
                                return {
                                    value: item,
                                    label: roles[item],
                                };
                                })
                            : [{ label: "Carregando...", value: "" }]
                        }
                        placeholder="Ocupação"
                        />
                    </Box>
                    <Box width="100%">
                        <FormSelect
                        defaultValue={RangeMonthlyIncomeCode}
                        onChange={(e) => setRangeMonthlyIncomeCode(e.value)}
                        options={
                          (rangePayments && Object.keys(rangePayments).length !== 0) || maritalStateLoading
                            ? Object.keys(rangePayments).map((item, index) => {
                                return {
                                    value: item,
                                    label: rangePayments[item],
                                };
                                })
                            : [{ label: "Carregando...", value: "" }]
                        }
                        placeholder="Salário"
                        />
                    </Box>
                </Box>
            </Box>        
        </Box>
        {Is0KM === "T" &&
        <>
            <H3 mb="30px" styling="light">
            Informações gerais da Concessionária
            </H3>
            <Box display="flex" width={"100%"} flexDirection="column">
                {Is0KM === "T" && (
                    <>
                    <FormInput
                        type="text"
                        placeholder="Nome da Concessionária"
                        value={DealershipName}
                        onBlur={(e) => validateVerify(e)}
                        maxLength={20}
                        onChange={(value) => {
                        setDealershipName(value.target.value);
                        }}
                    />
                    <FormInput
                        placeholder="Número da Nota Fiscal"
                        value={InvoiceNumber}
                        onBlur={(e) => validateVerify(e)}
                        onChange={(value) => {
                        setInvoiceNumber(keepOnlyNumbers(value.target.value));
                        }}
                    />
                    </>
                )}
            </Box>
        </>
        }
        
        <Button styling="primary" onClick={_handleVerify} width="296px" mt={25}>
          Concluir
        </Button>
      </Box>
    </>
  );
};


export default AdditionalInformation;