import React, { memo } from 'react';
import styled from 'styled-components';
import { variant, VariantArgs } from 'styled-system';
import { defaults, Box } from '../../SubatomicParticles';
import { BreadcrumbsProps, BreadcrumbsStyledProps } from './index';
import { ReactComponent as IconChevronRight } from './assets/chevron-right.svg';
import { ReactComponent as IconArrowLeftCircle } from './assets/arrow-left-circle.svg';

import Link from '../Link';
import Icon from '../Icon';

const variants = variant({
  prop: 'styling',
  variants: {
    base: {
      borderBottom: '1px solid',
      borderBottomColor: '#3f51b5',
      marginBottom: '15px',
      minHeight: '36px',
      paddingBottom: '10px',
      width: '100%',
      a: {
        color: '#1B2B37',
        fontFamily: 'OpenSans Regular',
        fontSize: '18px',
        height: '24px',
        lineHeight: '22px',
        textDecoration: 'none',
        svg: {
          marginLeft: '20px',
          path: {
            fill: 'none'
          }
        },
        '&:hover': {
          svg: {
            path: {
              fill: 'none'
            }
          },
        },
        '&:first-child svg': {
          position: 'relative',
          strokeWidth: '1',
          margin: '0',
          marginRight: '15px',
          '&:hover': {
            strokeWidth: '2',
          }
        },
        '&:last-child svg': {
          display: 'none'
        },
        '&:not(:first-child)': {
          paddingLeft: '20px'
        },

        '&:nth-child(2)': {
          paddingLeft: '0px'
        }
      }
    },
  },
} as VariantArgs);

const BreadcrumbsContent: React.FC<BreadcrumbsStyledProps> = styled.div`
  ${variants}
  ${defaults}
`;

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ steps: items, icon: svg, backPath, ...props }) => (
  <BreadcrumbsContent
    styling={props.styling ?? 'base'}
    style={{ ...props.style }}
  >
    <Box styling="row" justifyContent="flex-start">
      {backPath &&
        <Link styling="admin" height="24px" mr="10px" width="24px" to={backPath ?? items[0].path}>
          <Icon styling="active" title="Voltar"><IconArrowLeftCircle /></Icon>
        </Link>
      }
      {items.map((e, index) => (
        e.name && <Link styling="admin" key={`${e.name}-${index}`} to={e.path} display="flex">
          {e.name}
          {svg ?? <Icon styling="active"><IconChevronRight /></Icon>}
        </Link>
      ))}
    </Box>
  </BreadcrumbsContent>
);

export default memo(Breadcrumbs);
