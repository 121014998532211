import React, { memo, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import css from "@styled-system/css";

import { ReactComponent as IconEdit } from "../../../../assets/svg/edit-3.svg";
import { ReactComponent as IconFilter } from "../../../../assets/svg/filter.svg";

import { Box, Button } from "../../../../components/ds/SubatomicParticles";
import { P } from "../../../../components/ds/theme/typography";
import { Table } from "../../../../components/ds/atoms/Table";
import Link from "../../../../components/ds/atoms/Link";
import Icon from "../../../../components/ds/atoms/Icon";
import { getImages } from "../../../../state/context/images/services";
import { Props, State } from "./types";
import Breadcrumbs from "../../../../components/ds/atoms/Breadcrumbs";
import Badge from "../../../../components/ds/atoms/Badge";
import Loading from '../../../../components/SmallLoading';

const steps = [
  {
    path: "/admin/images",
    name: "Imagens",
  },
];

const List: React.FC<Props> = (props) => {
  const { getImages, images } = props;
  const [showFilter, toggleFilter] = useState<boolean>(false);
  const handleFilter = () => toggleFilter((prevState) => !prevState);
  const [countFilters, setCountFilters] = useState<number>(0);

  const columns = [
    {
      Header: "Contratante",
      accessor: "id",
      filter: "fuzzyText",
      placeholder: "Filtrar por Contratante",
    },
    {
      Header: "Ações",
      accessor: "actions",
      disableFilters: true,
      width: 200,
      Cell: (row: any) => (
        <P styling="base" width="100%" textAlign="right">
          {row.row.values.actions}
        </P>
      ),
    },
  ];

  useEffect(() => {
    getImages();
  }, [getImages]);

  const imagesList = useMemo(
    () =>
      images.map(image => ({
        ...image,
        actions: (
          <Box styling="row" justifyContent="flex-start">
            <Link styling="base" to={`/admin/images/edicao/${image.id}`}>
              <Icon styling="admin" title="Visualizar">
                <IconEdit height={20} width={20} />
              </Icon>
            </Link>
          </Box>
        ),
      })),
    [images],
  ); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      styling="column"
      width="100%"
      height="100%"
      overflow="hidden"
      position="relative"
    >
      <Box
        styling="row"
        width="calc(100% - 60px)"
        position="absolute"
        top="30px"
        left="30px"
      >
        <Breadcrumbs styling="base" steps={steps} />
      </Box>
      <Box
        styling="row"
        width="calc(100% - 60px)"
        position="absolute"
        top="85px"
        left="30px"
        justifyContent="flex-end"
      >
        <Button
          type="button"
          styling="underlined"
          css={css({
            color: `${countFilters > 0 ? '#3f51b5' : '#1B2B37'}`,
            fontFamily: 'OpenSans Semibold',
            border: 'none',
            fontSize: 2,
            display: "flex",
            m: 0,
            outline: 'none',
            position: 'relative',
            '&:hover': {
              color: '#3f51b5',
              'svg': {
                stroke: '#3f51b5',
                fill: '#3f51b5',
              },
            },
          })}
          onClick={handleFilter}
        >
          {countFilters > 0 && (
            <Badge
              styling="base"
              top="-6px"
              right="0px"
              bg="#3f51b5"
              color="#fff"
              borderColor="#3f51b5"
              border="1px solid"
            >
              {countFilters}
            </Badge>
          )}
          <Icon styling={countFilters > 0 ? 'adminActive' : 'admin'} title="Filtrar" mr="10px">
            <IconFilter />
          </Icon>
          Filtros
        </Button>
      </Box>
      <Box
        bottom="0"
        position="absolute"
        styling="row"
        top="135px"
        width="calc(100% - 60px)"
      >
        <Table<any>
          name="list-images"
          styling="base"
          data={imagesList}
          columns={columns}
          showFilter={showFilter}
          onCloseFilter={handleFilter}
          countFilter={setCountFilters}
        />
      </Box>
      {props.isLoading && (
        <Box
          styling="column"
          height="100vh"
           width="100%"
          zIndex={99999}
          bg="rgba(240, 241, 242, 0.62)"
          position="absolute"
        >
          <Loading />
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = (state: State) => ({
  images: state.images.images,
  isLoading: state.images.isLoading,
});

const mapDispatchToProps = {
  getImages,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(List as any));
