import React, { useState, useEffect } from 'react'
import css from '@styled-system/css';
import { Box } from "../../../../components/ds/SubatomicParticles";
import { H3, P } from "../../../../components/ds/theme/typography";
import { getLibertyPaymentToken } from '../../../../state/context/cotacao/repository'
import Icon from '../../../../components/ds/atoms/Icon';
import { ReactComponent as IconArrow } from '../../../../assets/svg/right-arrow.svg'

const StaticMode = (props) => {

  const {
    title,
    item,
    onClick,
    paymentContext,
    quoteId,
    paymentTypeCode,
    selectedPayment,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [paymentToken, setPaymentToken] = useState('');

  useEffect(() => {
    if (!paymentToken) {
      getLibertyPaymentToken({ type: `${paymentContext}Quotes`, quoteId })
        .then(response => setPaymentToken(response.data.token))
    }
  })

  return (
    <Box
      alignItems="stretch"
      backgroundColor="background.default"
      boxShadow="10px 10px 20px rgba(0, 0, 0, 0.05)"
      borderRadius="12px"
      flexWrap="wrap"
      gridColumn="2/span 10"
      mb="15px"
      onClick={() => setIsOpen(!isOpen)}
      padding="15px"
      position="relative"
      styling="column"
      width="100%"
    >
      <Box justifyContent="space-between" styling="row" width="100%">
        <Box alignItems="flex-start" styling="row" width="calc(100% - 40px)">
          <H3 fontSize="20px" styling="base" width="100%">{title}</H3>
        </Box>
        <Box
          css={css({
            transform: `${isOpen ? 'rotate(-90deg)' : 'rotate(90deg)'}`,
            transition: 'all linear .25s',
          })}
          height="20px"
          styling="row"
          width="20px">
          <Icon css={css({ 'svg path': { fill: 'primary.default' } })}>
            <IconArrow height={20} width={20} />
          </Icon>
        </Box>
      </Box>
      {isOpen &&
        <Box styling="column" width="100%">
          {item.paymentModes.map(paymentmode => (
            <Box
              key={`${item.paymentName}-mode-${paymentmode.paymentLabel}`}
              onClick={() => onClick({ InstallmentNumber: paymentmode.InstallmentNumber, paymentTypeCode, bankAccountInfo: item.bankAccountInfo })}
              backgroundColor="background.default"
              borderTop="1px solid"
              borderColor="grays.light"
              justifyContent="space-between"
              py="10px"
              styling="row"
              width="100%"
            >
              <P styling="base">
                {paymentmode.paymentLabel}
              </P>
              <Box
                border="2px solid"
                borderColor={
                  selectedPayment && selectedPayment.InstallmentNumber === paymentmode.InstallmentNumber && selectedPayment.paymentTypeCode === item.paymentTypeCode ? 'success.default' : 'grays.light'
                }
                borderRadius="50%"
                height="25px"
                width="25px">
                <P
                  color="success.default"
                  fontSize="15px"
                  styling="regular"
                  textAlign="center"
                >
                  {selectedPayment && selectedPayment.InstallmentNumber === paymentmode.InstallmentNumber && selectedPayment.paymentTypeCode === item.paymentTypeCode ? '√' : ''}
                </P>
              </Box>
            </Box>
          ))}
        </Box>
      }
    </Box>
  )
}

export default StaticMode