/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import moment from "moment";
import * as Yup from "yup"
import { validateVerify, autoCorrectedDatePipe } from "../../../utils/validations";
import { showToast, dateMask } from '../../../utils';
import { Formik, Field, Form } from "formik"
import { Box, Button } from "../../../components/ds/SubatomicParticles";
import { H3 } from "../../../components/ds/theme/typography";
import FormSelect from "../../../components/FormSelect";
import FormInput from "../../../components/FormInput";
import TextMaskFormInput from "../../../components/TextMaskFormInput";

export default function Step1({
  onComplete,
  listTripTypes,
  listContinent,
  handleFetchItems,
}) {
  const [dataIda, setDataIda] = useState("");
  const [dataVolta, setDataVolta] = useState("");

  useEffect(() => {
    handleFetchItems();
  }, []);

  const handleDateMessageValidation = () =>
    showToast(
      "warning",
      "Alerta",
      `A data da ida não pode ser maior que a data do retorno.`
    );

  const handleSubmit = (values) => {
    if (!checkStartDateIsGTOrETEndDate(dataIda, dataVolta)) {
      return onComplete({
        dataIda: moment(dataIda, 'DD/MM/YYYY').format('DD/MM/YYYY'),
        dataVolta: moment(dataVolta, 'DD/MM/YYYY').format('DD/MM/YYYY'),
        formaViagem: values.formaViagem,
        estadoDestino: values.estadoDestino,
        paisDestino: values.paisDestino,
        maisDestino: values.maisDestino,
        continenteDestino: values.continenteDestino
      });
    }
    return handleDateMessageValidation();
  };

  const initialValues = {};

  const validationSchema = Yup.object().shape({
    estadoDestino: Yup.string().required("Estado de destino é obrigatório"),
    paisDestino: Yup.string().required("País de destino é obrigatório"),
    continenteDestino: Yup.string().required("Continente de destino é obrigatório"),
    maisDestino: Yup.string().required("Obrigatório"),
    formaViagem: Yup.string().required("Forma de viagem é obrigatório"),
  });

  const checkStartDateIsGTOrETEndDate = (startDate, endDate) =>
    moment(startDate, "DD/MM/YYYY").format("YYYYMMDD") >
    moment(endDate, "DD/MM/YYYY").format("YYYYMMDD");

  function handleDateValidation(ida, volta) {
    if (checkStartDateIsGTOrETEndDate(ida, volta)) {
      return handleDateMessageValidation();
    }
  }

  return (
    <>
      <Box
        gridColumn={{
          mobile: "2/span 10",
          tablet: "2/span 10",
          desktop: "4/span 6",
        }}
        styling="column"
      >
        <H3 mb="30px" styling="light">Sobre sua Viagem</H3>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          render={({ errors, touched, handleChange, setFieldValue, values }) => (
            <Form translate="no" style={{ width: '100%' }}>
              <Field name="dataIda">
                {({ field }) => (
                  <TextMaskFormInput
                    {...field}
                    data-testid="dataIda"
                    error={touched.dataIda && errors.dataIda}
                    mask={dateMask}
                    name="dataIda"
                    onBlur={e => validateVerify(e)}
                    value={dataIda}
                    onChange={date => {
                      setDataIda(date.target.value)
                      setDataVolta("")
                    }}
                    placeholder="Data de ida"
                    type="text"
                    pipe={date => autoCorrectedDatePipe(date, true, false)}
                  />
                )}
              </Field>
              <Field name="dataVolta">
                {({ field }) => (
                  <TextMaskFormInput
                    {...field}
                    data-testid="dataVolta"
                    error={touched.dataVolta && errors.dataVolta}
                    mask={dateMask}
                    name="dataVolta"
                    onBlur={e => validateVerify(e) || handleDateValidation(dataIda, dataVolta)}
                    onChange={date => {
                      setDataVolta(date.target.value)
                    }}
                    placeholder="Data de retorno"
                    type="text"
                    pipe={date => autoCorrectedDatePipe(date, true, false)}
                    value={dataVolta}
                  />
                )}
              </Field>
              <Field name="estadoDestino">
                {({ field }) => (
                  <FormInput
                    {...field}
                    data-testid="estadoDestino"
                    error={touched.estadoDestino && errors.estadoDestino}
                    name="estadoDestino"
                    id="estadoDestino"
                    onBlur={e => validateVerify(e)}
                    onChange={e => setFieldValue('estadoDestino', e.target.value)}
                    placeholder="Estado de destino"
                    type="text"
                  />
                )}
              </Field>
              <Field name="paisDestino">
                {({ field }) => (
                  <FormInput
                    {...field}
                    data-testid="paisDestino"
                    error={touched.paisDestino && errors.paisDestino}
                    name="paisDestino"
                    id="paisDestino"
                    onBlur={e => validateVerify(e)}
                    onChange={e => setFieldValue('paisDestino', e.target.value)}
                    placeholder="País de destino"
                    type="text"
                  />
                )}
              </Field>
              <Field name="continenteDestino">
                {({ field }) => (
                  <FormSelect
                    {...field}
                    data-testid="continenteDestino"
                    id="continenteDestino"
                    name="continenteDestino"
                    options={
                      listContinent.length
                        ? listContinent.map((continent) => ({
                          value: continent,
                          label: continent,
                        }))
                        : [{ value: "Carregando", label: "Carregando" }]
                    }
                    onChange={({ value }) => setFieldValue('continenteDestino', value)}
                    placeholder="Continente"
                    styling="base"
                  />
                )}
              </Field>
              <Field name="maisDestino">
                {({ field }) => (
                  <FormSelect
                    {...field}
                    data-testid="maisDestino"
                    id="maisDestino"
                    name="maisDestino"
                    options={[
                      { value: "Sim", label: "Sim" },
                      { value: "Não", label: "Não" },
                    ]}
                    onChange={({ value }) => setFieldValue('maisDestino', value)}
                    placeholder="Possui mais de um destino?"
                    styling="base"
                  />
                )}
              </Field>
              <Field name="formaViagem">
                {({ field }) => (
                  <FormSelect
                    {...field}
                    data-testid="formaViagem"
                    id="formaViagem"
                    name="formaViagem"
                    options={
                      listTripTypes.length
                        ? listTripTypes.map((item) => ({
                          value: item,
                          label: item,
                        }))
                        : [{ value: "Carregando", label: "Carregando" }]
                    }
                    onChange={({ value }) => setFieldValue('formaViagem', value)}
                    placeholder="Transporte da viagem"
                    styling="base"
                  />
                )}
              </Field>
              <Button
                display="block"
                margin="0 auto"
                styling="primary"
                type="submit"
                width="195px"
              >
                Próximo
              </Button>
            </Form>
          )}
        />
      </Box>
    </>
  );
}
