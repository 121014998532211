export default {
  about1: {
    height: 320,
    width: 300,
    url: "",
    description: "Imagem Sobre",
    id: "about1"
  },
  contact1: {
    height: 640,
    width: 360,
    url: "",
    description: "Imagem Contato",
    id: "contact1"
  },
  generic1: {
    height: 120,
    width: 114,
    url: "",
    description: "Imagem Genérica",
    id: "generic1"
  },
  main1: {
    height: 1130,
    width: 1076,
    url: "",
    description: "Imagem Home Principal",
    id: "main1"
  },
  main2: {
    height: 911,
    width: 1398,
    url: "",
    description: "Imagem Home Secundária",
    id: "main2"
  },
  logo: {
    height: 24,
    width: 24,
    url: "",
    description: "Logo",
    id: "logo"
  },
  logoFull: {
    height: 32,
    width: 170,
    url: "",
    description: "Logo",
    id: "logo"
  },

}