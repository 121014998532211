/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import uuid from "uuid/v1";
import CollapsibleMode from "../../../components/residence/paymentType/CollapsibleMode";
import StaticMode from "../../../components/residence/paymentType/StaticMode";
import { Box, Button } from "../../../components/ds/SubatomicParticles";
import { H3, P } from "../../../components/ds/theme/typography";
import FormSelect from "../../../components/FormSelect";
import FormInput from "../../../components/FormInput";
import { getLibertyPaymentToken } from "../../../state/context/cotacao/repository";
import { validateVerify } from "../../../utils/validations";

function PaymentTypes({
  onComplete,
  values,
  paymentTypes,
  messages,
  warningMessages,
  errorMessages,
  statusErrorPaymentQuotation,
  onIncomplete,
  onBackToHome,
  isOnFlow,
  paymentContext,
  quoteId,
  onRedirectError,
}) {
  const [selectedPayment, setSelectedPayment] = useState(null);

  const [DebitBankCode, setDebitBankCode] = useState("");
  const [AgencyNumber, setAgencyNumber] = useState("");
  const [CheckingAccountNumber, setCheckingAccountNumber] = useState("");
  const [
    CheckingAccountControlDigit,
    setCheckingAccountControlDigit,
  ] = useState("");
  const [IsThirdAccountHolder, setIsThirdAccountHolder] = useState("");
  const [ThirdAccountHolderName, setThirdAccountHolderName] = useState("");
  const [ThirdPublicIdentification, setThirdPublicIdentification] = useState(
    "",
  );

  const [paymentToken, setPaymentToken] = useState("");
  const [iframeIsOpen, setIframeIsOpen] = useState(false);

  let valorParcelado
  paymentTypes.find(payment => {
    if (payment.paymentName === "Débito em Conta Corrente") {
      payment.paymentModes.map(paymentMode => {
        if (paymentMode.paymentLabel.includes("1+9")) {
          valorParcelado = paymentMode.paymentLabel
          return true
        }
      })
      return true
    }
  })

  useEffect(() => {
    if (selectedPayment && selectedPayment.paymentTypeCode === "CC") {
      getLibertyPaymentToken({
        type: `${paymentContext}Quotes`,
        quoteId,
      }).then((response) => setPaymentToken(response.data.token));
    }
  }, [selectedPayment]);

  useEffect(() => {
    window.scrollTo(0, 100);
  }, []);

  const handleVerifyFields = () => {
    if (selectedPayment.bankAccountInfo) {
      return (
        DebitBankCode !== "" &&
        AgencyNumber !== "" &&
        CheckingAccountNumber !== "" &&
        CheckingAccountControlDigit !== "" &&
        IsThirdAccountHolder !== ""
      );
    }
    return true;
  };

  const renderPaymentButton = () =>
    IsThirdAccountHolder === "F" ||
    (IsThirdAccountHolder === "T" &&
      ThirdPublicIdentification &&
      ThirdPublicIdentification.length > 10 &&
      ThirdAccountHolderName);

  const handleVerifyNames = () => {
    return (
      (IsThirdAccountHolder === "T" &&
        ThirdAccountHolderName !== "" &&
        ThirdPublicIdentification !== "") ||
      (IsThirdAccountHolder === "F" &&
        ThirdAccountHolderName === "" &&
        ThirdPublicIdentification === "")
    );
  };

  const handleBuyQuote = () => {
    if (selectedPayment && handleVerifyFields()) {
      const payment = {
        paymentType: {
          paymentTypeCode: selectedPayment.paymentTypeCode,
          InstallmentNumber: selectedPayment.InstallmentNumber,
        },
      };

      if (handleVerifyNames() || selectedPayment.paymentTypeCode === "FB") {
        if (!selectedPayment.bankAccountInfo) {
          onComplete({
            quoteId,
            payment: {
              ...payment,
              PolicyHolderAccount: {
                ThirdAccountHolderName: "",
                ThirdPublicIdentification: "",
              },
            },
          });
        } else {
          onComplete({
            quoteId,
            payment: {
              ...payment,
              PolicyHolderAccount: {
                DebitBankCode,
                AgencyNumber,
                CheckingAccountNumber,
                CheckingAccountControlDigit,
                IsThirdAccountHolder,
                ThirdAccountHolderName:
                  IsThirdAccountHolder === "F" ? "" : ThirdAccountHolderName,
                ThirdPublicIdentification:
                  IsThirdAccountHolder === "F" ? "" : ThirdPublicIdentification,
              },
            },
          });
        }
      } else {
        return onIncomplete();
      }
    } else {
      onIncomplete();
    }
  };

  if (statusErrorPaymentQuotation === 400) {
    return (
      <Box gridColumn="2/span 10" styling="column">
        <H3 mb="30px" styling="light">
          Atenção
        </H3>
        {warningMessages.map((msg) => (
          <P styling="base" key={uuid()}>
            {msg}
          </P>
        ))}
        {errorMessages.map((msg) => (
          <P styling="base" key={uuid()}>
            {msg}
          </P>
        ))}
        <Button
          display="block"
          margin="15px auto 0"
          onClick={onRedirectError}
          styling="primary"
          width="165px"
        >
          Finalizar
        </Button>
      </Box>
    );
  }

  if (statusErrorPaymentQuotation === 500) {
    return (
      <Box gridColumn="2/span 10" styling="column">
        <H3 mb="30px" styling="light">
          Houve um erro desconhecido.
        </H3>
        <P styling="base" key={uuid()}>
          {messages}
        </P>
        <Button
          display="block"
          margin="15px auto 0"
          onClick={onRedirectError}
          styling="primary"
          width="165px"
        >
          Finalizar
        </Button>
      </Box>
    );
  }

  if (!values)
    return (
      <Box gridColumn="2/span 10" styling="column">
        <H3 mb="30px" styling="light">
          Aguarde...
        </H3>
      </Box>
    );

  return (
    <>
      <Box
        gridColumn={{
          mobile: "2/span 10",
          tablet: "2/span 10",
          desktop: "4/span 6",
        }}
        styling="column"
        width="100%"
      >
        <H3 mb="30px" styling="light">
          Detalhes de pagamentos
        </H3>
        <Box
          borderTop="1px solid"
          borderColor="grays.light"
          justifyContent="space-between"
          py="10px"
          styling="row"
          width="100%"
        >
          <P fontSize="14" styling="base">Premio Líquido</P>
          <P fontSize="15" styling="base">{values.liquid}</P>
        </Box>
        <Box
          borderTop="1px solid"
          borderColor="grays.light"
          justifyContent="space-between"
          py="10px"
          styling="row"
          width="100%"
        >
          <P fontSize="14" styling="base">Adicional Fracionamento</P>
          <P fontSize="15" styling="base">{values.rate}</P>
        </Box>
        <Box
          borderTop="1px solid"
          borderColor="grays.light"
          justifyContent="space-between"
          py="10px"
          styling="row"
          width="100%"
        >
          <P fontSize="14" styling="base">IOF</P>
          <P fontSize="15" styling="base">{values.tax}</P>
        </Box>
        <Box
          borderTop="1px solid"
          borderColor="grays.light"
          justifyContent="space-between"
          py="10px"
          styling="row"
          width="100%"
        >
          <P fontSize="14" styling="regular">A partir de: </P>
          <P fontSize="15" styling="regular">{valorParcelado}</P>
        </Box>

        <H3 my="30px" styling="light">
          Tipos de pagamento
        </H3>
        {paymentTypes.map((paymentType, i) =>
          paymentType.bankAccountInfo ? (
            <CollapsibleMode
              key={`ab${i}`}
              title={paymentType.paymentName}
              paymentTypeCode={paymentType.paymentTypeCode}
              item={paymentType}
              onClick={(label) => setSelectedPayment(label)}
              selectedPayment={selectedPayment}
            />
          ) : (
            <StaticMode
              key={`ab${i}`}
              paymentContext={paymentContext}
              title={paymentType.paymentName}
              paymentTypeCode={paymentType.paymentTypeCode}
              item={paymentType}
              onClick={(label) => setSelectedPayment(label)}
              selectedPayment={selectedPayment}
              quoteId={quoteId}
              IsThirdAccountHolder={IsThirdAccountHolder}
              setIsThirdAccountHolder={setIsThirdAccountHolder}
              ThirdAccountHolderName={ThirdAccountHolderName}
              setThirdAccountHolderName={setThirdAccountHolderName}
              ThirdPublicIdentification={ThirdPublicIdentification}
              setThirdPublicIdentification={setThirdPublicIdentification}
            />
          ),
        )}

        {selectedPayment && selectedPayment.paymentTypeCode === "CC" && (
          <Box mt="30px" styling="column" width="100%">
            <H3 mb="30px" styling="light">
              Dados cartão de crédito
            </H3>

            <FormSelect
              defaultValue={IsThirdAccountHolder}
              onChange={(e) => setIsThirdAccountHolder(e.value)}
              onBlur={(e) => validateVerify(e)}
              placeholder="Cartão informado é de terceiro?"
              options={[
                { label: "Selecione", value: "" },
                { label: "Não", value: "F" },
                { label: "Sim", value: "T" },
              ]}
            />

            {IsThirdAccountHolder === "T" && (
              <Box styling="column" width="100%">
                <FormInput
                  value={ThirdAccountHolderName}
                  placeholder="Nome do Titular do Cartão"
                  onChange={(e) => setThirdAccountHolderName(e.target.value)}
                  onBlur={(e) => validateVerify(e)}
                />
                <FormInput
                  placeholder="CPF do Titular do Cartão"
                  mask="99999999999"
                  value={ThirdPublicIdentification}
                  onChange={(e) => setThirdPublicIdentification(e.target.value)}
                />
              </Box>
            )}
            {renderPaymentButton() && (
              <span className="span-item go-to-card">
                {paymentToken ? (
                  <Button
                    display="block"
                    margin="0 auto"
                    onClick={() => {
                      setIframeIsOpen(true);
                      setSelectedPayment({ bankAccountInfo: false });
                    }}
                    styling="primary"
                    width="295px"
                  >
                    Ir para pagamento com cartão
                  </Button>
                ) : (
                  <P fontSize="14px" styling="base">
                    Gerando dados de pagamento de cartão...
                  </P>
                )}
              </span>
            )}
          </Box>
        )}

        {selectedPayment && selectedPayment.bankAccountInfo && (
          <Box mt="30px" styling="column" width="100%">
            <H3 mb="30px" styling="light">
              Dados conta bancária
            </H3>

            <Box justifyContent="space-between" styling="row" width="100%">
              <Box styling="row" width="49%">
                <FormInput
                  placeholder="Código do banco"
                  mask="999"
                  value={DebitBankCode}
                  onChange={(e) => setDebitBankCode(e.target.value)}
                  onBlur={(e) => validateVerify(e)}
                />
              </Box>
              <Box styling="row" width="49%">
                <FormInput
                  placeholder="Agência"
                  mask="9999"
                  value={AgencyNumber}
                  onChange={(e) => setAgencyNumber(e.target.value)}
                  onBlur={(e) => validateVerify(e)}
                />
              </Box>
            </Box>

            <Box justifyContent="space-between" styling="row" width="100%">
              <Box styling="row" width="49%">
                <FormInput
                  placeholder="Conta"
                  mask="9999999999"
                  value={CheckingAccountNumber}
                  onChange={(e) => setCheckingAccountNumber(e.target.value)}
                  onBlur={(e) => validateVerify(e)}
                />
              </Box>
              <Box styling="row" width="49%">
                <FormInput
                  placeholder="Digito"
                  mask="99"
                  value={CheckingAccountControlDigit}
                  onChange={(e) =>
                    setCheckingAccountControlDigit(e.target.value)
                  }
                  onBlur={(e) => validateVerify(e)}
                />
              </Box>
            </Box>

            <FormSelect
              defaultValue={IsThirdAccountHolder}
              onChange={(e) => setIsThirdAccountHolder(e.value)}
              onBlur={(e) => validateVerify(e)}
              options={[
                { label: "Selecione", value: "" },
                { label: "Não", value: "F" },
                { label: "Sim", value: "T" },
              ]}
              placeholder="Conta informada é de terceiro?"
            />
            {IsThirdAccountHolder === "T" && (
              <Box styling="column" width="100%">
                <FormInput
                  value={ThirdAccountHolderName}
                  placeholder="Nome do Titular da Conta"
                  onChange={(e) => setThirdAccountHolderName(e.target.value)}
                  onBlur={(e) => validateVerify(e)}
                />
                <FormInput
                  placeholder="CPF do Titular da Conta"
                  mask="99999999999"
                  value={ThirdPublicIdentification}
                  onChange={(e) => setThirdPublicIdentification(e.target.value)}
                />
              </Box>
            )}
          </Box>
        )}

        <Modal
          style={{
            content: {
              top: 10,
              left: window.innerWidth <= 400 ? "1%" : "22%",
              bottom: 0,
              width: window.innerWidth <= 400 ? "87%" : "50%",
              height: "90%",
            },
          }}
          isOpen={iframeIsOpen}
        >
          <Box styling="column" width="100%">
            <P
              color="primary.default"
              fontSize="14px"
              mb="10px"
              onClick={() => setIframeIsOpen(false)}
              styling="base"
              textAlign="right"
              width="100%"
            >
              Voltar
            </P>
            <iframe
              id="iframe-liberty"
              title="cc-payment"
              height={`${window.innerHeight}px`}
              width="100%"
              frameBorder="none"
              src={`/pagamento/cartao/credito/${paymentToken}`}
            />
          </Box>
        </Modal>

        {isOnFlow ? (
          <Box
            justifyContent="space-between"
            mb="15px"
            styling="row"
            width="100%"
          >
            <Button
              display="block"
              margin="0 auto"
              onClick={onBackToHome}
              styling="primary"
              width="220px"
            >
              Ok, irei continuar depois
            </Button>
            <Button
              display="block"
              margin="0 auto"
              onClick={handleBuyQuote}
              styling="primary"
              width="220px"
            >
              Adquirir seguro
            </Button>
          </Box>
        ) : (
          <Button
            disabled={!selectedPayment}
            display="block"
            margin="0 auto"
            onClick={handleBuyQuote}
            styling="primary"
            width="295px"
          >
            Adquirir seguro
          </Button>
        )}
      </Box>
    </>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentTypes);
