import React, { useState } from "react";
import { notification } from "antd";

import { ReactComponent as IconAutomovel } from "../assets/svg/icon_auto.svg";
import { ReactComponent as IconResidencial } from "../assets/svg/icon_residencia.svg";
import { ReactComponent as IconViagem } from "../assets/svg/icon_viagem.svg";

export function useFormInput(
  initialValue = "",
  placeholder = "",
  type = "text"
) {
  const [value, setValue] = useState(initialValue);

  const onChange = e => {
    setValue(e.target.value);
  };

  return {
    value,
    placeholder,
    type,
    onChange

  };
}

export function useFormSelect(initialValue = "", placeholder = "") {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  };

  return {
    value,
    onChange: handleChange,
    placeholder
  };
}

export const showToast = (type, message, description, duration = 5) => {
  notification[type]({
    message,
    description,
    duration
  });
};

export const fieldNotFilledAlert = (field) => {
  showToast("warning", "Alerta", `"${field}" não foi preenchido ou não foi preenchido corretamente`);
}

export function useFormDatePicker(initialDate = "", placeholder = "", style) {
  const [value, setValue] = useState(initialDate);

  const onChange = e => {
    setValue(e);
  };

  return {
    value,
    placeholder,
    onChange,
    style: { width: "100%", ...style }
  };
}

export const returnIconToQuotation = type => {
  switch (type) {
    case "vehicleQuotes":
      return <IconAutomovel height={40} width={40} />;

    case "residenceQuotes":
      return <IconResidencial height={40} width={40} />;

    case "travelQuotes":
      return <IconViagem height={40} width={40} />;

    default:
      break;
  }
}

export const scrollToTop = () => window.scrollTo(0, 0)

export const dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
export const dateYearMask = [/\d/, /\d/, /\d/, /\d/];