/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import moment from "moment";
import {
  validateVerify,
  autoCorrectedDatePipe,
  checkLengthOfDate,
} from "../../../utils/validations";
import { fieldNotFilledAlert, dateMask } from "../../../utils";
import { currencyMask } from "../../../utils/masks";
import { Box, Button } from "../../../components/ds/SubatomicParticles";
import { H3 } from "../../../components/ds/theme/typography";
import FormSelect from "../../../components/FormSelect";
import FormInput from "../../../components/FormInput";
import TextMaskFormInput from "../../../components/TextMaskFormInput";

function Step2({
  onNext,
  portableTypes,
  handleGetPortableTypes,
  tipo,
  setTipo,
  dataAquisicao,
  setDataAquisicao,
  numeroNotaFiscal,
  setNumeroNotaFiscal,
  valorAquisicao,
  setValorAquisicao,
  marca,
  setMarca,
  modelo,
  setModelo,
}) {
  useEffect(() => {
    handleGetPortableTypes();
  }, []);

  const _handleIncomplete = () => {
    if (tipo === "") fieldNotFilledAlert("Tipo");
    if (marca === "") fieldNotFilledAlert("Marca");
    if (modelo === "") fieldNotFilledAlert("Modelo");
    if (!dataAquisicao || checkLengthOfDate(dataAquisicao) !== 8)
      fieldNotFilledAlert("Data da Aquisição");
    if (valorAquisicao === "") fieldNotFilledAlert("Valor da Aquisição");
    if (numeroNotaFiscal === "") fieldNotFilledAlert("Número da nota fiscal");
  };

  const _handleVerify = () => {
    if (
      tipo === "" ||
      marca === "" ||
      modelo === "" ||
      !dataAquisicao ||
      checkLengthOfDate(dataAquisicao) !== 8 ||
      valorAquisicao === "" ||
      numeroNotaFiscal === ""
    ) {
      _handleIncomplete();
    } else {
      onNext({
        tipo,
        marca,
        modelo,
        dataAquisicao: moment(dataAquisicao, "DD/MM/YYYY").format("DD/MM/YYYY"),
        valorAquisicao: valorAquisicao,
        numeroNotaFiscal,
      });
    }
  };

  return (
    <>
      <Box
        gridColumn={{
          mobile: "2/span 10",
          tablet: "2/span 10",
          desktop: "4/span 6",
        }}
        styling="column"
        pb={"30rem"}
      >
        <H3 mb="30px" styling="light">
          Sobre o usuário do equipamento
        </H3>
        <FormSelect
          defaultValue={tipo}
          options={
            portableTypes.length &&
            portableTypes.map((value) => ({
              value,
              label: value,
            }))
          }
          onChange={(e) => setTipo(e.value)}
          onBlur={(e) => validateVerify(e)}
          placeholder="Selecione o tipo"
          styling="base"
        />
        <FormInput
          onBlur={(e) => validateVerify(e)}
          value={numeroNotaFiscal}
          onChange={(e) => setNumeroNotaFiscal(e.target.value)}
          placeholder="Número da nota fiscal"
          type="number"
        />
        <FormInput
          onBlur={(e) => validateVerify(e)}
          value={marca}
          placeholder="Marca"
          onChange={(e) => setMarca(e.target.value)}
        />
        <FormInput
          onBlur={(e) => validateVerify(e)}
          value={modelo}
          placeholder="Modelo"
          onChange={(e) => setModelo(e.target.value)}
        />
        <TextMaskFormInput
          value={dataAquisicao}
          onChange={(e) => setDataAquisicao(e.target.value)}
          mask={dateMask}
          pipe={(date) => autoCorrectedDatePipe(date, false)}
          placeholder="Data da Aquisição"
          onBlur={(e) => validateVerify(e)}
        />
        <TextMaskFormInput
          placeholder="Valor da Aquisição"
          value={valorAquisicao}
          mask={currencyMask}
          onChange={(e) => setValorAquisicao(e.target.value)}
          onBlur={(e) => validateVerify(e)}
          styling="base"
        />
        <Button styling="primary" onClick={_handleVerify} width="296px" mt={25}>
          Próximo
        </Button>
      </Box>
    </>
  );
}

export default Step2;
