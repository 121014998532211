import React, { memo, useEffect, useState, useMemo } from 'react';
import { connect } from "react-redux";
import capitalize from "lodash/capitalize";
import { useRouteMatch, useParams, useLocation } from 'react-router-dom';
import {
  Formik,
  Field,
  FieldArray,
  FieldProps,
  FieldMetaProps,
  Form as FormikForm,
} from 'formik';

import localeDefault from '../../../../assets/locale/default';
import { getLocale, editLocale, createLocale } from '../../../../state/context/locales/services';
import { Box, Button } from '../../../../components/ds/SubatomicParticles';
import { Locale } from '../../../../state/context/locales/types';
import Breadcrumbs from '../../../../components/ds/atoms/Breadcrumbs';
import Loading from '../../../../components/SmallLoading';
import { Props, State } from './types';
import { Schema } from './schema';
import { P, Label } from '../../../../components/ds/theme/typography';
import css from '@styled-system/css';
import { Span } from '../../../../components/ds/theme/typography/Span/Span';

const Form: React.FC<Props> = props => {
  const location = useLocation();
  const { id } = useParams();
  const matchEdit = useRouteMatch('/admin/locales/edit/:id');
  const { getLocale } = props;
  const [dataForm, setDataForm] = useState<any>(
    matchEdit ?
      {
        locales: []
      } :
      {
        locales:
          Object.keys(localeDefault)
            .map(locale => ({
              // @ts-ignore
              [locale]: localeDefault[locale]
            }))
      }
  );

  const steps = useMemo(() => {
    if (matchEdit) {
      return [
        {
          path: '/admin/locales',
          name: 'Locales',
        },
        {
          path: location.pathname,
          name: 'Editar',
        },
      ];
    }
    return [
      {
        path: '/admin/locales',
        name: 'Locales',
      },
      {
        path: location.pathname,
        name: 'Cadastrar',
      },
    ];
  }, [location, matchEdit]);

  useEffect(() => {
    (async function loadingPage() {
      if (id && matchEdit) {
        const locales = await getLocale(id);
        setDataForm({
          locales:
            Object.keys(locales)
              .map(locale => ({
                // @ts-ignore
                [locale]: locales[locale]
              }))
        });
      }
    })();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Box
        styling="column"
        width="100%"
        height="100%"
        padding="30px"
        position="relative"
      >
        <Breadcrumbs
          styling="base"
          steps={steps}
          backPath="/admin/locales"
        />
        <Box
          styling="column"
          width="100%"
          minHeight="calc(100vh - 108px)"
          position="relative"
          justifyContent="flex-start"
          pt="30px"
        >
          <Formik
            enableReinitialize
            initialValues={dataForm}
            validationSchema={Schema}
            onSubmit={
              async (values, { setSubmitting }) => {
                const locale = values.locales.reduce((prev: any, curr: any) => {
                  const key = Object.keys(curr)[0];
                  prev[key] = curr[key]
                  return prev;
                }, {});

                if (id && matchEdit) {
                  await props.editLocale(id, locale);
                  setSubmitting(false);
                } else {
                  await props.createLocale(locale);
                  setSubmitting(false);
                }
              }
            }
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <FormikForm translate='no' style={{ height: "100%", width: "100%" }}>
                <Box
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  mb="15px"
                  height="calc(100vh - 190px)"
                  overflowY="scroll"
                  styling="column"
                >
                  <Box
                    display="block"
                    styling="column"
                    width="100%"
                  >
                    <Box
                      styling="grid"
                      gridTemplateColumns="repeat(2, 1fr)"
                      gridGap="30px"
                      width="100%"
                    >
                      <FieldArray
                        name="locales"
                        render={() => (
                          values.locales.map((locale: Locale, index: number) => {
                            const nameLocale = Object.keys(locale)[0];
                            return (
                              <Field key={nameLocale} name={`locales.${index}.${nameLocale}`}>
                                {({ field, meta }: FieldProps & FieldMetaProps<string>) => (
                                  <Box styling="column">
                                    <Label styling="base"color="#3f51b5" fontFamily="OpenSans Semibold" mb="10px" fontSize="16px">{capitalize(nameLocale.replace(/_/g, ' '))}</Label>
                                    <Span
                                      css={css({
                                        width: '100%',
                                        'textarea': {
                                          width: '100%',
                                          borderRadius: '4px',
                                          backgroundColor: '#FFF',
                                          resize: 'none',
                                          fontFamily: 'OpenSans Regular',
                                          padding: '2px 10px',
                                          border: '1px solid #cecece6e'
                                        }
                                      })}>
                                      <textarea {...field} rows={6} name={`locales.${index}.${nameLocale}`} />
                                    </Span>
                                    {meta.touched && meta.error && meta.error && (
                                      <P
                                        data-testid={`${nameLocale}Error`}
                                        fontSize="12px"
                                        styling="error"
                                        width="100%"
                                      >
                                        {meta.error}
                                      </P>
                                    )}
                                  </Box>
                                )}
                              </Field>
                            );
                          })
                        )}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  styling="row"
                  justifyContent="flex-end"
                  mt="15px"
                >
                  <Button
                    styling="outlineAdmin"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Salvar
              </Button>
                </Box>
              </FormikForm>
            )}
          </Formik>
        </Box>

      </Box>
      {props.isLoading && (
        <Box
          styling="column"
          height="100vh"
          width="100%"
          zIndex={99999}
          bg="rgba(240, 241, 242, 0.62)"
          position="absolute"
        >
          <Loading />
        </Box>
      )}
    </>
  )
};

const mapStateToProps = (state: State) => ({
  isLoading: state.locales.isLoading
});

const mapDispatchToProps = {
  getLocale,
  editLocale,
  createLocale
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(Form as any));