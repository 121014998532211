import React from "react";
import { Box, Button } from "../../../../components/ds/SubatomicParticles";
import { H3, P } from "../../../../components/ds/theme/typography";

const ModalSuccessSinistro = ({ redirect, name, onRedirect }) => {
  return (
    <Box
      backgroundColor="background.default"
      borderRadius="12px"
      maxWidth="415px"
      padding="2rem"
      position="fixed"
      styling="cloumn"
      width="90%"
      zIndex="2"
    >
      <H3 styling="light">
        {`${name ? `${name}, entraremos` : "Entraremos"} em contato nos próximos minutos.`}
      </H3>
      <P mt="30px" styling="base">Nosso atendimento é de segunda a sexta de 08:30 às 18:00 horas.</P>
      <P my="30px" styling="base">Nosso número: 0800 941 4980</P>
      <Button display="block" margin="0 auto" onClick={onRedirect} styling="primary" width="90%">Ok, obrigado(a)!</Button>
    </Box>
  );
};

export default ModalSuccessSinistro;
