export type Locale = {
  [key: string]: string
};

export interface State {
  locales: Locale[];
  isLoading: boolean;
}

export const SET_LOCALES = "locale/SET_LOCALES";
export const IS_LOADING = "locale/IS_LOADING";
