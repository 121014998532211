import firebase from 'firebase';

const headers = async () => {
  let token, uid;
  const currentUser = firebase.auth().currentUser;
  if ( currentUser !== null) {
    token = await currentUser.getIdToken();
    sessionStorage.setItem("tokenId", token);
    sessionStorage.setItem("uid", currentUser.uid);
    uid = currentUser.uid;
  } else {
    token = sessionStorage.getItem("tokenId");
    uid = sessionStorage.getItem("uid");
  }
  return ({
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
      uid,
      midia: '1'
    })
}
    
export default headers; 

// getIdToken(forceRefresh?: boolean): Promise<string>
// Returns the current token if it has not expired. Otherwise, this will refresh the token and return a new one.