/* eslint react-hooks/rules-of-hooks: off */
import React, { useState } from "react";
import { Box, Button } from "../../../components/ds/SubatomicParticles";
import { H3 } from "../../../components/ds/theme/typography";
import FormSelect from "../../../components/FormSelect";
import { validateVerify } from "../../../utils/validations";
import { fieldNotFilledAlert } from "../../../utils";
import FormInput from "../../../components/FormInput";

export default function step3({ pets, quantidade, onIncomplete, onNext }) {
  const [index, setIndex] = useState(0);

  const [idade, setIdade] = useState("");
  const [type, setType] = useState("");
  const [race, setRace] = useState("");

  const _handleOnNext = () => {
    pets[index].idade = idade;
    pets[index].tipo = type;
    pets[index].raca = race;

    if (
      pets[index].idade === "" ||
      pets[index].tipo === "" ||
      pets[index].raca === ""
    ) {
      onIncomplete();
      if (pets[index].idade === "") fieldNotFilledAlert("Idade");
      if (pets[index].raca === "") fieldNotFilledAlert("Raça");
      if (pets[index].tipo === "") fieldNotFilledAlert("Espécie");
    } else {
      if (quantidade === index + 1) {
        if (pets[index].idade === "" ||
          pets[index].tipo === "" ||
          pets[index].raca === "") {
          if (idade === "") fieldNotFilledAlert("Idade");
          if (race === "") fieldNotFilledAlert("Raça");
          if (type === "") fieldNotFilledAlert("Espécie");
        } else {
          onNext(pets);
        }
      } else {
        setIdade("");
        setType("");
        setRace("");
        setIndex(index + 1);
      }
    }
  };


  return (
    <>
      <Box
        gridColumn={{
          mobile: "2/span 10",
          tablet: "2/span 10",
          desktop: "4/span 6",
        }}
        styling="column"
        pb={"30rem"}
      >
        <H3 mb="30px" styling="light">
          Queremos conhecer mais sobre
          {pets[index].sexo === "macho" ? " o " : " a "}
          {pets[index].nome.charAt(0).toUpperCase() + pets[index].nome.slice(1)}
        </H3>
        <FormInput
          placeholder="Idade"
          min="0"
          max="100"
          maxLength={3}
          id="idade"
          type="number"
          value={idade}
          onChange={(e) => {
            if (e.target.value < 0) {
              e.target.value = 0;
            }
            setIdade(e.target.value)
          }}
          onBlur={(e) => validateVerify(e)}
        />
        <FormSelect
          data-testid="petType"
          id="petType"
          name="petType"
          options={[
            { value: "", label: "Selecione" },
            { value: "canideo", label: "Cão" },
            { value: "felino", label: "Felino" },
          ]}
          value={type}
          onChange={(e) => setType(e.value)}
          onBlur={(e) => validateVerify(e)}
          placeholder="Espécie"
          styling="base"
        />
        <FormInput
          data-testid="race"
          id="race"
          name="race"
          onBlur={(e) => validateVerify(e)}
          onChange={(e) => setRace(e.target.value)}
          placeholder="Raça"
          type="text"
          value={race}
        />

        <Button styling="primary" onClick={_handleOnNext} width="296px" mt={25}>
          Próximo
        </Button>
      </Box>
    </>
  );
}
