import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { validateVerify, checkLengthOfDate } from "../../../utils/validations";
import "moment/locale/pt-br";

import { Box, Button } from "../../../components/ds/SubatomicParticles";
import { H3 } from "../../../components/ds/theme/typography";
import FormInput from "../../../components/FormInput";
import Radio from "../../../components/ds/atoms/Radio";
import { getVehicleByPlateOrChassis } from "../../../state/context/form/services";
import {showModal} from "../../../state/context/user/services";

const Register = ({
  onComplete,
  onIncomplete,
  myVehiclesLoading,
  getVehicleBrands,
  myVehiclesList,
  getVehicleByPlateOrChassis,
  showModal,
}) => {
  const [manufactureYear, setManufactureYear] = useState("");
  const [plate, setPlate] = useState("");
  const [chassis, setChassis] = useState("");
  const [type, setType] = useState("");
  const [noPlate, setNoPlate] = useState("");
  const [modeloVeiculo, setModeloVeiculo] = useState("");
  const [anoModelo, setAnoModelo] = useState("");
  const [objectToSend, setObjectToSend] = useState({});

  useEffect(() => {
    getVehicleBrands();
    setType('plate');
  }, [getVehicleBrands]);

  const verifyPlateAndChassis = () =>
    !myVehiclesList.filter(
      (v) => v.vehicleData.Chassis === chassis || v.vehicleData.Plate === plate,
    ).length;

  const handleVerify = () =>
    (plate || noPlate) &&
    chassis &&
    verifyPlateAndChassis() &&
    manufactureYear &&
    checkLengthOfDate(manufactureYear) === 4;

  const handleRegister = () => {
    if (handleVerify()) {
      onComplete(objectToSend);
    } else {
      onIncomplete();
    }
  };

  const handleSearchVerify = () => {
    const value = type === "plate"? plate : chassis 
    return value && verifyPlateAndChassis();
  };
  
  const searchVehicle = async () => {
    if (handleSearchVerify()) {
      const value = type === "plate"? plate.replace(/[-&( )]/g, "").toUpperCase() : chassis
      const vehicleInfo = await getVehicleByPlateOrChassis(type, value);
      if(vehicleInfo.message){
        showModal({
          visible: true,
          type: "modalMessage",
          data: {
            title: vehicleInfo.data.message,
            message: "Não há dados para o veículo informado.",
          },
        });
      } else{
        handleVehicleInfo(vehicleInfo);
      }
    } else {
      onIncomplete();
    }
  };

  const handleVehicleInfo = (vehicleInfo) => {
    const vehicleData = vehicleInfo.vehicleData
    if(vehicleData.Plate){
      setPlate(vehicleData.Plate);
      setNoPlate(false)
    } else{
      setNoPlate(true)
    }
    setChassis(vehicleData.Chassis);
    setAnoModelo(vehicleData.ModelYear);
    setManufactureYear(vehicleData.ManufactureYear);
    setModeloVeiculo(vehicleInfo.description);
    setObjectToSend({
      description: vehicleInfo.description,
      vehicleType: "1",
      vehicleData: {
        ModelYear: vehicleData.ModelYear,
        Fuel: vehicleData.Fuel,
        FIPECode: vehicleData.FIPECode,
        ManufactureYear: vehicleData.ManufactureYear,
        Plate: noPlate ? "" : vehicleData.Plate,
        Chassis: vehicleData.Chassis
      }
    });
  }

  const objectIsEmpty = (obj) => Object.keys(obj).length === 0;

  return (
    <>
      <Box
        gridColumn={{
          mobile: "2/span 10",
          tablet: "2/span 10",
          desktop: "4/span 6",
        }}
        styling="column"
        pb={"30rem"}
      >
        <H3 mb="30px" styling="light">
          Registro de Automóvel
        </H3>
        { objectIsEmpty(objectToSend) ? 
        (<>
         <Box display="flex" alignItems="center" width="100%">
          <Box flex="1">
            <Radio
              label="Placa"
              id="placa"
              name="type"
              value="plate"
              checked={type === "plate"}
              onChange={(e) => {
                setType(e.target.value);
              }}
            />
          </Box>
          <Box flex="1">
            <Radio
              label="Chassi"
              id="chassi"
              name="type"
              value="chassis"
              checked={type === "chassis"}
              onChange={(e) => {
                setType(e.target.value);
              }}
            />
          </Box>
        </Box>
        <Box width="100%">
          { type === "plate"? 
            <FormInput
                data-testid="plate"
                id="plate"
                name="plate"
                value={plate}
                maxLength={8}
                onBlur={(e) => validateVerify(e)}
                onChange={(e) => setPlate(e.target.value.toUpperCase())}
                placeholder="Placa"
                type="text"
              />
             :
            <FormInput
                data-testid="chassis"
                id="chassis"
                name="chassis"
                value={chassis}
                maxLength={17}
                onChange={(e) => setChassis(e.target.value.toUpperCase())}
                placeholder="Chassi"
                onBlur={(e) => validateVerify(e)}
              /> 
          }
        </Box>
        <Button styling="primary" onClick={searchVehicle} width="296px">
          {myVehiclesLoading ? "•••" : "Pesquisar"}
        </Button> 
        </> ) : 
        (<>
          <Box width="100%">
            <FormInput
              data-testid="plate"
              id="plate"
              name="plate"
              value={plate || "-"}
              onBlur={(e) => validateVerify(e)}
              onChange={(e) => setPlate(e.target.value.toUpperCase())}
              disabled={true}
              placeholder="Placa"
              type="text"
            />
            <FormInput
              data-testid="chassis"
              id="chassis"
              name="chassis"
              value={chassis}
              maxLength={17}
              onChange={(e) => setChassis(e.target.value.toUpperCase())}
              disabled={true}
              placeholder="Chassi"
              onBlur={(e) => validateVerify(e)}
            /> 
            <FormInput
              id="modeloVeiculo"
              name="modeloVeiculo"
              value={modeloVeiculo}
              onChange={(e) => setModeloVeiculo(e.target.value.toUpperCase())}
              disabled={true}
              placeholder="Modelo do veículo"
              onBlur={(e) => validateVerify(e)}
            /> 
            <FormInput
              data-testid="vehicleYear"
              id="vehicleYear"
              mask={"9999"}
              name="vehicleYear"
              onBlur={(e) => validateVerify(e)}
              onChange={(e) => {
                setAnoModelo(e.target.value);
              }}
              disabled={true}
              placeholder="Ano modelo do veículo"
              type="text"
              value={anoModelo}
            />
            <FormInput
              data-testid="dateYear"
              id="dateYear"
              mask={"9999"}
              name="dateYear"
              onBlur={(e) => validateVerify(e)}
              onChange={(e) => {
                setManufactureYear(e.target.value);
              }}
              disabled={true}
              placeholder="Ano de fabricação"
              type="text"
              value={manufactureYear}
            />
          </Box>
          <Button styling="primary" onClick={handleRegister} width="296px">
            {myVehiclesLoading ? "•••" : "Confirmar"}
          </Button> 
        </>)
      }
      </Box>
    </>
  );
};

const mapDispatch = {
  getVehicleByPlateOrChassis: getVehicleByPlateOrChassis,
  showModal: showModal,
}; 

export default connect(null, mapDispatch)(Register);
