/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import * as cotacaoService from "../../state/context/cotacao/services";
import * as userService from "../../state/context/user/services";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import Card from "../../components/card";
import SmallLoading from "../../components/SmallLoading";
import { Box, Button } from "../../components/ds/SubatomicParticles";
import { H2,P } from "../../components/ds/theme/typography";
import Icon from "../../components/ds/atoms/Icon";
import { ReactComponent as Circle } from "../../assets/svg/circle.svg";
import { ReactComponent as Dots } from "../../assets/svg/dots.svg";

function MinhaConta({
  user,
  history,
  getInsurances,
  showModal,
  getQuotes,
  infos,
  loadingQuote,
  insuranceLoading,
  myInsurances,
  myQuotes,
}) {
  const [hasSearchedInsurance, setHasSearchedInsurance] = useState(false);
  const [indicator, setIndicator] = useState(false);

  const arrAuto = myInsurances.filter(
    (insurance) => insurance.tipoControle === "VEICULOS",
  );
  const arrVida = myInsurances.filter(
    (insurance) => insurance.tipoControle === "VIDA",
  );
  const arrResidencia = myInsurances.filter(
    (insurance) => insurance.tipoControle === "LOCAIS",
  );
  const arrPortateis = myInsurances.filter(
    (insurance) => insurance.tipoControle === "PORTATEIS",
  );
  const arrViagem = myInsurances.filter(
    (insurance) => insurance.tipoControle === "VIAGENS",
  );
  const arrPets = myInsurances.filter(
    (insurance) => insurance.tipoControle === "PETS",
  );
  const arrBike = myInsurances.filter(
    (insurance) => insurance.tipoControle === "BIKE",
  );

  useEffect(() => {
    setHasSearchedInsurance(false);
    getQuotes();
    getInsurances().then(() => {
      setHasSearchedInsurance(true);
    });
  }, []);

  const handleColor = () => {
    setTimeout(() => {
      setIndicator(false);
    }, 1000);
  };

  user.nome && sessionStorage.setItem("nome", user.nome);

  const renderOtherSecures = () => {
    return (
      <>
        <Card
          type="automovel"
          title="Automóvel"
          onPress={() => {
            history.push("/automovel");
          }}
        />
        <Card
          type="vida"
          title="Vida"
          onPress={() => {
            history.push("/vida");
          }}
        />
        <Card
          type="residencia"
          title="Residência"
          onPress={() => {
            history.push("/residencial");
          }}
        />
        <Card
          type="portatil"
          title="Portáteis"
          onPress={() => {
            history.push("/portateis");
          }}
        />
        <Card
          type="pets"
          title="Pets"
          onPress={() => {
            history.push("/pets");
          }}
        />
        <Card
          type="viagem"
          title="Viagem"
          onPress={() => {
            history.push("/viagem");
          }}
        />
        <Card
          type="bike"
          title="Bike"
          onPress={() => {
            history.push("/bike");
          }}
        />
      </>
    );
  };

  const renderMySecures = () => {
    return insuranceLoading && myInsurances.length === 0 ? (
      <Box styling="row">
        <SmallLoading />
      </Box>
    ) : (
      <>
        {arrAuto.length > 0 && (
          <Card
            active
            type="automovel"
            title="Automovel"
            onPress={() => {
              history.push({
                pathname: "/info",
                state: { type: "automovel", data: arrAuto },
              });
            }}
          />
        )}
        {arrVida.length > 0 && (
          <Card
            active
            type="vida"
            title="Vida"
            onPress={() => {
              history.push({
                pathname: "/info",
                state: { type: "vida", data: arrVida },
              });
            }}
          />
        )}
        {arrResidencia.length > 0 && (
          <Card
            active
            type="residencia"
            title="Residencia"
            onPress={() => {
              history.push({
                pathname: "/info",
                state: { type: "residencia", data: arrResidencia },
              });
            }}
          />
        )}
        {arrPortateis.length > 0 && (
          <Card
            active
            type="portatil"
            title="Portateis"
            onPress={() => {
              history.push({
                pathname: "/info",
                state: { type: "portateis", data: arrPortateis },
              });
            }}
          />
        )}
        {arrPets.length > 0 && (
          <Card
            active
            type="pets"
            title="Pets"
            onPress={() => {
              history.push({
                pathname: "/info",
                state: { type: "pets", data: arrPets },
              });
            }}
          />
        )}
        {arrViagem.length > 0 && (
          <Card
            active
            type="viagem"
            title="Viagem"
            onPress={() => {
              history.push({
                pathname: "/info",
                state: { type: "viagem", data: arrViagem },
              });
            }}
          />
        )}
        {arrBike.length > 0 && (
          <Card
            active
            type="bike"
            title="Bike"
            onPress={() => {
              history.push({
                pathname: "/info",
                state: { type: "bike", data: arrViagem },
              });
            }}
          />
        )}
        {hasSearchedInsurance && myInsurances.length === 0 && (
          <Box styling="row">
            <P styling="base">Você ainda não tem seguros.</P>
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      <Box
        gridTemplateColumns="repeat(12,1fr)"
        gridRowGap="1.5rem"
        minHeight="100vh"
        position="relative"
        styling="grid"
        width="100%"
      >
        <Box
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          gridColumn="6/span 5"
          gridRow="2/span 3"
          left="25%"
          position="absolute"
          styling="column"
          top="-35%"
          zIndex="-1"
        >
          <Icon styling="baseNone">
            <Circle />
          </Icon>
        </Box>

        {/* <Box
          backgroundColor="background.default"
          borderRadius="12px"
          boxShadow="10px 10px 28px 1px rgba(0,0,0,.1)"
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "4/span 6",
          }}
          gridRow="2"
          justifyContent="space-between"
          padding="16px"
          styling="row"
        >
          <P styling="base">Você tem {myQuotes.length} cotações</P>
          {loadingQuote ? (
            <SmallLoading />
          ) : (
            <Button
              onClick={() => {
                if (myQuotes.length !== 0) {
                  history.push({ pathname: "/cotacoes" });
                } else {
                  setIndicator(true);
                  window.scrollTo(0, document.body.scrollHeight);
                  handleColor();
                }
              }}
              styling="primary"
              width="120px"
            >
              {myQuotes.length !== 0 ? "Ver" : "Fazer cotação"}
            </Button>
          )}
        </Box> */}

        <Box
          alignItems="flex-start"
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "4/span 6",
          }}
          gridRow="2"
          styling="column"
        >
          <H2 styling="light">Meus Seguros</H2>
          <P styling="base" style={{fontSize:"11px"}}>* Seguros recentes podem demorar alguns minutos para serem apresentados</P>
        </Box>

        <Box
          alignItems="flex-start"
          flexWrap="wrap"
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "4/span 6",
          }}
          gridRow="3"
          styling="row"
        >
          {insuranceLoading && myInsurances.length !== 0 ? (
            <Box styling="row">
              <SmallLoading />
            </Box>
          ) : (
            renderMySecures()
          )}
        </Box>

        <Box
          alignItems="flex-start"
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "4/span 6",
          }}
          gridRow="4"
          styling="column"
        >
          <H2 className={`t2 ${indicator ? 'active' : ''}`} styling="light">Contratar outros seguros</H2>
        </Box>

        <Box
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          gridColumn="7/span 4"
          gridRow="4/span 4"
          position="absolute"
          styling="column"
          top="0"
          zIndex="-1"
        >
          <Icon styling="baseNone">
            <Circle />
          </Icon>
        </Box>

        <Box
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          gridColumn="9"
          gridRow="4"
          height="178px"
          position="absolute"
          top="-20px"
          width="62px"
          zInex="-1"
        >
          <Icon styling="baseNone" height="178px" width="62px">
            <Dots />
          </Icon>
        </Box>

        <Box
          alignItems="flex-start"
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "4/span 6",
          }}
          gridRow="5"
          justifyContent="flex-start"
          flexWrap="wrap"
          styling="row"
        >
          {renderOtherSecures()}
        </Box>
        {myInsurances.length !== 0 && (
          <Box gridColumn="4/span 6" gridRow="7" styling="column">
            <Button
              display="block"
              margin="0 auto"
              onClick={() => {
                history.push({
                  pathname: "/sinistro",
                  state: { type: "type", data: "other" },
                });
              }}
              styling="primary"
              width="165px"
            >
              Comunicar Sinistro
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  infos: state.user,
  myQuotes: state.user.myQuotes,
  loadingQuote: state.user.quotesLoading,
  insuranceLoading: state.user.insuranceLoading,
  myInsurances: state.user.myInsurances,
});

const mapDispatchToProps = {
  getInsurances: cotacaoService.getInsurances,
  getQuotes: cotacaoService.BuscaCotacoes,
  showModal: userService.showModal,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(MinhaConta);
