import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { State, Props } from "./types";
import { getImages } from "../../state/context/config/selector";

const InjectImages = <P extends Props>(Component: React.FC<P>) => (
  props: any,
) => <Component {...props} />;

const mapState = (state: State) => ({
  images: getImages(state),
});

export default compose(connect(mapState), InjectImages);
