import firebase from "firebase";
import { Theme } from "./types";

export const getThemes = () =>
  firebase
    .firestore()
    .collection('theme')
    .get()
    .then(function (querySnapshot) {
      const docs: Theme[] = [];
      querySnapshot.forEach(function (doc) {
        docs.push({ ...doc.data() as Theme, id: doc.id });
      });
      return docs;
    });

export const getTheme = (themeId: string) =>
  firebase
    .firestore()
    .collection('theme')
    .doc(themeId)
    .get()
    .then(doc => doc.data());

export const editTheme = (themeId: string, theme: Theme) =>
  firebase
    .firestore()
    .collection('theme')
    .doc(themeId)
    .update(theme);

export const createTheme = (clientId: string, theme: Theme) =>
  firebase
    .firestore()
    .collection('theme')
    .doc(clientId)
    .set(theme);

export const deleteTheme = (themeId: string) =>
  firebase
    .firestore()
    .collection('theme')
    .doc(themeId)
    .delete();