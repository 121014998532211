import { SET_USERS, IS_LOADING, User } from './types';

export const setUsers = (users: User[]) => ({
  type: SET_USERS,
  users
});

export const isLoading = (isLoading: boolean) => ({
  type: IS_LOADING,
  isLoading: isLoading,
});