import React, { memo } from 'react';
import { Field, FieldProps, useFormikContext } from 'formik';

import { Box } from '../../../../../components/ds/SubatomicParticles';
import { H3 } from '../../../../../components/ds/theme/typography';
import ColorPicker from '../../../../../components/ColorPicker';
import { Props } from './types';

const Color: React.FC<Props> = props => {
  const { values } = useFormikContext() as any;
  return (
    <>
      {props.default !== false &&
        <>
          {values[props.name] && values[props.name].default && (
            <Field name={`${props.name}.default`}>
              {({ field }: FieldProps<string>) => (
                <Box styling="row" mb="15px" >
                  <H3
                    color="#1B2B37"
                    fontSize="12px"
                    lineHeight="16px"
                    mr="10px"
                    minWidth="60px"
                    styling="base"
                  >
                    default:
              </H3>
                  <ColorPicker {...field} />
                </Box>
              )}
            </Field>
          )}
        </>
      }
      {props.dark !== false &&
        <>
          {values[props.name] && values[props.name].dark && (
            <Field name={`${props.name}.dark`}>
              {({ field }: FieldProps<string>) => (
                <Box styling="row" mb="10px" >
                  <H3
                    color="#1B2B37"
                    fontSize="12px"
                    lineHeight="16px"
                    mr="10px"
                    minWidth="60px"
                    styling="base"
                  >
                    dark:
              </H3>
                  <ColorPicker {...field} />
                </Box>
              )}
            </Field>
          )}
        </>
      }
      {props.light !== false &&
        <>
          {values[props.name] && values[props.name].light && (
            <Field name={`${props.name}.light`}>
              {({ field }: FieldProps<string>) => (
                <Box styling="row" mb="10px" >
                  <H3
                    color="#1B2B37"
                    fontSize="12px"
                    lineHeight="16px"
                    mr="10px"
                    minWidth="60px"
                    styling="base"
                  >
                    light:
              </H3>
                  <ColorPicker {...field} />
                </Box>
              )}
            </Field>
          )}
        </>
      }
      {props.contrast !== false &&
        <>
          {values[props.name] && values[props.name].contrast && (
            <Field name={`${props.name}.contrast`}>
              {({ field }: FieldProps<string>) => (
                <Box styling="row" mb="10px" >
                  <H3
                    color="#1B2B37"
                    fontSize="12px"
                    lineHeight="16px"
                    mr="10px"
                    minWidth="60px"
                    styling="base"
                  >
                    contrast:
              </H3>
                  <ColorPicker {...field} />
                </Box>
              )}
            </Field>
          )}
        </>
      }
    </>
  );
};

export default memo(Color);