import React, { memo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { variant, VariantArgs } from 'styled-system';
import classnames from 'classnames';
import { ReactComponent as IconCheck } from './assets/check.svg';
import { ReactComponent as IconX } from './assets/x.svg';
import { SwitchButtonProps, SwitchButtonStyledProps } from './index';
import { defaults } from '../../SubatomicParticles';
import { P } from '../../theme/typography';
import Icon from '../Icon';

const variants = variant({
  prop: 'styling',
  variants: {
    base: {
      alignItems: 'center',
      borderRadius: 'sm',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      height: '50px',
      width: '100%',
      input: {
        display: 'none',
      },
      label: {
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        fontFamily: 'OpenSans Regular',
        fontSize: 1,
        height: '28px',
        justifyContent: 'flex-start',
        position: 'relative',
        '&:before': {
          bg: 'danger.default',
          borderRadius: 'sm',
          content: JSON.stringify(''),
          display: 'block',
          height: '28px',
          mr: 'xs',
          transition: '0.3s ease-in-out background',
          width: '60px',
        },
        '&:after': {
          bg: 'whites.1',
          borderRadius: 'sm',
          content: JSON.stringify(''),
          display: 'block',
          height: '24px',
          left: '2px',
          position: 'absolute',
          top: '2px',
          transition: '0.3s ease-in-out left',
          width: '24px',
          zIndex: '10',
        },
        '.icon': {
          color: 'whites.1',
          display: 'block',
          height: '24px',
          left: '4px',
          lineHeight: 'normal',
          position: 'absolute',
          top: '2px',
          width: '24px',
          zIndex: '9',
          '&.icon-x': {
            left: '32px',
            svg: {
              line: {
                strokeWidth: '2',
                transition: '0.3s stroke-dashoffset ease-out',
                transitionDelay: '0.3s',
                strokeDasharray: '20',
                strokeDashoffset: '0',
                '&:last-of-type': {
                  transitionDelay: '0.6s',
                },
              },
            },
          },
          '&.icon-checked': {
            svg: {
              strokeWidth: '2',
              transition: '0.3s stroke-dashoffset ease-out',
              transitionDelay: '0.3s',
              strokeDasharray: '25',
              strokeDashoffset: '-25',
            },
          },
        },
      },
      'input[type="checkbox"]:checked + label': {
        '&:before': {
          bg: 'success.default',
        },
        '&:after': {
          left: '34px',
        },
        '.icon': {
          '&.icon-x': {
            svg: {
              line: {
                strokeDashoffset: '20',
              },
            },
          },
          '&.icon-checked': {
            svg: {
              strokeDashoffset: '0',
            },
          },
        },
      },
    },
  },
} as VariantArgs);

const Style: React.FC<SwitchButtonStyledProps> = styled.div`
  ${variants}
  ${defaults}
`;

const Switch: React.FC<SwitchButtonProps> = props => {
  const [value, setValue] = useState(!!props.checked || !!props.value);
  useEffect(() => setValue(props.value ?? false), [props.value]);
  const classes = classnames({
    checked: value,
  });

  return (
    <Style
      styling={props.styling ?? 'base'}
      style={{
        ...props.style,
      }}
    >
      <input
        type="checkbox"
        name={props.name}
        id={props.id}
        data-testid={props?.dataTestId}
        disabled={props?.disabled}
        checked={value}
        onChange={event => {
          setValue(!!props.checked || event.target.checked);
          props.onChange &&
            !props.checked &&
            props.onChange(event.target.checked);
        }}
      />
      <label className={classes} htmlFor={props.id} title={props.label} data-testid={`${props?.dataTestId}-title`}>
        <div className="icon icon-checked">
          <Icon size={24}>
            <IconCheck />
          </Icon>
        </div>
        <div className="icon icon-x">
          <Icon size={24}>
            <IconX />
          </Icon>
        </div>
        <P fontSize={props.labelFontSize ?? 2} styling="base" color="text.dark" width="calc(100% - 72px)">
          {props.label}
        </P>
      </label>
    </Style>
  );
};

export default memo(Switch);
