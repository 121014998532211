import React from 'react';
import { Route, Redirect, useLocation } from "react-router-dom";
import Can from "../Can";
import { Props } from './types';

const Router: React.FC<Props> = ({ component, ...rest }) => {
  const location = useLocation();

  return (
    <Can
      pathname={location.pathname}
      yes={() => <Route {...rest} component={component} />}
      no={() => {
        return (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        );
      }}
    />
  )
}

export default Router;