import React from "react";
import moment from "moment";
import { Box, Button } from "../../../components/ds/SubatomicParticles";
import { H3 } from "../../../components/ds/theme/typography";
import TextMaskFormInput from "../../../components/TextMaskFormInput";
import {
  validateVerify,
  autoCorrectedDatePipe,
  checkLengthOfDate,
} from "../../../utils/validations";
import { fieldNotFilledAlert, dateMask } from "../../../utils";
import { currencyMask } from "../../../utils/masks";

function Step4({
  onComplete,
  dataAquisicaoAcessorio,
  setDataAquisicaoAcessorio,
  valorAquisicaoAcessorio,
  setValorAquisicaoAcessorio,
}) {
  const _handleIncomplete = () => {
    if (
      !dataAquisicaoAcessorio ||
      checkLengthOfDate(dataAquisicaoAcessorio) !== 8
    )
      fieldNotFilledAlert("Data da Aquisição");
    if (valorAquisicaoAcessorio === "")
      fieldNotFilledAlert("Valor da Aquisição");
  };

  const _handleVerify = () => {
    if (
      !dataAquisicaoAcessorio ||
      checkLengthOfDate(dataAquisicaoAcessorio) !== 8 ||
      valorAquisicaoAcessorio === ""
    ) {
      _handleIncomplete();
    } else {
      onComplete({
        acessorio: {
          dataAquisicao: moment(dataAquisicaoAcessorio, "DD/MM/YYYY").format(
            "DD/MM/YYYY"
          ),
          valorAquisicao: valorAquisicaoAcessorio,
        },
      });
    }
  };

  return (
    <>
      <Box
        gridColumn={{
          mobile: "2/span 10",
          tablet: "2/span 10",
          desktop: "4/span 6",
        }}
        styling="column"
        pb={"30rem"}
      >
        <H3 mb="30px" styling="light">
          Sobre os acessórios
        </H3>
        <TextMaskFormInput
          value={dataAquisicaoAcessorio}
          onChange={(e) => setDataAquisicaoAcessorio(e.target.value)}
          mask={dateMask}
          pipe={(date) => autoCorrectedDatePipe(date, false)}
          placeholder="Data da Aquisição"
          onBlur={(e) => validateVerify(e)}
        />
        <TextMaskFormInput
          placeholder="Valor da Aquisição"
          value={valorAquisicaoAcessorio}
          mask={currencyMask}
          onChange={(e) => setValorAquisicaoAcessorio(e.target.value)}
          onBlur={(e) => validateVerify(e)}
          styling="base"
        />
        <Button styling="primary" onClick={_handleVerify} width="296px" mt={25}>
          Enviar
        </Button>
      </Box>
    </>
  );
}

export default Step4;
