export const benefitsCoverageResidence = [
    { label: 'Chaveiro' },
    { label: 'Encanador' },
    { label: 'Eletricista' },
    { label: 'Vidraceiro' },
    { label: 'Limpeza da residência em caso de sinistro' },
    { label: 'Cobertura provisória de telhados em caso de sinistro' },
    { label: 'Hospedagem em caso de sinistro' },
    { label: 'Instalação de Olho Mágico' },
    { label: 'Troca de lâmpadas' },
    { label: 'Tomadas e Interruptores' },
    { label: 'Conserto de eletrodomésticos(linha branca)' },
    { label: 'Fixação de antenas' },
    { label: 'Instalação de ventilador de teto' },
    { label: 'Limpeza de caixa d\'água' },
    { label: 'Limpeza de calhas' },
    { label: 'Lubrificação de fechadura e dobradiças' },
]

export const autoMock = {
    "warningMessages": ["s de CPF/CNPJ, placa e chassi são necessárias para a análise de aceitação do cálculo. Ao incluir ou alterar estes dados o valor do prêmio poderá ser alterado, assim como o parecer quanto à aceitação.", "Essa cotação tem validade até o dia 16/01/2020", "Liberty Sorteio - 1 Sorteio Mensal de R$ 5.000,00 para segurado e R$5.000,00 para o corretor.", "LIBERTY ASSISTÊNCIA PLANO RCF (0800 701 4120)"],
    "errorMessages": [],
    "quoteId": "uYU5tiIf3sfnYRPlwevf",
    "data": {
        "values": { "liquid": "R$658,19", "rate": "R$0,00", "tax": "R$48,57", "gross": "R$706,76" },
        "paymentTypes":
            [{
                "paymentName": "Débito em Conta Corrente", "paymentTypeCode": "DC",
                "bankAccountInfo": true,
                "paymentModes": [
                    { "paymentLabel": "À vista R$706,76", "InstallmentNumber": "1" },
                    { "paymentLabel": "1+1 de R$353,39", "InstallmentNumber": "2" },
                    { "paymentLabel": "1+2 de R$235,59", "InstallmentNumber": "3" },
                    { "paymentLabel": "1+3 de R$176,69", "InstallmentNumber": "4" },
                    { "paymentLabel": "1+4 de R$141,35", "InstallmentNumber": "5" },
                    { "paymentLabel": "1+5 de R$117,80", "InstallmentNumber": "6" },
                    { "paymentLabel": "1+6 de R$100,97", "InstallmentNumber": "7" },
                    { "paymentLabel": "1+7 de R$88,34", "InstallmentNumber": "8" },
                    { "paymentLabel": "1+8 de R$78,53", "InstallmentNumber": "9" },
                    { "paymentLabel": "1+9 de R$70,68", "InstallmentNumber": "10" }
                ]
            },
            {
                "paymentName": "Cartão de Crédito", "paymentTypeCode": "CC", "bankAccountInfo": false,
                "paymentModes": [
                    { "paymentLabel": "À vista R$706,76", "InstallmentNumber": "1" },
                    { "paymentLabel": "1+1 de R$353,39", "InstallmentNumber": "2" },
                    { "paymentLabel": "1+2 de R$235,59", "InstallmentNumber": "3" },
                    { "paymentLabel": "1+3 de R$176,69", "InstallmentNumber": "4" },
                    { "paymentLabel": "1+4 de R$141,35", "InstallmentNumber": "5" },
                    { "paymentLabel": "1+5 de R$117,80", "InstallmentNumber": "6" },
                    { "paymentLabel": "1+6 de R$100,97", "InstallmentNumber": "7" },
                    { "paymentLabel": "1+7 de R$88,34", "InstallmentNumber": "8" },
                    { "paymentLabel": "1+8 de R$78,53", "InstallmentNumber": "9" },
                    { "paymentLabel": "1+9 de R$70,68", "InstallmentNumber": "10" },
                    { "paymentLabel": "1+10 de R$64,26", "InstallmentNumber": "11" },
                    { "paymentLabel": "1+11 de R$58,90", "InstallmentNumber": "12" }
                ]
            }
            ]
    }
}