import React from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Box, Button } from "../../components/ds/SubatomicParticles";
import { H3 } from "../../components/ds/theme/typography";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";

export const ReferralForm = ({ submit, dropdownData }) => {
  return (
    <>
      <Box
        gridColumn={{
          mobile: "2/span 10",
          tablet: "2/span 10",
          desktop: "4/span 6",
        }}
        display="flex"
        flexDirection="column"
      >
        <H3
          alignSelf="center"
          color="primary.default"
          fontSize="24px"
          mb="30px"
          styling="light"
        >
          Nova indicação
      </H3>
        {dropdownData.indicador && (
          <Formik
            initialValues={{
              indicador: dropdownData.indicador,
              telefone: "",
              empresa: "",
              setor: "",
              funcao: "",
              indicacaoVenda: "",
              proposta: "",
              clienteNome: "",
              clienteEmail: "",
              clienteCelular: "",
              clienteFixo: "",
              vencimentoSeguro: "",
              clienteVeiculo: "",
              observacao: ""
            }}
            validationSchema={Yup.object().shape({
              indicador: Yup.string().required(
                "Preencha o nome do indicador"
              ),
              telefone: Yup.string().required(
                "Preencha o telefone do indicador"
              ),
              empresa: Yup.string().required("Selecione a empresa"),
              setor: Yup.string().required("Selecione o setor"),
              funcao: Yup.string().required("Preencha a função"),
              indicacaoVenda: Yup.string().required(
                "Selecione o tipo de indicação"
              ),
              proposta: Yup.string().required(
                "Preencha o número da proposta"
              ),
              clienteNome: Yup.string().required(
                "Preencha o nome do cliente"
              ),
              clienteEmail: Yup.string()
                .email("Preencha um email válido")
                .required("Preencha o email do cliente"),
              clienteCelular: Yup.string().required(
                "Preencha o número de celular"
              ),
              clienteFixo: "",
              vencimentoSeguro: "",
              clienteVeiculo: "",
              observacao: ""
            })}
            onSubmit={submit}
            render={({
              errors,
              values,
              touched,
              handleBlur,
              handleChange,
              setFieldValue
            }) => (
                <Form translate="no" style={{ width: '100%' }}>
                  <H3 mb="15px" styling="light">Indicador</H3>
                  <Field name="indicador">
                    {({ field }) => (
                      <FormInput
                        {...field}
                        data-testid="indicador"
                        name="indicador"
                        placeholder="Indicador"
                        type="text"
                        error={touched.indicador && errors.indicador}
                        readOnly
                      />
                    )}
                  </Field>
                  <Field name="telefone">
                    {({ field }) => (
                      <FormInput
                        {...field}
                        data-testid="telefone"
                        mask="(99) 99999-9999"
                        name="telefone"
                        placeholder="Telefone"
                        type="text"
                        error={touched.telefone && errors.telefone}
                      />
                    )}
                  </Field>
                  <Field name="empresa">
                    {({ field }) => (
                      <FormSelect
                        {...field}
                        data-testid="empresa"
                        id="empresa"
                        name="empresa"
                        type="select"
                        defaultValue={values.empresa}
                        error={touched.empresa && errors.empresa}
                        options={
                          dropdownData.companies &&
                          dropdownData.companies.map(company => ({
                            value: company.id,
                            label: company.value
                          }))
                        }
                        onChange={({value}) => setFieldValue('empresa', value)}
                        onBlur={handleBlur}
                        placeholder="Empresa na qual trabalho"
                        styling="base"
                      />
                    )}
                  </Field>
                  <Field name="setor">
                    {({ field }) => (
                      <FormSelect
                        {...field}
                        data-testid="setor"
                        id="setor"
                        name="setor"
                        defaultValue={values.setor}
                        error={touched.setor && errors.setor}
                        options={
                          dropdownData.sectors &&
                          dropdownData.sectors.map(sector => ({
                            value: sector.id,
                            label: sector.value
                          }))
                        }
                        onChange={({value}) => setFieldValue('setor', String(value))}
                        onBlur={handleBlur}
                        placeholder="Setor"
                        styling="base"
                      />
                    )}
                  </Field>
                  <Field name="funcao">
                    {({ field }) => (
                      <FormInput
                        {...field}
                        data-testid="funcao"
                        name="funcao"
                        placeholder="Função"
                        type="text"
                        error={touched.funcao && errors.funcao}
                      />
                    )}
                  </Field>
                  <H3 mb="15px" styling="light">Indicado</H3>
                  <Field name="clienteNome">
                    {({ field }) => (
                      <FormInput
                        {...field}
                        data-testid="clienteNome"
                        name="clienteNome"
                        placeholder="Nome do cliente"
                        type="text"
                        error={touched.clienteNome && errors.clienteNome}
                      />
                    )}
                  </Field>
                  <Field name="indicacaoVenda">
                    {({ field }) => (
                      <FormSelect
                        {...field}
                        data-testid="indicacaoVenda"
                        id="indicacaoVenda"
                        name="indicacaoVenda"
                        defaultValue={values.indicacaoVenda}
                        error={touched.indicacaoVenda && errors.indicacaoVenda}
                        options={
                          dropdownData.indicacaoVenda &&
                          dropdownData.indicacaoVenda.map(type => ({
                            value: type.id,
                            label: type.value
                          }))
                        }
                        onChange={({value}) => setFieldValue('indicacaoVenda', String(value))}
                        onBlur={handleBlur}
                        placeholder="Tipo de indicação"
                        styling="base"
                      />
                    )}
                  </Field>
                  <Field name="proposta">
                    {({ field }) => (
                      <FormInput
                        {...field}
                        data-testid="proposta"
                        name="proposta"
                        placeholder="Número da Proposta"
                        type="text"
                        error={touched.proposta && errors.proposta}
                      />
                    )}
                  </Field>
                  <Field name="clienteEmail">
                    {({ field }) => (
                      <FormInput
                        {...field}
                        data-testid="clienteEmail"
                        name="clienteEmail"
                        placeholder="Email"
                        type="text"
                        error={touched.clienteEmail && errors.clienteEmail}
                      />
                    )}
                  </Field>
                  <Field name="clienteCelular">
                    {({ field }) => (
                      <FormInput
                        {...field}
                        data-testid="clienteCelular"
                        mask="(99) 99999-9999"
                        name="clienteCelular"
                        placeholder="Número de celular"
                        type="text"
                        error={touched.clienteCelular && errors.clienteCelular}
                      />
                    )}
                  </Field>
                  <Field name="clienteFixo">
                    {({ field }) => (
                      <FormInput
                        {...field}
                        data-testid="clienteFixo"
                        mask="(99) 9999-9999"
                        name="clienteFixo"
                        placeholder="Número do telefone Fixo"
                        type="text"
                        error={touched.clienteFixo && errors.clienteFixo}
                      />
                    )}
                  </Field>

                  <Field name="vencimentoSeguro">
                    {({ field }) => (
                      <FormSelect
                        {...field}
                        data-testid="vencimentoSeguro"
                        id="vencimentoSeguro"
                        name="vencimentoSeguro"
                        defaultValue={values.vencimentoSeguro}
                        options={
                          dropdownData.months &&
                          dropdownData.months.map(month => ({
                            value: month.id,
                            label: month.value
                          }))
                        }
                        onChange={({value}) => setFieldValue('vencimentoSeguro', value)}
                        onBlur={handleBlur}
                        placeholder="Se já possui seguro, qual o mês de vencimento?"
                        styling="base"
                      />
                    )}
                  </Field>
                  <Field name="clienteVeiculo">
                    {({ field }) => (
                      <FormInput
                        {...field}
                        data-testid="clienteVeiculo"
                        name="clienteVeiculo"
                        placeholder="Descrição do veículo"
                        type="text"
                        error={touched.clienteVeiculo && errors.clienteVeiculo}
                      />
                    )}
                  </Field>
                  <Field name="observacao">
                    {({ field }) => (
                      <FormInput
                        {...field}
                        data-testid="observacao"
                        name="observacao"
                        placeholder="Observação"
                        type="text"
                        error={touched.observacao && errors.observacao}
                      />
                    )}
                  </Field>
                  <Button display="block" margin="15px auto 0" styling="primary" type="submit" width="295px">Enviar</Button>
                </Form>
              )}
          />
        )}
      </Box>
    </>
  );
};
