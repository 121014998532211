import React from "react";
import css from "@styled-system/css";
import { Box } from "../ds/SubatomicParticles";
import { P } from "../../components/ds/theme/typography";
import { ReactComponent as IconAutomovel } from "../../assets/svg/icon_auto.svg";
import { ReactComponent as IconPet } from "../../assets/svg/icon_pet.svg";
import { ReactComponent as IconResidencial } from "../../assets/svg/icon_residencia.svg";
import { ReactComponent as IconViagem } from "../../assets/svg/icon_viagem.svg";
import { ReactComponent as IconVida } from "../../assets/svg/icon_vida.svg";
import { ReactComponent as IconPortateis } from "../../assets/svg/icon_portateis.svg";
import { ReactComponent as IconBike } from "../../assets/svg/icon_bike.svg";
import { ReactComponent as IconFlagOnline } from "../../assets/svg/flag-online.svg";

export class IconHome extends React.Component {
  constructor() {
    super();
    this.click = this.click.bind(this);
  }

  click() {
    if (this.props.onPress) {
      this.props.onPress();
    }
  }
  
  render() {
    const { colors } = this.props;
    const backgroundDark =
      colors && colors.backgroundDark
        ? colors.backgroundDark
        : "background.dark";
    const backgroundDefault =
      colors && colors.backgroundDefault
        ? colors.backgroundDefault
        : "background.default";
    const primaryDefault =
      colors && colors.primaryDefault
        ? colors.primaryDefault
        : "primary.default";
    const textDark = colors && colors.textDark ? colors.textDark : "text.dark";
    const textLight =
      colors && colors.textLight ? colors.textLight : "text.light";
    
    return (
      <Box
        className={this.props.className}
        id={this.props.value}
        onClick={this.click}
        style={this.props.styles}
        width="100%"
        css={css({
          bg: backgroundDefault,
          border: "1px solid",
          borderColor: backgroundDefault,
          borderRadius: "1rem",
          boxShadow: "10px 10px 28px 1px rgba(0,0,0,.1)",
          cursor: "pointer",
          svg: {
            fill: primaryDefault,
            stroke: primaryDefault,
          },
          p: {
            color: textDark,
          },
          "&:hover": {
            border: "1px solid",
            borderColor: primaryDefault,
            boxShadow: (props) =>  `0 0 10px 1px ${colors && colors.primaryDefault
              ? colors.primaryDefault : props.colors.primary.default}`,
          },
          "&.active": {
            bg: backgroundDark,
            borderColor: backgroundDark,
            svg: {
              fill: textLight,
              stroke: textLight,
            },
            p: {
              color: textLight,
            },
          },
        })}
      >
          {this.props.online &&
          <Box
          className={this.props.className}
          style={this.props.styles}
          width="100%"
          css={css({
            height: 0,
            top: -1,
          })}>
            <Box
            className={this.props.className}
            style={this.props.styles}
            width='100.44%'
            top="inherit"
            css={css({
              position: "relative",
              textAlign: 'end',
              svg: {
                width: '43%',
                minWidth: '51px',
                height: '30%',
                stroke: "transparent",
                fill: primaryDefault,
                borderTopRightRadius: "1rem",
              },
              '.st0': {
                fill: primaryDefault
              },
              '.st1': {
                fill: textLight
              },
              "&.active": {
                '.st0': {
                  fill: textLight,
                  stroke: "transparent",
                },
                '.st1': {
                  fill: textDark,
                  stroke: "transparent",
                },
              },
            })}>
              <IconFlagOnline />
            </Box>
          </Box>
        }
        <Box
          className={this.props.className}
          height={{ mobile: "100px", tablet: "120px", desktop: "100%" }}
          minHeight={{ mobile: "100px", tablet: "120px", desktop: "150px" }}
          styling="column"
          style={this.props.styles}
          width="100%"
        >
          {this.props.children}
          <P
            fontSize={{ mobile: "13px", tablet: "13px", desktop: "16px" }}
            lineHeight={{ mobile: "15px", tablet: "18px", desktop: "22px" }}
            mt="10px"
            px="1rem"
            styling="base"
            textAlign="center"
          >
            {this.props.text}
          </P>
        </Box>
      </Box>
    );
  }
}

export class IconsMap extends React.Component {
  state = {
    contentSeguros: [
      {
        id: 1,
        title: "Auto",
        contentCopy:
          "Este seguro garante proteção contra perdas e danos ocorridos com seu automóvel em caso de acidentes, roubo ou furto, danos materiais ou corporais causados a terceiros e morte/invalidez dos passageiros",
        goto: "/automovel",
        icon: IconAutomovel,
      },
      {
        id: 2,
        title: "Residência",
        contentCopy:
          "Já pensou na sua residência protegida garantindo sua segurança e sua tranquilidade? Você pode cobrir residências habituais ou de veraneio e ainda contar com serviços que facilitam o seu dia-a-dia. Explore e conheça as coberturas disponíveis.",
        goto: "/residencial",
        icon: IconResidencial,
      },
      {
        id: 3,
        title: "Viagem",
        contentCopy:
          "Este serviço garante a indenização de riscos desde o embarque para o destino até o retorno em viagens nacionais ou para o exterior. Não se preocupe com extravios de bagagem ou acidentes pessoais: a pessego.com se preocupa com isso pra você!",
        goto: "/viagem",
        icon: IconViagem,
      },
      {
        id: 4,
        title: "Vida",
        contentCopy:
          "Imprevistos acontecem e, com o seguro de vida, a pessego.com assegura que sua família e você não ficarão desamparados financeiramente em caso de doenças, acidentes ou outros acontecimentos inesperados. Esteja preparado.",
        goto: "/vida",
        icon: IconVida,
      },
      {
        id: 5,
        title: "Pet",
        contentCopy:
          "Garantir o cuidado de quem te recebe com tanta alegria é a melhor forma de retribuir este carinho. Seu pet também está vulnerável a contrair doenças e ocasionalmente pode precisar de atendimento emergencial. Deixe seu animalzinho em boas mãos!",
        goto: "/pets",
        icon: IconPet,
      },
      {
        id: 6,
        title: "Portáteis",
        contentCopy:
          "Muito vulneráveis aos riscos cotidianos - quebra, danos elétricos, roubo, furto, etc. Os aparelhos portáteis são ferramentas necessárias para o trabalho, lazer e conectividade. Já pensou ficar sem o seu? Esteja protegido contra este imprevisto.",
        goto: "/portateis",
        icon: IconPortateis,
      },
      {
        id: 7,
        title: "Bike",
        contentCopy:
          "Muito vulneráveis aos riscos cotidianos - quebra, danos elétricos, roubo, furto, etc. Os aparelhos portáteis são ferramentas necessárias para o trabalho, lazer e conectividade. Já pensou ficar sem o seu? Esteja protegido contra este imprevisto.",
        goto: "/bike",
        icon: IconBike,
      },
    ],
  };
  render() {
    let { history } = this.props;
    let { location } = history;
    return this.state.contentSeguros.map((item) => (
      <IconHome
        key={item.id}
        text={item.title}
        online={item.online}
        className={
          this.props.className +
          " iconMap " +
          (location.pathname === item.goto ? "active " : "")
        }
        onPress={() => history.push(item.goto)}
      >
        <item.icon width="38px" />
      </IconHome>
    ));
  }
}

export default IconHome;
