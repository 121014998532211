import React from "react";
import { Box } from "../ds/SubatomicParticles";
import arrowRight from "../../assets/svg/arrow-right.svg";
import arrowLeft from "../../assets/svg/arrow-left.svg";

//Marcas
import allianz from "../../assets/images/logo_allianz.png";
import azul from "../../assets/images/logo_azul.png";
import bradesco from "../../assets/images/logo_bradesco.png";
import itau from "../../assets/images/logo_itau.png";
import liberty from "../../assets/images/logo_liberty.png";
import portoseguro from "../../assets/images/logo_portoseguro.png";
import sulamerica from "../../assets/images/logo_sulamerica.png";
import zurich from "../../assets/images/logo_zurich.png";

export class MarcasCarousel extends React.Component {
  constructor() {
    super();
    this.state = {
      //set width and height
      // holding the current index for the image that has to be rendered at each time on the screen
      currentImageIndex: 0,
      // array of the source links to the images, simple placeholders for now
      images: [
        allianz,
        azul,
        bradesco,
        itau,
        liberty,
        portoseguro,
        sulamerica,
        zurich,
      ],
      // imported images of right and left arrows
      arrowNext: arrowRight,
      arrowPrev: arrowLeft,
    };
    this.nextSlide = this.nextSlide.bind(this);
    this.prevSlide = this.prevSlide.bind(this);
  }

  prevSlide() {
    // find the index of the last image in the array
    const lastIndex = this.state.images.length - 1;
    // check if we need to start over from the last index again
    const resetIndex = this.state.currentImageIndex === 0;
    const index = resetIndex ? lastIndex : this.state.currentImageIndex - 1;
    // assign the logical index to currentImageIndex that will use in render method
    this.setState({
      currentImageIndex: index,
    });
  }
  nextSlide() {
    // find the index of the last image in the array
    const lastIndex = this.state.images.length - 1;
    // check if we need to start over from the first index
    const resetIndex = this.state.currentImageIndex === lastIndex;
    const index = resetIndex ? 0 : this.state.currentImageIndex + 1;
    // assign the logical index to currentImageIndex that will use in render method
    this.setState({
      currentImageIndex: index,
    });
  }

  render() {
    const isMobile = window.innerWidth < 480;

    const showItems = isMobile ? 1 : 8;
    // get current image index
    const index = this.state.currentImageIndex;
    // create a new array with 5 videos from the source images
    let firstFiveVideo = this.state.images.slice(index, index + showItems);
    // check the length of the new array (it’s less than 5 when index is near the end of the array)
    if (firstFiveVideo.length < showItems) {
      // if the firstFiveVideo's length is lower than 5 images than append missing images from the beginning of the original array
      firstFiveVideo = firstFiveVideo.concat(
        this.state.images.slice(0, showItems - firstFiveVideo.length),
      );
    }

    return (
      <Box styling="row" width="100%">
        <Box opacity="0.2" styling="row" width="20px">
          <img src={this.state.arrowPrev} onClick={this.prevSlide} alt="" />
        </Box>
        <Box styling="row" width="calc(100% - 40px)">
          {firstFiveVideo.map((image, index) => (
            <Box styling="row" margin="0 auto" key={`c${index}`}>
              <img key={index} src={image} alt="" />
            </Box>
          ))}
        </Box>
        <Box opacity="0.2" styling="row" width="20px">
          <img src={this.state.arrowNext} onClick={this.nextSlide} alt="" />
        </Box>
      </Box>
    );
  }
}

export default MarcasCarousel;
