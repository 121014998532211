/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { fieldNotFilledAlert } from "../../../utils";
import { validateVerify } from "../../../utils/validations";
import FormInput from "../../../components/FormInput";
import { Box, Button } from "../../../components/ds/SubatomicParticles";
import { H3 } from "../../../components/ds/theme/typography";

export default function RegisterMyLocation({
  getEndereco,
  endereco,
  onNext,
  setEndereco,
}) {
  const [cep, setCep] = useState("");
  const [logradouro, setLogradouro] = useState("")
  const [tipo, setTipo] = useState("")
  const [numero, setNumero] = useState("")
  const [complemento, setComplemento] = useState("")
  const [bairro, setBairro] = useState("")
  const [cidade, setCidade] = useState("")
  const [uf, setUf] = useState("")

  const addressIsNotFilled = () =>
    cep === "" ||
    numero === "" ||
    logradouro === "" ||
    bairro === "" ||
    cidade === "" ||
    uf === "" ||
    tipo === "";

  useEffect(() => {
    if (cep.length < 8) {
      setEndereco({});
      setLogradouro("")
      setTipo("")
      setNumero("")
      setComplemento("")
      setBairro("")
      setCidade("")
      setUf("")
    }
  }, [cep]);

  useEffect(() => {
    if (endereco.cep) {
      if (cep === "") setCep(endereco.cep)
      if (bairro === "") setBairro(endereco.bairro);
      if (complemento === "") setComplemento(endereco.complemento);
      if (uf === "") setUf(endereco.uf);
      if (cidade === "") setCidade(endereco.localidade);
    }
    if (endereco.logradouro) {
      const length = endereco.logradouro.split(" ")[0].length + 1;
      if (tipo === "") setTipo(endereco.logradouro.split(" ")[0]);
      if (logradouro === "")
        setLogradouro(endereco.logradouro.substring(length))
    }
  }, [endereco]);


  const _handleVerify = () => {
    if (addressIsNotFilled()) {
      if (cep === "") fieldNotFilledAlert("Digite o seu CEP");
      if (numero === "") fieldNotFilledAlert("Número");
      if (logradouro === "") fieldNotFilledAlert("Logradouro");
      if (bairro === "") fieldNotFilledAlert("Bairro");
      if (cidade === "") fieldNotFilledAlert("Cidade");
      if (uf === "") fieldNotFilledAlert("Estado");
      if (tipo === "") fieldNotFilledAlert("Rua, Av, etc...");
    } else {
      onNext({
        cep,
        logradouro: logradouro,
        numero: numero,
        complemento: complemento,
        bairro: bairro,
        cidade: cidade,
        estado: uf,
        tipo: tipo,
      });
    }
  };

  const onCepChange = async (e) => {
    setCep(e.target.value.replace(/\.|-/g, ""));
    if (e.target.value.length === 9) {
      const formatedCep = e.target.value.replace(/\.|-/g, "");
      getEndereco(formatedCep);
    }
  };

  const renderEndereco = () => {
    return (
      <>
        <Box justifyContent="space-between" styling="row" width="100%">
          <Box styling="row" width="48%">
            <FormInput
              {...tipo}
              label="Rua, Av, etc..."
              data-testid="tipo"
              id="tipo"
              onChange={e => setTipo(e.target.value)}
              onBlur={(e) => validateVerify(e)}
              value={tipo}
            />
          </Box>
          <Box styling="row" width="48%">
            <FormInput
              {...logradouro}
              label="Logradouro"
              data-testid="logradouro"
              id="logradouro"
              onChange={e => setLogradouro(e.target.value)}
              onBlur={(e) => validateVerify(e)}
              value={logradouro}
            />
          </Box>
        </Box>

        <Box justifyContent="space-between" styling="row" width="100%">
          <Box styling="row" width="48%">
            <FormInput
              {...numero}
              label="Número"
              data-testid="numero"
              id="numero"
              onChange={e => setNumero(e.target.value)}
              onBlur={(e) => validateVerify(e)}
              value={numero}
            />
          </Box>
          <Box styling="row" width="48%">
            <FormInput
              label="Complemento"
              {...complemento}
              data-testid="complemento"
              id="complemento"
              onChange={e => setComplemento(e.target.value)}
              onBlur={(e) => validateVerify(e)}
              value={complemento}
            />
          </Box>
        </Box>

        <FormInput
          {...bairro}
          data-testid="bairro"
          label="Bairro"
          id="bairro"
          onChange={e => setBairro(e.target.value)}
          onBlur={(e) => validateVerify(e)}
          value={bairro}
        />

        <Box justifyContent="space-between" styling="row" width="100%">
          <Box styling="row" width="48%">
            <FormInput
              label="Cidade"
              {...cidade}
              data-testid="cidade"
              id="cidade"
              onChange={e => setCidade(e.target.value)}
              onBlur={(e) => validateVerify(e)}
              value={cidade}
            />
          </Box>
          <Box styling="row" width="48%">
            <FormInput
              {...uf}
              label="UF"
              data-testid="uf"
              id="uf"
              onChange={e => setUf(e.target.value)}
              onBlur={(e) => validateVerify(e)}
              value={uf}
            />
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Box
      gridColumn={{
        mobile: "2/span 10",
        tablet: "2/span 10",
        desktop: "4/span 6",
      }}
      styling="column"
    >
      <H3 mb="30px" styling="light">
        Endereço Residencial
      </H3>
      <FormInput
        data-testid="cep"
        id="cep"
        mask="99999-999"
        maxLength={9}
        name="cep"
        onBlur={(e) => validateVerify(e)}
        onChange={onCepChange}
        placeholder="Digite o seu CEP"
        type="text"
        value={cep}
      />
      {renderEndereco()}
      <Button
        display="block"
        margin="0 auto"
        onClick={_handleVerify}
        onComplete={onNext}
        styling="primary"
        width="295px"
      >
        Próximo
      </Button>
    </Box>
  );
}
