import { Dispatch } from 'redux';
import kebabCase from "lodash/kebabCase";
import * as repository from './repository';
import { setClients, isLoading } from './actions';
import { Client } from './types';
import { createTheme, deleteTheme } from '../themes/repository';
import { createLocale, deleteLocale } from '../locales/repository';
import { createImages, deleteImages, deleteBucket } from '../images/repository';
import { showToast } from '../../../utils';
import themeDefault from '../../../assets/themes/default';
import localeDefault from '../../../assets/locale/default';
import imageDefault from '../../../assets/images/default';

export const getClients = () => async (dispatch: Dispatch) => {
  try {
    dispatch(isLoading(true));
    const response = await repository.getClients();
    dispatch(setClients(response));
    dispatch(isLoading(false));
  } catch (error) {
    showToast(
      "error",
      "Ops :(",
      "Algo deu errado"
    );
    dispatch(isLoading(false));
  };
};

export const getClient = (clientId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(isLoading(true));
    const response = await repository.getClient(clientId);
    dispatch(isLoading(false));
    return response || {};
  } catch (error) {
    showToast(
      "error",
      "Ops :(",
      "Algo deu errado"
    );
    dispatch(isLoading(false));
  }
};

export const editClient = (clientId: string, client: Client) => async (dispatch: Dispatch) => {
  try {
    dispatch(isLoading(true));
    const response = await repository.editClient(clientId, client);
    showToast(
      "success",
      "Sucesso :)",
      "O registrado foi atualizado !"
    );
    dispatch(isLoading(false));
    return response;
  } catch (error) {
    showToast(
      "error",
      "Ops :(",
      "Algo deu errado"
    );
    dispatch(isLoading(false));
  }
};

export const createClient = (client: Client) => async (dispatch: Dispatch) => {
  try {
    dispatch(isLoading(true));
    const clientId = kebabCase(client.domain);
    const response = await repository.createClient(client, clientId);
    await createTheme(clientId, themeDefault);
    await createLocale(clientId, localeDefault);
    await createImages(clientId, imageDefault);
    showToast(
      "success",
      "Sucesso :)",
      "O registrado foi criado !"
    );
    dispatch(isLoading(false));
    return response;
  } catch (error) {
    showToast(
      "error",
      "Ops :(",
      "Algo deu errado"
    );
    dispatch(isLoading(false));
  }
};

export const deleteClient = (clientId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(isLoading(true));
    const response = await repository.deleteClient(clientId);
    await deleteTheme(clientId);
    await deleteLocale(clientId);
    await deleteImages(clientId);
    await deleteBucket(clientId);
    await dispatch(getClients() as any);
    showToast(
      "success",
      "Sucesso :)",
      "O registrado foi excluído !"
    );
    dispatch(isLoading(false));
    return response;
  } catch (error) {
    showToast(
      "error",
      "Ops :(",
      "Algo deu errado"
    );
    dispatch(isLoading(false));
  }
};

export const getContractorSyonetParameters = (url: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(isLoading(true));
    const response = await repository.getContractorSyonetParameters(url);
    dispatch(isLoading(false));
    return response.data;
  } catch (error) {
    showToast(
      "error",
      "Ops :(",
      "Algo deu errado"
    );
    dispatch(isLoading(false));
  }
};

export const addContractorSyonetParameters = (url: string, grupoEvento: string, tipoEvento: string, midia: string, modalidade: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(isLoading(true));
    await repository.addContractorSyonetParameters({
      url,
      modalidade,
      params: {
        grupoEvento,
        tipoEvento,
        midia
      }
    });
    showToast(
      "success",
      "Sucesso :)",
      "Parametrização salva no banco de dados."
    );
    dispatch(isLoading(false));
    return true;
  } catch (error) {
    showToast(
      "error",
      "Ops :(",
      "Algo deu errado"
    );
    dispatch(isLoading(false));
  }
};

export const getSyonetModalityTypes = () => async (dispatch: Dispatch) => {
  try {
    dispatch(isLoading(true));
    const response = await repository.getSyonetModalityTypes();    
    dispatch(isLoading(false));
    return response.data;
  } catch (error) {
    showToast(
      "error",
      "Ops :(",
      "Algo deu errado"
    );
    dispatch(isLoading(false));
  }
};

export const getContractorQuiverParameters = (url: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(isLoading(true));
    const response = await repository.getContractorQuiverParameters(url);
    dispatch(isLoading(false));
    return response.data;
  } catch (error) {
    showToast(
      "error",
      "Ops :(",
      "Algo deu errado"
    );
    dispatch(isLoading(false));
  }
};

export const addContractorQuiverParameters = (url: string, grupoHierarquico: string, modalidade: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(isLoading(true));
    await repository.addContractorQuiverParameters({
      url,
      modalidade,
      params: { grupoHierarquico: parseInt(grupoHierarquico) }
    });
    showToast(
      "success",
      "Sucesso :)",
      "Parametrização salva no banco de dados."
    );
    dispatch(isLoading(false));
    return true;
  } catch (error) {
    showToast(
      "error",
      "Ops :(",
      "Algo deu errado"
    );
    dispatch(isLoading(false));
  }
};

export const getQuiverModalityTypes = () => async (dispatch: Dispatch) => {
  try {
    dispatch(isLoading(true));
    const response = await repository.getQuiverModalityTypes();    
    dispatch(isLoading(false));
    return response.data;
  } catch (error) {
    showToast(
      "error",
      "Ops :(",
      "Algo deu errado"
    );
    dispatch(isLoading(false));
  }
};