import React, { memo, useState, useEffect } from 'react';
import { ChromePicker, ColorResult } from 'react-color';
import { useFormikContext } from 'formik';

import { Props } from './types';
import { Box, Button } from '../ds/SubatomicParticles';

const ColorPicker: React.FC<Props> = props => {

  const { setFieldValue } = useFormikContext();

  const [show, setShow] = useState<boolean>(false);
  const handleShow = () => setShow(prevState => !prevState);
  const [color, setColor] = useState<string>('#000000');

  const handleChange = (color: ColorResult) => {
    setFieldValue(props.name, color.hex);
  };

  useEffect(() => { setColor(props.value) }, [props.value])

  return (
    <Box
      styling="row"
      position="relative"
      width="50px"
    >
      <Button
        type="button"
        p="5px"
        borderRadius="1px"
        bg="#FFFFFF"
        onClick={handleShow}
      >
        <Box
          width="36px"
          height="14px"
          borderRadius="2px"
          bg={color}
        />
      </Button>
      {show && (
        <Box
          position="absolute"
          left="55px"
          top="0"
          zIndex={2}
        >
          <ChromePicker
            disableAlpha
            color={color}
            onChange={handleChange}
          />
          <Box
            styling="overlay"
            // @ts-ignore
            zIndex="-1"
            onClick={handleShow}
          />
        </Box>
      )}
    </Box>

  )
};
export default memo(ColorPicker);