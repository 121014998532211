import React, { memo, useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { useRouteMatch, useParams, useLocation } from "react-router-dom";
import {
  Formik,
  Field,
  Form as FormikForm,
  FieldProps,
  FieldMetaProps,
} from "formik";
import { getImage, editImage } from "../../../../state/context/images/services";
import ImageUpload from "../../../../components/ds/atoms/ImageUpload";
import { Image } from "../../../../state/context/images/types";
import { Box, Button } from "../../../../components/ds/SubatomicParticles";
import { H3 } from "../../../../components/ds/theme/typography";
import { Props, State } from "./types";
import { Schema, initialValues } from "./schema";
import Breadcrumbs from "../../../../components/ds/atoms/Breadcrumbs";
import Loading from "../../../../components/SmallLoading";

const Form: React.FC<Props> = (props) => {
  const location = useLocation();
  const { id } = useParams();

  const matchEdit = useRouteMatch("/admin/images/edicao/:id");
  const [dataForm, setDataForm] = useState<any>(initialValues);

  const steps = useMemo(() => {
    if (matchEdit) {
      return [
        {
          path: "/admin/images",
          name: "Imagens",
        },
        {
          path: location.pathname,
          name: "Editar",
        },
      ];
    }
    return [
      {
        path: "/admin/images",
        name: "Imagens",
      },
      {
        path: "/admin/images/create",
        name: "Cadastrar",
      },
    ];
  }, [location, matchEdit]);

  useEffect(() => {
    (async function loadingPage() {
      if (id && matchEdit) {
        const image = await props.getImage(id);
        setDataForm(image);
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getImageBoxes = (values: any, setFieldValue: any) => {
    const resp = [];
    for (const imgKey in values) {
      resp.push(
        <Field name={imgKey} key={imgKey}>
          {({ meta }: FieldProps & FieldMetaProps<string>) => (
            <Box styling="column" mb="50px" width="100%" height="350px">
              <H3
                color={meta.touched && meta.error ? "red" : "#3f51b5"}
                fontSize="16px"
                lineHeight="20px"
                mb="15px"
                styling="base"
                width="100%"
                textAlign="center"
              >
                {values[imgKey].description} (
                {`${values[imgKey].width} x ${values[imgKey].height} `})
              </H3>
              <ImageUpload
                defaultImage={values[imgKey].url}
                height={values[imgKey].height}
                width={values[imgKey].width}
                onSave={(data) => {
                  const newImage: Image = values[imgKey];
                  newImage.blob = data.file;
                  setFieldValue(imgKey, newImage);
                }}
                onRemove={() => {
                  const newImage: Image = values[imgKey];
                  delete newImage.blob;
                  newImage.url = '';
                  setFieldValue(imgKey, newImage);
                }}
                styling={"base"}
                id={imgKey}
              />
            </Box>
          )}
        </Field>,
      );
    }
    return resp;
  };
  const onSubmit = async (values: any) => {
    await props.editImage(id, values);
  };

  return (
    <Box
      styling="column"
      width="100%"
      height="100%"
      padding="30px"
      position="relative"
    >
      <Breadcrumbs styling="base" steps={steps} backPath="/admin/images" />
      <Box
        styling="column"
        width="100%"
        minHeight="calc(100vh - 108px)"
        overflow="hidden"
        position="relative"
        justifyContent="flex-start"
        pt="30px"
      >
        <Formik
          enableReinitialize
          initialValues={dataForm}
          validationSchema={Schema}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            await onSubmit(values);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <FormikForm translate='no' style={{ height: "100%", width: "100%" }}>
              <Box
                alignItems="flex-start"
                justifyContent="flex-start"
                mb="15px"
                height="calc(100vh - 190px)"
                overflowY="scroll"
                styling="column"
              >
                <Box styling="grid" width="100%" gridTemplateColumns="repeat(3, 1fr)" gridGap="30px">
                  {getImageBoxes(values, setFieldValue)}
                </Box>
              </Box>
              <Box styling="row" justifyContent="flex-end">
                <Button
                  styling="outlineAdmin"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Salvar
                </Button>
              </Box>
            </FormikForm>
          )}
        </Formik>
      </Box>
      {props.isLoading && (
        <Box
          styling="column"
          height="100vh"
          width="100%"
          zIndex={99999}
          bg="rgba(240, 241, 242, 0.62)"
          position="absolute"
        >
          <Loading />
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = (state: State) => ({
  isLoading: state.images.isLoading,
});

const mapDispatchToProps = {
  getImage,
  editImage,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(Form as any));
