import * as types from "./types";

const initialState = {
  data: {
    user: {},
    loading: false,
    errorMessage: "",
    toast: {
      show: false,
      type: "",
      message: ""
    },
    modal: {
      visible: false,
      type: ""
    },
    myInsurances: [],
    residences: {
      list: [],
      isLoading: false
    },
    myVehicles: {
      list: [],
      isLoading: false
    },
    insuranceLoading: false,
    myQuotes: [],
    quotesLoading: false,
    quoteInfo: [],
    quoteInfoLoading: false,
    referencia: {
      reference: {},
      isLoading: false
    },
    brands: {
      list: []
    },
    formVehicles: {
      models: [],
      modelYear: [],
      selectedVehicle: {}
    }
  },
};

const reducers = (state = initialState.data, action) => {
  switch (action.type) {
    case types.SUCCESS_LOGIN:
      return { ...state, user: action.user };
    case types.LOADING:
      return { ...state, loading: action.loading };
    case types.AUTH_USER:
      return { ...state, user: action.user };
    case types.LOGOUT_USER:
      return initialState.data;
    case types.TOAST:
      return { ...state, toast: action.toast };
    case types.SHOW_MODAL:
      return { ...state, modal: action.modal };
    case types.SET_MY_INSURANCES:
      return { ...state, myInsurances: action.data };
    case types.SET_MY_QUOTES:
      return { ...state, myQuotes: action.data };
    case types.QUOTES_LOADING:
      return { ...state, quotesLoading: action.loading };
    case types.INSURANCE_LOADING:
      return { ...state, insuranceLoading: action.loading };
    case types.SET_QUOTE_INFO:
      return { ...state, quoteInfo: action.data };
    case types.QUOTE_INFO_LOADING:
      return { ...state, quoteInfoLoading: action.loading };
    case types.SUCCESS_GET_RESIDENCES:
      return { ...state, residences: { ...state.residences, list: action.list } };
    case types.IS_LOADING_RESIDENCES:
      return { ...state, residences: { ...state.residences, isLoading: action.isLoading } };
    case types.SUCCESS_GET_VEHICLES:
      return { ...state, myVehicles: { ...state.myVehicles, list: action.list } };
    case types.LOADING_VEHICLES:
      return { ...state, myVehicles: { ...state.myVehicles, isLoading: action.isLoading } };
    case types.SUCCESS_GET_REFERENCE_DATE:
      return { ...state, referencia: { ...state.referencia, reference: action.object } };
    case types.SUCCESS_GET_BRANDS:
      return { ...state, brands: { ...state.brands, list: action.list } };
    case types.SUCCESS_GET_MODELS:
      return { ...state, formVehicles: { ...state.formVehicles, models: action.list } };
    case types.SUCCESS_GET_YEAR_MODEL:
      return { ...state, formVehicles: { ...state.formVehicles, modelYear: action.list } };
    case types.SUCCESS_GET_VEHICLE:
      return { ...state, formVehicles: { ...state.formVehicles, selectedVehicle: action.obj } };

    default:
      return state;
  }
};

export { initialState };
export default reducers;
