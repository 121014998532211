import React from "react";
import Input from "../ds/atoms/Input";
import { Box } from "../ds/SubatomicParticles";
import { P, Label } from "../ds/theme/typography";

type LabelFileStyle = {
  border?: string;
  borderRadius?: string;
  bg?: string;
  color?: string;
  fontFamily?: string;
  fontSize?: string;
  padding?: string;
  height?: string;
  lineHeight?: string;
  overflow?: string;
  width?: string;
  paddingRight?: string;
  maxWidth?: string;
  customStyle?: string;
};

interface DispatchProps {
  id?: string;
  name: string;
  placeholder: string;
  type: string;
  value: string;
  error: string | boolean | undefined;
  success?: boolean;
  readOnly?: boolean;
  mask?: string;
  file?: boolean;
  maxLength?: number;
  disabled?: boolean;
  autoComplete?: "on" | "off" | "new-password";
  maskChar?: string | null;
  fileName?: string;
  labelFileStyle?: LabelFileStyle;
  paddingXInput?: string;
  onBlur?: (e: any) => void;
  onChange?: (e: any) => void;
  onClick?: (e: any) => void;
  backgroundColor?: string;
  errorBelow?: boolean;
  label?: string | boolean;
  labelColor?: string;
  borderColor?: string;
}

interface getLabelProps {
  label?: string | boolean;
  labelColor?: string;
  placeholder: string;
}

type Props = DispatchProps;

const getLabel = ({ label, labelColor, placeholder }: getLabelProps) => {
  if (label === false) {
    return null;
  } else if (typeof label === "string") {
    return <Label styling="base" color={labelColor === undefined ? '' : labelColor}>{label}</Label>;
  } else if (typeof label === "undefined") {
    return <Label styling="base" color={labelColor === undefined ? '' : labelColor}>{placeholder}</Label>;
  }
};

const FormInput = ({
  id,
  value,
  error,
  mask,
  success,
  file,
  maskChar,
  fileName,
  labelFileStyle,
  errorBelow,
  paddingXInput,
  placeholder,
  label,
  labelColor,
  borderColor,
  ...rest
}: Props) => (
    <Box styling="column" width="100%" mb="15px" alignItems="flex-start">
      {getLabel({ label, labelColor, placeholder })}
      <Box styling="column" width="100%" position="relative">
        <Input
          placeholder={label === undefined ? '' : placeholder}
          {...rest}
          id={rest.name}
          data-testid={rest.name}
          value={value}
          mask={mask || ""}
          styling="base"
          maskChar={maskChar || null}
          autoComplete="new-password"
        />
        {id === "cpf" &&
        (
          <P
          fontSize="12px"
          styling="base"
          alignSelf="flex-end"
          fontStyle="italic"
          fontWeight="bold"
        >
          *O fornecimento do CPF é uma exigência obrigatória da SUSEP para cotação de seguros
        </P>
        )}
        
        {error && !errorBelow && (
          <P
            data-testid={`${rest.name}Error`}
            fontSize="12px"
            styling="error"
            width="100%"
          >
            {error}
          </P>
        )}
      </Box>
    </Box>
  );

export default FormInput;
