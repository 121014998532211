import { persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import storageSession from 'redux-persist/lib/storage/session';
import storageLocal from 'redux-persist/lib/storage';

import user from './user';
import form from './form';
import config from './config';
import clients from './clients';
import themes from './themes';
import users from './users';
import locales from './locales';
import images from './images';

const rootConfig = {
  key: 'root',
  version: 1,
  storage: storageSession,
  whitelist: ['user', 'form'],
};

const configurationConfig = {
  key: 'config',
  version: 1,
  storage: storageLocal,
  whitelist: ['contractor', 'clients', 'themes', 'locales', 'images'],
};

export default persistReducer(
  rootConfig,
  combineReducers({
    config: persistReducer(configurationConfig, config),
    user,
    form,
    clients,
    themes,
    users,
    locales,
    images
  }),
);
