/* eslint-disable react-hooks/exhaustive-deps */
/* eslint react-hooks/rules-of-hooks: off */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Box, Button } from "../../../components/ds/SubatomicParticles";

import { H3 } from "../../../components/ds/theme/typography";
import FormSelect from "../../../components/FormSelect";

import { getVehicleQuestionnaireStep3 } from "../../../state/context/form/services";

function step3({ onNext, onIncomplete, getQuestionnaire, onGetErrorFromQuiz }) {
  const [questionnaire, setQuestionnaire] = useState([]);
  const [answers, setAnswers] = useState({});
  const [arrQuestNumberCodes, setArrQuestNumberCodes] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await getQuestionnaire();
        setQuestionnaire(data);
        // preenche array para futura validação
        setArrQuestNumberCodes(
          data.map((question) => {
            return question.QuestionNumber;
          }),
        );
      } catch (e) {
        onGetErrorFromQuiz();
      }
    };
    fetch();
  }, []);

  const _handleVerify = () => {
    // valida se todas as perguntas foram respondidas
    let alreadyAnswered = Object.keys(answers);
    let diff = arrQuestNumberCodes.filter((questionCode) => {
      return alreadyAnswered.indexOf(questionCode) === -1;
    });

    if (diff.length) {
      onIncomplete();
    } else {
      onNext(Object.values(answers));
    }
  };

  return (
    <Box
      gridColumn={{
        mobile: "2/span 10",
        tablet: "2/span 10",
        desktop: "4/span 6",
      }}
      styling="column"
    >
      <H3 mb="30px" styling="light">
        Informações gerais do seguro/veículo
      </H3>
      <Box display="flex" flexDirection="column" width="100%">
        {questionnaire.map((question, i) => (
          <FormSelect
            key={`question${i}`}
            placeholder={question.DescQuestion}
            options={question.Answers.map((answer) => ({
              value: answer.AnswerChoiceCode,
              label: answer.DescAnswer,
            }))}
            onChange={(e) =>
              setAnswers({
                ...answers,
                [question.QuestionNumber]: {
                  QuestionNumber: question.QuestionNumber,
                  AnswerChoiceCode: e.value,
                },
              })
            }
          />
        ))}
      </Box>
      <Button styling="primary" onClick={_handleVerify} width="296px" mt={25}>
        Próximo
      </Button>
    </Box>
  );
}

const mapState = (state) => ({});

const mapDispatch = {
  getQuestionnaire: getVehicleQuestionnaireStep3,
};

export default connect(mapState, mapDispatch)(step3);
