import React, { useState } from 'react'
import css from '@styled-system/css';
import { Box } from "../../components/ds/SubatomicParticles";
import { H3, P } from "../../components/ds/theme/typography";
import Icon from '../../components/ds/atoms/Icon';
import { ReactComponent as IconArrow } from '../../assets/svg/right-arrow.svg';

const CollapsibleCard = ({ title, content, key }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Box
      alignItems="stretch"
      backgroundColor="background.default"
      boxShadow="10px 10px 20px rgba(0, 0, 0, 0.05)"
      borderRadius="12px"
      flexWrap="wrap"
      gridColumn="2/span 10"
      key={key}
      onClick={() => setIsOpen(!isOpen)}
      paddingX="24px"
      paddingY="16px"
      position="relative"
      styling="column"
      width="100%"
    >
      <Box justifyContent="space-between" styling="row" width="100%">
        <Box alignItems="flex-start" styling="row" width="calc(100% - 40px)">
          <H3 color="text.dark" styling="base" width="100%">{title}</H3>
        </Box>
        <Box
          css={css({
            transform: `${isOpen ? 'rotate(-90deg)' : 'rotate(90deg)'}`,
            transition: 'all linear .25s',
          })}
          height="30px"
          styling="row"
          width="30px">
          <Icon css={css({'svg path': {fill: 'primary.default'}})}>
            <IconArrow height={30} width={30} />
          </Icon>
        </Box>
      </Box>
      {isOpen &&
        <P fontSize="20px" mt="20px" styling="base">
          {content}
        </P>
      }
    </Box>
  )
}

export default CollapsibleCard