import React, { memo } from 'react';
import styled from 'styled-components';
import { variant, VariantArgs } from 'styled-system';
import { defaults, Box } from '../../SubatomicParticles';
import { RadioProps, RadioStyledProps } from './index';
import { P } from '../../theme/typography';

const variants = variant({
    prop: 'styling',
    variants: {
        base: {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            position: 'relative',
            color: 'secondary.default',
            '&:focus': {
                outline: 'none',
                label: {
                    '& > span': {
                        backgroundColor: (props: any) => `${props.colors.contrast}10`
                    }
                }
            },
            label: {
                cursor: 'pointer',
                display: 'inline-flex',
                alignItems: 'center',
                marginLeft: '-11px',
                verticalAlign: 'middle',
                '& > span': {
                    flex: '0 0 auto',
                    color: 'currentColor',
                    padding: '12px',
                    overflow: 'visible',
                    fontSize: '1.5rem',
                    textAlign: 'center',
                    transition:
                        'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    borderRadius: '50%',
                    '&:hover': {
                        backgroundColor: (props: any) => `${props.colors.contrast}10`
                    },
                    '& > span': {
                        width: '100%',
                        display: 'flex',
                        alignItems: 'inherit',
                        justifyContent: 'inherit',
                    },
                },
                '&.checked': {
                    div: {
                        'svg:last-child': {
                            transform: 'scale(1)',
                            transition: 'transform 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
                        }
                    }
                }
            },
            input: {
                top: '0',
                left: '0',
                width: '100%',
                cursor: 'inherit',
                height: '100%',
                margin: '0',
                opacity: '0',
                padding: '0',
                zIndex: '1',
                position: 'absolute',
            },
            div: {
                svg: {
                    fill: 'currentColor',
                    width: '1em',
                    height: '1em',
                    display: 'inline-block',
                    fontSize: '1.5rem',
                    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    flexShrink: '0',
                    userSelect: 'none',
                },
                'svg:last-child': {
                    left: '0',
                    position: 'absolute',
                    transform: 'scale(0)',
                    transition: 'transform 150ms cubic-bezier(0.4, 0, 1, 1) 0ms'
                }
            },
            p: {
                color: 'contrast',
                fontSize: 1,
            },
        },
        get disabled() {
            return {
                ...this.base,
                color: (props: any) => `${props.colors.contrast}40`,
                opacity: 0.5
            };
        },
    },
} as VariantArgs);

const Style: React.FC<RadioStyledProps> = styled.div`
    ${variants}
    ${defaults}
`;


const Radio: React.FC<RadioProps> = props => (
    <Style styling={props.styling ?? 'base'} style={{ color: props?.color }}>
        <label className={props.checked ? 'checked' : ''} htmlFor={props.id}>
            <span>
                <span>
                    <input
                        type="radio"
                        name={props.name}
                        id={props.id}
                        data-testid={props?.dataTestId}
                        disabled={props?.disabled}
                        {...props}
                    />
                    <Box styling="row" position="relative">
                        <svg
                            focusable="false"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            role="presentation"
                        >
                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
                        </svg>
                        <svg
                            focusable="false"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            role="presentation"
                        >
                            <path d="M8.465 8.465C9.37 7.56 10.62 7 12 7C14.76 7 17 9.24 17 12C17 13.38 16.44 14.63 15.535 15.535C14.63 16.44 13.38 17 12 17C9.24 17 7 14.76 7 12C7 10.62 7.56 9.37 8.465 8.465Z">
                            </path>
                        </svg>
                    </Box>
                </span>
            </span>
            {props?.label && <P styling="base">{props.label}</P>}
        </label>
    </Style>
);


export default memo(Radio);
