import { applyMiddleware, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { composeWithDevTools } from "redux-devtools-extension";

import reducers from './context';

const enhancer = applyMiddleware(thunk);
const store =
  process.env.NODE_ENV === 'development'
    ? createStore(reducers, composeWithDevTools(enhancer))
    : createStore(reducers, enhancer);

export const persistor = persistStore(store);
export default store;
