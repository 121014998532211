import firebase from "firebase";
import { Client } from "./types";
import axios from "axios";
import headers from "../../../utils/mountFirebaseHeaders";

const { REACT_APP_FIREBASE_FUNCTIONS_URL } = process.env;

export const getClients = () =>
  firebase
    .firestore()
    .collection('contractor')
    .get()
    .then(function (querySnapshot) {
      const docs: Client[] = [];
      querySnapshot.forEach(function (doc) {
        docs.push({ ...doc.data() as Client, id: doc.id });
      });
      return docs;
    });

export const getClient = (clientId: string) =>
  firebase
    .firestore()
    .collection('contractor')
    .doc(clientId)
    .get()
    .then(doc => doc.data());

export const editClient = (clientId: string, client: Client) =>
  firebase
    .firestore()
    .collection('contractor')
    .doc(clientId)
    .update(client);

export const createClient = (client: Client, clientId: string) =>
  firebase
    .firestore()
    .collection('contractor')
    .doc(clientId)
    .set(client);

export const deleteClient = async (clientId: string) => {
  const syonet = await firebase.firestore().collection("contractor").doc(clientId).collection("syonet").get()
  syonet.forEach(async data => {
    await firebase.firestore().collection("contractor").doc(clientId).collection("syonet").doc(data.id).delete()
  })

  const quiver = await firebase.firestore().collection("contractor").doc(clientId).collection("quiver").get()
  quiver.forEach(async data => {
    await firebase.firestore().collection("contractor").doc(clientId).collection("quiver").doc(data.id).delete()
  })
  return firebase.firestore().collection("contractor").doc(clientId).delete()
}

export const getContractorSyonetParameters = async (url: string) =>
  axios.get(`${REACT_APP_FIREBASE_FUNCTIONS_URL}/getContractorSyonetParameters?url=${url}`, { headers: await headers() })

export const addContractorSyonetParameters = async (body: object) =>
  axios.post(`${REACT_APP_FIREBASE_FUNCTIONS_URL}/addContractorSyonetParameters`, body, { headers: await headers() })

export const getSyonetModalityTypes = async () => axios.get(`${REACT_APP_FIREBASE_FUNCTIONS_URL}/getSyonetModalityTypes`, { headers: await headers() })

export const getContractorQuiverParameters = async (url: string) =>
  axios.get(`${REACT_APP_FIREBASE_FUNCTIONS_URL}/getContractorQuiverParameters?url=${url}`, { headers: await headers() })

export const addContractorQuiverParameters = async (body: object) =>
  axios.post(`${REACT_APP_FIREBASE_FUNCTIONS_URL}/addContractorQuiverParameters`, body, { headers: await headers() })

export const getQuiverModalityTypes = async () => axios.get(`${REACT_APP_FIREBASE_FUNCTIONS_URL}/getQuiverModalityTypes`, { headers: await headers() })