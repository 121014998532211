export const firebaseConfigDev = {
  apiKey: "AIzaSyCrfNJIoLZaLb3ZOHXNNzfC9Qm1hixPP1s",
  authDomain: "pessego-dev.firebaseapp.com",
  databaseURL: "https://pessego-dev.firebaseio.com",
  projectId: "pessego-dev",
  storageBucket: "pessego-dev.appspot.com",
  messagingSenderId: "888679748627",
  appId: "1:888679748627:web:acc88efc682d79be"
};

export const firebaseConfigProd = {
  apiKey: "AIzaSyCFfo0_0dz-L_BOF5WLEVmowQuCF4Xn6Kc",
  authDomain: "pessego-prod.firebaseapp.com",
  databaseURL: "https://pessego-prod.firebaseio.com",
  projectId: "pessego-prod",
  storageBucket: "pessego-prod.appspot.com",
  messagingSenderId: "212211107057",
  appId: "1:212211107057:web:74ae5981d467d083"
};
