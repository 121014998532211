import React from "react";
import { validateVerify } from "../../../utils/validations";
import { fieldNotFilledAlert } from '../../../utils';
import { currencyMask } from '../../../utils/masks';
import { Box, Button } from "../../../components/ds/SubatomicParticles";
import { H3 } from "../../../components/ds/theme/typography";
import FormInput from "../../../components/FormInput";
import FormSelect from "../../../components/FormSelect";
import TextMaskFormInput from "../../../components/TextMaskFormInput";

function Step2({
  onComplete,
  rendaMensal,
  setRendaMensal,
  type,
  setType,
  quantidadeDependentes,
  setQuantidadeDependentes,
  sexoConjuge,
  setSexoConjuge,
  fumante,
  setFumante,
  estadoCivil,
  setEstadoCivil,
  historicoDoencasGraves,
  setHistoricoDoencasGraves
}) {

  if (type !== 0) setType(0);

  const _handleIncomplete = () => {
    if (rendaMensal === "") fieldNotFilledAlert('Renda mensal');
    if (quantidadeDependentes === "") fieldNotFilledAlert('Quantidade de dependentes');
    if (estadoCivil === "") fieldNotFilledAlert('Estado civil');
    if (fumante === "") fieldNotFilledAlert('Fumante?');
    if (historicoDoencasGraves === "") fieldNotFilledAlert('Histórico de doenças graves?');
    if (estadoCivil === "casado" && sexoConjuge === "") fieldNotFilledAlert('Sexo do cônjuge')
  }

  const _handleVerify = () => {
    if (
      rendaMensal === "" ||
      quantidadeDependentes === "" ||
      estadoCivil === "" ||
      fumante === "" ||
      historicoDoencasGraves === "" ||
      (estadoCivil === "casado" && sexoConjuge === "")
    ) {
      _handleIncomplete();
    } else {
      onComplete();
    }
  };

  return (
    <>
      <Box
        gridColumn={{
          mobile: "2/span 10",
          tablet: "2/span 10",
          desktop: "4/span 6",
        }}
        styling="column"
      >
        <H3 mb="30px" styling="light">Um pouco mais sobre você</H3>
        <TextMaskFormInput
          placeholder="Renda Mensal"
          value={rendaMensal}
          mask={currencyMask}
          onChange={(e) => setRendaMensal(e.target.value)}
          onBlur={(e) => validateVerify(e)}
          styling="base"
        />
        <FormInput
          data-testid="quantidadeDependentes"
          id="quantidadeDependentes"
          name="quantidadeDependentes"
          onBlur={e => validateVerify(e)}
          onChange={(e) => {
            if (Number(e.target.value) >= 0) {
              setQuantidadeDependentes(e.target.value)
            }
          }}
          placeholder="Quantidade de dependentes"
          type="number"
          value={quantidadeDependentes}
        />
        <FormSelect
          data-testid="fumante"
          id="fumante"
          name="fumante"
          onBlur={e => validateVerify(e)}
          onChange={(e) => setFumante(e.value)}
          options={[
            { value: 'nao', label: 'Não' },
            { value: 'sim', label: 'Sim' },
          ]}
          placeholder="Fumante?"
          styling="base"
          defaultValue={fumante}
        />
        <FormSelect
          data-testid="historicoDoencasGraves"
          id="historicoDoencasGraves"
          name="historicoDoencasGraves"
          onBlur={e => validateVerify(e)}
          onChange={(e) => setHistoricoDoencasGraves(e.value)}
          options={[
            { value: 'nao', label: 'Não' },
            { value: 'sim', label: 'Sim' },
          ]}
          placeholder="Histórico de doenças graves?"
          styling="base"
          defaultValue={historicoDoencasGraves}
        />
        <FormSelect
          data-testid="estadoCivil"
          id="estadoCivil"
          name="estadoCivil"
          onBlur={e => validateVerify(e)}
          onChange={(e) => setEstadoCivil(e.value)}
          options={[
            { value: 'solteiro', label: 'Solteiro' },
            { value: 'casado', label: 'Casado' },
            { value: 'divorciado', label: 'Divorciado' },
            { value: 'viuvo', label: 'Viúvo' },
            { value: 'separado', label: 'Separado' },
          ]}
          placeholder="Estado civil"
          styling="base"
          defaultValue={estadoCivil}
        />
        {estadoCivil === "casado" &&
          <FormSelect
            data-testid="sexoConjuge"
            id="sexoConjuge"
            name="sexoConjuge"
            onBlur={e => validateVerify(e)}
            onChange={(e) => setSexoConjuge(e.value)}
            options={[
              { value: 'F', label: 'Feminino' },
              { value: 'M', label: 'Masculino' }
            ]}
            placeholder="Sexo do Conjuge"
            styling="base"
            defaultValue={sexoConjuge}
          />
        }
        <Button display="block" margin="0 auto" onClick={_handleVerify} styling="primary" width="195px">Próximo</Button>
      </Box>
    </>
  );
}

export default Step2;
