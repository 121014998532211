import * as actions from "../user/actions";
import * as repository from "./repository";

export const getInsurances = () => async dispatch => {
  try {
    dispatch(actions.insuranceLoading(true));
    const insurances = await repository.getInsurances()
    dispatch(actions.setMyInsurances(insurances))
    dispatch(actions.insuranceLoading(false));
  } catch (e) {
    dispatch(actions.insuranceLoading(false));
  }
}

export const BuscaCotacoes = () => async dispatch => {
  try {
    dispatch(actions.quotesLoading(true));
    const data = await repository.BuscaCotacoes();
    dispatch(actions.setMyQuotes(data))
    dispatch(actions.quotesLoading(false));
  } catch (e) {
    dispatch(actions.quotesLoading(false));
  }
}

export const BuscaInfoCotacoes = (document) => async dispatch => {
  try {
    dispatch(actions.quoteInfoLoading(true));
    const data = await repository.BuscaInfoCotacoes(document);
    dispatch(actions.setQuoteInfo(data))
    dispatch(actions.quoteInfoLoading(false));
  } catch (e) {
    dispatch(actions.quoteInfoLoading(false));
  }
}

export const getValueDetails = body => async (dispatch, getState) => {
  const { user: { myQuotes } } = getState()
  try {
    dispatch(actions.quotesLoading(true));
    const response = await repository.getValueDetails(body)
    const newArray = myQuotes.map(item => {
      if (item.quoteId === body.quoteId) {
        return {
          ...item,
          valueDetails: response.data.data
        }
      }
      return item
    })
    dispatch(actions.setMyQuotes(newArray))
    dispatch(actions.quotesLoading(false));
    return response
  } catch (e) {
    dispatch(actions.quotesLoading(false));
  }
}