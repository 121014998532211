import React from "react";
import { Box, Button } from "../../../../components/ds/SubatomicParticles";
import { H3, P } from "../../../../components/ds/theme/typography";

const ModalLogin = ({ onRedirectAuto, onRedirectSinistro }) => {
  return (
    <Box
      backgroundColor="background.default"
      borderRadius="12px"
      maxWidth="415px"
      padding="2rem"
      position="fixed"
      styling="cloumn"
      width="90%"
      zIndex="2"
    >
      <H3 styling="light">Comunicar sinistro</H3>
      <P my="30px" styling="base">Escolha uma opção para iniciar o alerta de um sinistro.</P>
        <Button display="block" margin="0 auto" onClick={onRedirectAuto} styling="primary" width="90%">Automóvel</Button>
        <Button display="block" margin="15px auto 0" onClick={onRedirectSinistro} styling="primary" width="90%">Outros</Button>
    </Box>
  );
};

export default ModalLogin;
