import matchSorter, {rankings} from 'match-sorter';
import { FilterValue, IdType, Row } from 'react-table';

export function tobeEqualKeyFilter<T extends object>(rows: Array<Row<T>>, id: IdType<T>, filterValue: FilterValue) {
  return matchSorter(rows, filterValue, {
    keys: [{threshold: rankings.EQUAL, key: (row: Row<T>) => row.values[id].key}]
  });
};

// Let the table remove the filter if the string is empty
tobeEqualKeyFilter.autoRemove = (val: any) => !val;
