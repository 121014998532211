export type Client = {
  id?: string;
  name: string;
  domain: string;
  config: {
    sac: boolean;
    pages: [
      {
        sobre: boolean;
      }
    ]
  },
  syonetModality?: any[],
  syonetTypes?: SyonetModalityType[],
  quiverModality?: any[],
  quiverTypes?: QuiverModalityType[],
};

export type SyonetParam = {
  tipo: string;
  label: string;
  midia?: string;
  grupoEvento?: string;
  tipoEvento?: string;
};

export type SyonetModalityType = {
  value: string;
  label: string;
};

export type QuiverParam = {
  tipo: string;
  label: string;
  grupoHierarquico?: string;
};

export type QuiverModalityType = {
  value: string;
  label: string;
};


export interface State {
  clients: Client[];
  isLoading: boolean;
};

export const SET_CLIENTS = "clients/SET_CLIENTS";
export const IS_LOADING = "clients/IS_LOADING";

