import React from "react";
import { validateEmail, validateVerify } from "../../../utils/validations";
import { fieldNotFilledAlert } from '../../../utils';
import FormInput from "../../../components/FormInput";
import { Box, Button } from "../../../components/ds/SubatomicParticles";
import { H3 } from "../../../components/ds/theme/typography";
import { getRawPhone } from '../../../utils/validations';

export default function Step2({
  onNext,
  email,
  telefone,
}) {
  const _handleVerify = () => {
    const rawTelephone = getRawPhone(telefone.value);
    if (email.value !== "" && telefone.value !== "") {
      if (rawTelephone.length < 11) {
        return fieldNotFilledAlert('Telefone')
      }
      if (validateEmail(email.value)) {
        let ddd = rawTelephone.substring(0, 2)
        let phone = rawTelephone.substring(2, rawTelephone.length)
        onNext({ email: email.value, ddd, telefone: phone });
      } else {
        fieldNotFilledAlert('Email')
      }
    } else {
      if (email.value === "") {
        fieldNotFilledAlert('Email')
      }
      if (telefone.value === "") {
        fieldNotFilledAlert('Telefone')
      }
    }
  };

  return (
    <Box gridColumn={{ mobile: '2/span 10', tablet: '2/span 10', desktop: '4/span 6' }} styling="column">
      <H3 mb="30px" styling="light">Informe seus dados pessoais</H3>
      <FormInput
        {...email}
        data-testid="email"
        id="email"
        name="email"
        onBlur={e => validateVerify(e)}
        placeholder="E-mail"
        type="text"
      />
      <FormInput
        {...telefone}
        data-testid="telefone"
        id="telefone"
        mask="(99) 99999-9999"
        name="telefone"
        onBlur={e => validateVerify(e)}
        placeholder="Telefone celular"
        type="text"
      />
      <Button display="block" margin="0 auto" onClick={_handleVerify} styling="primary" width="295px">Próximo</Button>
    </Box>
  );
}
