/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { ReactComponent as IconClipboard } from "../../../assets/svg/icon_clipboard.svg";
import SmallLoading from "../../SmallLoading";
import { Box, Button } from "../../../components/ds/SubatomicParticles";
import { H2, P } from "../../../components/ds/theme/typography";
import Icon from "../../../components/ds/atoms/Icon";

const Coverages = ({
  onIncomplete,
  onComplete,
  selectedCoverage,
  setSelectedCoverage,
  coverage,
  onGetCoverage,
  coverageLoading,
  benefitsCoverage,
}) => {
  useEffect(() => {
    onGetCoverage();
  }, []);

  const handleNextStep = (selected) => {
    if (selected) {
      return onComplete(selected);
    }
    return onIncomplete();
  };

  return (
    <Box gridColumn="2/span 10" styling="column">
      <H2 mb="52px" styling="light">
        Escolha uma cobertura
      </H2>
      {coverageLoading && coverage.length === 0 ? (
        <Box styling="row">
          <SmallLoading />
        </Box>
      ) : (
        <Box
          gridGap="30px"
          gridTemplateColumns={{
            mobile: "1fr",
            tablet: "1fr",
            desktop: "1fr 1fr 1fr",
          }}
          styling="grid"
          width="100%"
        >
          {coverage.map((item, i) => (
            <Box
              key={`c${i}`}
              alignItems="flex-start"
              backgroundColor="background.default"
              borderRadius="12px"
              boxShadow="10px 10px 28px 1px rgba(0, 0, 0, 0.1)"
              padding="20px"
              styling="column"
            >
              <H2 mb="10px" styling="light" textAlign="center" width="100%">
                {item.title}
              </H2>
              {item.description.map((description, i) => (
                <React.Fragment key={`c${i}`}>
                  <P fontSize="12px" styling="base">
                    {description.label}
                  </P>
                  <P fontSize="14px" mb="10px" styling="base">
                    {description.value ? description.value : "Incluso"}
                  </P>
                </React.Fragment>
              ))}
              <Button
                mt="10px"
                onClick={() => {
                  setSelectedCoverage(item.coverageId);
                  handleNextStep(item.coverageId);
                }}
                styling="primary"
                width="100%"
              >
                Escolher este
              </Button>
            </Box>
          ))}
        </Box>
      )}
      {coverageLoading && coverage.length !== 0 && (
        <Box styling="row">
          <SmallLoading />
        </Box>
      )}

      {benefitsCoverage.length !== 0 && (
        <Box
          backgroundColor="background.default"
          borderRadius="12px"
          boxShadow="10px 10px 28px 1px rgba(0, 0, 0, 0.1)"
          justifyContent="space-between"
          mt="20px"
          padding="20px"
          styling="row"
        >
          <Box styling="row" width="48%">
            <Icon styling="base" width="61px" height="80px">
              <IconClipboard className="icon-clipboard" />
            </Icon>
            <P ml="15px" styling="base">
              Todos os planos contam
              <br />
              com benefícios especiais:
            </P>
          </Box>
          <Box
            alignItems="flex-start"
            justifyContent="flex-start"
            styling="column"
            width="48%"
          >
            {benefitsCoverage.map((item) => (
              <P
                fontSize="14px"
                mb="5px"
                key={`benefit-${item.label}`}
                styling="base"
              >
                - {item.label};
              </P>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Coverages;
