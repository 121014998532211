import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import * as userService from "../../state/context/user/services";
import InjectImages from "../../containers/Images";

function iFrameAuto({ history, showModal, user, images, location }) {

  let type = location.state.type
  let moto_url = "https://www.corretor-online.com.br/canalcliente/index.htm?ParamWS=SFl3eERvSkFFQUMvWXJXZEVBa1NOZGxHUWlzRjhRSHJjY1pMMWx1eXQ0UUhXZmdJQ3dzZTVCZFVxc2tVTTUvWG00UHowUkVlMjFOM3JwdXVhMkdnWGttdzJNRWdhc1JZN21GTUkya1FyTVZvZnM0UFdiV1JRL1RnaEVYeFdsVGJvZ0tmTExCZ3MyQURvekxleklaMHlQTnBtaklucXQ1RTE3SzBQNzluRjgzQjk4SCtFK0xrdnc9PQ=="
  let auto_url = "https://www.corretor-online.com.br/canalcliente/index.htm?ParamWS=SFl3eENzSkFFQUMvWXJXZENSNGFSTmpHa05ZVXdRZXNseE1QMXV5eHR5RVBzdkFSRmhaNWtGOVFVdzFUekh4ZWI0NCtESjd3Mko2NmM5MTBYUXVKZWlWQnQ0Y2thc1M0cldETUkya1VyTVZvZnM0UFdiVUR4eUdBRnhiRnE2dDJ6a0hJRmxtd1diQ0JVUmx2WmlrZnluS2Fwc0tMYWpEUnRTenR6Ky9GUlVzSWZiVC9oRGlITHc9PQ=="
  let url = ""
  type === "motocicleta" ? url = moto_url : url = auto_url
  return (
    <>

     <iframe 
      src={url} 
      overflow="hidden" 
      name="iframe_b" 
      width="100%" 
      height="1500px"
      title="Iframe"
      >       
     </iframe>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = {
  showModal: userService.showModal,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(InjectImages(iFrameAuto));
