import * as Yup from 'yup';

export const Schema = Yup.object().shape({});

export const initialValues = {
  background: {
    "dark": "#f0f1f2",
    "light": "#2F3F4E"
  },
  blacks: [
    "#35363a"
  ],
  danger: {
    "contrast": "#ffffff",
    "dark": "#ff261b",
    "default": "#ff6961",
    "light": "#ffbdb9"
  },
  dark: false,
  disabled: "#9398a6",
  grays: {
    "default": "#9ba8b2",
    "dark": "#4b4b4b",
    "light": "#eeeeef",
    "contrast": "#f5f5f5",
  },
  hovers: [
    "#f5f7f7"
  ],
  info: {
    "contrast": "#ffffff",
    "dark": "#0a7676",
    "default": "#0ea7a7",
    "light": "#11d4d4"
  },
  primaries: [
    "#d7edf4"
  ],
  primary: {
    "contrast": "#ffffff",
    "dark": "#d14b0c",
    "default": "#F26522",
    "light": "#f69263"
  },
  secondary: {
    "contrast": "#1B2B3A",
    "dark": "#2c2522",
    "default": "#372E2A",
    "light": "#453935"
  },
  success: {
    "contrast": "#ffffff",
    "dark": "#0a753c",
    "default": "#0E9d51",
    "light": "#10bc60"
  },
  text: {
    "dark": "#1B2b3a",
    "light": "#ffffff"
  },
  warning: {
    "contrast": "#ffffff",
    "dark": "#e4902c",
    "default": "#ffb143",
    "light": "#ffd868"
  },
  whites: [
    "#eeeeef",
    "#f2fbfe"
  ]
}