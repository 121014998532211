import * as types from "./types";

const initialState = {
  data: {
    loading: false,
    isLoadingQuotationValues: false,
    isBuyingQuotation: false,
    continents: {
      list: [],
      isLoading: false
    },
    tripTypes: {
      list: [],
      isLoading: false
    },
    portableTypes: {
      list: [],
      isLoading: false
    },
    roles: {
      list: [],
      isLoading: false
    },
    rangePayments: {
      list: [],
      isLoading: false
    },
    residenceCoverage: {
      list: [],
      isLoading: false
    },
    autoCoverage: {
      list: [],
      isLoading: false
    },
    maritalState: {
      list: {},
      isLoading: false
    },
    generalRegistryState: {
      list: {},
      isLoading: false
    },
    autoInsuranceTypes: {
      list: [],
      isLoading: false
    }
  }
};

const reducers = (state = initialState.data, action) => {
  switch (action.type) {
    case types.LOADING:
      return { ...state, loading: action.user };
    case types.LOADING_QUOTATION_VALUES:
      return { ...state, isLoadingQuotationValues: action.isLoading };
    case types.BUYING_QUOTATION:
        return { ...state, isBuyingQuotation: action.isLoading };
    case types.SUCCESS_GET_CONTINENTS:
      return { ...state, continents: { ...state.continents, list: action.list } }
    case types.LOADING_CONTINENTS:
      return { ...state, continents: { ...state.continents, isLoading: action.isLoading } }
    case types.SUCCESS_GET_TRIP_TYPES:
      return { ...state, tripTypes: { ...state.tripTypes, list: action.list } }
    case types.LOADING_TRIP_TYPES:
      return { ...state, tripTypes: { ...state.tripTypes, isLoading: action.isLoading } }
    case types.SUCCESS_GET_PORTABLE_TYPES:
      return { ...state, portableTypes: { ...state.portableTypes, list: action.list } }
    case types.LOADING_PORTABLE_TYPES:
      return { ...state, portableTypes: { ...state.portableTypes, isLoading: action.isLoading } }
    case types.SUCCESS_GET_ROLES:
      return { ...state, roles: { ...state.roles, list: action.list } }
    case types.LOADING_ROLES_TYPES:
      return { ...state, roles: { ...state.roles, isLoading: action.isLoading } }
    case types.SUCCESS_GET_RANGE_PAYMENT:
      return { ...state, rangePayments: { ...state.rangePayments, list: action.list } }
    case types.LOADING_RANGE_PAYMENT:
      return { ...state, rangePayments: { ...state.rangePayments, isLoading: action.isLoading } }
    case types.SUCCESS_GET_RESIDENCE_COVERAGE:
      return { ...state, residenceCoverage: { ...state.residenceCoverage, list: action.list } }
    case types.LOADING_RESIDENCE_COVERAGE:
      return { ...state, residenceCoverage: { ...state.residenceCoverage, isLoading: action.isLoading } }
    case types.SUCCESS_GET_AUTO_COVERAGE:
      return { ...state, autoCoverage: { ...state.autoCoverage, list: action.list } }
    case types.LOADING_AUTO_COVERAGE:
      return { ...state, autoCoverage: { ...state.autoCoverage, isLoading: action.isLoading } }
    case types.SUCCESS_GET_MARITAL_STATE:
      return { ...state, maritalState: { ...state.maritalState, list: action.list } }
    case types.LOADING_MARITAL_STATE:
      return { ...state, maritalState: { ...state.maritalState, isLoading: action.isLoading } }
    case types.SUCCESS_GET_GENERAL_REGISTRY_STATE:
      return { ...state, generalRegistryState: { ...state.generalRegistryState, list: action.list } }
    case types.LOADING_GENERAL_REGISTRY_STATE:
      return { ...state, generalRegistryState: { ...state.generalRegistryState, isLoading: action.isLoading } }
    case types.SUCCESS_GET_AUTO_INSURANCE_TYPES:
      return { ...state, autoInsuranceTypes: { ...state.autoInsuranceTypes, list: action.list } }
    case types.LOADING_GET_AUTO_INSURANCE_TYPES:
      return { ...state, autoInsuranceTypes: { ...state.autoInsuranceTypes, isLoading: action.isLoading } }
    case types.LOGOUT_USER:
      return { ...initialState.data };
    default:
      return state;
  }
};

export { initialState };
export default reducers;
