/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import SmallLoading from "../../../components/SmallLoading";
import { ReactComponent as ChevronRight } from "../../../assets/svg/chevron.svg";
import { ReactComponent as InfoIco } from "../../../assets/svg/info-ico.svg";
import Tooltip from "react-tooltip-lite";
import { Box } from "../../../components/ds/SubatomicParticles";
import Card from "../../../components/ds/atoms/Card";
import { H3, Span } from "../../../components/ds/theme/typography";
import Icon from '../../../components/ds/atoms/Icon';
import { useTheme } from "styled-components";

const InsuranceConfirm = ({
  getAutoInsuranceTypes,
  autoInsuranceTypeList,
  autoInsuranceTypeLoading,
  selectedTypeInsurance,
  setSelectedTypeInsurance,
  onNext,
  onFail,
}) => {
  useEffect(() => {
    getAutoInsuranceTypes();
  }, []);

  const theme = useTheme();
  
  return (
    <Box
      gridColumn={{
        mobile: "2/span 10",
        tablet: "2/span 10",
        desktop: "4/span 6",
      }}
      p={"5rem 0"}
      display="flex"
      flexDirection="column"
    >
      <H3
        alignSelf="center"
        color="primary.default"
        fontSize="24px"
        mb="52px"
        styling="light"
      >
        Selecione o seguro auto desejado
      </H3>

      {autoInsuranceTypeLoading && autoInsuranceTypeList.length === 0 ? (
        <Box display="flex" justifyContent="center" width="100%">
          <SmallLoading />
        </Box>
      ) : (
          <>
            {autoInsuranceTypeList.map((item, i) => (
              <Box
                width="100%"
                display="flex"
                flexDirection="column"
                key={`card${i}`}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  width="100%"
                >
                  <Box
                    display={{
                      mobile: "none",
                      tablet: "flex",
                      desktop: "flex",
                    }}
                    flexDirection="column"
                    justifyContent="center"
                    mr="1rem"
                  >
                    <Tooltip
                      background={theme.colors.primary.default}
                      color={theme.colors.text.light}
                      content={item.description}
                      direction="up"
                      className="target"
                      tipContentClassName=""
                    >
                      <Icon styling="baseNone" height="20px" width="20px">
                        <InfoIco height="20px" width="20px" />
                      </Icon>
                    </Tooltip>
                  </Box>
                  <Card
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    width="100%"
                    onClick={() =>
                      setSelectedTypeInsurance(item.CommercialProductCode) ||
                      onNext(item.CommercialProductCode)
                    }
                  >
                    <Box display="flex" flexDirection="column" flexGrow="10">
                      <Box display="flex" flexDirection="column">
                        <Span className="title">{`${item.label} `}</Span>
                      </Box>
                      <Box
                        display={{
                          mobile: "flex",
                          tablet: "none",
                          desktop: "none",
                        }}
                        flexDirection="column"
                      >
                        <Span className="description">{`${item.description} `}</Span>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <ChevronRight style={{ width: 15, height: 15 }} />
                    </Box>
                  </Card>
                </Box>
              </Box>
            ))}
          </>
        )}
    </Box>
  );
};

export default InsuranceConfirm;
