/* eslint-disable react-hooks/exhaustive-deps */
/* eslint react-hooks/rules-of-hooks: off */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getVehicleConstants } from "../../../state/context/form/services";
import { useFormInput, dateMask } from "../../../utils";
import { validateVerify, keepOnlyNumbers, autoCorrectedDatePipe } from "../../../utils/validations";
import { Box, Button } from "../../../components/ds/SubatomicParticles";
import { H3 } from "../../../components/ds/theme/typography";
import FormSelect from "../../../components/FormSelect";
import FormInput from "../../../components/FormInput";
import TextMaskFormInput from "../../../components/TextMaskFormInput";

function step2({ onNext, onIncomplete, getVehicleConstants }) {
  const cpfCnpjMask = {
    cpf: "999.999.999-99",
    cnpj: "99.999.999/9999-99",
  };

  const [vehicleOwnerOptions, setVehicleOwnerOptions] = useState({});
  const [vehicleOwner, setVehicleOwner] = useState("");
  const [ownerGender, setOwnerGender] = useState("");
  const [ownerBirthdate, setOwnerBirthdate] = useState("");
  const ownerName = useFormInput("", "Nome");
  const ownerCpf = useFormInput("", "CPF/CNPJ", "text");
  const [ownerCpfCnpjMask, setOwnerCpfCnpjMask] = useState(cpfCnpjMask.cpf);

  const [vehicleDriverOptions, setVehicleDriverOptions] = useState({});
  const [vehicleDriver, setVehicleDriver] = useState("");
  const [driverGender, setDriverGender] = useState("");
  const [driverBirthdate, setDriverBirthdate] = useState("");
  const driverName = useFormInput("", "Nome");
  const driverCpf = useFormInput("", "CPF/CNPJ", "text");
  const [driverCpfCnpjMask, setDriverCpfCnpjMask] = useState(cpfCnpjMask.cpf);

  const [keyPressCount, setkeyPressCount] = useState(0);

  const IsPolicyHolder = "F"; // "condutor" em questão não é segurado - desconsiderar
  const IsDriver = "T"; // indica se dirige semanalmente
  const DaysPerWeek = "7"; // quantidade de dias da 7 que condutor digire - documentação pede para desconsiderar, mas deixarei fixo
  const IsReferenceDriver = "F"; // indica se o condutor em questão é ou não o condutor referência Liberty
  const MaritalStatus = "S"; // indica situação civil do condutor - no sistema da Liberty não oferece esta opção. deixarei fixo 'S': solteiro
  // tipo do condutor
  const insuredDriverType = "1"; // segurado
  const ownerDriverType = "2"; // proprietário do veículo
  const conductorDriverType = "3"; // condutor principal

  useEffect(() => {
    const fetch = async () => {
      let data = await getVehicleConstants("vinculoProprietarioComVeiculo");
      setVehicleOwnerOptions(data);
      data = await getVehicleConstants("vinculoCondutorComProprietario");
      setVehicleDriverOptions(data);
    };
    fetch();
  }, []);

  const _handleVerify = () => {
    if (
      vehicleOwner === "" ||
      ownerGender === "" ||
      ownerBirthdate === "" ||
      ownerName.value === "" ||
      ownerCpf.value === "" ||
      vehicleDriver === "" ||
      driverGender === "" ||
      driverBirthdate === "" ||
      driverName.value === "" ||
      driverCpf.value === ""
    ) {
      onIncomplete();
    } else {
      let ownerCpfValue = keepOnlyNumbers(ownerCpf.value);
      // indica se trata de pessoa física ou jurídica
      let ownerIsIndividual = ownerCpfValue.length < 12 ? "T" : "F";
      let owner = {
        IsPolicyHolder,
        IsDriver,
        DaysPerWeek,
        IsReferenceDriver,
        MaritalStatus,
        DriverType: ownerDriverType,
        IsIndividual: ownerIsIndividual,
        RelationalTypeCode: vehicleOwner,
        Gender: ownerGender,
        BirthDate: ownerBirthdate.format("YYYY-MM-DD"),
        Name: ownerName.value,
        PublicIdNumber: ownerCpfValue,
      };

      let conductorDriverCpf = keepOnlyNumbers(driverCpf.value);
      // indica se trata de pessoa física ou jurídica
      let driverIsIndividual = conductorDriverCpf.length < 12 ? "T" : "F";
      let driver = {
        IsPolicyHolder,
        IsDriver,
        DaysPerWeek,
        IsReferenceDriver,
        MaritalStatus,
        DriverType: conductorDriverType,
        IsIndividual: driverIsIndividual,
        RelationalTypeCode: vehicleDriver,
        Gender: driverGender,
        BirthDate: driverBirthdate.format("YYYY-MM-DD"),
        Name: driverName.value,
        PublicIdNumber: conductorDriverCpf,
      };
      let insured = JSON.parse(JSON.stringify(driver));
      insured.RelationalTypeCode = "0"; // segurado (que é o condutor principal) recebe código 0 por default
      insured.DriverType = insuredDriverType;

      onNext([
        insured, // segurado (que é o condutor principal)
        owner, // proprietário do veículo
        driver, // condutor principal do veículo
      ]);
    }
  };

  const handleOnKeyUp = (cpf, setCPF) => {
    setkeyPressCount(keyPressCount + 1);
    let valueLength = cpf.value.replace(/[^\d]/g, "").length;
    if (valueLength <= 11 && keyPressCount > valueLength) {
      setkeyPressCount(valueLength);
      setCPF(cpfCnpjMask.cpf);
    }
    if (keyPressCount > 11) {
      setCPF(cpfCnpjMask.cnpj);
    }
  };

  return (
    <>
      <Box
        gridColumn={{
          mobile: "2/span 10",
          tablet: "2/span 10",
          desktop: "4/span 6",
        }}
        styling="column"
      >
        <H3 mb="30px" styling="light">Dados</H3>
        <H3 mb="15px" styling="light">Proprietário do Veículo</H3>

        {vehicleOwnerOptions && vehicleOwnerOptions.length > 0 && (
          <FormSelect
            data-testid="vehicleOwner"
            id="vehicleOwner"
            name="vehicleOwner"
            options={
              vehicleOwnerOptions.map((options) => ({
                value: options.COD,
                label: options.DESCRICAO,
              }))
            }
            defaultValue={vehicleOwner}
            onChange={(value) => setVehicleOwner(value)}
            placeholder="Vinculo segurado"
            styling="base"
          />
        )}
        <FormInput
          {...ownerCpf}
          data-testid="ownerCpf"
          mask={ownerCpfCnpjMask}
          name="ownerCpf"
          onKeyUp={() => handleOnKeyUp(ownerCpf, setOwnerCpfCnpjMask)}
          onBlur={(e) => validateVerify(e)}
          placeholder="CPF/CNPJ"
          type="text"
        />
        <FormInput
          {...ownerName}
          data-testid="ownerName"
          minLength="6"
          name="ownerName"
          onBlur={(e) => validateVerify(e)}
          placeholder="Nome"
          type="text"
        />
        <FormSelect
          id="ownerGender"
          name="ownerGender"
          onChange={(value) => setOwnerGender(value)}
          options={[
            { value: 'F', label: 'Feminino' },
            { value: 'M', label: 'Masculino' }
          ]}
          placeholder="Sexo"
          styling="base"
        />
        <TextMaskFormInput
          data-testid="ownerBirthdate"
          id="ownerBirthdate"
          name="ownerBirthdate"
          onChange={(e) => {
            setOwnerBirthdate(e.target.value);
          }}
          mask={dateMask}
          pipe={date => autoCorrectedDatePipe(date, false)}
          placeholder="Data de nascimento"
          onBlur={e => validateVerify(e)}
          value={ownerBirthdate}
        />

        <H3 my="15px" styling="light">Principal Condutor Habilitado</H3>
        {vehicleDriverOptions && vehicleDriverOptions.length > 0 && (
          <FormSelect
            data-testid="vehicleDriver"
            id="vehicleDriver"
            name="vehicleDriver"
            options={
              vehicleDriverOptions.map((options) => ({
                value: options.COD,
                label: options.DESCRICAO,
              }))
            }
            defaultValue={vehicleDriver}
            onChange={(value) => setVehicleDriver(value)}
            placeholder="Vinculo segurado"
            styling="base"
          />
        )}
        <FormInput
          {...driverCpf}
          data-testid="driverCpf"
          mask={driverCpfCnpjMask}
          name="driverCpf"
          onKeyUp={() => handleOnKeyUp(driverCpf, setDriverCpfCnpjMask)}
          onBlur={(e) => validateVerify(e)}
          placeholder="CPF/CNPJ"
          type="text"
        />
        <FormInput
          {...driverName}
          data-testid="driverName"
          minLength="6"
          name="driverName"
          onBlur={(e) => validateVerify(e)}
          placeholder="Nome"
          type="text"
        />
        <FormSelect
          id="driverGender"
          name="driverGender"
          onChange={(value) => setDriverGender(value)}
          options={[
            { value: 'F', label: 'Feminino' },
            { value: 'M', label: 'Masculino' }
          ]}
          placeholder="Sexo"
          styling="base"
        />
        <TextMaskFormInput
          data-testid="driverBirthdate"
          id="driverBirthdate"
          name="driverBirthdate"
          onChange={(e) => {
            setDriverBirthdate(e.target.value);
          }}
          mask={dateMask}
          pipe={date => autoCorrectedDatePipe(date, false)}
          placeholder="Data de nascimento"
          onBlur={e => validateVerify(e)}
          value={ownerBirthdate}
        />

        <Button
          display="block"
          margin="0 auto"
          onClick={_handleVerify}
          styling="primary"
          type="button"
          width="195px"
        >
          Próximo
        </Button>
      </Box>
    </>
  );
}

const mapState = (state) => ({});

const mapDispatch = {
  getVehicleConstants: getVehicleConstants,
};

export default connect(mapState, mapDispatch)(step2);
