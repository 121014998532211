import * as types from "./types";

export const isLoading = loading => ({
  type: types.LOADING,
  loading
});

export const isLoadingQuotationValues = isLoading => ({
  type: types.LOADING_QUOTATION_VALUES,
  isLoading
});

export const isBuyingQuotation = isLoading => ({
  type: types.BUYING_QUOTATION,
  isLoading
});

export const isLoadingContinents = isLoading => ({
  type: types.LOADING_CONTINENTS,
  isLoading
})

export const successGetContinents = list => ({
  type: types.SUCCESS_GET_CONTINENTS,
  list
})

export const isLoadingTripTypes = isLoading => ({
  type: types.LOADING_TRIP_TYPES,
  isLoading
})

export const successGetTripTypes = list => ({
  type: types.SUCCESS_GET_TRIP_TYPES,
  list
})

export const isLoadingPortableTypes = isLoading => ({
  type: types.LOADING_PORTABLE_TYPES,
  isLoading
})

export const successGetPortableTypes = list => ({
  type: types.SUCCESS_GET_PORTABLE_TYPES,
  list
})

export const successGetRangePayment = list => ({
  type: types.SUCCESS_GET_RANGE_PAYMENT,
  list
})

export const isLoadingRangePayment = isLoading => ({
  type: types.LOADING_RANGE_PAYMENT,
  isLoading
})

export const successGetRoles = list => ({
  type: types.SUCCESS_GET_ROLES,
  list
})

export const isLoadingRoles = isLoading => ({
  type: types.LOADING_ROLES_TYPES,
  isLoading
})

export const successGetResidenceCoverages = list => ({
  type: types.SUCCESS_GET_RESIDENCE_COVERAGE,
  list
})

export const isLoadingResidenceCoverages = isLoading => ({
  type: types.LOADING_RESIDENCE_COVERAGE,
  isLoading
})

export const successGetAutoCoverages = list => ({
  type: types.SUCCESS_GET_AUTO_COVERAGE,
  list
})

export const isLoadingAutoCoverages = isLoading => ({
  type: types.LOADING_AUTO_COVERAGE,
  isLoading
})

export const successGetMaritalState = list => ({
  type: types.SUCCESS_GET_MARITAL_STATE,
  list
})

export const isLoadingMaritalState = isLoading => ({
  type: types.LOADING_MARITAL_STATE,
  isLoading
})

export const successGetGeneralRegistryState = list => ({
  type: types.SUCCESS_GET_GENERAL_REGISTRY_STATE,
  list
})

export const isLoadingGeneralRegistryState = isLoading => ({
  type: types.LOADING_GENERAL_REGISTRY_STATE,
  isLoading
})

export const successGetAutoInsuranceTypes = list => ({
  type: types.SUCCESS_GET_AUTO_INSURANCE_TYPES,
  list
})

export const isLoadingAutoInsuranceTypes = isLoading => ({
  type: types.LOADING_GET_AUTO_INSURANCE_TYPES,
  isLoading
})