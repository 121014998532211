import { Dispatch } from 'redux';
import * as repository from './repository';
import { setUsers, isLoading } from './actions';
import { Admin } from './types';
import { showToast } from '../../../utils';

export const getUsers = () => async (dispatch: Dispatch) => {
  try {
    dispatch(isLoading(true));
    const response = await repository.getUsers();
    dispatch(setUsers(response));
    dispatch(isLoading(false));
  } catch (error) {
    showToast(
      "error",
      "Ops :(",
      "Algo deu errado"
    )
    dispatch(isLoading(true));
  };
};

export const setAdmin = (userId: string, admin: Admin) => async (dispatch: Dispatch) => {
  try {
    dispatch(isLoading(true));
    await repository.setAdmin(userId, admin);
    showToast(
      "success",
      "Sucesso :)",
      "O registro foi atualizado !"
    );
    dispatch(isLoading(false));
  } catch (error) {
    showToast(
      "error",
      "Ops :(",
      "Algo deu errado"
    )
    dispatch(isLoading(true));
  };
};
