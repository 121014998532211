import * as types from "./types";

const initialState = {
  images: [],
  isLoading: false
};

const reducers = (state: any = initialState, action: { type: string, [key: string]: any }) => {
  switch (action.type) {
    case types.SET_IMAGES:
      return {
        ...state, images: action.images
      };
    case types.IS_LOADING:
      return {
        ...state, isLoading: action.isLoading
      };
    default:
      return state;
  }
};

export { initialState };
export default reducers;