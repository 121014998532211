export default {
  contrate_title: "Contrate seu seguro agora",
  contrate_dados_1: "Mais de 250 mil seguros vendidos",
  contrate_dados_2: "Mais de 27 anos de mercado",
  contrate_dados_3: "Mais de R$50 milhões pagos em sinistros",
  contrate_description: "Disponível na Play Store e na App Store.",
  slider_home_1_title: "Avise seu sinistro pelo app!",
  slider_home_1_description_1: "Com o aplicativo ",
  slider_home_1_description_2: "pessego.com ",
  slider_home_1_description_3: "você poderá acionar o sinistro de forma rápida e sem burocracia, nós ligaremos o quanto antes.",
  slider_home_1_description_4: "Tranquilidade e comodidade no momento que precisa do seu seguro.",
  slider_home_2_title: "Simule grátis suas cotações!",
  slider_home_2_description_1: "No App e no portal da ",
  slider_home_2_description_2: "pessego.com ",
  slider_home_2_description_3: "você faz, sem compromisso, cotações de qualquer um dos seguros disponíveis, contrata com rapidez, segurança e consultoria dos nossos profissionais.",
  slider_home_3_title: "Conte com a experiência de quem conhece!",
  slider_home_3_description_1: "Temos mais de 25 anos de história e mais de 200 mil seguros vendidos!",
  slider_home_3_description_2: "Time experiente, treinado e capacitado pelas seguradoras parceiras à sua disposição.",
  slider_home_4_title: "Visualização de coberturas!",
  slider_home_4_description_1: "Suas apólices ficam disponíveis para que você possa visualizar as coberturas contratadas a qualquer momento via app ou através do seu login aqui no site.",
  slider_home_5_title: "Rapidez e agilidade na comunicação!",
  slider_home_5_description_1: "Para falar com um de nossos atendentes, acesse o menu ",
  slider_home_5_description_2: "Fale Conosco",
  slider_home_5_description_3: ", preencha com seus dados e rapidamente você será acionado por um de nossos promotores.",
  slider_home_6_title: "Economize tempo e ganhe conveniência!",
  slider_home_6_description_1: "Contrate seguros pelo app em até 5 minutos. Controle e tenha conveniência na palma da sua mão!",
  contato_title: "Quer ligar para a pessego.com?",
  contato_phone: "0800.941.4980",
  contato_opening_hours: "De segunda a sexta, 8h30 às 18h. Não há funcionamento em sábados, domingos e feriados nacionais.",
  fale_conosco: "Ou fale conosco através das nossas redes sociais.",
  seguro_auto_moto_title: "Seguro Auto / Moto",
  seguro_auto_moto_description: "Este seguro garante proteção contra perdas e danos ocorridos com seu automóvel em caso de acidentes, roubo ou furto, danos materiais ou corporais causados a terceiros e morte/invalidez dos passageiros.",
  seguro_residencia_title: "Seguro Residencial",
  seguro_residencia_description: "Já pensou na sua residência protegida garantindo sua segurança e sua tranquilidade? Você pode cobrir residências habituais ou de veraneio e ainda contar com serviços que facilitam o seu dia-a-dia. Explore e conheça as coberturas disponíveis.",
  seguro_viagem_title: "Seguro Viagem",
  seguro_viagem_description: "Este serviço garante a indenização de riscos desde o embarque para o destino até o retorno em viagens nacionais ou para o exterior. Não se preocupe com extravios de bagagem ou acidentes pessoais: a pessego.com se preocupa com isso pra você!",
  seguro_vida_title: "Seguro Vida",
  seguro_vida_description: "Imprevistos acontecem e, com o seguro de vida, a pessego.com assegura que sua família e você não ficarão desamparados financeiramente em caso de doenças, acidentes ou outros acontecimentos inesperados. Esteja preparado.",
  seguro_pet_title: "Seguro Pet",
  seguro_pet_description: "Garantir o cuidado de quem te recebe com tanta alegria é a melhor forma de retribuir este carinho. Seu pet também está vulnerável a contrair doenças e ocasionalmente pode precisar de atendimento emergencial. Deixe seu animalzinho em boas mãos!",
  seguro_pet_description_2: "",
  seguro_portateis_title: "Seguro Portáteis",
  seguro_portateis_description: "Muito vulneráveis aos riscos cotidianos - quebra, danos elétricos, roubo, furto, etc. Os aparelhos portáteis são ferramentas necessárias para o trabalho, lazer e conectividade. Já pensou ficar sem o seu? Esteja protegido contra este imprevisto.",
  seguro_bike_title: "Seguro Bike",
  seguro_bike_description: "Pedalar é ótimo e faz muito bem pra saúde, sua bike merece estar segura!."
}