import React from "react";
import { withRouter } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { connect } from "react-redux";
import { compose } from "recompose";
import { IconsMap } from "../../components/iconHome/iconhome";
import { showToast } from "../../utils";
import * as Yup from "yup";
import * as formService from "../../state/context/form/services";
import * as userService from "../../state/context/user/services";
import FormInput from "../../components/FormInput";
import { Box, Button } from "../../components/ds/SubatomicParticles";
import { H1, H2, H3, P } from "../../components/ds/theme/typography";
import Icon from "../../components/ds/atoms/Icon";
import BgContato from "../../assets/images/bg_contact.png";
import { ReactComponent as IconMailSend } from "../../assets/svg/icon_mail_send.svg";
import { ReactComponent as DotsRight } from "../../assets/svg/dots_left.svg";
import { ReactComponent as Dotsleft } from "../../assets/svg/dots_right.svg";
import { ReactComponent as Circle } from "../../assets/svg/circle.svg";
import injectLocale from "../../containers/Locale";
import InjectImages from "../Images";
import PreImg from "../../components/PreImg";

const initialValues = {
  nome: "",
  mensagem: "",
  email: "",
  telefone: "",
  motivo: "",
};

const validationSchema = Yup.object().shape({
  nome: Yup.string().required("Nome é obrigatório."),
  mensagem: Yup.string().required("Mensagem é obrigatório."),
  email: Yup.string()
    .email("Email está no formato inválido.")
    .required("Email é obrigatório"),
  telefone: Yup.string()
    .min(11, "Telefone inválido.")
    .required("Telefone é obrigatório"),
  motivo: Yup.string()
    .min(6, "Motivo deve ter pelo menos 6 caractéres")
    .required("Motivo é obrigatório"),
});

function Contato({ history, sendContactMessage, showModal, locale, images }) {
  return (
    <>
      <Box
        gridTemplateColumns="repeat(12,1fr)"
        gridRowGap="1.5rem"
        position="relative"
        styling="grid"
      >
        <Box
          alignItems="flex-start"
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "2/span 4",
          }}
          gridRow="1"
          styling="column"
        >
          <H1 styling="base">
            {locale.contato_title}
          </H1>
          <Box
            styling="base"
            height="2px"
            width="4vw"
            mt="1rem"
            backgroundColor="primary.default"
          ></Box>
        </Box>

        <Box
          alignItems="flex-start"
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "2/span 4",
          }}
          gridRow="2"
          styling="column"
        >
          <H3 fontSize="36px" mb="40px" mt="30px" styling="base">
            {locale.contato_phone}
          </H3>
          <P fontSize="18px" styling="bold">
            Horário de funcionamento:
          </P>
          <P mt="15px" styling="base" textAlign="justify">
            {locale.contato_opening_hours}
          </P>
        </Box>

        <Box
          justifyContent="flex-end"
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          gridColumn="5/span 8"
          gridRow="2/span 4"
          height="210px"
          mt="150px"
          styling="column"
        >
          <Icon styling="baseNone" height="100%" width="100%">
            <DotsRight width="100%" height="100%" />
          </Icon>
        </Box>

        <Box
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          gridColumn="8/span 4"
          gridRow="1/span 4"
          ml="30px"
          styling="column"
        >
          <PreImg
            src={images?.contact1?.url || BgContato}
            style={{
              width: "100%",
              height: "640px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              styling="row"
              width="50%"
              height="50%"
              borderRadius="4px"
              mt="30px"
              backgroundColor="#ededed"
            />
          </PreImg>
        </Box>

        <Box
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          gridColumn="7/span 8"
          gridRow="1/span 4"
          position="absolute"
          styling="column"
          top="50px"
        >
          <Circle />
        </Box>

        <Box
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          gridColumn="9/span 2"
          gridRow="2/span 4"
          position="absolute"
          styling="column"
          top="145px"
          zIndex="-1"
        >
          <Circle />
        </Box>

        <Box
          alignItems="center"
          backgroundColor="background.default"
          borderRadius="12px"
          boxShadow="10px 10px 20px rgba(0,0,0,.05)"
          flexWrap="wrap"
          gridColumn="2/span 10"
          gridRow="4"
          justifyContent="space-between"
          padding="3rem"
          position="relative"
          styling={{ mobile: "column", tablet: "column", desktop: "row" }}
        >
          <Box
            alignItems="flex-start"
            styling="column"
            width={{ mobile: "100%", tablet: "100%", desktop: "45%" }}
          >
            <Icon styling="baseNone" height="105px" width="105px">
              <IconMailSend height="105px" width="105px" />
            </Icon>
            <H2 my="1rem" styling="base">Prefere enviar uma mensagem?</H2>
            <P styling="base">
              Preencha aqui e aguarde o contato de um dos nossos promotores
            </P>
          </Box>

          <Box
            alignItems="flex-start"
            styling="column"
            width={{ mobile: "100%", tablet: "100%", desktop: "45%" }}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(fields, { resetForm }) => {
                sendContactMessage(fields)
                  .then((r) => {
                    if (r && r.status === 200) {
                      showModal({ visible: true, type: "createSuccess" });
                      resetForm({
                        values: {
                          nome: "",
                          mensagem: "",
                          email: "",
                          telefone: "",
                          motivo: "",
                        },
                      });
                    } else {
                      showToast(
                        "warning",
                        "Atenção",
                        "Não foi possível enviar a mensagem, tente novamente mais tarde!",
                      );
                    }
                  })
                  .catch((e) => {});
              }}
              render={({ errors, touched }) => (
                <Form translate="no" style={{ width: "100%" }}>
                  <Field name="nome">
                    {({ field }) => (
                      <FormInput
                        {...field}
                        data-testid="nome"
                        name="nome"
                        placeholder="Seu nome"
                        type="text"
                        error={touched.nome && errors.nome}
                      />
                    )}
                  </Field>

                  <Field name="email">
                    {({ field }) => (
                      <FormInput
                        {...field}
                        data-testid="email"
                        name="email"
                        placeholder="Seu e-mail"
                        type="text"
                        error={touched.email && errors.email}
                        maxLength={50}
                      />
                    )}
                  </Field>

                  <Field name="telefone">
                    {({ field }) => (
                      <FormInput
                        {...field}
                        data-testid="telefone"
                        name="telefone"
                        placeholder="Telefone celular"
                        type="text"
                        mask="(99) 99999-9999"
                        error={touched.telefone && errors.telefone}
                      />
                    )}
                  </Field>

                  <Field name="motivo">
                    {({ field }) => (
                      <FormInput
                        {...field}
                        data-testid="motivo"
                        name="motivo"
                        placeholder="Motivo"
                        type="text"
                        error={touched.motivo && errors.motivo}
                      />
                    )}
                  </Field>

                  <Field name="mensagem">
                    {({ field }) => (
                      <FormInput
                        {...field}
                        data-testid="mensagem"
                        name="mensagem"
                        placeholder="Mensagem"
                        type="text"
                        error={touched.mensagem && errors.mensagem}
                      />
                    )}
                  </Field>
                  <Button
                    display="block"
                    margin={{
                      mobile: "0 auto",
                      tablet: "0 auto",
                      desktop: "0 0 0 auto",
                    }}
                    maxWidth="295px"
                    styling="primary"
                    type="submit"
                    width="90%"
                  >
                    Enviar
                  </Button>
                </Form>
              )}
            />
          </Box>
        </Box>

        <Box
          alignItems="flex-start"
          gridColumn="2/span 10"
          gridRow="5"
          justifyContent="flex-start"
          styling={{ mobile: "column", tablet: "column", desktop: "row" }}
        >
          <H2 styling="base">
            {locale.fale_conosco}
          </H2>
        </Box>

        <Box
          alignItems="flex-start"
          gridColumn="1/span 8"
          gridRow="7"
          height="210px"
          my="30px"
          styling="column"
        >
          <Icon styling="baseNone" height="100%" width="70%">
            <Dotsleft width="100%" height="100%" />
          </Icon>
        </Box>

        <Box gridColumn="2/span 10" gridRow="8" styling="column">
          <H1 fontSize="2rem" mb="2rem" styling="light">
            Outros seguros
          </H1>
          <Box
            gridGap="1rem"
            gridTemplateColumns={{
              mobile: "repeat(2,1fr)",
              tablet: "repeat(3,1fr)",
              desktop: "repeat(7,1fr)",
            }}
            styling="grid"
            width="100%"
          >
            <IconsMap history={history} />
          </Box>
        </Box>
      </Box>
    </>
  );
}

const mapDispatchToProps = {
  sendContactMessage: formService.sendContactMessage,
  showModal: userService.showModal,
};

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(InjectImages(injectLocale(Contato)));
