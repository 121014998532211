/* eslint-disable react-hooks/exhaustive-deps */
/* eslint react-hooks/rules-of-hooks: off */
import React, { useEffect } from "react";
import { Box, Button } from "../../../components/ds/SubatomicParticles";
import { H3 } from "../../../components/ds/theme/typography";
import FormSelect from "../../../components/FormSelect";

import {
  lmircf,
  dmMock,
  assistanceMock,
  mirrorMock,
  ppMock,
  iiMock,
  ppiiMock,
  thirdMock,
} from "./step4Mocks";
import { validateVerify } from "../../../utils/validations";
import { fieldNotFilledAlert } from "../../../utils";
import { currencyMask } from "../../../utils/masks";
import TextMaskFormInput from "../../../components/TextMaskFormInput";

export default function step4({
  onNext,
  assistance,
  setAssistance,
  invalidity,
  setInvalidity,
  death,
  setDeath,
  dm,
  setDm,
  dc,
  setDc,
  dMaterial,
  setDMaterial,
  hospital,
  setHospital,
  mirror,
  setMirror,
  ppValue,
  setPPValue,
  iiValue,
  setIIValue,
  ppiiValue,
  setPPIIValue,
  third,
  setThird,
  showModal,
}) {
  useEffect(() => {
    showModal({
      visible: true,
      type: "modalMessage",
      data: {
        title: "Para agilizarmos seu tempo",
        message:
          "Já preenchemos as coberturas com os valores que a maioria dos seguros Auto são fechados no Brasil. Mas fique à vontade se quiser trocar alguma.",
      },
    });
  }, []);

  const checkFields = () => {
    return (
      dc.coveragePrice &&
      dc.coveragePrice !== "" &&
      dMaterial.coveragePrice &&
      dMaterial.coveragePrice !== ""
    );
  };

  const _handleIncomplete = () => {
    if (!dc.coveragePrice) fieldNotFilledAlert("Danos Corporais");
    if (!dMaterial.coveragePrice) fieldNotFilledAlert("Danos Materiais");
  };

  const _handleVerify = () => {
    if (checkFields()) {
      const assistanceCheck =
        Object.keys(assistance).length !== 0 && assistance.coverageValue !== "";
      const invalidityCheck =
        Object.keys(invalidity).length !== 0 &&
        invalidity.coveragePrice !== ",00" &&
        invalidity.coveragePrice !== "0,00" &&
        invalidity.coveragePrice !== "";
      const deathCheck =
        Object.keys(death).length !== 0 &&
        death.coveragePrice !== ",00" &&
        death.coveragePrice !== "0,00" &&
        death.coveragePrice !== "";
      const dmCheck = Object.keys(dm).length !== 0 && dm.coveragePrice !== "";
      const hospitalCheck =
        Object.keys(hospital).length !== 0 &&
        hospital.coveragePrice !== ",00" &&
        hospital.coveragePrice !== "0,00" &&
        hospital.coveragePrice !== "";
      const mirrorCheck =
        Object.keys(mirror).length !== 0 && mirror.coverageValue !== "";
      const ppValueCheck =
        Object.keys(ppValue).length !== 0 && ppValue.coverageValue !== "";
      const iiValueCheck =
        Object.keys(iiValue).length !== 0 && iiValue.coverageValue !== "";
      const ppiiValueCheck =
        Object.keys(ppiiValue).length !== 0 && ppiiValue.coverageValue !== "";
      const thirdCheck =
        Object.keys(third).length !== 0 && third.coverageValue !== "";
      let obj = {
        dc,
        dMaterial,
      };

      if (assistanceCheck) {
        obj = { ...obj, assistance };
      }
      if (invalidityCheck) {
        obj = {
          ...obj,
          invalidity: {
            ...invalidity,
            coveragePrice: invalidity.coveragePrice
              .replace(/[.]/g, "")
              .replace(/[,]/g, "."),
          },
        };
      }
      if (deathCheck) {
        obj = {
          ...obj,
          death: {
            ...death,
            coveragePrice: death.coveragePrice
              .replace(/[.]/g, "")
              .replace(/[,]/g, "."),
          },
        };
      }
      if (dmCheck) {
        obj = { ...obj, dm };
      }
      if (hospitalCheck) {
        obj = {
          ...obj,
          hospital: {
            ...hospital,
            coveragePrice: hospital.coveragePrice
              .replace(/[.]/g, "")
              .replace(/[,]/g, "."),
          },
        };
      }
      if (mirrorCheck) {
        obj = { ...obj, mirror };
      }
      if (ppValueCheck) {
        obj = { ...obj, ppValue };
      }
      if (iiValueCheck) {
        obj = { ...obj, iiValue };
      }
      if (ppiiValueCheck) {
        obj = { ...obj, ppiiValue };
      }
      if (thirdCheck) {
        obj = { ...obj, third };
      }
      onNext(obj)
    } else {
      _handleIncomplete();
    }
  };

  return (
    <>
      <Box
        gridColumn={{
          mobile: "2/span 10",
          tablet: "2/span 10",
          desktop: "4/span 6",
        }}
        styling="column"
      >
        <H3 mb="30px" styling="light">
          Selecione as coberturas
        </H3>

        <Box display="flex" width={"100%"} flexDirection="column">
          <FormSelect
            placeholder="Danos Materiais"
            defaultValue={dMaterial.coveragePrice}
            value={dMaterial.coveragePrice}
            options={lmircf}
            onChange={(e) => {
              setDMaterial({
                coverageCode: "53001",
                coverageValue: "A07",
                coveragePrice: e.value,
              });
            }}
          />
          <FormSelect
            placeholder="Danos Corporais"
            defaultValue={dc.coveragePrice}
            options={lmircf}
            onChange={(e) => {
              setDc({
                coverageCode: "53001",
                coverageValue: "A08",
                coveragePrice: e.value,
              });
            }}
          />
          <FormSelect
            placeholder="Danos Morais"
            value={dm.coveragePrice}
            options={dmMock}
            onChange={(e) => {
              setDm({
                coverageCode: "53003",
                coverageValue: "A49",
                coveragePrice: e.value,
              });
            }}
          />
          <TextMaskFormInput
            placeholder="Morte"
            value={death.coveragePrice}
            mask={currencyMask}
            onChange={(e) =>
              setDeath({
                coverageCode: "81001",
                coverageValue: "A09",
                coveragePrice: e.target.value.replace("R$ ", ""),
              })
            }
            onBlur={(e) => validateVerify(e)}
            styling="base"
          />
          <TextMaskFormInput
            placeholder="Despesas Hospitalares"
            value={hospital.coveragePrice}
            mask={currencyMask}
            onChange={(e) =>
              setHospital({
                coverageCode: "81001",
                coverageValue: "A26",
                coveragePrice: e.target.value.replace("R$ ", ""),
              })
            }
            onBlur={(e) => validateVerify(e)}
          />
          <TextMaskFormInput
            placeholder="Invalidez Permanente"
            value={invalidity.coveragePrice}
            mask={currencyMask}
            onChange={(e) =>
              setInvalidity({
                coverageCode: "81001",
                coverageValue: "A10",
                coveragePrice: e.target.value.replace("R$ ", ""),
              })
            }
            onBlur={(e) => validateVerify(e)}
          />
          <FormSelect
            placeholder="Assistência 24h"
            options={assistanceMock}
            defaultValue={assistance.coverageValue}
            onChange={(e) =>
              setAssistance({
                coverageCode: "42111",
                coverageValue: e.value,
                coveragePrice: "0.00",
              })
            }
          />
          <FormSelect
            placeholder="Vidros (Rede referenciada pela seguradora)"
            options={mirrorMock}
            defaultValue={mirror.coverageValue}
            onChange={(e) =>
              setMirror({
                coverageCode: "42109",
                coverageValue: e.value,
                coveragePrice: "0.00",
              })
            }
          />
          <FormSelect
            placeholder="Carro reserva ou desconto franquia - Perda parcial (PP)"
            options={ppMock}
            defaultValue={ppValue.coverageValue}
            onChange={(e) =>
              setPPValue({
                coverageCode: "42108",
                coverageValue: e.value,
                coveragePrice: "0.00",
              })
            }
          />
          <FormSelect
            placeholder="Indenização integral (II)"
            options={iiMock}
            defaultValue={iiValue.coverageValue}
            onChange={(e) =>
              setIIValue({
                coverageCode: "42106",
                coverageValue: e.value,
                coveragePrice: "0.00",
              })
            }
          />
          <FormSelect
            placeholder="Todos os eventos (PP ou II)"
            options={ppiiMock}
            defaultValue={ppiiValue.coverageValue}
            onChange={(e) =>
              setPPIIValue({
                coverageCode: "42110",
                coverageValue: e.value,
                coveragePrice: "0.00",
              })
            }
          />
          <FormSelect
            placeholder="Terceiros"
            options={thirdMock}
            defaultValue={third.coverageValue}
            onChange={(e) =>
              setThird({
                coverageCode: "42107",
                coverageValue: e.value,
                coveragePrice: "0.00",
              })
            }
          />
        </Box>
        <Button styling="primary" onClick={_handleVerify} width="296px" mt={25}>
          Próximo
        </Button>
      </Box>
    </>
  );
}
