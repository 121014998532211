import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import * as userService from "../../state/context/user/services";
import { IconsMap } from "../../components/iconHome/iconhome";
import { Box, Button } from "../../components/ds/SubatomicParticles";
import { H1, H2, H4, P } from "../../components/ds/theme/typography";
import Link from "../../components/ds/atoms/Link";
import Icon from "../../components/ds/atoms/Icon";
import BgAuto from "../../assets/images/bg_auto.png";
import { ReactComponent as Circle } from "../../assets/svg/circle.svg";
import { ReactComponent as DotsRight } from "../../assets/svg/dots_left.svg";
import { ReactComponent as Dotsleft } from "../../assets/svg/dots_right.svg";
import { ReactComponent as PesegoCare } from "../../assets/svg/pessego-care.svg";
import InjectImages from "../Images";
import PreImg from "../../components/PreImg";

function Automovel({ history, showModal, user, images }) {
  return (
    <>
      <Box
        gridTemplateColumns="repeat(12,1fr)"
        gridRowGap="1.5rem"
        paddingY={{ mobile: "px", tablet: "0px", desktop: "3rem" }}
        position="relative"
        styling="grid"
      >
        <Box
          alignItems="flex-start"
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "2/span 4",
          }}
          styling="column"
        >
          <H1 styling="base">Tranquilidade para você e proteção para seu veículo.</H1>
          <Box
            styling="base"
            height="2px"
            width="4vw"
            mt="2rem"
            backgroundColor="primary.default"
          ></Box>
        </Box>

        <Box
          alignItems="flex-start"
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "2/span 4",
          }}
          gridRow="2"
          styling="column"
        >
          <P lineHeight="1.4" textAlign="justify" styling="base">
            Você dirige com mais confiança sabendo que seu automóvel está
            protegido em caso de colisão, roubo, furto, incêndio, danos
            elétricos e muito mais. Melhor ainda é saber que a pessego.com vai
            te guiar e sanar todas as suas dúvidas, além de acompanhar sua
            apólice da contratação ao sinistro e até a renovação!
          </P>
        </Box>

        <Box
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "2/span 4",
          }}
          gridRow="3"
          my="2rem"
          styling="column"
        >
          <Button
            onClick={() => {
              history.push({
                pathname: "/iframe-auto",
                state: { type: "automovel" },
              })
            }}
            styling="primary"
            width="350px"
            height="50px"
          >
            <div style={{ fontSize: 18 }}>Cotar agora seu seguro automóvel</div>
          </Button>
          <Button
            onClick={() => {
              history.push({
                pathname: "/iframe-auto",
                state: { type: "motocicleta" },
              })
            }}
            styling="primary"
            width="350px"
            height="50px"
            mt="10px"
          >
            <div style={{ fontSize: 18 }}>Cotar agora seu seguro motocicleta</div>
          </Button>
          {user && (user.telefone || user.telefoneCelular) && (
            <Button
              my="10px"
              onClick={() => showModal({ visible: true, type: "contact" })}
              styling="primary"
              width="295px"
            >
              Quero falar com alguém, me ligue!
            </Button>
          )}
        </Box>

        <Box
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          gridColumn="5/span 8"
          gridRow="2/span 4"
          height="210px"
          mt="150px"
          styling="column"
        >
          <Icon styling="baseNone" height="100%" width="100%">
            <DotsRight width="100%" height="100%" />
          </Icon>
        </Box>

        <Box
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          gridColumn="8/span 4"
          gridRow="1/span 4"
          ml="30px"
          styling="column"
        >
          <img alt="" height="100%" src={BgAuto} width="100%" />
        </Box>

        <Box
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          gridColumn="7/span 8"
          gridRow="1/span 4"
          position="absolute"
          styling="column"
          top="50px"
        >
          <Circle />
        </Box>

        <Box
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          gridColumn="10/span 2"
          gridRow="2/span 4"
          position="absolute"
          styling="column"
          top="145px"
          zIndex="-1"
        >
          <Circle />
        </Box>

        <Box
          alignItems="stretch"
          flexWrap="wrap"
          gridColumn="2/span 10"
          gridRow="4"
          justifyContent="space-between"
          margin={{ mobile: "2rem 0", tablet: "2rem 0", desktop: "0 0 2rem" }}
          position="relative"
          styling="row"
        >
          <Box
            backgroundColor="background.default"
            borderRadius="12px"
            boxShadow="10px 10px 28px 1px rgba(0,0,0,.1)"
            justifyContent="flex-start"
            mb={{ mobile: "25px", tablet: "25px", desktop: "0" }}
            padding="20px"
            styling="column"
            width={{ mobile: "100%", tablet: "100%", desktop: "22%" }}
          >
            <H4 mb="24px" styling="base">Cobertura contra Roubo e Furto</H4>
            <P lineHeight="1.4" styling="base">
              Caso seu automóvel seja roubado e não seja encontrado, você é
              indenizado de acordo com a porcentagem da Tabela Fipe que você
              PODE ESCOLHER no momento da contratação do seguro. Isso
              influenciará no valor que pagará por ele. Veja tabela Fipe.
            </P>
          </Box>

          <Box
            backgroundColor="background.default"
            borderRadius="12px"
            boxShadow="10px 10px 28px 1px rgba(0,0,0,.1)"
            justifyContent="flex-start"
            mb={{ mobile: "25px", tablet: "25px", desktop: "0" }}
            padding="20px"
            styling="column"
            width={{ mobile: "100%", tablet: "100%", desktop: "22%" }}
          >
            <H4 mb="24px" styling="base">Serviço de Guincho para várias distâncias</H4>
            <P lineHeight="1.4" styling="base">
              As seguradoras parceiras da pessego.com oferecem serviço de
              guincho. Você personaliza a distância que devem percorrer ou
              escolhe a opção de quilometragem ilimitada. Assim é possível que
              seu veículo seja sempre cuidado pela sua oficina de confiança.
            </P>
          </Box>

          <Box
            backgroundColor="background.default"
            borderRadius="12px"
            boxShadow="10px 10px 28px 1px rgba(0,0,0,.1)"
            justifyContent="flex-start"
            mb={{ mobile: "25px", tablet: "25px", desktop: "0" }}
            padding="20px"
            styling="column"
            width={{ mobile: "100%", tablet: "100%", desktop: "22%" }}
          >
            <H4 mb="24px" styling="base">Opções de planos com carro reserva</H4>
            <P lineHeight="1.4" styling="base">
              A pessego.com oferece serviço de carro reserva com opções de
              modelos populares, luxo, com ar ou não, etc. Tem prazos de locação
              que variam de 7 a 30 dias, ideal para quem utiliza o automóvel
              diariamente com a família ou o trabalho.
            </P>
          </Box>

          <Box
            backgroundColor="background.default"
            borderRadius="12px"
            boxShadow="10px 10px 28px 1px rgba(0,0,0,.1)"
            justifyContent="flex-start"
            mb={{ mobile: "25px", tablet: "25px", desktop: "0" }}
            padding="20px"
            styling="column"
            width={{ mobile: "100%", tablet: "100%", desktop: "22%" }}
          >
            <H4 mb="24px" styling="base">Conte com outras Coberturas Adicionais</H4>
            <P lineHeight="1.4" styling="base">
              Seu seguro pode fornecer também cobertura para blindagem,
              acessórios em geral, kit-gás, vidros com retrovisores, faróis,
              lanternas e muito mais. Converse com o consultor pessego.com,
              ressalte suas necessidades e tenha um seguro que é a sua cara!
            </P>
          </Box>
        </Box>

        <Box
          alignItems="flex-start"
          gridColumn="2/span 10"
          gridRow="5"
          styling="column"
        >
          <H2 styling="base">A pessego.com tranquiliza você e cuida de seu veículo!</H2>
        </Box>

        <Box
          alignItems="flex-start"
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "2/span 6",
          }}
          gridRow="6"
          styling="column"
        >
          <P my="2rem" styling="regular">
            Não sabe exatamente o que procura?
          </P>
          <P styling="base">
            Não tem problema! Nossos consultores são especialistas em seguro e
            vão ajudar você a encontrar exatamente o que você precisa e do jeito
            que você preferir: por telefone, e-mail ou chat.
          </P>
          <P my="2rem" styling="regular">
            Preparamos uma lista de dúvidas mais frequentes pra você.
          </P>
          <P display="block" styling="base">
            Aqui está tudo bem explicadinho, mas se preferir conversar com um de
            nossos promotores é só clicar no botão “
            <Link color="primary.default" to="/contato">
              fale conosco
            </Link>
            ”, ok?
          </P>
        </Box>

        <Box
          backgroundColor="background.default"
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          borderRadius="50%"
          gridColumn="10/span 2"
          gridRow="6"
          height="256px"
          ml="16px"
          styling="column"
          width="256px"
        >
          {images?.generic1?.url ? (
            <PreImg
              src={images.generic1.url}
              style={{
                width: "120px",
                height: "114px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                styling="row"
                width="80%"
                height="80%"
                borderRadius="4px"
                backgroundColor="#ededed"
              />
            </PreImg>
          ) : (
            <PesegoCare height="120px" width="114px" />
          )}
        </Box>

        <Box
          alignItems="flex-start"
          gridColumn="1/span 8"
          gridRow="7"
          height="210px"
          my="30px"
          styling="column"
        >
          <Icon styling="baseNone" height="100%" width="100%">
            <Dotsleft width="100%" height="100%" />
          </Icon>
        </Box>

        <Box gridColumn="2/span 10" gridRow="8" styling="column">
          <H2 mb="2rem" styling="base">Outros seguros</H2>
          <Box
            gridGap="1rem"
            gridTemplateColumns={{
              mobile: "repeat(2,1fr)",
              tablet: "repeat(3,1fr)",
              desktop: "repeat(7,1fr)",
            }}
            styling="grid"
            width="100%"
          >
            <IconsMap history={history} />
          </Box>
        </Box>
      </Box>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = {
  showModal: userService.showModal,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(InjectImages(Automovel));
