import React, { useState, useCallback } from "react";
import classnames from "classnames";
import { css } from "@styled-system/css";
import { useLocation, useHistory } from "react-router-dom";

import * as userService from "../../state/context/user/services";
import { connect } from "react-redux";
import { Props } from "./types";
import { Box } from '../../components/ds/SubatomicParticles';
import { P } from '../../components/ds/theme/typography';
import Sidebar from '../../components/Sidebar';
import Link from '../../components/Link';
import Icon from '../../components/ds/atoms/Icon';
import { ReactComponent as IconClients } from '../../assets/svg/icon_clients.svg';
import { ReactComponent as IconLocale } from '../../assets/svg/icon_locale.svg';
import { ReactComponent as IconTheme } from '../../assets/svg/icon_theme.svg';
import { ReactComponent as IconUser } from '../../assets/svg/icon_user.svg';
import { ReactComponent as IconImagesTwo } from "../../assets/svg/icon_image-2.svg";
import { ReactComponent as IconExit } from "../../assets/svg/icon_exit.svg";
import { ReactComponent as IconInfo } from "../../assets/svg/icon_info.svg";

const Admin: React.FC<Props> = ({ children, logoutUser }) => {
  const location = useLocation();
  const { push } = useHistory();
  const [openMenu, toggleMenu] = useState<boolean>(false);
  const handleMenu = useCallback(
    () => toggleMenu((prevState) => !prevState),
    [],
  );

  const isActive = (path: string) => {
    return location.pathname.indexOf(path) !== -1;
  };

  const handleLogout = async () => {
    await logoutUser()
    sessionStorage.clear()
    localStorage.clear()
    push("/")
  }

  return (
    <Box
      styling="row"
      width="100%"
      height="100%"
      position="fixed"
      top="0"
      backgroundColor="#f0f1f2"
      zIndex={9999999}
    >
      <Sidebar handleMenu={handleMenu} open={openMenu}>
        <Box
          styling="row"
          justifyContent="flex-start"
          width="100%"
          px="15px"
          py="xxs"
          css={css({
            p: {
              color: '#1B2B37',
            },
            '&:hover': {
              bg: '#f0f1f2',
              svg: {
                stroke: '#3f51b5',
                fill: '#3f51b5',
              },
              'a p': {
                color: '#3f51b5',
              },
            },
          })}
        >
          <Link
            id="id_menu_empresa"
            styling="base"
            width="100%"
            className={classnames({
              active2: isActive('/admin/clients'),
            })}
            to="/admin/clients"
          >
            <Icon styling="admin" title="Clientes" width="40px" height="100%">
              <IconClients height={25} width={25} />
            </Icon>
            <P
              styling="bold"
              ml="xm"
              letterSpacing="1px"
              fontSize="11px"
            >
              CLIENTES
            </P>
          </Link>
        </Box>
        <Box
          styling="row"
          justifyContent="flex-start"
          width="100%"
          px="15px"
          py="xxs"
          css={css({
            p: {
              color: '#1B2B37',
            },
            '&:hover': {
              bg: '#f0f1f2',
              svg: {
                stroke: '#3f51b5',
                fill: '#3f51b5',
              },
              'a p': {
                color: '#3f51b5',
              },
            },
          })}
        >
          <Link
            id="id_menu_empresa"
            styling="base"
            width="100%"
            className={classnames({
              active2: isActive("/admin/images"),
            })}
            to="/admin/images"
          >
            <Icon styling="admin" title="Imagens" width="40px" height="100%">
              <IconImagesTwo height={25} width={25} />
            </Icon>
            <P
              styling="bold"
              ml="xm"
              letterSpacing="1px"
              fontSize="11px"
            >
              IMAGENS
            </P>
          </Link>
        </Box>
        <Box
          styling="row"
          justifyContent="flex-start"
          width="100%"
          px="15px"
          py="xxs"
          css={css({
            p: {
              color: '#1B2B37',
            },
            '&:hover': {
              bg: '#f0f1f2',
              svg: {
                stroke: '#3f51b5',
                fill: '#3f51b5',
              },
              'a p': {
                color: '#3f51b5',
              },
            },
          })}
        >
          <Link
            id="id_menu_empresa"
            styling="base"
            width="100%"
            className={classnames({
              active2: isActive("/admin/locales"),
            })}
            to="/admin/locales"
          >
            <Icon styling="admin" title="Locale" width="40px" height="100%">
              <IconLocale height={25} width={25} />
            </Icon>
            <P
              styling="bold"
              ml="xm"
              letterSpacing="1px"
              fontSize="11px"
            >
              LOCALE
            </P>
          </Link>
        </Box>
        <Box
          styling="row"
          justifyContent="flex-start"
          width="100%"
          px="15px"
          py="xxs"
          css={css({
            p: {
              color: '#1B2B37',
            },
            '&:hover': {
              bg: '#f0f1f2',
              svg: {
                stroke: '#3f51b5',
                fill: '#3f51b5',
              },
              'a p': {
                color: '#3f51b5',
              },
            },
          })}
        >
          <Link
            id="id_menu_empresa"
            styling="base"
            width="100%"
            className={classnames({
              active2: isActive('/admin/themses'),
            })}
            to="/admin/themes"
          >
            <Icon styling="admin" title="Tema" width="40px" height="100%">
              <IconTheme height={25} width={25} />
            </Icon>
            <P
              styling="bold"
              ml="xm"
              letterSpacing="1px"
              fontSize="11px"
            >
              TEMA
            </P>
          </Link>
        </Box>
        <Box
          styling="row"
          justifyContent="flex-start"
          width="100%"
          px="15px"
          py="xxs"
          css={css({
            p: {
              color: '#1B2B37',
            },
            '&:hover': {
              bg: '#f0f1f2',
              svg: {
                stroke: '#3f51b5',
                fill: '#3f51b5',
              },
              'a p': {
                color: '#3f51b5',
              },
            },
          })}
        >
          <Link
            id="id_menu_empresa"
            styling="base"
            width="100%"
            className={classnames({
              active2: isActive('/admin/users'),
            })}
            to="/admin/users"
          >
            <Icon
              styling="admin"
              title="Tema"
              width="40px"
              height="100%"
            >
              <IconUser height={25} width={25} />
            </Icon>
            <P
              styling="bold"
              ml="xm"
              letterSpacing="1px"
              fontSize="11px"
            >
              USUÁRIOS
            </P>
          </Link>
        </Box>
        <Box
          mt="auto"
          styling="row"
          justifyContent="flex-start"
          width="100%"
          px="15px"
          py="xxs"
          css={css({
            a: {
              display: 'flex',
              height: '28px',
              textDecoration: 'none',
            },
            p: {
              color: '#1B2B37',
            },
            '&:hover': {
              bg: '#f0f1f2',
              svg: {
                stroke: '#3f51b5',
                fill: '#3f51b5',
              },
              'a p': {
                color: '#3f51b5',
              },
            },
          })}
        >
          <a
            id="id_menu_empresa"
            href="https://git.rarolabs.com.br/bonsucesso/pessego-web/-/wikis/M%C3%B3dulo-Administrador"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Icon styling="admin" title="Info" width="40px" height="100%">
              <IconInfo height={25} width={25} />
            </Icon>
            <P
              styling="bold"
              ml="xm"
              letterSpacing="1px"
              fontSize="11px"
            >
              INFO
          </P>
          </a>
        </Box>
        <Box
          onClick={handleLogout}
          styling="row"
          justifyContent="flex-start"
          width="100%"
          px="15px"
          py="xxs"
          css={css({
            p: {
              color: '#1B2B37',
            },
            '&:hover': {
              bg: '#f0f1f2',
              svg: {
                stroke: '#3f51b5',
                fill: '#3f51b5',
              },
              'a p': {
                color: '#3f51b5',
              },
            },
          })}
        >
          <Link
            id="id_menu_empresa"
            styling="base"
            width="100%"
            to="/"
          >
            <Icon styling="admin" title="Sair" width="40px" height="100%">
              <IconExit height={25} width={25} />
            </Icon>
            <P
              styling="bold"
              ml="xm"
              letterSpacing="1px"
              fontSize="11px"
            >
              SAIR
          </P>
          </Link>
        </Box>
      </Sidebar>
      <Box
        styling="column"
        height="100%"
        width="100%"
        justifyContent="flex-start"
        pl="58px"
      >
        {children}
      </Box>
    </Box>
  );
};

const mapState = (state: any) => ({
  user: state.user.user
});

const mapDispatchToProps = {
  showModal: userService.showModal,
  logoutUser: userService.logoutUser
};

export default connect(mapState, mapDispatchToProps)(Admin);
