import React from "react";
import cpfValidate from "cpf";
import moment from "moment";
import { Box, Button } from "../../../components/ds/SubatomicParticles";
import { H3 } from "../../../components/ds/theme/typography";
import FormSelect from "../../../components/FormSelect";
import FormInput from "../../../components/FormInput";
import {
  validateVerify,
  autoCorrectedDatePipe,
  checkLengthOfDate,
} from "../../../utils/validations";
import { fieldNotFilledAlert, dateMask } from "../../../utils";
import { currencyMask } from "../../../utils/masks";
import TextMaskFormInput from "../../../components/TextMaskFormInput";

function Step1({
  onNext,
  valorAssegurado,
  setValorAssegurado,
  otherUser,
  setOtherUser,
  dataNascimentoPortable,
  setDataNascimentoPortable,
  cpf,
  setCpf,
}) {
  const _handleVerify = () => {
    var verifyStatus = true;
    if (otherUser === "" || valorAssegurado === "") {
      if (!valorAssegurado) fieldNotFilledAlert("Valor que deseja assegurar");
      if (!otherUser) fieldNotFilledAlert("Quem será o principal usuário?");
      verifyStatus = false;
    }

    if (otherUser === "outros" && verifyStatus) {
      if (
        cpf === "" ||
        dataNascimentoPortable === "" ||
        !dataNascimentoPortable
      ) {
        if (!cpf) fieldNotFilledAlert("CPF");
        if (
          !dataNascimentoPortable ||
          checkLengthOfDate(dataNascimentoPortable) !== 8
        )
          fieldNotFilledAlert("Data de nascimento");
        verifyStatus = false;
      }

      if (cpf !== "" && verifyStatus && !cpfValidate.isValid(cpf)) {
        fieldNotFilledAlert("CPF");
        verifyStatus = false;
      }
    }

    if (verifyStatus) {
      onNext({
        valorAssegurado: valorAssegurado,
        outroUsuario: otherUser === "outros" ? "outros" : "proprio",
        outroUsuarioDados: {
          dataNascimento: dataNascimentoPortable
            ? moment(dataNascimentoPortable, "DD/MM/YYYY").format("DD/MM/YYYY")
            : "",
          cpf: cpf ? cpf.replace(/\.|-/g, "") : "",
        },
      });
    }
  };

  const changeSelect = (value) => {
    setOtherUser(value);
    if (value !== "outros") {
      setCpf("");
      setDataNascimentoPortable("");
    }
  };

  return (
    <>
      <Box
        gridColumn={{
          mobile: "2/span 10",
          tablet: "2/span 10",
          desktop: "4/span 6",
        }}
        styling="column"
        pb={"30rem"}
      >
        <H3 mb="30px" styling="light">
          Sobre o usuário do equipamento
        </H3>
        <TextMaskFormInput
          placeholder="Valor que deseja assegurar"
          value={valorAssegurado}
          mask={currencyMask}
          onChange={(e) => setValorAssegurado(e.target.value)}
          onBlur={(e) => validateVerify(e)}
          styling="base"
        />
        <FormSelect
          data-testid="otherUser"
          id="otherUser"
          name="otherUser"
          options={[
            { value: "proprio", label: "Próprio" },
            { value: "outros", label: "Outros" },
          ]}
          defaultValue={otherUser}
          onChange={(e) => changeSelect(e.value)}
          onBlur={(e) => validateVerify(e)}
          placeholder="Quem será o principal usuário?"
          styling="base"
        />
        {otherUser === "outros" && (
          <>
            <TextMaskFormInput
              value={dataNascimentoPortable}
              onChange={(e) => setDataNascimentoPortable(e.target.value)}
              mask={dateMask}
              pipe={(date) => autoCorrectedDatePipe(date, false)}
              placeholder="Data de nascimento"
              onBlur={(e) => validateVerify(e)}
            />
            <FormInput
              placeholder="CPF"
              mask="999.999.999-99"
              maskChar={null}
              value={cpf}
              onChange={(e) => setCpf(e.target.value.replace(/\.|-/g, ""))}
              onBlur={(e) => validateVerify(e)}
            />
          </>
        )}

        <Button styling="primary" onClick={_handleVerify} width="296px" mt={25}>
          Próximo
        </Button>
      </Box>
    </>
  );
}

export default Step1;
