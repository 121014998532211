import React from "react";
import Slider from "react-slick";
import css from "@styled-system/css";
import { Box } from "../ds/SubatomicParticles";
import { H1, P, Span } from "../../components/ds/theme/typography";
import Link from "../../components/ds/atoms/Link";
import f1Mobile from "../../assets/images/feature1.png";
import { ReactComponent as ArrowLeft } from "../../assets/svg/arrow-left-solid.svg";
import { ReactComponent as ArrowRight } from "../../assets/svg/arrow-right-solid.svg";
import injectLocale from "../../containers/Locale";
import Icon from "../../components/ds/atoms/Icon";
import injectImages from "../../containers/Images/injectImages";
import PreImg from "../PreImg";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <Box className={className} ml="10px" styling="row" onClick={onClick}>
      <Icon styling="base" height="22px" width="22px">
        <ArrowRight height="22px" width="22px" />
      </Icon>
    </Box>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <Box className={className} mr="10px" styling="row" onClick={onClick}>
      <Icon styling="base" height="22px" width="22px">
        <ArrowLeft height="22px" width="22px" />
      </Icon>
    </Box>
  );
}

export class FeaturesCarousel extends React.Component {
  // constructor(props) {
  // super(props);
  // this.next = this.next.bind(this);
  // this.previous = this.previous.bind(this);
  // }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      appendDots: (dots) => (
        <ul>
          {dots.map((dot, i) => (
            <Span
              key={`span-${i}`}
              css={css({
                "li button:before": {
                  color: "#e1e1e1",
                },
                "li.slick-active button:before": {
                  color: "secondary.default",
                },
              })}
            >
              {dot}
            </Span>
          ))}
        </ul>
      ),
      nextArrow: <SampleNextArrow className="headten" />,
      prevArrow: <SamplePrevArrow />,
    };
    // FOI DESABILITADO POR QUE O CÓDIGO EWTÁ DANDO ERRO NA LINHA 70, dizendo que as urls nao podem ter scripts.
    const { locale } = this.props;
    /*eslint-disable*/
    return (
      <Box
        styling="row"
        gridColumn={{
          mobile: "2/span 10",
          tablet: "5/span 7",
          desktop: "5/span 7",
        }}
      >
        <Slider ref={(c) => (this.slider = c)} {...settings}>
          <Box display="inline-block" key={1} styling="column" width="100%">
            <Box
              display={{ mobile: "flex", tablet: "none", desktop: "none" }}
              styling="row"
              width="100%"
            >
              <PreImg
                style={{
                  height: "250px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                src={this.props?.images?.main1?.url || f1Mobile}
              >
                <Box
                  styling="row"
                  width="50%"
                  height="50%"
                  borderRadius="4px"
                  backgroundColor="#ededed"
                />
              </PreImg>
            </Box>
            <Box
              alignItems="flex-start"
              styling="column"
              flex="1 1"
              justifyContent="flex-end"
              py="4rem"
            >
              <H1 styling="light" my="1.5rem">
                {locale.slider_home_1_title}
              </H1>
              <P display="block" fontSize="19px" mt="2.5rem" styling="base">
                {locale.slider_home_1_description_1}
                <Span styling="base" color="primary.default">
                  {locale.slider_home_1_description_2}
                </Span>
                {locale.slider_home_1_description_3}
              </P>
              <P fontSize="19px" mt="2.5rem" styling="base">
                {locale.slider_home_1_description_4}
              </P>
            </Box>
          </Box>
          <Box display="inline-block" key={2} styling="column" width="100%">
            <Box
              display={{ mobile: "flex", tablet: "none", desktop: "none" }}
              styling="row"
              width="100%"
            >
              <PreImg
                style={{
                  height: "250px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                src={this.props?.images?.main1?.url || f1Mobile}
              >
                <Box
                  styling="row"
                  width="50%"
                  height="50%"
                  borderRadius="4px"
                  backgroundColor="#ededed"
                />
              </PreImg>
            </Box>
            <Box
              alignItems="flex-start"
              styling="column"
              flex="1 1"
              justifyContent="flex-end"
              py="4rem"
            >
              <H1 styling="light" my="1.5rem">
                {locale.slider_home_2_title}
              </H1>
              <P display="block" fontSize="19px" mt="2.5rem" styling="base">
                {locale.slider_home_2_description_1}
                <Span styling="base" color="primary.default">
                  {locale.slider_home_2_description_2}
                </Span>
                {locale.slider_home_2_description_3}
              </P>
            </Box>
          </Box>
          <Box display="inline-block" key={3} styling="column" width="100%">
            <Box
              display={{ mobile: "flex", tablet: "none", desktop: "none" }}
              styling="row"
              width="100%"
            >
              <PreImg
                style={{
                  height: "250px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                src={this.props?.images?.main1?.url || f1Mobile}
              >
                <Box
                  styling="row"
                  width="50%"
                  height="50%"
                  borderRadius="4px"
                  backgroundColor="#ededed"
                />
              </PreImg>
            </Box>
            <Box
              alignItems="flex-start"
              styling="column"
              flex="1 1"
              justifyContent="flex-end"
              py="4rem"
            >
              <H1 styling="light" my="1.5rem">
                {locale.slider_home_3_title}
              </H1>
              <P fontSize="19px" mt="2.5rem" styling="base">
                {locale.slider_home_3_description_1}
              </P>
              <P fontSize="19px" mt="2.5rem" styling="base">
                {locale.slider_home_3_description_2}
              </P>
            </Box>
          </Box>
          <Box display="inline-block" key={4} styling="column" width="100%">
            <Box
              display={{ mobile: "flex", tablet: "none", desktop: "none" }}
              styling="row"
              width="100%"
            >
              <PreImg
                style={{
                  height: "250px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                src={this.props?.images?.main1?.url || f1Mobile}
              >
                <Box
                  styling="row"
                  width="50%"
                  height="50%"
                  borderRadius="4px"
                  backgroundColor="#ededed"
                />
              </PreImg>
            </Box>
            <Box
              alignItems="flex-start"
              styling="column"
              flex="1 1"
              justifyContent="flex-end"
              py="4rem"
            >
              <H1 styling="light" my="1.5rem">
                {locale.slider_home_4_title}
              </H1>
              <P fontSize="19px" mt="2.5rem" styling="base">
                {locale.slider_home_4_description_1}
              </P>
            </Box>
          </Box>
          <Box display="inline-block" key={5} styling="column" width="100%">
            <Box
              display={{ mobile: "flex", tablet: "none", desktop: "none" }}
              styling="row"
              width="100%"
            >
              <PreImg
                style={{
                  height: "250px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                src={this.props?.images?.main1?.url || f1Mobile}
              >
                <Box
                  styling="row"
                  width="50%"
                  height="50%"
                  borderRadius="4px"
                  backgroundColor="#ededed"
                />
              </PreImg>
            </Box>
            <Box
              alignItems="flex-start"
              styling="column"
              flex="1 1"
              justifyContent="flex-end"
              py="4rem"
            >
              <H1 styling="light" my="1.5rem">
                {locale.slider_home_5_title}
              </H1>
              <P display="block" fontSize="19px" mt="2.5rem" styling="base">
                {locale.slider_home_5_description_1}
                <Link to="/contato" color="primary.default">
                  {locale.slider_home_5_description_2}
                </Link>
                {locale.slider_home_5_description_3}
              </P>
            </Box>
          </Box>
          <Box display="inline-block" key={6} styling="column" width="100%">
            <Box
              display={{ mobile: "flex", tablet: "none", desktop: "none" }}
              styling="row"
              width="100%"
            >
              <PreImg
                style={{
                  height: "250px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                src={this.props?.images?.main1?.url || f1Mobile}
              >
                <Box
                  styling="row"
                  width="50%"
                  height="50%"
                  borderRadius="4px"
                  backgroundColor="#ededed"
                />
              </PreImg>
            </Box>
            <Box
              alignItems="flex-start"
              styling="column"
              flex="1 1"
              justifyContent="flex-end"
              py="4rem"
            >
              <H1 styling="light" my="1.5rem">
                {locale.slider_home_6_title}
              </H1>
              <P fontSize="19px" mt="2.5rem" styling="base">
                {locale.slider_home_6_description_1}
              </P>
            </Box>
          </Box>
        </Slider>
      </Box>
    );
  }
}

export default injectImages(injectLocale(FeaturesCarousel));
