import React from "react";
import { Props as ReactSelectProps } from "react-select";
import Select from "../ds/atoms/Select";
import { Box } from "../ds/SubatomicParticles";
import { P, Label } from "../ds/theme/typography";
import { css } from "styled-components";

interface DispatchProps extends ReactSelectProps {
  name: string;
  styling: "base";
  label?: string;
  mask?: string;
  error?: string;
  style?: any;
  defaultValue?: any;
  onChange?(e: any): void;
  placeholder: string;
  colors?: any;
}

interface getLabelProps {
  label?: string | boolean;
  placeholder: string;
  textDark?: DispatchProps["colors"]["textDark"];
}

const getLabel = ({ label, textDark, placeholder }: getLabelProps) => {
  if (label === false) {
    return null;
  } else if (typeof label === "string") {
    return <Label styling="base" color={textDark}>{label}</Label>;
  } else if (typeof label === "undefined") {
    return <Label styling="base" color={textDark}>{placeholder}</Label>;
  }
};

type Props = DispatchProps;

const FormSelect = ({
  name,
  label,
  error,
  appendClass,
  style,
  options,
  defaultValue,
  placeholder,
  value,
  colors,
  ...rest
}: Props) => {

  const valueSelected = defaultValue || value;
  //@ts-ignore
  const _defaultValue = options && options.find((item: any) => item.value === valueSelected);

  const backgroundDefault = colors && colors.backgroundDefault ? colors.backgroundDefault : 'background.default';
  const disabled = colors && colors.disabled ? colors.disabled : 'disabled';
  const primaryDark = colors && colors.primaryDark ? colors.primaryDark : 'primary.dark';
  const primaryDefault = colors && colors.primaryDefault ? colors.primaryDefault : 'primary.default';
  const primaryLight = colors && colors.primaryLight ? colors.primaryLight : 'primary.light';
  const textDark = colors && colors.textDark ? colors.textDark : 'text.dark';
  const textLight = colors && colors.textLight ? colors.textLight : 'text.light';
  const graysDefault = colors && colors.graysDefault ? colors.graysDefault : 'grays.default';

  return (
    <Box styling="column" width="100%" mb="15px" alignItems="flex-start">
      {getLabel({ label, textDark, placeholder })}
      <Box styling="column" width="100%" position="relative">
        <Select
          {...style}
          {...rest}
          data-testid={name}
          id={name}
          name={name}
          noOptionsMessage={() => "Sem sugestão"}
          options={options}
          width="100%"
          value={_defaultValue}
          defaultValue={_defaultValue}
          placeholder={label === undefined ? 'Selecione' : placeholder}
          css={css({
            '.select__input': {
              color: textLight,
            },
            '.select__control': {
              borderColor: textDark,
              color: textDark,
              '&:hover': {
                borderColor: primaryDefault,
              }
            },
            '.select__menu': {
              borderColor: graysDefault,
              backgroundColor: backgroundDefault,
            },
            '.select__menu-list': {
              color: textDark,
            },
            '.select__indicator': {
              color: textDark,
            },
            '.select__indicator-separator': {
              backgroundColor: textDark,
            },
            '.select__single-value': {
              color: textDark,
            },
            '.select__single-value--is-disabled': {
              color: disabled,
            },
            '.select__multi-value': {
              backgroundColor: primaryLight,
            },
            '.select__multi-value__label': {
              color: textDark,
            },
            '.select__multi-value__remove': {
              '&:hover': {
                backgroundColor: backgroundDefault,
                color: textLight,
                svg: {
                  path: {
                    color: textLight,
                  }
                }
              }
            },
            '.select__option': {
              '&:hover': {
                backgroundColor: primaryDefault,
                color: textLight,
              },
            },
            '.select__option--is-focused': {
              backgroundColor: 'hovers.0',
              color: textDark,
            },
            '.select__option--is-selected': {
              backgroundColor: primaryDark,
              color: textLight,
            },
            '.select__placeholder': {
              color: graysDefault
            },
          })}
        />
        {error && (
          <P
            data-testid={`${name}Error`}
            fontSize="12px"
            styling="error"
            width="100%"
          >
            {error}
          </P>
        )}
      </Box>
    </Box>
  );
};

export default FormSelect;
