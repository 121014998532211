import React, { useCallback, useEffect } from 'react';
import { TableInstance } from 'react-table';
import css from '@styled-system/css';
import { Box, Button as ButtonDs } from '../../SubatomicParticles';
import Drawer from '../Drawer';
import { ReactComponent as IconFilter } from './assets/filter.svg';
import { ReactComponent as IconX } from './assets/x.svg';
import Icon from '../Icon';

type TableFilterProps<T extends object> = {
  styling?: 'base';
  instance: TableInstance<T>;
  onClose: () => void;
  open?: boolean;
  countFilter?: (count: number) => void;
  reseterFilters?: (setAllFilters: any) => void;
};

export function TableFilter<T extends object>({ instance, onClose, open, countFilter, reseterFilters }: TableFilterProps<T>): React.ReactElement {
  const { allColumns, setAllFilters, state } = instance;

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onClose();
    },
    [onClose]
  );

  const resetFilters = useCallback(() => {
    setAllFilters([]);
    onClose();
  }, [setAllFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (countFilter) {
      countFilter(state.filters.length);
    }
  }, [state.filters]); // eslint-disable-line react-hooks/exhaustive-deps

  const cssCuston = css({
    color: 'secondary.default',
    fontFamily: 'OpenSans Semibold',
    border: 'none',
    fontSize: 2,
    display: 'flex',
    m: 0,
    p: 0,
    '&:hover': {
      color: '#3f51b5',
      svg: {
        path: {
          fill: '#3f51b5',
          stroke: '#3f51b5',
        },
      },
    },
    '& svg': {
      path: {
        stroke: 'secondary.default',
      },
    },
  })

  return (
    <Drawer zIndex={99999} styling="right" open={open ?? false} size={450} onClose={onClose} overlay>
      <form onSubmit={onSubmit} style={{ height: "100%", margin: "24px" }}>
        <Box styling="column" height="100%" justifyContent="space-between">
          <Box styling="column" width="100%" justifyContent="space-between">
            <Box styling="row" width="100%" justifyContent="space-between" mb="20px">
              <ButtonDs
                type="button"
                styling="underlined"
                css={cssCuston}
              >
                <Icon styling="admin">
                  <IconFilter style={{ marginRight: '5px' }} fill="none" />
                </Icon>
                Filtros
              </ButtonDs>
              <ButtonDs
                type="button"
                styling="underlined"
                css={cssCuston}
                onClick={onClose}
              >
                <Icon styling="admin" title="Fechar">
                  <IconX />
                </Icon>
              </ButtonDs>
            </Box>
            {allColumns
              .filter(it => it.canFilter)
              .map(column => (
                <React.Fragment key={column.id}>
                  {column.render('Filter')}
                </React.Fragment>
              ))}
          </Box>

          <Box styling="row" width="100%" justifyContent="space-between" mt="20px" mb="5px">
            <ButtonDs type="button" styling="outlineAdmin" onClick={resetFilters} data-testid="clearFilter">
              LIMPAR FILTROS
            </ButtonDs>
            <ButtonDs type="submit" styling="outlineAdmin" m="none" data-testid="applyFilter">
              APLICAR FILTROS
            </ButtonDs>
          </Box>
        </Box>
      </form>
    </Drawer>
  )
};

