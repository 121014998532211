import React from "react";
import { validateVerify, checkLengthOfDate, autoCorrectedDatePipe } from "../../../utils/validations";
import { fieldNotFilledAlert, dateMask } from '../../../utils';
import { currencyMask } from '../../../utils/masks';
import { Box, Button } from "../../../components/ds/SubatomicParticles";
import { H3 } from "../../../components/ds/theme/typography";
import FormInput from "../../../components/FormInput";
import FormSelect from "../../../components/FormSelect";
import TextMaskFormInput from "../../../components/TextMaskFormInput";

function Step2({
  onComplete,
  dataNascimentoDestino,
  setDataNascimentoDestino,
  sexoDestino,
  setSexoDestino,
  cpfCnpjDestino,
  setCpfCnpjDestino,
  rendaMensal,
  setRendaMensal,
  type,
  setType,
  telefone,
  setTelefone,
  quantidadeDependentes,
  setQuantidadeDependentes,
  nomeDestino,
  setNomeDestino,
  sexoConjuge,
  setSexoConjuge,
  fumante,
  setFumante,
  estadoCivil,
  setEstadoCivil,
  historicoDoencasGraves,
  setHistoricoDoencasGraves
}) {

  if (type !== 1) setType(1);

  const _handleIncomplete = () => {
    if (!dataNascimentoDestino || checkLengthOfDate(dataNascimentoDestino) !== 8) fieldNotFilledAlert('Data de nascimento');
    if (sexoDestino === '') fieldNotFilledAlert('Sexo');
    if (rendaMensal === '') fieldNotFilledAlert('Renda mensal');
    if (!telefone || (telefone && (telefone.length !== 15 && telefone.length !== 14))) fieldNotFilledAlert('Telefone');
    if (quantidadeDependentes === '') fieldNotFilledAlert('Quantidade de dependentes');
    if (nomeDestino === '') fieldNotFilledAlert('Nome');
    if (estadoCivil === '') fieldNotFilledAlert('Estado civil');
    if (fumante === '') fieldNotFilledAlert('Fumante?');
    if (historicoDoencasGraves === '') fieldNotFilledAlert('Histórico de doenças graves?');
    if (estadoCivil === "casado" && sexoConjuge === "") fieldNotFilledAlert('Sexo do cônjuge');
    if (!cpfCnpjDestino || (cpfCnpjDestino && cpfCnpjDestino.length !== 11)) fieldNotFilledAlert('CPF');
  }

  const _handleVerify = () => {
    if (
      !dataNascimentoDestino || checkLengthOfDate(dataNascimentoDestino) !== 8 ||
      sexoDestino === "" ||
      rendaMensal === "" ||
      quantidadeDependentes === "" ||
      nomeDestino === "" ||
      estadoCivil === "" ||
      fumante === "" ||
      historicoDoencasGraves === "" ||
      (estadoCivil === "casado" && sexoConjuge === "") ||
      (!telefone || (telefone && (telefone.length !== 15 && telefone.length !== 14))) ||
      (!cpfCnpjDestino || (cpfCnpjDestino && cpfCnpjDestino.length !== 11))
    ) {
      _handleIncomplete();
    } else {
      onComplete();
    }
  };

  return (
    <>
      <Box
        gridColumn={{
          mobile: "2/span 10",
          tablet: "2/span 10",
          desktop: "4/span 6",
        }}
        styling="column"
      >
        <H3 mb="30px" styling="light">Sobre a pessoa que deseja contratar o seguro</H3>
        <FormInput
          data-testid="nome"
          id="nome"
          name="nomeDestino"
          onBlur={e => validateVerify(e)}
          onChange={(e) => setNomeDestino(e.target.value)}
          placeholder="Nome"
          type="text"
          value={nomeDestino}
        />
        <FormInput
          data-testid="cpfCnpjDestino"
          id="cpfCnpjDestino"
          mask="99999999999"
          name="cpfCnpjDestino"
          onBlur={e => validateVerify(e)}
          onChange={e => setCpfCnpjDestino(e.target.value)}
          placeholder="CPF"
          type="text"
          value={cpfCnpjDestino}
        />
        <TextMaskFormInput
          name="dataNascimentoDestino" 
          data-testid="dataNascimentoDestino"
          id="dataNascimentoDestino"
          value={dataNascimentoDestino}
          onChange={(e) => setDataNascimentoDestino(e.target.value)}
          mask={dateMask}
          pipe={date => autoCorrectedDatePipe(date, false)}
          placeholder="Data de nascimento"
          onBlur={e => validateVerify(e)}
        />
        <FormSelect
          data-testid="sexoDestino"
          id="sexoDestino"
          name="sexoDestino"
          onBlur={e => validateVerify(e)}
          onChange={(e) => setSexoDestino(e.value)}
          options={[
            { value: 'F', label: 'Feminino' },
            { value: 'M', label: 'Masculino' }
          ]}
          placeholder="Sexo"
          styling="base"
          defaultValue={sexoDestino}
        />
        <FormInput
          data-testid="telefone"
          id="telefone"
          mask="(99) 99999-9999"
          name="telefone"
          onBlur={e => validateVerify(e)}
          onChange={e => setTelefone(e.target.value)}
          placeholder="Telefone"
          type="text"
          value={telefone}
        />
        <TextMaskFormInput
          placeholder="Renda Mensal"
          value={rendaMensal}
          mask={currencyMask}
          onChange={(e) => setRendaMensal(e.target.value)}
          onBlur={(e) => validateVerify(e)}
          styling="base"
        />
        <FormInput
          data-testid="quantidadeDependentes"
          id="quantidadeDependentes"
          name="quantidadeDependentes"
          onBlur={e => validateVerify(e)}
          onChange={(e) => {
            if (Number(e.target.value) >= 0) {
              setQuantidadeDependentes(e.target.value)
            }
          }}
          placeholder="Quantidade de dependentes"
          type="number"
          value={quantidadeDependentes}
        />
        <FormSelect
          data-testid="fumante"
          id="fumante"
          name="fumante"
          onBlur={e => validateVerify(e)}
          onChange={(e) => setFumante(e.value)}
          options={[
            { value: 'nao', label: 'Não' },
            { value: 'sim', label: 'Sim' },
          ]}
          placeholder="Fumante?"
          styling="base"
          defaultValue={fumante}
        />
        <FormSelect
          data-testid="historicoDoencasGraves"
          id="historicoDoencasGraves"
          name="historicoDoencasGraves"
          onBlur={e => validateVerify(e)}
          onChange={(e) => setHistoricoDoencasGraves(e.value)}
          options={[
            { value: 'nao', label: 'Não' },
            { value: 'sim', label: 'Sim' },
          ]}
          placeholder="Histórico de doenças graves?"
          styling="base"
          defaultValue={historicoDoencasGraves}
        />
        <FormSelect
          data-testid="estadoCivil"
          id="estadoCivil"
          name="estadoCivil"
          onBlur={e => validateVerify(e)}
          onChange={(e) => setEstadoCivil(e.value)}
          options={[
            { value: 'solteiro', label: 'Solteiro' },
            { value: 'casado', label: 'Casado' },
            { value: 'divorciado', label: 'Divorciado' },
            { value: 'viuvo', label: 'Viúvo' },
            { value: 'separado', label: 'Separado' },
          ]}
          placeholder="Estado civil"
          styling="base"
          defaultValue={estadoCivil}
        />
        {estadoCivil === "casado" &&
          <FormSelect
            data-testid="sexoConjuge"
            id="sexoConjuge"
            name="sexoConjuge"
            onBlur={e => validateVerify(e)}
            onChange={(e) => setSexoConjuge(e.value)}
            options={[
              { value: 'F', label: 'Feminino' },
              { value: 'M', label: 'Masculino' }
            ]}
            placeholder="Sexo do Conjuge"
            styling="base"
            defaultValue={sexoConjuge}
          />
        }
        <Button display="block" margin="0 auto" onClick={_handleVerify} styling="primary" width="195px">Próximo</Button>
      </Box>
    </>
  );
}

export default Step2;
