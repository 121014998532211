/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Box } from "../../components/ds/SubatomicParticles";
import { H1 } from "../../components/ds/theme/typography";

import { getCreditCardPaymentScreen } from "../../state/context/cotacao/repository";

function PagamentoCartao(props) {
  useEffect(() => {
    getCreditCardPaymentScreen(props.match.params.token);
  }, []);

  return (
    <Box styling="row">
      <H1 styling="base">Aguarde só um instante...</H1>
    </Box>
  );
}

export default compose(withRouter)(PagamentoCartao);
