import React, { memo } from "react";
import styled from "styled-components";
import { variant, VariantArgs } from "styled-system";
import { SliderProps, SliderStyledProps } from "./index";
import { defaults } from "../../SubatomicParticles";
import { P } from "../../theme/typography";

const variants = variant({
  prop: "styling",
  variants: {
    base: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      position: "relative",
      color: "secondary.default",
      padding: "12px",
      alignItems: "center",
      "&:focus": {
        outline: "none",
        label: {
          "& > span": {
            backgroundColor: (props: any) => `#3f51b510`,
          },
        },
      },
      label: {
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "center",
        marginLeft: "-11px",
        verticalAlign: "middle",
        "& > span": {
          flex: "0 0 auto",
          color: "currentColor",
          overflow: "visible",
          fontSize: "1.5rem",
          textAlign: "center",
          transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          borderRadius: "50%",
          "&:hover": {
            backgroundColor: (props: any) => `#3f51b510`,
          },
          "& > span": {
            width: "100%",
            display: "flex",
            alignItems: "inherit",
            justifyContent: "inherit",
          },
        },
      },
      "input[type=range]": {
        "-webkit-appearance": "none",
        appearance: "none",
        width: "100%",
        height: "5px",
        background: (props: any) => props.colors.grays[3],
        outline: "none",
        "  -webkit-transition": ".2s",
        transition: "opacity .2s",
      },
      "input[type=range]::-webkit-slider-thumb": {
        "-webkit-appearance": "none",
        appearance: "none",
        width: "15px",
        height: "15px",
        borderRadius: "10px",
        background: "#3f51b5",
        cursor: "pointer",
      },
      p: {
        color: "contrast",
        fontSize: 2,
        marginRight: "0.3rem",
      },
    },
  },
} as VariantArgs);

const Style: React.FC<SliderStyledProps> = styled.div`
  ${variants}
  ${defaults}
`;

const Slider: React.FC<SliderProps> = (props) => {
  return (
    <Style
      styling={props.styling ?? "base"}
      style={{ ...props.style, color: props?.color }}
    >
      {props?.label && <P styling="base">{props.label}</P>}{" "}
      <span>
        <input type="range" data-testid={props?.dataTestId} {...props} />
      </span>
    </Style>
  );
};

export default memo(Slider);
