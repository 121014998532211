import { get, post } from "axios";

const { REACT_APP_FIREBASE_FUNCTIONS_URL } = process.env;

const PENTAGRAMA_LOGIN_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/pentagramaLoginPOST`;
const PENTAGRAMA_DROPDOWN_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/pentagramaQuizGET`;
const PENTAGRAMA_LEAD_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/pentagramaLeadPOST`;

export const login = body =>
  post(PENTAGRAMA_LOGIN_URL, body).then(({ data }) => data);

export const getDropdownData = token =>
  get(PENTAGRAMA_DROPDOWN_URL, {
    headers: { authorization: `Bearer ${token}` }
  }).then(({ data }) => data);

export const sendLead = (token, body) =>
  post(PENTAGRAMA_LEAD_URL, body, {
    headers: { authorization: `Bearer ${token}` }
  }).then(({ data }) => data);
