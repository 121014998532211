import React from "react";
import { Box, Button } from "../../../../components/ds/SubatomicParticles";
import { H3, P } from "../../../../components/ds/theme/typography";

const ModalMessage = ({ title, message, onClose }) => {
  return (
    <Box
      backgroundColor="background.default"
      borderRadius="12px"
      maxWidth="415px"
      padding="2rem"
      position="fixed"
      styling="cloumn"
      width="90%"
      zIndex="2"
    >
      <H3 styling="light">{title}</H3>
      <P my="30px" styling="base">{message}</P>
      <Button display="block" margin="0 auto" onClick={() => onClose()} styling="primary" width="90%">Ok, obrigado(a).</Button>
    </Box>
  );
};

export default ModalMessage;
