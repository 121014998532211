import { Dispatch } from 'redux';
import * as repository from './repository';
import { setLocales, isLoading } from './actions';
import { Locale } from './types';
import { showToast } from '../../../utils';

export const getLocales = () => async (dispatch: Dispatch) => {
  try {
    dispatch(isLoading(true));
    const response = await repository.getLocales();
    dispatch(setLocales(response));
    dispatch(isLoading(false));
  } catch (error) {
    showToast(
      "error",
      "Ops :(",
      "Algo deu errado"
    );
    dispatch(isLoading(true));
  };
};

export const getLocale = (localeId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(isLoading(true));
    const response = await repository.getLocale(localeId);
    dispatch(isLoading(false));
    return response;
  } catch (error) {
    showToast(
      "error",
      "Ops :(",
      "Algo deu errado"
    );
    dispatch(isLoading(true));
  }
};

export const editLocale = (localeId: string, locale: Locale) => async (dispatch: Dispatch) => {
  try {
    dispatch(isLoading(true));
    const response = await repository.editLocale(localeId, locale);
    showToast(
      "success",
      "Sucesso :)",
      "O registrado foi atualizado !"
    );
    dispatch(isLoading(false));
    return response;
  } catch (error) {
    showToast(
      "error",
      "Ops :(",
      "Algo deu errado"
    );
    dispatch(isLoading(true));
  }
};

export const createLocale = (clientId: string, locale: Locale) => async (dispatch: Dispatch) => {
  try {
    dispatch(isLoading(true));
    const response = await repository.createLocale(clientId, locale);
    showToast(
      "success",
      "Sucesso :)",
      "O registrado foi criado !"
    );
    dispatch(isLoading(false));
    return response;
  } catch (error) {
    showToast(
      "error",
      "Ops :(",
      "Algo deu errado"
    );
    dispatch(isLoading(true));
  }
};

export const deleteLocale = (localeId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(isLoading(true));
    const response = await repository.deleteLocale(localeId);
    showToast(
      "success",
      "Sucesso :)",
      "O registrado foi excluído !"
    );
    dispatch(isLoading(false));
    return response;
  } catch (error) {
    showToast(
      "error",
      "Ops :(",
      "Algo deu errado"
    );
    dispatch(isLoading(true));
  }
};