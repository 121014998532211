/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "recompose";
import moment from "moment";
import { Box } from "../../components/ds/SubatomicParticles";
import Icon from "../../components/ds/atoms/Icon";

import * as Residencia from "./residencia";
import * as Portateis from "./portateis";
import * as Viagem from "./viagem";
import * as Pets from "./pets";
import * as Auto from "./auto";
import * as Vida from "./vida";
import * as Bike from "./bike";

import { ReactComponent as Logo } from "../../assets/svg/logo_fruta.svg";
import { ReactComponent as Back } from "../../assets/svg/back.svg";
import { benefitsCoverageResidence } from "./auto/constants";
import * as formService from "../../state/context/form/services";
import * as userService from "../../state/context/user/services";
import Coverages from "../../components/shared/Coverages";
import { showToast, scrollToTop } from "../../utils";
import PaymentTypes from "../util/paymentTypes";
import InjectImages from "../Images";

function Form({
  history,
  showModal,
  getListRaces,
  createPets,
  location,
  getListTiposPortateis,
  getListMarcasPortateis,
  getListModelosPortateis,
  createPortateis,
  getListFormasDeViagem,
  createVida,
  createAuto,
  getListContinent,
  createViagem,
  createBike,
  user,
  authUser,
  getCEP,
  createResidence,
  userUpdate,
  listContinent,
  listTripTypes,
  portableTypes,
  userResidences,
  removeResidence,
  userResidencesLoading,
  getResidences,
  addResidence,
  getRoles,
  getRangeOfPayment,
  roles,
  rangePayments,
  getResidenceCoverage,
  residenceCoverage,
  getMaritalState,
  maritalStateList,
  maritalStateLoading,
  getGeneralRegistryState,
  generalRegistryList,
  generalRegistryLoading,
  buyInsuranceResidence,
  residenceCoverageLoading,
  getVehicles,
  removeVehicle,
  getAutoInsuranceTypes,
  myVehiclesList,
  myVehiclesLoading,
  autoInsuranceTypeList,
  autoInsuranceTypeLoading,
  registerVehicles,
  getVehiclesCoverages,
  autoCoverage,
  autoCoverageLoading,
  buyInsuranceVehicle,
  newLead,
  updateLead,
  referencia,
  vehicleBrands,
  vehicleModels,
  vehicleYearModel,
  selectedVehicle,
  getVehicleBrands,
  getVehiclesFromBrand,
  getVehicleModelYear,
  getVehicleInfo,
  images,
  verificarCpf,
  openVehicleQuotation,
  openResidenceQuotation,
  openGadgetsQuotation,
  openTravelQuotation,
  openBikeQuotation,
  openPetQuotation,
  openLifeQuotation
}) {
  let type = location.state.type;
  
  const [nomeDestino, setNomeDestino] = useState();
  const [cpfCnpjDestino, setCpfCnpjDestino] = useState();
  const [sexoDestino, setSexoDestino] = useState();
  const [dataNascimentoDestino, setDataNascimentoDestino] = useState();
  
  const [step, setStep] = useState(1);
  const [pet, setPet] = useState([]);
  const [races, setRaces] = useState([]);
  const [endereco, setEndereco] = useState({});
  const [residence, setResidence] = useState({});
  const [auto, setAuto] = useState({});
  const [paymentMethod, setPaymentMethod] = useState({});

  const [portateis, setPortateis] = useState();
  const [marcaPortateis, setMarcaPortateis] = useState();
  const [modeloPortateis, setModeloPortateis] = useState();

  const [quoteId, setQuoteId] = useState();

  // Auto 1st step fields
  const [selectedAuto, setSelectedAuto] = useState();
  const [selectedTypeInsurance, setSelectedTypeInsurance] = useState();

  const [Is0KM, setIs0KM] = useState("F");
  const [DealershipName, setDealershipName] = useState("");
  const [InvoiceNumber, setInvoiceNumber] = useState("");
  const [HasLoJack, setHasLoJack] = useState("F");
  const [LoJackCode, setLoJackCode] = useState("");
  const [CategoryCode, setCategoryCode] = useState("");
  const [StartDate, setStartDate] = useState(moment().format("DD/MM/YYYY"));
  const [EndDate, setEndDate] = useState(
    moment().add("year", 1).format("DD/MM/YYYY"),
  );
  const [UsageCode, setUsageCode] = useState("");
  const [DealerExitDateTime, setDealerExitDateTime] = useState("");
  const [ZipCode, setZipCode] = useState("");

  const [IsDriver, setIsDriver] = useState("");
  const [PoliticallyExposedPerson, setPoliticallyExposedPerson] = useState("");
  const [DaysPerWeek, setDaysPerWeek] = useState("");
  const [DriverLicenseNumber, setDriverLicenseNumber] = useState("");
  const [DriverLicenseType, setDriverLicenseType] = useState("");

  const [assistance, setAssistance] = useState({
    coverageCode: "42111",
    coverageValue: "C51",
    coveragePrice: "0.00",
  });

  const [invalidity, setInvalidity] = useState({
    coverageCode: "81001",
    coverageValue: "A10",
    coveragePrice: "5000",
  });
  const [death, setDeath] = useState({
    coverageCode: "81001",
    coverageValue: "A09",
    coveragePrice: "5000",
  });
  const [dm, setDm] = useState({});
  const [dc, setDc] = useState({
    coverageCode: "53001",
    coverageValue: "A08",
    coveragePrice: "100000.00",
  });
  const [dMaterial, setDMaterial] = useState({
    coverageCode: "53001",
    coverageValue: "A07",
    coveragePrice: "100000.00",
  });
  const [hospital, setHospital] = useState({});
  const [mirror, setMirror] = useState({
    coverageCode: "42109",
    coverageValue: "A69",
    coveragePrice: "0.00",
  });

  const [ppValue, setPPValue] = useState({});
  const [iiValue, setIIValue] = useState({});
  const [ppiiValue, setPPIIValue] = useState({
    coverageCode: "42110",
    coverageValue: "A23",
    coveragePrice: "0.00",
  });
  const [third, setThird] = useState({});

  const [dataHabCondutor, setDataHabCondutor] = useState("");

  // PaymentTypes
  const [valuesQuotationMessages, setValuesQuotationMessages] = useState({});
  const [
    statusErrorPaymentQuotation,
    setStatusErrorOnPaymentQuotation,
  ] = useState(0);
  const [valueQuotationData, setValueQuotationData] = useState({});

  //Pets fields props
  const [quantidadePets, setQuantidadePets] = useState("");

  //Viagem fields props
  const [viagem, setViagem] = useState({});

  //Life fields props
  const [typePerson, setTypePerson] = useState("");
  const [name, setName] = useState("");
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [sexo, setSexo] = useState("");
  const [telefone, setTelefone] = useState("");
  const [rendaMensal, setRendaMensal] = useState("");
  const [quantidadeDependentes, setQuantidadeDependentes] = useState("");
  const [estadoCivil, setEstadoCivil] = useState("");
  const [sexoConjuge, setSexoConjuge] = useState("");
  const [fumante, setFumante] = useState("");
  const [historicoDoencasGraves, setHistoricoDoencasGraves] = useState("");

  //Portable fields props
  const [valorAssegurado, setValorAssegurado] = useState("");
  const [otherUser, setOtherUser] = useState("");
  const [dataNascimentoPortable, setDataNascimentoPortable] = useState("");
  const [cpf, setCpf] = useState("");

  const [tipo, setTipo] = useState("");
  const [dataAquisicao, setDataAquisicao] = useState("");
  const [numeroNotaFiscal, setNumeroNotaFiscal] = useState("");
  const [valorAquisicao, setValorAquisicao] = useState("");
  const [marca, setMarca] = useState("");
  const [modelo, setModelo] = useState("");
  const [dataAquisicaoAcessorio, setDataAquisicaoAcessorio] = useState("");
  const [valorAquisicaoAcessorio, setValorAquisicaoAcessorio] = useState("");

  //Residence fields props
  const [selectedAddress, setSelectedAddress] = useState();

  const [PropertyType, setPropertyType] = useState(0);
  const [
    ResidenceUnoccupiedUnderRenovationOrConstruction,
    setResidenceUnoccupiedUnderRenovationOrConstruction,
  ] = useState("");
  const [TypeRegionLocation, setTypeRegionLocation] = useState(0);
  const [ConstructionType, setConstructionType] = useState(0);
  const [OccupanceType, setOcupationType] = useState(0);
  const [RobberyProtectiveSystem, setRobberyProtectiveSystem] = useState(0);
  const [TumbledHomeOwners, setTumbledHomeOwners] = useState("");
  const [HasAnotherInsurance, setHasAnotherInsurance] = useState("");
  const [
    ResidenceHasWoodOutsideWalls,
    setResidenceHasWoodOutsideWalls,
  ] = useState("");

  const [startDateResidence, setStartDateResidence] = useState(
    moment().format("DD/MM/YYYY"),
  );
  const [endDateResidence, setEndDateResidence] = useState(
    moment().add("year", 1).format("DD/MM/YYYY"),
  );
  const [
    ResidenceOwnerIsPolicyHolder,
    setResidenceOwnerIsPolicyHolder,
  ] = useState("");
  const [ProfessionalActivityType, setProfessionalActivityType] = useState(0);
  const [Gender, setGender] = useState("");
  const [RangeMonthlyIncomeCode, setRangeMonthlyIncomeCode] = useState("");
  const [OccupationCode, setOccupationCode] = useState("");
  const [selectedCoverage, setSelectedCoverage] = useState();

  const [IndividualNumber, setIndividualNumber] = useState("");
  const [IndNumIssuanceDate, setIndNumIssuanceDate] = useState("");
  const [IndNumIssuer, setIndNumIssuer] = useState("");
  const [IndNumIssuerState, setIndNumIssuerState] = useState("");
  const [MaritalStatus, setMaritalStatus] = useState("");
  const [CellPhoneNumber, setCellPhoneNumber] = useState("");

  const [email, setEmail] = useState("");
  const [nome, setNome] = useState("");

  //Bike field props
  const [marcaBike, setMarcaBike] = useState("");
  const [dataAquisicaoBike, setDataAquisicaoBike] = useState("");
  const [garagemBike, setGaragemBike] = useState("");
  const [bikeUtilizacao, setBikeUtilizacao] = useState("");
  const [valorAquisicaoBike, setValorAquisicaoBike] = useState("");
  const [saberSobreCoberturas, setSaberSobreCoberturas] = useState("");
  const [bike, setBike] = useState("");

  const [leadId, setLeadId] = useState();

  useEffect(() => {
    if(Object.keys(user).length !== 0){
      if (type === "automovel") {
        handleNewLead("vehicleQuotes");
      }
      if (type === "residencial") {
        handleNewLead("residenceQuotes");
        authUser(
          sessionStorage.getItem("uid"),
          sessionStorage.getItem("tokenId"),
        );
      }
    }
  }, [type]);

  useEffect(() => {
    if (Object.keys(user).length !== 0) {
      sessionStorage.setItem("user", JSON.stringify(user));
    }
  }, [user]);

  useEffect(() => {
    scrollToTop();
  }, [step]);

  const handleStepLifeForUser = () => {
    setCpfCnpj(user.cpfCnpj);
    setSexo(user.sexo);
    setTelefone(``);
    setDataNascimento(user.dataNascimento);
    setName(user.nome);
    setCpfCnpj(user.cpfCnpj);
    _handleChangeStep(2);
  };

  const handleNewLead = (type) => {
    newLead({ type }).then((r) => {
      if (r && r.status === 200) {
        setLeadId(r.data.leadId);
      }
    });
  };

  const handleNewLeadWithParams = (type, data) => {
    newLead({ type, data: { ...data } }).then((r) => {
      if (r && r.status === 200) {
        setLeadId(r.data.leadId);
         history.push("minhaconta");
      }
    });
  };

  const renderHeaderStepOther = () => {
    return (
      <Box
        borderBottom="1px solid #e1e1e1"
        justifyContent="flex-start"
        styling="row"
        mb="30px"
        py="1rem"
        px="3rem"
      >
        <Icon styling="base">
          <Back
            onClick={() => {
              if (type === "automovel") {
                if((Object.keys(user).length !== 0 && (step === 5 || step === 9)) 
                    || (step === 4 || step === 8) ){
                  return _handleChangeStep(-2);
                } else if(Object.keys(user).length !== 0 && step === 6){
                  return _handleChangeStep(-3);
                } else if(step === 11){
                  setStep(1);
                } else{
                  if (step === 1) {
                    history.push(type);
                  }
                  setStep(step - 1);
                }
              } else if (type === "residencial" && step === 3) {
                  setStep(1);
              } else if (type === "vida") {
                if (step > 1) {
                  setStep(1);
                } else {
                  history.push(type);
                  setStep(step - 1);
                }
              } else {
                if (step === 1) {
                  history.push(type);
                }
                setStep(step - 1);
              }
            }}
          />
        </Icon>
        <Box styling="row" ml="15px">
          {images?.logo?.url ? (
            <img src={images.logo.url} width={24} height={24} alt="" />
          ) : (
            <Logo />
          )}
        </Box>
      </Box>
    );
  };

  const _handleChangeStep = (count = 1) => {
    setStep(step + count);
  };

  const _handleChangeStepPortateis = (data) => {
    setPortateis({ ...portateis, ...data });
    _handleChangeStep();
  };

  const _handleGetCep = async (cep) => {
    await getCEP(cep).then((r) => {
      if (r.erro || !r) {
        showToast(
          "warning",
          "Alerta",
          "Não foi encontrado informações para o CEP informado",
        );
      } else {
        delete r.complemento
        setEndereco({
          ...r,
        });
      }
    });
  };

  const _handleIncomplete = () => {
    showToast("warning", "Alerta", "Verifique os campos para continuar.");
  };

  const _handleCompletePets = (data) => {
    createPets({ pets: data })
      .then((r) => {
        showModal({ visible: true, type: "createSuccess" });
        history.push("minhaconta");
      })
      .catch(() => {});
  };

  const handleCleanFields = () => {
    setMaritalStatus("");
    setIndividualNumber("");
    setIndNumIssuanceDate("");
    setIndNumIssuer("");
    setIndNumIssuerState("");
    setCellPhoneNumber("");
    setOccupationCode("");
    setRangeMonthlyIncomeCode("");
  };

  const handlePaymentMethodAuto = (paymentMode, additionalInformationData) => {
    buyInsuranceVehicle({
      quoteId: paymentMode.quoteId,
      payment: paymentMode.payment,
      leadId,
      ...additionalInformationData
    }).then((r) => {
      if (r && r.status === 200) {
        setQuoteId();
        showModal({ visible: true, type: "successPaymentQuotation" });
        handleCleanFields();
        history.push("minhaconta");
      } else {
        const { errorMessages } = r.response.data;
        if (errorMessages.length !== 0) {
          return showToast("warning", "Alerta", errorMessages[0], 0);
        }
        return showToast(
          "warning",
          "Alerta",
          "Não foi possível concluir a compra, tente novamente ou contate o pessego.com",
          0,
        );
      }
    });
  };

  const handleCompleteAuto = () => {
    const objToSend = { ...auto, leadId };
    createAuto(objToSend).then((r) => {
      if (r.status === 200) {
        const { values, paymentTypes } = r.data.data;
        const { errorMessages, messages, warningMessages } = r.data;
        setQuoteId(r.data.quoteId);
        setValuesQuotationMessages({
          errorMessages,
          messages,
          warningMessages,
        });
        setValueQuotationData({ values, paymentTypes });
        setStatusErrorOnPaymentQuotation(0);
        setIs0KM(r.data.Is0KM);
        return _handleChangeStep();
      } else {
        if (r.response.status === 400) {
          const { errorMessages, warningMessages } = r.response.data;
          setStatusErrorOnPaymentQuotation(r.response.status);
          setValuesQuotationMessages({ errorMessages, warningMessages });
          return _handleChangeStep();
        }
        if (r.response.status === 403) {
          const { message } = r.response.data;
          return showToast("warning", "Alerta", message);
        }
        if (r.response.status === 500) {
          const { message } = r.response.data;
          setStatusErrorOnPaymentQuotation(r.response.status);
          setValuesQuotationMessages({ messages: message });
          return _handleChangeStep();
        }
      }
    });
  };

  const handleAddResidenciaVida = (data) => {
    const endereco = {
      ZipCode: data.cep,
      StreetType: data.tipo,
      StreetName: data.logradouro,
      StreetNumber: data.numero,
      StreetExtraInformation: data.complemento,
      DistrictName: data.bairro,
      CityName: data.cidade,
      StateCode: data.estado,
    };

    _handleCompleteVida(endereco);
  };

  const handleAddNewResidence = (data) => {
    const newObject = {
      ZipCode: data.cep,
      StreetType: data.tipo,
      StreetName: data.logradouro,
      StreetNumber: data.numero,
      StreetExtraInformation: data.complemento,
      DistrictName: data.bairro,
      CityName: data.cidade,
      StateCode: data.estado,
    };
    addResidence(newObject).then((r) => {
      if(type === "automovel") {
        const send = { ...auto, residenceId: r.data.residenceId };
        setAuto(send);
        updateLead({ data: { ...send }, leadId });
        _handleChangeStep(2);

      } else if(type === "residencial"){
        const send = { ...residence, residenceId: r.data.residenceId };
        setResidence(send);
        updateLead({ data: { ...send }, leadId });
        _handleChangeStep();

      }
    });
  };

  const _handleCompletePortateis = async (obj = []) => {
    const body = { ...portateis, ...obj };
    await createPortateis(body).then((r) => {
      if (r === 200) {
        showModal({ visible: true, type: "createSuccess" });
        history.push("minhaconta");
      }
    });
  };

  const handleNewUserWithCotationGadgets = async (body) => {
    openGadgetsQuotation(body).then( async (r) => {
      if(r.status !== 200){
        return showToast("warning", "Alerta", r.response.data.error);
      } else{
        sessionStorage.setItem("uid", r.data.uid);
        sessionStorage.setItem("tokenId", r.data.token);
        await authUser(r.data.uid, r.data.token);
        showModal({ visible: true, type: "createSuccess" });
        history.push("minhaconta");
      }
    });
  }

  const handleNewUserWithCotationBike = async (body) => {
    openBikeQuotation(body).then( async (r) => {
      if(r.status !== 200){
        return showToast("warning", "Alerta", r.response.data.error);
      } else{
        sessionStorage.setItem("uid", r.data.uid);
        sessionStorage.setItem("tokenId", r.data.token);
        await authUser(r.data.uid, r.data.token);
        showModal({ visible: true, type: "createSuccess" });
        history.push("minhaconta");
      }
    });
  }

  const handleNewUserWithCotationPets = async (body) => {
    openPetQuotation(body).then( async (r) => {
      if(r.status !== 200){
        return showToast("warning", "Alerta", r.response.data.error);
      } else{
        sessionStorage.setItem("uid", r.data.uid);
        sessionStorage.setItem("tokenId", r.data.token);
        await authUser(r.data.uid, r.data.token);
        showModal({ visible: true, type: "createSuccess" });
        history.push("minhaconta");
      }
    });
  }

  const handleNewUserWithCotationLife = async (body) => {
    openLifeQuotation(body).then( async (r) => {
      if(r.status !== 200){
        return showToast("warning", "Alerta", r.response.data.error);
      } else{
        sessionStorage.setItem("uid", r.data.uid);
        sessionStorage.setItem("tokenId", r.data.token);
        await authUser(r.data.uid, r.data.token);
        showModal({ visible: true, type: "createSuccess" });
        history.push("minhaconta");
      }
    });
  }

  const _handleCompleteBike = async () => {
    await createBike({
      marcaBike,
      valorAquisicaoBike: valorAquisicaoBike,
      dataAquisicaoBike: moment(dataAquisicaoBike, "DD/MM/YYYY").format(
        "YYYY-MM-DD",
      ),
      garagemBike,
      bikeUtilizacao,
      saberSobreCoberturas,
    })
      .then((r) => {
        showModal({ visible: true, type: "createSuccess" });
        history.push("minhaconta");
      })
      .catch(() => {});
  };

  const _handleCompleteVida = async (endereco) => {
    const splitedTelefone = telefone.split(" ");
    await createVida({
      type: typePerson,
      nome: typePerson === 0 ? undefined : name,
      rendaMensal,
      quantidadeDependentes,
      cpfCnpj: typePerson === 0 ? undefined : cpfCnpj,
      dataNascimento:
        typePerson === 0
          ? undefined
          : moment(dataNascimento, "DD/MM/YYYY").format("YYYY-MM-DD"),
      sexo: typePerson === 0 ? undefined : sexo,
      ddd: typePerson === 0 ? undefined : splitedTelefone[0],
      telefone: typePerson === 0 ? undefined : splitedTelefone[1],
      estadoCivil,
      sexoConjuge,
      fumante,
      historicoDoencasGraves,
      residence: endereco,
    }).then((r) => {
      if (r === 200) {
        showModal({ visible: true, type: "createSuccess" });
        history.push("minhaconta");
      }
    });
  };

  const _handleCompleteResidence = (residenceObj) => {
    const residenceInfo = {
      ConstructionType: residenceObj.ConstructionType,
      PropertyType: residenceObj.PropertyType,
      OccupanceType: residenceObj.OccupanceType,
      RobberyProtectiveSystem: residenceObj.RobberyProtectiveSystem,
      TumbledHomeOwners: residenceObj.TumbledHomeOwners,
      ResidenceOwnerIsPolicyHolder: residenceObj.ResidenceOwnerIsPolicyHolder,
      ResidenceHasWoodOutsideWalls: residenceObj.ResidenceHasWoodOutsideWalls,
      ResidenceHasProfessionalActivity:
      residenceObj.ResidenceHasProfessionalActivity,
      ProfessionalActivityType: residenceObj.ProfessionalActivityType,
      ResidenceUnoccupiedUnderRenovationOrConstruction:
      residenceObj.ResidenceUnoccupiedUnderRenovationOrConstruction,
      TypeRegionLocation: residenceObj.TypeRegionLocation,
    };
    const PurchaseParameters = {
      Residence: {
        BeneficiaryName: residenceObj.BeneficiaryName,
        HasAnotherInsurance: residenceObj.HasAnotherInsurance,
      },
      PolicyHolder: {
        Gender: residenceObj.Gender,
        IndividualNumber: residenceObj.IndividualNumber,
        IndNumIssuanceDate: residenceObj.IndNumIssuanceDate,
        IndNumIssuer: residenceObj.IndNumIssuer,
        IndNumIssuerState: residenceObj.IndNumIssuerState,
        BirthDate: residenceObj.BirthDate,
        MaritalStatus: residenceObj.MaritalStatus,
        CellPhoneAreaCode: residenceObj.CellPhoneAreaCode,
        CellPhoneNumber: residenceObj.CellPhoneNumber,
        RangeMonthlyIncomeCode: residenceObj.RangeMonthlyIncomeCode,
        OccupationCode: residenceObj.OccupationCode,
      },
    };
    const objToSend = {
      residenceInfo,
      residenceId: residenceObj.residenceId,
      coverageId: residenceObj.coverageId.toString(),
      PurchaseParameters,
      calculateRequest: {
        StartDate: residenceObj.StartDate,
        EndDate: residenceObj.EndDate,
      },
      leadId,
    };

    createResidence(objToSend).then((r) => {
      if (r.status === 200) {
        const { values, paymentTypes } = r.data.data;
        const { errorMessages, messages, warningMessages, quoteId } = r.data;
        setQuoteId(quoteId);
        setValuesQuotationMessages({
          errorMessages,
          messages,
          warningMessages,
        });
        setValueQuotationData({ values, paymentTypes });
        setStatusErrorOnPaymentQuotation(0);
        return _handleChangeStep(2);
      } else {
        if (r.response.status === 400) {
          const { errorMessages, warningMessages } = r.response.data;
          setStatusErrorOnPaymentQuotation(r.response.status);
          setValuesQuotationMessages({ errorMessages, warningMessages });
          return _handleChangeStep(2);
        }
        if (r.response.status === 403) {
          const { message } = r.response.data;
          return showToast("warning", "Alerta", message);
        }
        if (r.response.status === 500) {
          const { message } = r.response.data;
          setStatusErrorOnPaymentQuotation(r.response.status);
          setValuesQuotationMessages({ messages: message });
          return _handleChangeStep(2);
        }
      }
    });
  };

  const handlePaymentMethod = (paymentMode, additionalInformationData) => {
    buyInsuranceResidence({
      quoteId: paymentMode.quoteId,
      payment: paymentMode.payment,
      leadId,
      ...additionalInformationData
    }).then((r) => {
      if (r && r.status === 200) {
        setQuoteId();
        handleCleanFields();
        showModal({ visible: true, type: "successPaymentQuotation" });
        history.push("minhaconta");
      } else {
        const { errorMessages } = r.response.data;
        if (errorMessages.length !== 0) {
          return showToast("warning", "Alerta", errorMessages[0], 0);
        }
        return showToast(
          "warning",
          "Alerta",
          "Não foi possível concluir a compra, tente novamente ou contate o pessego.com",
          0,
        );
      }
    });
  };

  const handleRegisterAuto = (data) => {
    registerVehicles(data).then((r) => {
      const send = { ...auto, vehicleId: r.data.vehicleId };
      setAuto(send);
      updateLead({ data: { ...send }, leadId });
      selectedTypeInsurance === "31111" ? _handleChangeStep() : _handleChangeStep(2)
    });
  };

  const _handleCompleteViagem = async (obj) => {
    await createViagem(obj).then((r) => {
      showModal({ visible: true, type: "createSuccess" });
    });
  };

  const _handleGetListRaces = async (type) => {
    await getListRaces(type).then((data) => {
      setRaces(data);
    });
  };

  const _handleGetListMarcas = async (tipo) => {
    await getListMarcasPortateis(tipo).then((data) => {
      setMarcaPortateis(data);
    });
  };

  const _handleGetListModelos = async (tipo, marca) => {
    await getListModelosPortateis(tipo, marca).then((data) => {
      setModeloPortateis(data);
    });
  };

  const handleFetchItems = () => {
    getListFormasDeViagem();
    getListContinent();
  };

  const handleErrorOnGoForwardMsg = () =>
    showToast(
      "warning",
      "Alerta",
      "Não foi possivel carregar os dados. Verifique sua conexão e tente novamente.",
    );

  const handleErrorOnGetInfo = (goBackSteps) => {
    handleErrorOnGoForwardMsg();
    _handleChangeStep(goBackSteps);
  };

  const handleGetPortableTypes = () =>
    getListTiposPortateis()
      .then(() => {})
      .catch(() => handleErrorOnGetInfo(-1));

  const onMountBeneficiaryInfo = async () => {
    try {
      await getMaritalState();
      await getRoles();
      await getRangeOfPayment();
      await getGeneralRegistryState();
    } catch (e) {
      handleErrorOnGetInfo(-1);
    }
  };

  const getMaritalStateInfo = async () => {
    try {
      await getMaritalState();
    } catch (e) {
      handleErrorOnGetInfo(-1);
    }
  };

  const handleResetLifeInsuranceFields = () => {
    setTypePerson("");
    setName("");
    setCpfCnpj("");
    setDataNascimento("");
    setSexo("");
    setTelefone("");
    setRendaMensal("");
    setQuantidadeDependentes("");
    setEstadoCivil("");
    setSexoConjuge("");
    setFumante("");
    setHistoricoDoencasGraves("");
  };

  const _handleVerificarCPF = () => {
    return verificarCpf(cpf.replace(/\.|-/g, ""))
      .then((r) => {
        if (!r.userCpf) {
          showToast("warning", "Alerta", "CPF já existente.");
          setCpf("");
          return false;
        }
        return true;
      })
      .catch((e) => {
        showToast(
          "warning",
          "Alerta",
          "Ocorreu um problema ao validar o CPF. Verifique suas informações e tente novamente.",
        );
        return false;
      });
  };

  const onGetCoveragesFromResidence = () =>
    getResidenceCoverage()
      .then(() => {})
      .catch(() => handleErrorOnGetInfo(-1));

  const onGetCoveragesFromAuto = () =>
    getVehiclesCoverages()
      .then(() => {})
      .catch(() => handleErrorOnGetInfo(-1));

  const onGetAutoInsurancesTypes = () =>
    getAutoInsuranceTypes()
      .then(() => {})
      .catch(() => handleErrorOnGetInfo(-2));

  const handleNewUserWithCotationVehicle = async (autoObject) => {
    const leadResult = await newLead({ type: "vehicleQuotes"});
    setLeadId(leadResult.data.leadId);
    setAuto({...autoObject, leadId: leadResult.data.leadId});
    const payloadAuto = {
      quotation: {
        ...autoObject,
        leadId: leadResult.data.leadId
      },
      userData: autoObject.userData
    };
    openVehicleQuotation(payloadAuto).then( async (r) => {
      if (r.status === 200) {
        const quotationResponse = r.data.quotation;
        const { values, paymentTypes } = quotationResponse.data;
        const { errorMessages, messages, warningMessages } = quotationResponse;
        setQuoteId(quotationResponse.quoteId);
        setValuesQuotationMessages({
          errorMessages,
          messages,
          warningMessages,
        });
        setValueQuotationData({ values, paymentTypes });
        setStatusErrorOnPaymentQuotation(0);
        setIs0KM(quotationResponse.Is0KM);

        sessionStorage.setItem("uid", r.data.uid);
        sessionStorage.setItem("tokenId", r.data.token);
        await authUser(r.data.uid, r.data.token);

        return _handleChangeStep(2);
      } else {
        if(r.response.data && r.response.data.uid){
          sessionStorage.setItem("uid", r.response.data.uid);
          sessionStorage.setItem("tokenId", r.response.data.token);
          await authUser(r.response.data.uid, r.response.data.token);
        }
        if (r.response.status === 400) {
          if(r.response.data.error){
            return showToast("warning", "Alerta", r.response.data.error);
          } else{
            const { errorMessages, warningMessages } = r.response.data.quotation;
            setStatusErrorOnPaymentQuotation(r.response.status);
            setValuesQuotationMessages({ errorMessages, warningMessages });
            return _handleChangeStep(2);
          }
        }
        if (r.response.status === 403) {
          const { message } = r.response.data;
          return showToast("warning", "Alerta", message);
        }
        if (r.response.status === 500) {
          const { message } = r.response.data.quotation;
          setStatusErrorOnPaymentQuotation(r.response.status);
          setValuesQuotationMessages({ messages: message });
          return _handleChangeStep(2);
        }
      }
    })
  }

  const handleNewUserWithCotationTravel = async (body) => {
    openTravelQuotation(body).then( async (r) => {
      if(r.status !== 200){
        return showToast("warning", "Alerta", r.response.data.error);
      } else{
        sessionStorage.setItem("uid", r.data.uid);
        sessionStorage.setItem("tokenId", r.data.token);
        await authUser(r.data.uid, r.data.token);
        showModal({ visible: true, type: "createSuccess" });
        history.push("minhaconta");
      }
    });
  }

  const handleNewUserWithCotationResidence = async (residenceObj) => {
    const leadResult = await newLead({ type: "residenceQuotes"});
    setLeadId(leadResult.data.leadId);
    residenceObj = { ...residenceObj, leadId: leadResult.data.leadId };
    setResidence(residenceObj);

    const residenceInfo = {
      ConstructionType: residenceObj.ConstructionType,
      PropertyType: residenceObj.PropertyType,
      OccupanceType: residenceObj.OccupanceType,
      RobberyProtectiveSystem: residenceObj.RobberyProtectiveSystem,
      TumbledHomeOwners: residenceObj.TumbledHomeOwners,
      ResidenceOwnerIsPolicyHolder: residenceObj.ResidenceOwnerIsPolicyHolder,
      ResidenceHasWoodOutsideWalls: residenceObj.ResidenceHasWoodOutsideWalls,
      ResidenceHasProfessionalActivity:
        residenceObj.ResidenceHasProfessionalActivity,
      ProfessionalActivityType: residenceObj.ProfessionalActivityType,
      ResidenceUnoccupiedUnderRenovationOrConstruction:
      residenceObj.ResidenceUnoccupiedUnderRenovationOrConstruction,
      TypeRegionLocation: residenceObj.TypeRegionLocation,
    };
    const PurchaseParameters = {
      Residence: {
        BeneficiaryName: residenceObj.userData.nome,
        HasAnotherInsurance: residenceObj.HasAnotherInsurance,
      },
      PolicyHolder: {
        Gender: residenceObj.Gender,
        IndividualNumber: residenceObj.IndividualNumber,
        IndNumIssuanceDate: residenceObj.IndNumIssuanceDate,
        IndNumIssuer: residenceObj.IndNumIssuer,
        IndNumIssuerState: residenceObj.IndNumIssuerState,
        BirthDate: residenceObj.BirthDate,
        MaritalStatus: residenceObj.MaritalStatus,
        CellPhoneAreaCode: residenceObj.CellPhoneAreaCode,
        CellPhoneNumber: residenceObj.CellPhoneNumber,
        RangeMonthlyIncomeCode: residenceObj.RangeMonthlyIncomeCode,
        OccupationCode: residenceObj.OccupationCode,
      },
    };
    const objToSend = {
      quotation: {
        residenceInfo,
        residenceId: residenceObj.residenceId,
        coverageId: residenceObj.coverageId.toString(),
        PurchaseParameters,
        calculateRequest: {
          StartDate: residenceObj.StartDate,
          EndDate: residenceObj.EndDate,
        },
        leadId: residenceObj.leadId,
      }, 
      userData: {
        ...residenceObj.userData
      }
    };
    openResidenceQuotation(objToSend).then( async (r) => {
      if (r.status === 200) {
        const quotationResponse = r.data.quotation;
        const { values, paymentTypes } = quotationResponse.data;
        const { errorMessages, messages, warningMessages, quoteId } = quotationResponse;
        setQuoteId(quoteId);
        setValuesQuotationMessages({
          errorMessages,
          messages,
          warningMessages,
        });
        setValueQuotationData({ values, paymentTypes });
        setStatusErrorOnPaymentQuotation(0);

        sessionStorage.setItem("uid", r.data.uid);
        sessionStorage.setItem("tokenId", r.data.token);
        await authUser(r.data.uid, r.data.token);
        
        return _handleChangeStep();
      } else {
        if(r.response.data && r.response.data.uid){
          sessionStorage.setItem("uid", r.response.data.uid);
          sessionStorage.setItem("tokenId", r.response.data.token);
          await authUser(r.response.data.uid, r.response.data.token);
        }
        if (r.response.status === 400) {
          if(r.response.data.error){
            return showToast("warning", "Alerta", r.response.data.error);
          } else{
            const { errorMessages, warningMessages } = r.response.data.quotation;
            setStatusErrorOnPaymentQuotation(r.response.status);
            setValuesQuotationMessages({ errorMessages, warningMessages });
            return _handleChangeStep();
          }
        }
        if (r.response.status === 403) {
          const { message } = r.response.data;
          return showToast("warning", "Alerta", message);
        }
        if (r.response.status === 500) {
          const { message } = r.response.data.quotation;
          setStatusErrorOnPaymentQuotation(r.response.status);
          setValuesQuotationMessages({ messages: message });
          return _handleChangeStep();
        }
      }
    });
  };

  const renderType = () => {
    switch (type) {
      case "pets":
        switch (step) {
          case 1:
            return (
              <Pets.Step1
                onNext={() => _handleChangeStep()}
                quantidadePets={quantidadePets}
                setQuantidadePets={setQuantidadePets}
              />
            );
          case 2:
            return (
              <Pets.Step2
                quantidade={parseInt(quantidadePets)}
                pets={pet}
                onNext={(pets) => {
                  setPet([...pets]);
                  _handleChangeStep();
                }}
                onIncomplete={_handleIncomplete}
              />
            );
          case 3:
            return (
              <Pets.Step3
                pets={pet}
                quantidade={parseInt(quantidadePets)}
                onChangeType={_handleGetListRaces}
                listRaces={races}
                onIncomplete={_handleIncomplete}
                onNext={(data) => {
                  if(Object.keys(user).length !== 0) {
                    _handleCompletePets(data);
                  } else {
                    setPet(data);
                    _handleChangeStep();
                  }
                }}
              />
            );
          case 4:
            return (
              <Residencia.PersonalData
              onNext={(data) => {
                const send = {
                  quotation: { pets: pet },
                  userData: data
                }
                handleNewUserWithCotationPets(send);
              }}
              onCancel={(data) => {
                const send = {
                  pets: pet,
                  userData: data
                }
                handleNewLeadWithParams( "petQuotes", send);
                history.push("/");
              }}
              onGetErrorFromQuiz={() => handleErrorOnGetInfo(-1)}
              setNome={setNome}
              nome={nome}
              setEmail={setEmail}
              email={email}
              sexo={sexo}
              setSexo={setSexo}
              cpfCnpj={cpfCnpj}
              setCpfCnpj={setCpfCnpj}
              dataNascimento={dataNascimento}
              setDataNascimento={setDataNascimento}
              CellPhoneNumber={CellPhoneNumber}
              setCellPhoneNumber={setCellPhoneNumber}
              maritalStateLoading={maritalStateLoading}
              maritalStateList={maritalStateList}
              setMaritalStatus={setMaritalStatus}
              MaritalStatus={MaritalStatus}
              getMaritalStateInfo={getMaritalStateInfo}
              onFailBirthDayDate={() =>
                showToast(
                  "warning",
                  "Alerta",
                  "Cadastro permitido somente para idades iguais ou superiores a 18 anos.",
                )
              }
              onFailName={(option) => {
                if (option === 1) {
                  showToast(
                    "warning",
                    "Alerta",
                    "É preciso preencher nome e sobrenome.",
                  );
                } else {
                  showToast(
                    "warning",
                    "Alerta",
                    "Nome e sobrenome precisam ter no mínimo 2 caracteres cada.",
                  );
                }
              }}
                _handleVerificarCPF={_handleVerificarCPF}
              />
            );
          default:
            break;
        }
        break;

      case "automovel":
        switch (step) {
          case 1:
            return (
              <Auto.InsuranceConfirm
                getAutoInsuranceTypes={onGetAutoInsurancesTypes}
                autoInsuranceTypeList={autoInsuranceTypeList}
                autoInsuranceTypeLoading={autoInsuranceTypeLoading}
                selectedTypeInsurance={selectedTypeInsurance}
                setSelectedTypeInsurance={setSelectedTypeInsurance}
                onFail={() =>
                  showToast(
                    "warning",
                    "Alerta",
                    "Selecione um tipo de seguro para continuar.",
                  )
                }
                onNext={(data) => {
                  const send = {
                    ...auto,
                    CalculateRequestAuto: {
                      ...auto.CalculateRequestAuto,
                      CommercialProductCode: data,
                    },
                  };
                  setAuto(send);
                  if(Object.keys(user).length !== 0) updateLead({ data: { ...send }, leadId })
                  _handleChangeStep();
                }}
              />
            );
          case 2:
            return selectedTypeInsurance === "31111" ? (
              <Auto.Step4
                onNext={(selected) => {
                  const data = Object.values(selected);
                  const send = { ...auto, coverages: data };
                  setAuto(send);
                  if(Object.keys(user).length !== 0) {
                    updateLead({ data: { ...send }, leadId });
                    _handleChangeStep();
                  } else {
                    _handleChangeStep(2);
                  }
                }}
                assistance={assistance}
                setAssistance={setAssistance}
                invalidity={invalidity}
                setInvalidity={setInvalidity}
                death={death}
                setDeath={setDeath}
                dm={dm}
                setDm={setDm}
                dc={dc}
                setDc={setDc}
                dMaterial={dMaterial}
                setDMaterial={setDMaterial}
                hospital={hospital}
                setHospital={setHospital}
                mirror={mirror}
                setMirror={setMirror}
                ppValue={ppValue}
                setPPValue={setPPValue}
                iiValue={iiValue}
                setIIValue={setIIValue}
                ppiiValue={ppiiValue}
                setPPIIValue={setPPIIValue}
                third={third}
                setThird={setThird}
                showModal={showModal}
              />
            ) : (
              <Coverages
                onComplete={(coverageId) => {
                  const send = { ...auto, coverageId: coverageId };
                  setAuto(send);
                  if(Object.keys(user).length !== 0){
                    updateLead({ data: { ...send }, leadId });
                    _handleChangeStep()
                  } else{
                    _handleChangeStep(2)
                  }
                }}
                onIncomplete={() =>
                  showToast(
                    "warning",
                    "Alerta",
                    "Selecione uma cobertura para continuar.",
                  )
                }
                selectedCoverage={selectedCoverage}
                setSelectedCoverage={setSelectedCoverage}
                coverage={autoCoverage}
                onGetCoverage={onGetCoveragesFromAuto}
                coverageLoading={autoCoverageLoading}
                benefitsCoverage={[]}
              />
            );
          case 3:
            return (
              <Auto.AutoConfirm
                getVehicles={getVehicles}
                removeVehicle={removeVehicle}
                myVehiclesList={myVehiclesList}
                myVehiclesLoading={myVehiclesLoading}
                selectedAuto={selectedAuto}
                setSelectedAuto={setSelectedAuto}
                onRegisterAuto={() => _handleChangeStep()}
                onNext={(data) => {
                  const send = { ...auto, vehicleId: data };
                  setAuto(send);
                  updateLead({ data: { ...send }, leadId });
                  selectedTypeInsurance === "31111" ? _handleChangeStep(2) : _handleChangeStep(3)
                }}
                onFail={() =>
                  showToast(
                    "warning",
                    "Alerta",
                    "Selecione um automóvel para continuar.",
                  )
                }
              />
            );
          case 4:
            return (
              <Auto.Register
                onComplete={ (vehicleData) => {
                  if(Object.keys(user).length !== 0){
                    handleRegisterAuto(vehicleData) 
                  } else {
                    const send = {
                      ...auto, 
                      userData: {
                        vehicles: {
                          vehicleData: {
                            ...vehicleData.vehicleData,
                            description: vehicleData.description,
                            vehicleType: "1"
                          },
                        }
                      }
                    };
                    setAuto(send);
                    _handleChangeStep();
                  } 
                }}
                onIncomplete={_handleIncomplete}
                myVehiclesLoading={myVehiclesLoading}
                getVehicleBrands={getVehicleBrands}
                getVehiclesFromBrand={getVehiclesFromBrand}
                getVehicleModelYear={getVehicleModelYear}
                getVehicleInfo={getVehicleInfo}
                referencia={referencia}
                vehicleBrands={vehicleBrands}
                vehicleModels={vehicleModels}
                vehicleYearModel={vehicleYearModel}
                selectedVehicle={selectedVehicle}
                myVehiclesList={myVehiclesList}
              />
            );
          case 5:
            return (
              <Auto.Step3
                onGetErrorFromQuiz={() => handleErrorOnGetInfo(-1)}
                onIncomplete={_handleIncomplete}
                onNext={(data) => {
                  const send = { ...auto, Question: data };
                  setAuto(send);
                  if (Object.keys(user).length !== 0) updateLead({ data: { ...send }, leadId });
                  _handleChangeStep();
                }}
              />
            );
          case 6:
            return (
              <Auto.Step1
                onGetErrorFromQuiz={() => handleErrorOnGetInfo(-1)}
                onNext={(data) => {
                  const send = {
                    ...auto,
                    CalculateRequestAuto: {
                      ...auto.CalculateRequestAuto,
                      StartDate: data.validity.StartDate,
                      EndDate: data.validity.EndDate,
                    },
                    Vehicle: data.vehicleInfo,
                    PurchaseParameters: {
                      ...auto.PurchaseParameters,
                      Vehicles: {
                        InvoiceNumber: data.InvoiceNumber,
                        DealershipName: data.DealershipName,
                      },
                    },
                    PotentialDriver: {
                      IsDriver: data.IsDriver,
                      DaysPerWeek: data.DaysPerWeek,
                      MaritalStatus: data.MaritalStatus,
                    },
                  };
                  setAuto(send);
                  if(Object.keys(user).length !== 0) {
                    updateLead({ data: { ...send }, leadId });
                    _handleChangeStep();
                  } else{
                    _handleChangeStep(2);
                  }
                }}
                DealerExitDateTime={DealerExitDateTime}
                setDealerExitDateTime={setDealerExitDateTime}
                HasLoJack={HasLoJack}
                setHasLoJack={setHasLoJack}
                LoJackCode={LoJackCode}
                setLoJackCode={setLoJackCode}
                ZipCode={ZipCode}
                setZipCode={setZipCode}
                CategoryCode={CategoryCode}
                setCategoryCode={setCategoryCode}
                UsageCode={UsageCode}
                setUsageCode={setUsageCode}
                Is0KM={Is0KM}
                setIs0KM={setIs0KM}
                StartDate={StartDate}
                setStartDate={setStartDate}
                EndDate={EndDate}
                setEndDate={setEndDate}
                IsDriver={IsDriver}
                setIsDriver={setIsDriver}
                DaysPerWeek={DaysPerWeek}
                setDaysPerWeek={setDaysPerWeek}
                maritalStateList={maritalStateList}
                maritalStateLoading={maritalStateLoading}
                setMaritalStatus={setMaritalStatus}
                MaritalStatus={MaritalStatus}
                onMountBeneficiaryInfo={onMountBeneficiaryInfo}
                user={user}
              />
            );
          case 7:
            return (
              <Residencia.AddressConfirm
                type="auto"
                titleEmpty="Escolha a residência do veículo"
                getResidences={getResidences}
                userResidences={userResidences}
                removeResidence={removeResidence}
                userResidencesLoading={userResidencesLoading}
                onNextMyHouse={(residenceId) => {
                  const send = { ...auto, residenceId };
                  setAuto(send);
                  updateLead({ data: { ...send }, leadId });
                  _handleChangeStep(3);  
                }}
                onNextOtherHouse={(data) => {
                  _handleChangeStep();
                }}
                onFail={() => {
                  showToast(
                    "warning",
                    "Alerta",
                    "Selecione um endereço para continuar.",
                  );
                }}
                selectedAddress={selectedAddress}
                setSelectedAddress={setSelectedAddress}
              />
            );
          case 8:
            return (
              <Residencia.RegisterMyLocation
                endereco={endereco}
                getEndereco={_handleGetCep}
                onNext={(enderecoData) => {
                  if(Object.keys(user).length !== 0){
                    handleAddNewResidence(enderecoData) 
                  } else {
                    const send = {
                      ...auto
                    }
                    send.userData = {
                      ...auto.userData,
                      residence: {
                        CityName: enderecoData.cidade,
                        DistrictName: enderecoData.bairro,
                        StateCode: enderecoData.estado,
                        StreetExtraInformation: enderecoData.complemento,
                        StreetName: enderecoData.logradouro,
                        StreetNumber: enderecoData.numero,
                        StreetType: enderecoData.tipo,
                        ZipCode: enderecoData.cep
                      }
                    }
                    setAuto(send);
                    _handleChangeStep();
                  } 
                }}
                setEndereco={setEndereco}
              />
            );
          case 9: 
            return (
              <Residencia.PersonalData
              onNext={(data) => {
                const send = {
                  ...auto
                }
                send.PotentialDriver.MaritalStatus = data.situacaoCivil; 
                send.userData = {
                  ...auto.userData,
                  ...data,
                }
                setAuto(send);
                handleNewUserWithCotationVehicle(send);
              }}
              onCancel={(data) => {
                const send = {
                  ...auto
                }
                send.PotentialDriver.MaritalStatus = data.situacaoCivil; 
                send.userData = {
                  ...auto.userData,
                  ...data,
                }
                setAuto(send);
                handleNewLeadWithParams( "vehicleQuotes", send);
                history.push("/");
              }}
              onGetErrorFromQuiz={() => handleErrorOnGetInfo(-1)}
              setNome={setNome}
              nome={nome}
              setEmail={setEmail}
              email={email}
              sexo={sexo}
              setSexo={setSexo}
              cpfCnpj={cpfCnpj}
              setCpfCnpj={setCpfCnpj}
              dataNascimento={dataNascimento}
              setDataNascimento={setDataNascimento}
              CellPhoneNumber={CellPhoneNumber}
              setCellPhoneNumber={setCellPhoneNumber}
              maritalStateLoading={maritalStateLoading}
              maritalStateList={maritalStateList}
              setMaritalStatus={setMaritalStatus}
              MaritalStatus={MaritalStatus}
              getMaritalStateInfo={getMaritalStateInfo}
              onFailBirthDayDate={() =>
                showToast(
                  "warning",
                  "Alerta",
                  "Cadastro permitido somente para idades iguais ou superiores a 18 anos.",
                )
              }
              onFailName={(option) => {
                if (option === 1) {
                  showToast(
                    "warning",
                    "Alerta",
                    "É preciso preencher nome e sobrenome.",
                  );
                } else {
                  showToast(
                    "warning",
                    "Alerta",
                    "Nome e sobrenome precisam ter no mínimo 2 caracteres cada.",
                  );
                }
              }}
                _handleVerificarCPF={_handleVerificarCPF}
              />
            );
          case 10:
            handleCompleteAuto();
            break;
          case 11:
            return (
              <PaymentTypes
                {...valuesQuotationMessages}
                onRedirectError={() => {
                  history.push("minhaconta");
                }}
                onBackToHome={() => {
                  showModal({ visible: true, type: "confirmEndQuotation" });
                }}
                onComplete={(selectedPaymentMethod) =>{
                    setPaymentMethod(selectedPaymentMethod)
                    _handleChangeStep()
                  }
                }
                onIncomplete={() => {
                  showToast(
                    "warning",
                    "Alerta",
                    "Selecione uma forma de pagamento para continuar.",
                  );
                }}
                values={valueQuotationData.values}
                quoteId={quoteId}
                paymentTypes={valueQuotationData.paymentTypes}
                paymentContext="vehicle"
                statusErrorPaymentQuotation={statusErrorPaymentQuotation}
                isOnFlow
              />
            );
          case 12:
            return (
              <Auto.AdditionalInformation
                onMountBeneficiaryInfo={onMountBeneficiaryInfo}
                IndividualNumber={IndividualNumber}
                IndNumIssuanceDate={IndNumIssuanceDate}
                IndNumIssuer={IndNumIssuer}
                IndNumIssuerState={IndNumIssuerState}
                OccupationCode={OccupationCode}
                RangeMonthlyIncomeCode={RangeMonthlyIncomeCode}
                DriverLicenseType={DriverLicenseType}
                setDriverLicenseType={setDriverLicenseType}
                roles={roles}
                dataHabCondutor={dataHabCondutor}
                setDataHabCondutor={setDataHabCondutor}
                rangePayments={rangePayments}
                maritalStateList={maritalStateList}
                maritalStateLoading={maritalStateLoading}
                generalRegistryList={generalRegistryList}
                generalRegistryLoading={generalRegistryLoading}
                setRangeMonthlyIncomeCode={setRangeMonthlyIncomeCode}
                setIndividualNumber={setIndividualNumber}
                setIndNumIssuanceDate={setIndNumIssuanceDate}
                setIndNumIssuer={setIndNumIssuer}
                setIndNumIssuerState={setIndNumIssuerState}
                setOccupationCode={setOccupationCode}
                PoliticallyExposedPerson={PoliticallyExposedPerson}
                setPoliticallyExposedPerson={setPoliticallyExposedPerson}
                DriverLicenseNumber={DriverLicenseNumber}
                setDriverLicenseNumber={setDriverLicenseNumber}
                showModal={showModal}
                Is0KM={Is0KM}
                DealershipName={DealershipName}
                setDealershipName={setDealershipName}
                InvoiceNumber={InvoiceNumber}
                setInvoiceNumber={setInvoiceNumber}
                CellPhoneNumber={CellPhoneNumber}
                setCellPhoneNumber={setCellPhoneNumber}
                onComplete={(data) => {
                  const additionalInformationData = {
                    quiver: {
                      dataHabCondutor: data.dataHabCondutor,
                    },
                    PurchaseParameters: {
                      PolicyHolder: {
                        RangeMonthlyIncomeCode: data.RangeMonthlyIncomeCode,
                        OccupationCode: data.OccupationCode,
                        CellPhoneNumber: data.CellPhoneNumber,
                        CellPhoneAreaCode: data.CellPhoneAreaCode,
                        IndNumIssuerState: data.IndNumIssuerState,
                        IndNumIssuer: data.IndNumIssuer,
                        IndNumIssuanceDate: data.IndNumIssuanceDate,
                        IndividualNumber: data.IndividualNumber,
                        DriverLicenseNumber: data.DriverLicenseNumber,
                        DriverLicenseType: data.DriverLicenseType,
                        PoliticallyExposedPerson: data.PoliticallyExposedPerson    
                      }
                    }
                  };
                  
                  handlePaymentMethodAuto(paymentMethod, additionalInformationData)
                }}
                onRedirectError={() => {
                  history.push("minhaconta");
                }}
                onBackToHome={() => {
                  showModal({ visible: true, type: "confirmEndQuotation" });
                }}
              />
            );
          default:
            throw new Error("wrong step");
        }
        break;
      case "portateis":
        switch (step) {
          case 1:
            return (
              <Portateis.Step1
                onNext={_handleChangeStepPortateis}
                valorAssegurado={valorAssegurado}
                setValorAssegurado={setValorAssegurado}
                otherUser={otherUser}
                setOtherUser={setOtherUser}
                dataNascimentoPortable={dataNascimentoPortable}
                setDataNascimentoPortable={setDataNascimentoPortable}
                cpf={cpf}
                setCpf={setCpf}
              />
            );
          case 2:
            return (
              <Portateis.Step2
                handleGetPortableTypes={handleGetPortableTypes}
                onNext={_handleChangeStepPortateis}
                portableTypes={portableTypes}
                onChangeTipo={_handleGetListMarcas}
                onChangeMarcas={_handleGetListModelos}
                listMarca={marcaPortateis}
                listModelo={modeloPortateis}
                tipo={tipo}
                setTipo={setTipo}
                dataAquisicao={dataAquisicao}
                setDataAquisicao={setDataAquisicao}
                numeroNotaFiscal={numeroNotaFiscal}
                setNumeroNotaFiscal={setNumeroNotaFiscal}
                valorAquisicao={valorAquisicao}
                setValorAquisicao={setValorAquisicao}
                marca={marca}
                setMarca={setMarca}
                modelo={modelo}
                setModelo={setModelo}
              />
            );
          case 3:
            return (
              <Portateis.Step3
                modelo={portateis.modelo}
                onYes={() => _handleChangeStep()}
                onNo={() => {
                  if(Object.keys(user).length !== 0){
                    _handleCompletePortateis();
                  } else{
                    _handleChangeStep(2);
                  }
                }}
              />
            );
          case 4:
            return (
              <Portateis.Step4
                onComplete={(data) => {
                  if(Object.keys(user).length !== 0){
                    _handleCompletePortateis(data);
                  } else{
                    _handleChangeStepPortateis(data);
                  }
                }}
                dataAquisicaoAcessorio={dataAquisicaoAcessorio}
                setDataAquisicaoAcessorio={setDataAquisicaoAcessorio}
                valorAquisicaoAcessorio={valorAquisicaoAcessorio}
                setValorAquisicaoAcessorio={setValorAquisicaoAcessorio}
              />
            );
          case 5:
            return (
              <Residencia.PersonalData
              onNext={(data) => {
                const send = {
                  quotation: portateis,
                  userData: data
                }
                handleNewUserWithCotationGadgets(send);
              }}
              onCancel={(data) => {
                const send = {
                  ...portateis,
                  userData: data
                }
                handleNewLeadWithParams( "portableQuotes", send);
                history.push("/");
              }}
              onGetErrorFromQuiz={() => handleErrorOnGetInfo(-1)}
              setNome={setNome}
              nome={nome}
              setEmail={setEmail}
              email={email}
              sexo={sexo}
              setSexo={setSexo}
              cpfCnpj={cpfCnpj}
              setCpfCnpj={setCpfCnpj}
              dataNascimento={dataNascimento}
              setDataNascimento={setDataNascimento}
              CellPhoneNumber={CellPhoneNumber}
              setCellPhoneNumber={setCellPhoneNumber}
              maritalStateLoading={maritalStateLoading}
              maritalStateList={maritalStateList}
              setMaritalStatus={setMaritalStatus}
              MaritalStatus={MaritalStatus}
              getMaritalStateInfo={getMaritalStateInfo}
              onFailBirthDayDate={() =>
                showToast(
                  "warning",
                  "Alerta",
                  "Cadastro permitido somente para idades iguais ou superiores a 18 anos.",
                )
              }
              onFailName={(option) => {
                if (option === 1) {
                  showToast(
                    "warning",
                    "Alerta",
                    "É preciso preencher nome e sobrenome.",
                  );
                } else {
                  showToast(
                    "warning",
                    "Alerta",
                    "Nome e sobrenome precisam ter no mínimo 2 caracteres cada.",
                  );
                }
              }}
                _handleVerificarCPF={_handleVerificarCPF}
              />
            );
          default:
            break;
        }
        break;

      case "residencial":
        switch (step) {
          case 1:
            return (
              <Coverages
                onComplete={(coverageId) => {
                  if(Object.keys(user).length !== 0) {
                    setResidence({ ...residence, coverageId });
                    updateLead({ data: { coverageId }, leadId });
                    _handleChangeStep();
                  } else {
                    setResidence({ ...residence, coverageId });
                    _handleChangeStep(2);
                  }
                }}
                onIncomplete={_handleIncomplete}
                selectedCoverage={selectedCoverage}
                setSelectedCoverage={setSelectedCoverage}
                coverage={residenceCoverage}
                onGetCoverage={onGetCoveragesFromResidence}
                coverageLoading={residenceCoverageLoading}
                benefitsCoverage={benefitsCoverageResidence}
              />
            );
          case 2:
            return (
              <Residencia.AddressConfirm
                getResidences={getResidences}
                userResidences={userResidences}
                removeResidence={removeResidence}
                userResidencesLoading={userResidencesLoading}
                onNextMyHouse={(residenceId) => {
                  setResidence({ ...residence, residenceId });
                  updateLead({ data: { residenceId }, leadId });
                  _handleChangeStep(2);
                }}
                onNextOtherHouse={(data) => {
                  _handleChangeStep();
                }}
                onFail={() => {
                  showToast(
                    "warning",
                    "Alerta",
                    "Selecione um endereço para continuar.",
                  );
                }}
                selectedAddress={selectedAddress}
                setSelectedAddress={setSelectedAddress}
              />
            );
          case 3:
            return (
              <Residencia.RegisterMyLocation
                endereco={endereco}
                getEndereco={_handleGetCep}
                onNext={(enderecoData) => {
                  if(Object.keys(user).length !== 0){
                    handleAddNewResidence(enderecoData); 
                  } else {
                    setResidence(
                      {
                        ...residence,
                        userData: {
                          residence: {
                            CityName: enderecoData.cidade,
                            DistrictName: enderecoData.bairro,
                            StateCode: enderecoData.estado,
                            StreetExtraInformation: enderecoData.complemento,
                            StreetName: enderecoData.logradouro,
                            StreetNumber: enderecoData.numero,
                            StreetType: enderecoData.tipo,
                            ZipCode: enderecoData.cep
                          } 
                        }
                      }
                    );
                    _handleChangeStep();
                  } 
                }}
                setEndereco={setEndereco}
              />
            );
          case 4:
            return (
              <Residencia.ResidenceInfo
                onNext={(data) => {
                  setResidence({ ...residence, ...data });
                  if(Object.keys(user).length !== 0) updateLead({ data: { ResidenceInfo: data }, leadId })
                  _handleChangeStep();
                }}
                PropertyType={PropertyType}
                setPropertyType={setPropertyType}
                ConstructionType={ConstructionType}
                setConstructionType={setConstructionType}
                OccupanceType={OccupanceType}
                setOcupationType={setOcupationType}
                RobberyProtectiveSystem={RobberyProtectiveSystem}
                setRobberyProtectiveSystem={setRobberyProtectiveSystem}
                TumbledHomeOwners={TumbledHomeOwners}
                setTumbledHomeOwners={setTumbledHomeOwners}
                HasAnotherInsurance={HasAnotherInsurance}
                setHasAnotherInsurance={setHasAnotherInsurance}
                ResidenceHasWoodOutsideWalls={ResidenceHasWoodOutsideWalls}
                setResidenceHasWoodOutsideWalls={
                  setResidenceHasWoodOutsideWalls
                }
                ResidenceOwnerIsPolicyHolder={ResidenceOwnerIsPolicyHolder}
                setResidenceOwnerIsPolicyHolder={
                  setResidenceOwnerIsPolicyHolder
                }
                ResidenceUnoccupiedUnderRenovationOrConstruction={
                  ResidenceUnoccupiedUnderRenovationOrConstruction
                }
                setResidenceUnoccupiedUnderRenovationOrConstruction={
                  setResidenceUnoccupiedUnderRenovationOrConstruction
                }
                TypeRegionLocation={TypeRegionLocation}
                setTypeRegionLocation={setTypeRegionLocation}
              />
            );
          case 5:
            return (
              <Residencia.ValidityDate
                onNext={(data) => {
                  const send = { ...residence, ...data };
                  setResidence(send);
                  if(Object.keys(user).length !== 0){
                    updateLead({ data: { ValidityDate: data }, leadId });
                    _handleCompleteResidence(send);
                  } else {
                    _handleChangeStep();
                  }
                }}
                onIncomplete={_handleIncomplete}
                startDateResidence={startDateResidence}
                setStartDateResidence={setStartDateResidence}
                endDateResidence={endDateResidence}
                setEndDateResidence={setEndDateResidence}
                ProfessionalActivityType={ProfessionalActivityType}
                setProfessionalActivityType={setProfessionalActivityType}
              />
              );
          case 6:
            return (
              <Residencia.PersonalData
                onNext={(data) => {
                  const send = {
                    ...residence
                  }
                  send.userData = {
                    ...residence.userData,
                    ...data,
                  }
                  setResidence(send);
                  handleNewUserWithCotationResidence(send);
               }}
                onCancel={(data) => {
                  const send = {
                    ...residence
                  }
                  send.userData = {
                    ...residence.userData,
                    ...data,
                  }
                  setResidence(send);
                  handleNewLeadWithParams( "residenceQuotes", send);
                  history.push("/");
                }}
                onGetErrorFromQuiz={() => handleErrorOnGetInfo(-1)}
                setNome={setNome}
                nome={nome}
                setEmail={setEmail}
                email={email}
                sexo={sexo}
                setSexo={setSexo}
                cpfCnpj={cpfCnpj}
                setCpfCnpj={setCpfCnpj}
                dataNascimento={dataNascimento}
                setDataNascimento={setDataNascimento}
                CellPhoneNumber={CellPhoneNumber}
                setCellPhoneNumber={setCellPhoneNumber}
                maritalStateLoading={maritalStateLoading}
                maritalStateList={maritalStateList}
                setMaritalStatus={setMaritalStatus}
                MaritalStatus={MaritalStatus}
                getMaritalStateInfo={getMaritalStateInfo}
                onFailBirthDayDate={() =>
                  showToast(
                    "warning",
                    "Alerta",
                    "Cadastro permitido somente para idades iguais ou superiores a 18 anos.",
                  )
                }
                onFailName={(option) => {
                  if (option === 1) {
                    showToast(
                      "warning",
                      "Alerta",
                      "É preciso preencher nome e sobrenome.",
                    );
                  } else {
                    showToast(
                      "warning",
                      "Alerta",
                      "Nome e sobrenome precisam ter no mínimo 2 caracteres cada.",
                    );
                  }
                }}
                _handleVerificarCPF={_handleVerificarCPF}
            />
          );
          case 7:
            return (
              <PaymentTypes
                {...valuesQuotationMessages}
                onRedirectError={() => {
                  history.push("minhaconta");
                }}
                onBackToHome={() => {
                  showModal({ visible: true, type: "confirmEndQuotation" });
                }}
                onComplete={(selectedPaymentMethod) => {
                  setPaymentMethod(selectedPaymentMethod)
                  _handleChangeStep()
                }
                }
                onIncomplete={() => {
                  showToast(
                    "warning",
                    "Alerta",
                    "Selecione uma forma de pagamento para continuar.",
                  );
                }}
                quoteId={quoteId}
                paymentContext="residence"
                values={valueQuotationData.values}
                paymentTypes={valueQuotationData.paymentTypes}
                statusErrorPaymentQuotation={statusErrorPaymentQuotation}
                isOnFlow
              />
            );
          case 8:
            return (
              <Residencia.ResidenceBeneficiaryInfo
                onMountBeneficiaryInfo={onMountBeneficiaryInfo}
                maritalStateList={maritalStateList}
                maritalStateLoading={maritalStateLoading}
                generalRegistryList={generalRegistryList}
                generalRegistryLoading={generalRegistryLoading}
                onComplete={(data) => {
                  const additionalInformationData = {
                    PurchaseParameters: {
                      PolicyHolder: {
                        RangeMonthlyIncomeCode: data.RangeMonthlyIncomeCode,
                        OccupationCode: data.OccupationCode,
                        IndNumIssuerState: data.IndNumIssuerState,
                        IndNumIssuer: data.IndNumIssuer,
                        IndNumIssuanceDate: data.IndNumIssuanceDate,
                        IndividualNumber: data.IndividualNumber,
                        PoliticallyExposedPerson: data.PoliticallyExposedPerson,
                        Gender: data.Gender,
                        MaritalStatus: data.MaritalStatus
                      }
                    }
                  };
                  handlePaymentMethod(paymentMethod, additionalInformationData)
                }}
                IndividualNumber={IndividualNumber}
                setIndividualNumber={setIndividualNumber}
                IndNumIssuanceDate={IndNumIssuanceDate}
                setIndNumIssuanceDate={setIndNumIssuanceDate}
                IndNumIssuer={IndNumIssuer}
                setIndNumIssuer={setIndNumIssuer}
                IndNumIssuerState={IndNumIssuerState}
                setIndNumIssuerState={setIndNumIssuerState}
                MaritalStatus={MaritalStatus}
                setMaritalStatus={setMaritalStatus}
                roles={roles}
                OccupationCode={OccupationCode}
                setOccupationCode={setOccupationCode}
                rangePayments={rangePayments}
                RangeMonthlyIncomeCode={RangeMonthlyIncomeCode}
                setRangeMonthlyIncomeCode={setRangeMonthlyIncomeCode}
                Gender={Gender}
                setGender={setGender}
                PoliticallyExposedPerson={PoliticallyExposedPerson}
                setPoliticallyExposedPerson={setPoliticallyExposedPerson}
              />
            );
          default:
            break;
        }
        break;

      case "viagem":
        switch (step) {
          case 1:
            return (
              <Viagem.Step1
                handleFetchItems={handleFetchItems}
                onComplete={ (data) => {
                  if(Object.keys(user).length !== 0) {
                    _handleCompleteViagem(data);
                  } else {
                    setViagem(data)
                    _handleChangeStep();
                  }
                }}
                listTripTypes={listTripTypes}
                listContinent={listContinent}
              />
            );
          case 2:
            return (
              <Residencia.PersonalData
              onNext={(data) => {
                const send = {
                  quotation: viagem,
                  userData: data
                }
                handleNewUserWithCotationTravel(send);
              }}
              onCancel={(data) => {
                const send = {
                  ...viagem,
                  userData: data
                }
                handleNewLeadWithParams( "travelQuotes", send);
                history.push("/");
              }}
              onGetErrorFromQuiz={() => handleErrorOnGetInfo(-1)}
              setNome={setNome}
              nome={nome}
              setEmail={setEmail}
              email={email}
              sexo={sexo}
              setSexo={setSexo}
              cpfCnpj={cpfCnpj}
              setCpfCnpj={setCpfCnpj}
              dataNascimento={dataNascimento}
              setDataNascimento={setDataNascimento}
              CellPhoneNumber={CellPhoneNumber}
              setCellPhoneNumber={setCellPhoneNumber}
              maritalStateLoading={maritalStateLoading}
              maritalStateList={maritalStateList}
              setMaritalStatus={setMaritalStatus}
              MaritalStatus={MaritalStatus}
              getMaritalStateInfo={getMaritalStateInfo}
              onFailBirthDayDate={() =>
                showToast(
                  "warning",
                  "Alerta",
                  "Cadastro permitido somente para idades iguais ou superiores a 18 anos.",
                )
              }
              onFailName={(option) => {
                if (option === 1) {
                  showToast(
                    "warning",
                    "Alerta",
                    "É preciso preencher nome e sobrenome.",
                  );
                } else {
                  showToast(
                    "warning",
                    "Alerta",
                    "Nome e sobrenome precisam ter no mínimo 2 caracteres cada.",
                  );
                }
              }}
                _handleVerificarCPF={_handleVerificarCPF}
              />
            );
          default:
            break;
        }
        break;

      case "vida":
        switch (step) {
          case 1:
            return (
              <Vida.Step1
                handleResetLifeInsuranceFields={handleResetLifeInsuranceFields}
                onYes={() => {
                  if(Object.keys(user).length !== 0) {
                    handleStepLifeForUser();
                  } else {
                    _handleChangeStep(2);
                  }
                }}
                onNo={() => _handleChangeStep(1)}
              />
            );
          case 2:
            return (
              <Vida.Step2
                onComplete={() => setStep(4)}
                dataNascimentoDestino={dataNascimentoDestino}
                setDataNascimentoDestino={setDataNascimentoDestino}
                cpfCnpjDestino={cpfCnpjDestino}
                setCpfCnpjDestino={setCpfCnpjDestino}
                sexoDestino={sexoDestino}
                setSexoDestino={setSexoDestino}
                type={typePerson}
                setType={setTypePerson}
                rendaMensal={rendaMensal}
                setRendaMensal={setRendaMensal}
                setQuantidadeDependentes={setQuantidadeDependentes}
                quantidadeDependentes={quantidadeDependentes}
                telefone={telefone}
                setTelefone={setTelefone}
                nomeDestino={nomeDestino}
                setNomeDestino={setNomeDestino}
                sexoConjuge={sexoConjuge}
                setSexoConjuge={setSexoConjuge}
                fumante={fumante}
                setFumante={setFumante}
                estadoCivil={estadoCivil}
                setEstadoCivil={setEstadoCivil}
                historicoDoencasGraves={historicoDoencasGraves}
                setHistoricoDoencasGraves={setHistoricoDoencasGraves}
              />
            );
          case 3:
            return (
              <Vida.Step3
                onComplete={() => {
                  if(Object.keys(user).length !== 0) {
                    _handleCompleteVida();
                  } else {
                    _handleChangeStep();
                  }
                }}
                rendaMensal={rendaMensal}
                setRendaMensal={setRendaMensal}
                type={typePerson}
                setType={setTypePerson}
                telefone={telefone}
                setTelefone={setTelefone}
                setQuantidadeDependentes={setQuantidadeDependentes}
                quantidadeDependentes={quantidadeDependentes}
                sexoConjuge={sexoConjuge}
                setSexoConjuge={setSexoConjuge}
                cpfCnpj={cpfCnpj}
                setCpfCnpj={setCpfCnpj}
                fumante={fumante}
                setFumante={setFumante}
                estadoCivil={estadoCivil}
                setEstadoCivil={setEstadoCivil}
                historicoDoencasGraves={historicoDoencasGraves}
                setHistoricoDoencasGraves={setHistoricoDoencasGraves}
              />
            );
          case 4:
            return (
              <Residencia.RegisterMyLocation
                endereco={endereco}
                getEndereco={_handleGetCep}
                onNext={(data) => {
                  if(Object.keys(user).length !== 0) {
                    handleAddResidenciaVida(data);
                  } else {
                    setEndereco({
                      ZipCode: data.cep,
                      StreetType: data.tipo,
                      StreetName: data.logradouro,
                      StreetNumber: data.numero,
                      StreetExtraInformation: data.complemento,
                      DistrictName: data.bairro,
                      CityName: data.cidade,
                      StateCode: data.estado,
                    });
                    _handleChangeStep();
                  }
                }}
                setEndereco={setEndereco}
              />
            );
          case 5:
            return (
              <Residencia.PersonalData
              onNext={(data) => {
                const splitedTelefone = telefone.split(" ");
                const dataLife = {
                  type: typePerson,
                  nome: typePerson === 0 ? nome: nomeDestino,
                  rendaMensal,
                  quantidadeDependentes,
                  cpfCnpj: typePerson === 0 ? cpfCnpj : cpfCnpjDestino,
                  dataNascimento:
                    typePerson === 0
                      ? moment(dataNascimento, "DD/MM/YYYY").format("YYYY-MM-DD")
                      : moment(dataNascimentoDestino, "DD/MM/YYYY").format("YYYY-MM-DD"),
                  sexo: typePerson === 0 ? sexo : sexoDestino,
                  ddd: telefone? splitedTelefone[0] : data.ddd,
                  telefone: telefone? splitedTelefone[1] : data.telefone,
                  estadoCivil,
                  sexoConjuge,
                  fumante,
                  historicoDoencasGraves,
                  residence: endereco,
                };
                const send = {
                  quotation: dataLife,
                  userData: data
                }
                handleNewUserWithCotationLife(send);
              }}
              onCancel={(data) => {
                const splitedTelefone = telefone.split(" ");
                const send = {
                  type: typePerson,
                  nome: typePerson === 0 ? nome: nomeDestino,
                  rendaMensal,
                  quantidadeDependentes,
                  cpfCnpj: typePerson === 0 ? cpfCnpj : cpfCnpjDestino,
                  dataNascimento:
                    typePerson === 0
                      ? moment(dataNascimento, "DD/MM/YYYY").format("YYYY-MM-DD")
                      : moment(dataNascimentoDestino, "DD/MM/YYYY").format("YYYY-MM-DD"),
                  sexo: typePerson === 0 ? sexo : sexoDestino,
                  ddd: telefone? splitedTelefone[0] : data.ddd,
                  telefone: telefone? splitedTelefone[1] : data.telefone,
                  estadoCivil,
                  sexoConjuge,
                  fumante,
                  historicoDoencasGraves,
                  residence: endereco,
                  userData: data
                }
                handleNewLeadWithParams( "lifeQuotes", send);
                history.push("/");
              }}
              onGetErrorFromQuiz={() => handleErrorOnGetInfo(-1)}
              setNome={setNome}
              nome={nome}
              setEmail={setEmail}
              email={email}
              sexo={sexo}
              setSexo={setSexo}
              cpfCnpj={cpfCnpj}
              setCpfCnpj={setCpfCnpj}
              dataNascimento={dataNascimento}
              setDataNascimento={setDataNascimento}
              CellPhoneNumber={CellPhoneNumber}
              setCellPhoneNumber={setCellPhoneNumber}
              maritalStateLoading={maritalStateLoading}
              maritalStateList={maritalStateList}
              setMaritalStatus={setMaritalStatus}
              MaritalStatus={MaritalStatus}
              getMaritalStateInfo={getMaritalStateInfo}
              onFailBirthDayDate={() =>
                showToast(
                  "warning",
                  "Alerta",
                  "Cadastro permitido somente para idades iguais ou superiores a 18 anos.",
                )
              }
              onFailName={(option) => {
                if (option === 1) {
                  showToast(
                    "warning",
                    "Alerta",
                    "É preciso preencher nome e sobrenome.",
                  );
                } else {
                  showToast(
                    "warning",
                    "Alerta",
                    "Nome e sobrenome precisam ter no mínimo 2 caracteres cada.",
                  );
                }
              }}
                _handleVerificarCPF={_handleVerificarCPF}
              />
            );
          default:
            break;
        }
        break;

      case "bike":
        switch (step) {
          case 1:
            return (
              <Bike.Step1
                onComplete={ (data) => {
                    if(Object.keys(user).length !== 0) {
                      _handleCompleteBike()
                    } else {
                      setBike(data)
                      _handleChangeStep();
                    }
                  }
                }
                marcaBike={marcaBike}
                setMarcaBike={setMarcaBike}
                valorAquisicao={valorAquisicaoBike}
                setValorAquisicao={setValorAquisicaoBike}
                dataAquisicao={dataAquisicaoBike}
                setDataAquisicao={setDataAquisicaoBike}
                garagemBike={garagemBike}
                setGaragemBike={setGaragemBike}
                bikeUtilizacao={bikeUtilizacao}
                setBikeUtilizacao={setBikeUtilizacao}
                setSaberSobreCoberturas={setSaberSobreCoberturas}
                saberSobreCoberturas={saberSobreCoberturas}
              />
            );
          case 2:
            return (
              <Residencia.PersonalData
              onNext={(data) => {
                const dataBike = {
                  ...bike
                };
                dataBike.dataAquisicaoBike = moment(dataBike.dataAquisicaoBike, "DD/MM/YYYY").format(
                  "YYYY-MM-DD",
                );
                setBike(dataBike);
                const send = {
                  quotation: dataBike,
                  userData: data
                }
                handleNewUserWithCotationBike(send);
              }}
              onCancel={(data) => {
                const send = {
                  ...bike,
                  userData: data
                }
                handleNewLeadWithParams( "bikeQuotes", send);
                history.push("/");
              }}
              onGetErrorFromQuiz={() => handleErrorOnGetInfo(-1)}
              setNome={setNome}
              nome={nome}
              setEmail={setEmail}
              email={email}
              sexo={sexo}
              setSexo={setSexo}
              cpfCnpj={cpfCnpj}
              setCpfCnpj={setCpfCnpj}
              dataNascimento={dataNascimento}
              setDataNascimento={setDataNascimento}
              CellPhoneNumber={CellPhoneNumber}
              setCellPhoneNumber={setCellPhoneNumber}
              maritalStateLoading={maritalStateLoading}
              maritalStateList={maritalStateList}
              setMaritalStatus={setMaritalStatus}
              MaritalStatus={MaritalStatus}
              getMaritalStateInfo={getMaritalStateInfo}
              onFailBirthDayDate={() =>
                showToast(
                  "warning",
                  "Alerta",
                  "Cadastro permitido somente para idades iguais ou superiores a 18 anos.",
                )
              }
              onFailName={(option) => {
                if (option === 1) {
                  showToast(
                    "warning",
                    "Alerta",
                    "É preciso preencher nome e sobrenome.",
                  );
                } else {
                  showToast(
                    "warning",
                    "Alerta",
                    "Nome e sobrenome precisam ter no mínimo 2 caracteres cada.",
                  );
                }
              }}
                _handleVerificarCPF={_handleVerificarCPF}
              />
            );
          default:
            break;
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      {renderHeaderStepOther()}
      <Box
        gridTemplateColumns="repeat(12,1fr)"
        gridRowGap="1.5rem"
        minHeight="100vh"
        position="relative"
        styling="grid"
      >
        {renderType(type)}
      </Box>
    </>
  );
}

const mapStateToProps = (state) => ({
  listContinent: state.form.continents.list,
  listTripTypes: state.form.tripTypes.list,
  portableTypes: state.form.portableTypes.list,
  user: state.user.user,
  userResidences: state.user.residences.list,
  userResidencesLoading: state.user.residences.isLoading,
  roles: state.form.roles.list,
  rangePayments: state.form.rangePayments.list,
  residenceCoverage: state.form.residenceCoverage.list,
  residenceCoverageLoading: state.form.residenceCoverage.isLoading,
  autoCoverage: state.form.autoCoverage.list,
  autoCoverageLoading: state.form.autoCoverage.isLoading,
  maritalStateList: state.form.maritalState.list,
  maritalStateLoading: state.form.maritalState.isLoading,
  generalRegistryList: state.form.generalRegistryState.list,
  generalRegistryLoading: state.form.generalRegistryState.isLoading,
  myVehiclesList: state.user.myVehicles.list,
  myVehiclesLoading: state.user.myVehicles.isLoading,
  autoInsuranceTypeList: state.form.autoInsuranceTypes.list,
  autoInsuranceTypeLoading: state.form.autoInsuranceTypes.isLoading,
  referencia: state.user.referencia.reference,
  vehicleBrands: state.user.brands.list,
  vehicleModels: state.user.formVehicles.models,
  vehicleYearModel: state.user.formVehicles.modelYear,
  selectedVehicle: state.user.formVehicles.selectedVehicle,
});

const mapDispatchToProps = {
  getListRaces: formService.getListRaces,
  createPets: formService.setPets,
  showModal: userService.showModal,
  getListTiposPortateis: formService.getListTiposPortateis,
  getListMarcasPortateis: formService.getListMarcasPortateis,
  getListModelosPortateis: formService.getListModelosPortateis,
  createPortateis: formService.createPortateis,
  createVida: formService.createVida,
  createAuto: formService.createAuto,
  getListContinent: formService.getListContinent,
  createViagem: formService.createViagem,
  createBike: formService.createBike,
  authUser: userService.authUser,
  getCEP: userService.getCEP,

  createResidence: formService.setResidence,
  userUpdate: userService.userUpdate,
  getResidences: userService.getResidences,
  addResidence: userService.addResidence,
  removeResidence: userService.removeResidence,
  getListFormasDeViagem: formService.getListFormasDeViagem,
  getRoles: formService.getRoles,
  getRangeOfPayment: formService.getRangeOfPayment,
  getResidenceCoverage: formService.getResidenceCoverage,
  getMaritalState: formService.getMaritalState,
  getGeneralRegistryState: formService.getGeneralRegistryState,
  buyInsuranceResidence: formService.buyInsuranceResidence,
  getVehicles: userService.getVehicles,
  removeVehicle: userService.removeVehicle,
  getAutoInsuranceTypes: formService.getAutoInsuranceTypes,
  getVehiclesCoverages: formService.getVehiclesCoverages,
  registerVehicles: userService.registerVehicles,
  buyInsuranceVehicle: formService.buyInsuranceVehicle,
  newLead: formService.newLead,
  updateLead: formService.updateLead,
  getVehicleBrands: userService.getVehicleBrands,
  getVehiclesFromBrand: userService.getVehiclesFromBrand,
  getVehicleModelYear: userService.getVehicleModelYear,
  getVehicleInfo: userService.getVehicleInfo,
  verificarCpf: userService.verificarCpf,
  openVehicleQuotation: formService.openVehicleQuotation,
  openResidenceQuotation: formService.openResidenceQuotation,
  openGadgetsQuotation: formService.openGadgetsQuotation,
  openTravelQuotation: formService.openTravelQuotation,
  openBikeQuotation: formService.openBikeQuotation,
  openPetQuotation: formService.openPetQuotation,
  openLifeQuotation: formService.openLifeQuotation
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(InjectImages(Form));
