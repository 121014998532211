import createSelector from 'selectorator';

export const getLocale = createSelector(
  ['config.contractor', 'config.locales'],
  (contractor, locales) => locales[contractor] || {}
);

export const getClient = createSelector(
  ['config.contractor', 'config.clients'],
  (contractor, clients) => clients[contractor] || {}
);

export const getImages = createSelector(
  ['config.contractor', 'config.images'],
  (contractor, images) => images[contractor] || {}
);
