import React, { Fragment } from "react";
import * as userService from "../../state/context/user/services";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";

import Header from "../../components/header";
import Footer from "../../components/footer";
import Modal from "../util/modal";
import Loading from "../util/loading";

const Main = ({ children, showModal, history, logoutUser, user }) => {
  const handleLogout = () => {
    logoutUser()
    sessionStorage.clear()
    localStorage.clear()
    history.push("/#")
  }

  return (
    <Fragment>
      <Modal />
      <Loading />
      <Header
        handleOpenModal={() => showModal({ visible: true, type: "login" })}
        handleSair={handleLogout}
      />
      {children}
      <Footer />
    </Fragment>
  );
};

const mapStateToProps = state => ({
  user: state.user.user
});

const mapDispatchToProps = {
  showModal: userService.showModal,
  logoutUser: userService.logoutUser
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Main);
