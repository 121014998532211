/* eslint-disable react-hooks/exhaustive-deps */
/* eslint react-hooks/rules-of-hooks: off */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";

import {
  validateVerify,
  keepOnlyNumbers,
  autoCorrectedDatePipe,
  checkLengthOfDate,
} from "../../../utils/validations";
import { fieldNotFilledAlert, dateMask, showToast } from "../../../utils";
import { getVehicleQuestionnaireStep1 } from "../../../state/context/form/services";
import { Box, Button } from "../../../components/ds/SubatomicParticles";
import { H3 } from "../../../components/ds/theme/typography";
import FormSelect from "../../../components/FormSelect";
import FormInput from "../../../components/FormInput";
import TextMaskFormInput from "../../../components/TextMaskFormInput";
import Checkbox from "../../../components/ds/atoms/Checkbox";

function step1({
  onNext,
  DealerExitDateTime,
  setDealerExitDateTime,
  HasLoJack,
  setHasLoJack,
  ZipCode,
  setZipCode,
  CategoryCode,
  setCategoryCode,
  UsageCode,
  setUsageCode,
  Is0KM,
  setIs0KM,
  StartDate,
  setStartDate,
  EndDate,
  setEndDate,
  getQuestionnaireStep1,
  onGetErrorFromQuiz,
  LoJackCode,
  setLoJackCode,
  IsDriver,
  setIsDriver,
  DaysPerWeek,
  setDaysPerWeek,
  MaritalStatus,
  setMaritalStatus,
  maritalStateList,
  maritalStateLoading,
  onMountBeneficiaryInfo,
  user
}) {
  const questionnaireTypes = ["categoria", "utilizacao", "antifurto"];
  const [questionnaireStep1, setQuestionnaireStep1] = useState({});

  useEffect(() => {
    const fetch = async () => {
      try {
        let data = await getQuestionnaireStep1(questionnaireTypes);
        setQuestionnaireStep1(data);
        onMountBeneficiaryInfo();

      } catch (e) {
        onGetErrorFromQuiz();
      }
    };
    fetch();
  }, []);

  const _handleIncomplete = () => {
    if (!StartDate || checkLengthOfDate(StartDate) !== 8)
      fieldNotFilledAlert("Início da vigência");
    if (!EndDate || checkLengthOfDate(EndDate) !== 8)
      fieldNotFilledAlert("Fim da vigência");
    if (
      Is0KM === "T" &&
      (!DealerExitDateTime || checkLengthOfDate(DealerExitDateTime) !== 8)
    )
      fieldNotFilledAlert("Data/Hora de saída da concessionária");
    if (!ZipCode) fieldNotFilledAlert("CEP de pernoite (5 primeiros números)");
    if (!CategoryCode) fieldNotFilledAlert("Categoria Tarifária");
    if (!UsageCode) fieldNotFilledAlert("Utilização");
    if (HasLoJack === "T" && !LoJackCode)
      fieldNotFilledAlert("Dispositivo Antifurto");
    if (!IsDriver) fieldNotFilledAlert("Segurado dirige veículo semanalmente?");
    if (!DaysPerWeek)
    fieldNotFilledAlert(
      "Quantidade de dias (na semana) que veículo é conduzido",
    );
    if ( Object.keys(user).length !== 0 && (!MaritalStatus || MaritalStatus === "0"))
      fieldNotFilledAlert("Situação Civil");
  };

  const _handleVerify = () => {
    if (
      !StartDate ||
      checkLengthOfDate(StartDate) !== 8 ||
      !EndDate ||
      checkLengthOfDate(EndDate) !== 8 ||
      (Is0KM === "T" &&
        (!DealerExitDateTime || checkLengthOfDate(DealerExitDateTime) !== 8)) ||
      (HasLoJack === "T" && !LoJackCode) ||
      !ZipCode ||
      !CategoryCode ||
      !UsageCode ||
      !IsDriver ||
      !DaysPerWeek ||
      (Object.keys(user).length !== 0 && !MaritalStatus)
    ) {
      _handleIncomplete();
    } else {
      onNext({
        validity: {
          StartDate: moment(StartDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
          EndDate: moment(EndDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
        },
        vehicleInfo: {
          Is0KM,
          DealerExitDateTime:
            Is0KM === "T"
              ? `${moment(DealerExitDateTime, "DD/MM/YYYY").format(
                  "YYYY-MM-DD",
                )} ${moment(DealerExitDateTime, "DD/MM/YYYY")
                  .toISOString()
                  .substring(11, 16)}`
              : "",
          HasLoJack,
          LoJackCode: HasLoJack === "T" ? LoJackCode : "",
          ZipCode,
          CategoryCode,
          UsageCode
        },
        IsDriver,
        DaysPerWeek,
        MaritalStatus
      });
    }
  };

  const checkStartDateIsGTOrETEndDate = (startDate, endDate) =>
    moment(startDate, "DD/MM/YYYY").format("YYYYMMDD") >
    moment(endDate, "DD/MM/YYYY").format("YYYYMMDD");

  function handleDateValidation(ida, volta) {
    if (checkStartDateIsGTOrETEndDate(ida, volta)) {
      showToast(
        "warning",
        "Alerta",
        `A data da ida não pode ser maior que a data do retorno.`,
      );
      return setEndDate("");
    }
  }

  const handleValidationOnRange = (date) => {
    if (
      moment(StartDate, "DD/MM/YYYY").add("day", 365).format("YYYYMMDD") >=
      moment(date, "DD/MM/YYYY").format("YYYYMMDD")
    ) {
      showToast("warning", "Alerta", "Período minimo de vigência: 1 ano.");
      return setEndDate("");
    }
  };

  return (
    <>
      <Box
        gridColumn={{
          mobile: "2/span 10",
          tablet: "2/span 10",
          desktop: "4/span 6",
        }}
        styling="column"
      >
        <H3 mb="30px" styling="light">
          Informações gerais do seguro/veículo
        </H3>

        <Box display="flex" width={"100%"}>
          <Box mr={"3rem"} width="100%">
            <TextMaskFormInput
              value={StartDate}
              onChange={(e) => {
                setStartDate(e.target.value);
                setEndDate(
                  moment(e.target.value, "DD/MM/YYYY")
                    .add("year", 1)
                    .format("DD/MM/YYYY"),
                );
              }}
              mask={dateMask}
              pipe={(date) => autoCorrectedDatePipe(date, true, false)}
              placeholder="Início da vigência"
              onBlur={(e) => validateVerify(e)}
            />
          </Box>
          <Box width="100%">
            <TextMaskFormInput
              value={EndDate}
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
              mask={dateMask}
              pipe={(date) => autoCorrectedDatePipe(date, true, false)}
              placeholder="Fim da vigência"
              onBlur={(e) =>
                validateVerify(e) ||
                handleDateValidation(StartDate, EndDate) ||
                handleValidationOnRange(e.target.value)
              }
            />
          </Box>
        </Box>
        <Box display="flex" width={"100%"} flexDirection="column">
          <Box mb="15px" display="flex">
            <Checkbox
              label="O Automóvel é 0 Km?"
              id="Is0KM"
              checked={Is0KM === "T"}
              onChange={(e) => {
                setIs0KM(e.target.checked ? "T" : "F");
              }}
              style={{ width: "100%" }}
            />
          </Box>

          {Is0KM === "T" && (
            <>
              <TextMaskFormInput
                value={DealerExitDateTime}
                onChange={(e) => {
                  setDealerExitDateTime(e.target.value);
                }}
                mask={dateMask}
                pipe={(date) => autoCorrectedDatePipe(date, false)}
                placeholder="Data de saída da concessionária"
                onBlur={(e) => validateVerify(e)}
              />
            </>
          )}
          <Box mb="15px" display="flex">
            <Checkbox
              id="HasLoJack"
              checked={HasLoJack === "T"}
              onChange={(e) => setHasLoJack(e.target.checked ? "T" : "F")}
              type="checkbox"
              className="input-checkbox"
              label="O veículo possui dispositivo anti furto instalado pela Companhia?"
            />
          </Box>
          {HasLoJack === "T" && (
                <>
                <FormSelect
                    data-testid="select"
                    id="marca"
                    name="marca"
                    options={
                        questionnaireStep1.antifurto
                        ? questionnaireStep1.antifurto.map(
                            (antifurtoQuestionnarie) => ({
                            value: antifurtoQuestionnarie.COD,
                            label: antifurtoQuestionnarie.DISPOSITIVO,
                            }),
                        )
                        : []
                    }
                    defaultValue={LoJackCode}
                    onChange={(e) => {
                    setLoJackCode(e.value);
                    }}
                    onBlur={(e) => validateVerify(e)}
                    placeholder="Dispositivo Antifurto"
                    styling="base"
                />
                </>
            )}
          <FormInput
            type="text"
            maxLength="5"
            placeholder="CEP de pernoite (5 primeiros números)"
            value={ZipCode}
            onBlur={(e) => validateVerify(e)}
            onChange={(e) => {
              setZipCode(keepOnlyNumbers(e.target.value));
            }}
          />
          <FormSelect
            placeholder="Categoria Tarifária"
            defaultValue={CategoryCode}
            options={
              questionnaireStep1.categoria
                ? questionnaireStep1.categoria.map(
                    (categoriaQuestionnarie) => ({
                      value: categoriaQuestionnarie.COD,
                      label: categoriaQuestionnarie.CATEGORIA,
                    }),
                  )
                : []
            }
            onChange={(e) => {
              setCategoryCode(e.value);
            }}
          />
          <FormSelect
            placeholder="Utilização"
            defaultValue={UsageCode}
            options={
              questionnaireStep1.utilizacao
                ? questionnaireStep1.utilizacao.map(
                    (utilizacaoQuestionnarie) => ({
                      value: utilizacaoQuestionnarie.COD,
                      label: utilizacaoQuestionnarie.UTILIZACAO,
                    }),
                  )
                : []
            }
            onChange={(e) => {
              setUsageCode(e.value);
            }}
          />
          <FormSelect
            defaultValue={IsDriver}
            onChange={(e) => setIsDriver(e.value)}
            onBlur={(e) => validateVerify(e)}
            placeholder="Segurado dirige veículo semanalmente?"
            options={[
              {
                label: "Selecione",
                value: "",
              },
              {
                label: "Não",
                value: "F",
              },
              {
                label: "Sim",
                value: "T",
              },
            ]}
          />
          <FormSelect
            defaultValue={DaysPerWeek}
            onChange={(e) => setDaysPerWeek(e.value)}
            onBlur={(e) => validateVerify(e)}
            placeholder="Quantidade de dias (na semana) que veículo é conduzido"
            options={[
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7",
                value: "7",
              },
            ]}
          />
        </Box>
        { Object.keys(user).length !== 0?
          (<>
            <H3 mb="30px" styling="light">
            Informações do Segurado
            </H3>
            <Box display="flex" width={"100%"} flexDirection="column">
              <FormSelect
                  defaultValue={MaritalStatus}
                  onChange={(e) => setMaritalStatus(e.value)}
                  onBlur={(e) => validateVerify(e)}
                  placeholder="Situação Civil"
                  options={
                    Object.keys(maritalStateList).length !== 0 || maritalStateLoading
                      ? Object.keys(maritalStateList).map((item, index) => {
                          return {
                            value: item,
                            label: maritalStateList[item],
                          };
                        })
                      : [{ label: "Carregando...", value: "" }]
                  }
                />
            </Box>
          </>) 
          :
          (<></>)
        } 
        
        <Button styling="primary" onClick={_handleVerify} width="296px" mt={25}>
          Próximo
        </Button>
      </Box>
    </>
  );
}

const mapDispatch = {
  getQuestionnaireStep1: getVehicleQuestionnaireStep1,
};

export default connect(null, mapDispatch)(step1);
