import React from "react";
import css from "@styled-system/css";
import { Box } from "../ds/SubatomicParticles";
import { P } from "../ds/theme/typography";

import { ReactComponent as IconAutomovel } from "../../assets/svg/icon_auto.svg";
import { ReactComponent as IconPet } from "../../assets/svg/icon_pet.svg";
import { ReactComponent as IconResidencial } from "../../assets/svg/icon_residencia.svg";
import { ReactComponent as IconViagem } from "../../assets/svg/icon_viagem.svg";
import { ReactComponent as IconVida } from "../../assets/svg/icon_vida.svg";
import { ReactComponent as IconPortateis } from "../../assets/svg/icon_portateis.svg";
import { ReactComponent as IconBike } from "../../assets/svg/icon_bike.svg";

export default function Card({ type, title, onPress, active }) {
  const renderIcon = () => {
    switch (type) {
      case "pets":
        return <IconPet />;

      case "automovel":
        return <IconAutomovel />;

      case "residencia":
        return <IconResidencial />;

      case "viagem":
        return <IconViagem />;

      case "vida":
        return <IconVida />;

      case "portatil":
        return <IconPortateis />;

      case "bike":
        return <IconBike />;

      default:
        break;
    }
  };
  return (
    <Box
      className={`seguro ${active && "active"}`}
      height="110px"
      margin="0 10px 10px"
      onClick={onPress}
      position="relative"
      width="80px"
    >
      <Box
        backgroundColor="background.default"
        borderRadius="12px"
        boxShadow="10px 10px 28px 1px rgba(0,0,0,.1)"
        css={css({
          cursor: "pointer",
          svg: {
            height: "40px",
            width: "40px",
            "& path": {
              fill: "primary.default",
              stroke: "primary.default",
            },
          },
        })}
        height="80px"
        mb="10px"
        width="80px"
        styling="row"
      >
        {renderIcon()}
      </Box>
      <P fontSize="12px" styling="base" textAlign="center">
        {title}
      </P>
    </Box>
  );
}
