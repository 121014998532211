/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import * as userService from "../../state/context/user/services";
import * as cotacaoService from "../../state/context/cotacao/services";

import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Box } from "../../components/ds/SubatomicParticles";
import { H3, P } from "../../components/ds/theme/typography";
import Icon from "../../components/ds/atoms/Icon";
import InfoBorderBottomCard from "../../components/InfoBorderBottomCard";
import { ReactComponent as Logo } from "../../assets/svg/logo_fruta.svg";
import { ReactComponent as Back } from "../../assets/svg/back.svg";
import { ReactComponent as IconAutomovel } from "../../assets/svg/icon_auto.svg";
import { ReactComponent as IconPet } from "../../assets/svg/icon_pet.svg";
import { ReactComponent as IconResidencial } from "../../assets/svg/icon_residencia.svg";
import { ReactComponent as IconViagem } from "../../assets/svg/icon_viagem.svg";
import { ReactComponent as IconVida } from "../../assets/svg/icon_vida.svg";
import { ReactComponent as IconPortateis } from "../../assets/svg/icon_portateis.svg";
import { ReactComponent as IconBike } from "../../assets/svg/icon_bike.svg";
import InjectImages from "../Images";

const Sinistro = ({
  history,
  location,
  getInsurances,
  showModal,
  myInsurances,
  images,
}) => {
  let auxType = location.state.data;

  const [type, setType] = useState(auxType);
  const [step, setStep] = useState(1);

  useEffect(() => {
    getInsurances();
  }, []);

  const arrAuto = myInsurances.filter(
    (insurance) => insurance.tipoControle === "VEICULOS",
  );
  const arrVida = myInsurances.filter(
    (insurance) => insurance.tipoControle === "VIDA",
  );
  const arrResidencia = myInsurances.filter(
    (insurance) => insurance.tipoControle === "LOCAIS",
  );
  const arrPortateis = myInsurances.filter(
    (insurance) => insurance.tipoControle === "PORTATEIS",
  );
  const arrViagem = myInsurances.filter(
    (insurance) => insurance.tipoControle === "VIAGENS",
  );
  const arrPets = myInsurances.filter(
    (insurance) => insurance.tipoControle === "PETS",
  );
  const arrBike = myInsurances.filter(
    (insurance) => insurance.tipoControle === "BIKE",
  );

  const renderHeaderStepOther = () => {
    return (
      <Box
        styling="row"
        borderBottom="1px solid #e1e1e1"
        px="3rem"
        py="1rem"
        justifyContent="flex-start"
        width="100%"
      >
        <Icon styling="base">
          <Back
            onClick={() => {
              if (step === 1) {
                history.push("/minhaconta");
              } else {
                setType("other");
              }
              setStep(step - 1);
            }}
          />
        </Icon>
        <Box styling="row" ml="15px">
          {images?.logo?.url ? (
            <img src={images.logo.url} width={24} height={24} alt="" />
          ) : (
            <Logo />
          )}
        </Box>
      </Box>
    );
  };

  const renderList = () => {
    return (
      <>
        <Box
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "4/span 6",
          }}
          styling="column"
          py={"40px"}
        >
          <H3 mb="30px" styling="light">
            Escolha o tipo de sinistro
          </H3>

          {arrAuto.length > 0 && (
            <Box
              backgroundColor="background.default"
              borderRadius="12px"
              boxShadow="10px 10px 20px rgba(0, 0, 0, 0.05)"
              justifyContent="flex-start"
              mb="15px"
              padding="20px"
              styling="row"
              onClick={() => {
                setType("automovel");
                setStep(step + 1);
              }}
              width="100%"
            >
              <Icon styling="base" height="40px" width="40px">
                <IconAutomovel />
              </Icon>
              <P ml="15px" styling="base">
                Veículo
              </P>
            </Box>
          )}

          {arrVida.length > 0 && (
            <Box
              backgroundColor="background.default"
              borderRadius="12px"
              boxShadow="10px 10px 20px rgba(0, 0, 0, 0.05)"
              justifyContent="flex-start"
              mb="15px"
              padding="20px"
              styling="row"
              onClick={() => {
                setType("vida");
                setStep(step + 1);
              }}
              width="100%"
            >
              <Icon styling="base" height="40px" width="40px">
                <IconVida />
              </Icon>
              <P ml="15px" styling="base">
                Vida
              </P>
            </Box>
          )}

          {arrResidencia.length > 0 && (
            <Box
              backgroundColor="background.default"
              borderRadius="12px"
              boxShadow="10px 10px 20px rgba(0, 0, 0, 0.05)"
              justifyContent="flex-start"
              mb="15px"
              padding="20px"
              styling="row"
              onClick={() => {
                setType("residencia");
                setStep(step + 1);
              }}
              width="100%"
            >
              <Icon styling="base" height="40px" width="40px">
                <IconResidencial />
              </Icon>
              <P ml="15px" styling="base">
                Residência
              </P>
            </Box>
          )}

          {arrViagem.length > 0 && (
            <Box
              backgroundColor="background.default"
              borderRadius="12px"
              boxShadow="10px 10px 20px rgba(0, 0, 0, 0.05)"
              justifyContent="flex-start"
              mb="15px"
              padding="20px"
              styling="row"
              onClick={() => {
                setType("viagem");
                setStep(step + 1);
              }}
              width="100%"
            >
              <Icon styling="base" height="40px" width="40px">
                <IconViagem />
              </Icon>
              <P ml="15px" styling="base">
                Viagem
              </P>
            </Box>
          )}

          {arrPortateis.length > 0 && (
            <Box
              backgroundColor="background.default"
              borderRadius="12px"
              boxShadow="10px 10px 20px rgba(0, 0, 0, 0.05)"
              justifyContent="flex-start"
              mb="15px"
              padding="20px"
              styling="row"
              onClick={() => {
                setType("portateis");
                setStep(step + 1);
              }}
              width="100%"
            >
              <Icon styling="base" height="40px" width="40px">
                <IconPortateis />
              </Icon>
              <P ml="15px" styling="base">
                Portáteis
              </P>
            </Box>
          )}

          {arrPets.length > 0 && (
            <Box
              backgroundColor="background.default"
              borderRadius="12px"
              boxShadow="10px 10px 20px rgba(0, 0, 0, 0.05)"
              justifyContent="flex-start"
              mb="15px"
              padding="20px"
              styling="row"
              onClick={() => {
                setType("pet");
                setStep(step + 1);
              }}
              width="100%"
            >
              <Icon styling="base" height="40px" width="40px">
                <IconPet />
              </Icon>
              <P ml="15px" styling="base">
                Pet
              </P>
            </Box>
          )}
          {arrBike.length > 0 && (
            <Box
              backgroundColor="background.default"
              borderRadius="12px"
              boxShadow="10px 10px 20px rgba(0, 0, 0, 0.05)"
              justifyContent="flex-start"
              mb="15px"
              padding="20px"
              styling="row"
              onClick={() => {
                setType("pet");
                setStep(step + 1);
              }}
              width="100%"
            >
              <Icon styling="base" height="40px" width="40px">
                <IconBike />
              </Icon>
              <P ml="15px" styling="base">
                Pet
              </P>
            </Box>
          )}
        </Box>
      </>
    );
  };

  const _handleOnClick = (infos) => {
    showModal({ visible: true, type: "modalPhone", infos });
  };

  const renderType = () => {
    switch (type) {
      case "automovel":
        return (
          <Box
            gridColumn={{
              mobile: "2/span 10",
              tablet: "2/span 10",
              desktop: "4/span 6",
            }}
            py={"40px"}
            styling="column"
          >
            <H3
              color="primary.default"
              fontSize="24px"
              mb="30px"
              styling="light"
            >
              Escolha um automóvel para comunicar o sinistro
            </H3>
            {arrAuto.map((e, i) => (
              <InfoBorderBottomCard
                key={`auto${i}`}
                onClick={() => _handleOnClick(e)}
                icon={<IconAutomovel />}
                title={e.itemSegurado}
                status={e.status}
              />
            ))}
          </Box>
        );

      case "residencia":
        return (
          <Box
            gridColumn={{
              mobile: "2/span 10",
              tablet: "2/span 10",
              desktop: "4/span 6",
            }}
            py={"40px"}
            styling="column"
          >
            <H3
              color="primary.default"
              fontSize="24px"
              mb="30px"
              styling="light"
            >
              Escolha uma residência para comunicar o sisnistro
            </H3>
            {arrResidencia.map((e, i) => (
              <InfoBorderBottomCard
                key={`b${i}`}
                onClick={() => _handleOnClick(e)}
                icon={<IconResidencial />}
                title={e.itemSegurado}
                status={e.status}
              />
            ))}
          </Box>
        );

      case "pet":
        return (
          <Box
            gridColumn={{
              mobile: "2/span 10",
              tablet: "2/span 10",
              desktop: "4/span 6",
            }}
            py={"40px"}
            styling="column"
          >
            <H3
              color="primary.default"
              fontSize="24px"
              mb="30px"
              styling="light"
            >
              Escolha um pet para comunicar o sisnistro
            </H3>
            {arrPets.map((e, i) => (
              <InfoBorderBottomCard
                key={`c${i}`}
                onClick={() => _handleOnClick(e)}
                icon={<IconPet />}
                title={e.itemSegurado}
                status={e.status}
              />
            ))}
          </Box>
        );

      case "vida":
        return (
          <Box
            gridColumn={{
              mobile: "2/span 10",
              tablet: "2/span 10",
              desktop: "4/span 6",
            }}
            py={"40px"}
            styling="column"
          >
            <H3
              color="primary.default"
              fontSize="24px"
              mb="30px"
              styling="light"
            >
              Escolha uma vida para comunicar o sisnistro
            </H3>
            {arrVida.map((e, i) => (
              <InfoBorderBottomCard
                key={`d${i}`}
                onClick={() => _handleOnClick(e)}
                icon={<IconVida />}
                title={e.itemSegurado}
                status={e.status}
              />
            ))}
          </Box>
        );

      case "viagem":
        return (
          <Box
            gridColumn={{
              mobile: "2/span 10",
              tablet: "2/span 10",
              desktop: "4/span 6",
            }}
            py={"40px"}
            styling="column"
          >
            <H3
              color="primary.default"
              fontSize="24px"
              mb="30px"
              styling="light"
            >
              Escolha uma viagem para comunicar o sisnistro
            </H3>
            {arrViagem.map((e, i) => (
              <InfoBorderBottomCard
                key={`e${i}`}
                onClick={() => _handleOnClick(e)}
                icon={<IconViagem />}
                title={e.itemSegurado}
                status={e.status}
              />
            ))}
          </Box>
        );

      case "portateis":
        return (
          <Box
            gridColumn={{
              mobile: "2/span 10",
              tablet: "2/span 10",
              desktop: "4/span 6",
            }}
            py={"40px"}
            styling="column"
          >
            <H3
              color="primary.default"
              fontSize="24px"
              mb="30px"
              styling="light"
            >
              Escolha um portátil para comunicar o sisnistro
            </H3>
            {arrPortateis.map((e, i) => (
              <InfoBorderBottomCard
                key={`f${i}`}
                onClick={() => _handleOnClick(e)}
                icon={<IconPortateis />}
                title={e.itemSegurado}
                status={e.status}
              />
            ))}
          </Box>
        );
      case "bike":
        return (
          <Box
            gridColumn={{
              mobile: "2/span 10",
              tablet: "2/span 10",
              desktop: "4/span 6",
            }}
            py={"40px"}
            styling="column"
          >
            <H3
              color="primary.default"
              fontSize="24px"
              mb="30px"
              styling="light"
            >
              Escolha um portátil para comunicar o sisnistro
            </H3>
            {arrBike.map((e, i) => (
              <InfoBorderBottomCard
                key={`f${i}`}
                onClick={() => _handleOnClick(e)}
                icon={<IconBike />}
                title={e.itemSegurado}
                status={e.status}
              />
            ))}
          </Box>
        );

      case "other":
        return renderList();

      default:
        break;
    }
  };

  return (
    <Box
      alignItems="flex-start"
      minHeight="100vh"
      justifyContent="flex-start"
      styling="column"
      width="100%"
    >
      {renderHeaderStepOther()}
      <Box
        gridRowGap="1.5rem"
        gridTemplateColumns="repeat(12, 1fr)"
        minHeight="100vh"
        position="relative"
        styling="grid"
        width="100%"
      >
        {renderType()}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  myInsurances: state.user.myInsurances,
});

const mapDispatchToProps = {
  showModal: userService.showModal,
  getInsurances: cotacaoService.getInsurances,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(InjectImages(Sinistro));
