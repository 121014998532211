/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import {
  validateVerify,
  autoCorrectedDatePipe,
  validInputBorder,
  checkLengthOfDate,
  getRawPhone
} from "../../../utils/validations";
import moment from "moment";
import cpfValidator from "cpf";
import { fieldNotFilledAlert, dateMask } from "../../../utils";
import { Box, Button } from "../../../components/ds/SubatomicParticles";
import { H3, P } from "../../../components/ds/theme/typography";
import FormSelect from "../../../components/FormSelect";
import FormInput from "../../../components/FormInput";
import TextMaskFormInput from "../../../components/TextMaskFormInput";
import Link from '../../../components/ds/atoms/Link';


const PersonalData = ({
    onNext,
    setNome,
    nome,
    email,
    setEmail,
    sexo,
    setSexo,
    cpfCnpj,
    setCpfCnpj,
    dataNascimento,
    setDataNascimento,
    CellPhoneNumber,
    setCellPhoneNumber,
    onFailName,
    onFailBirthDayDate,
    _handleVerificarCPF,
    onInvalidEmail,
    maritalStateList,
    setMaritalStatus,
    MaritalStatus,
    maritalStateLoading,
    getMaritalStateInfo,
    onGetErrorFromQuiz,
    onCancel
}) => {
    useEffect(() => {
      const fetch = async () => {
        try {
          getMaritalStateInfo();
        } catch (e) {
          onGetErrorFromQuiz();
        }
      };
      fetch();
    }, []);

    const [visibleModal, setVisibleModal] = useState(false);

    const _handleVerify = async () => {
        setNome(nome.trim());
        setCpfCnpj(cpfCnpj.replace(/[.*+?^${}()|[\]\\]/g, "").replace("-", ""));
        const dataNascimentoFormat = dataNascimento
          ? moment(dataNascimento, "DD/MM/YYYY").format("YYYY-MM-DD")
          : "";
        const nameList = nome.split(" ");
        if (checkDiffBirthdayDate(dataNascimentoFormat)) {
          return onFailBirthDayDate();
        }
        if (checkInvalidEmail(email)) {
          return onInvalidEmail();
        }
        if (checkIfHasNotNameOrSurname(nameList)) {
          return onFailName(1);
        }
        if (checkLengthOfSurnameAndName(nameList)) {
          return onFailName(2);
        }
        if (
          checkIfFieldsAreNotEmpty() &&
          checkLengthOfDate(dataNascimentoFormat) === 10 &&
          !checkPersonAge(dataNascimentoFormat) &&
          CellPhoneNumber.length === 15
        ) {
          if (cpfValidator.isValid(cpfCnpj)) {
            if (await _handleVerificarCPF()) {
              setVisibleModal(true);
            }
          } else {
            fieldNotFilledAlert("CPF");
          }
        } else {
          if (nome === "") {
            fieldNotFilledAlert("Nome");
          }
          if (sexo === "") {
            fieldNotFilledAlert("Sexo");
          }
          if (cpfCnpj === "") {
            fieldNotFilledAlert("CPF");
          }
          if (checkPersonAge(dataNascimentoFormat)) {
            return onFailBirthDayDate();
          }
          if (
            dataNascimento === "" ||
            checkLengthOfDate(dataNascimentoFormat) !== 10 ||
            checkPersonAge(dataNascimentoFormat)
          ) {
            fieldNotFilledAlert("Data de Nascimento");
          }
          if (CellPhoneNumber === "" ||
              CellPhoneNumber.length !== 15 
          ) {
            fieldNotFilledAlert("Número de telefone");
          }
          if (MaritalStatus === ""){
            fieldNotFilledAlert("Situação Civil");
          }
        }
      };
    
    const checkIfFieldsAreNotEmpty = () =>
        nome !== "" && email !== "" && sexo !== "" && cpfCnpj !== "" && dataNascimento !== "" && CellPhoneNumber !== "" && MaritalStatus !== "";
    const checkLengthOfSurnameAndName = (name) =>
        name[0] && name[1] && (name[0].length < 2 || name[1].length < 2);
    const checkIfHasNotNameOrSurname = (name) => !name[0] || !name[1];
    const checkDiffBirthdayDate = (age) =>
        moment().diff(
          moment(age, "DD/MM/YYYY").format("YYYY-MM-DD"),
          "years",
          false,
        ) < 17;
    const checkPersonAge = (date) =>
        date &&
        parseInt(moment(date, "DD/MM/YYYY").format("YYYYMMDD")) >=
          parseInt(moment().format("YYYYMMDD"));
    const checkInvalidEmail = (email) =>{
        const regex = /\S+@\S+\.\S+/;
        return !(regex.test(email));
    }

    const _handleCloseModal = ()=>{
      setVisibleModal(!visibleModal);
    }

  return (
    <>
      <Box
        gridColumn={{
          mobile: "2/span 10",
          tablet: "2/span 10",
          desktop: "4/span 6",
        }}
        styling="column"
      >
        <H3 mb="30px" styling="light">
            Informe seus dados pessoais
        </H3>
        <Box display="flex" width={"100%"} flexDirection="column">
            <FormInput
                {...nome}
                onChange={(e) => setNome(e.target.value)}
                data-testid="nome"
                id="nome"
                name="nome"
                placeholder="Nome Completo"
                type="text"
            />
            <FormInput
                {...email}
                onChange={(e) => setEmail(e.target.value)}
                data-testid="email"
                id="email"
                name="email"
                onBlur={e => validateVerify(e)}
                placeholder="E-mail"
                type="text"
            />
            <FormSelect
                data-testid="select"
                id="sexo"
                name="sexo"
                options={[
                { value: "", label: "Selecione" },
                { value: "F", label: "Feminino" },
                { value: "M", label: "Masculino" },
                ]}
                value={sexo}
                onChange={(e) => {
                validInputBorder("sexo");
                setSexo(e.value);
                }}
                onBlur={(e) => validateVerify(e)}
                placeholder="Sexo"
                styling="base"
            />
            <FormInput
                data-testid="cpf"
                id="cpf"
                mask="999.999.999-99"
                name="cpf"
                onBlur={(e) => {
                validateVerify(e);
                }}
                onChange={(e) => setCpfCnpj(e.target.value)}
                placeholder="CPF"
                type="text"
                value={cpfCnpj}
            />
            <TextMaskFormInput
                data-testid="dataNascimento"
                id="dataNascimento"
                value={dataNascimento}
                onChange={(e) => setDataNascimento(e.target.value)}
                mask={dateMask}
                pipe={(date) => autoCorrectedDatePipe(date, false)}
                placeholder="Data de nascimento"
                onBlur={(e) => validateVerify(e)}
                onKeyDown={(e) => {
                if (e.key === "Enter") {
                    _handleVerify();
                }
                }}
            />
            <FormInput
                {...CellPhoneNumber}
                onChange={(e) => setCellPhoneNumber(e.target.value)}
                data-testid="telefone"
                id="telefone"
                mask="(99) 99999-9999"
                name="telefone"
                onBlur={e => validateVerify(e)}
                placeholder="Número de telefone"
                type="text"
            />
            <FormSelect
              defaultValue={MaritalStatus}
              onChange={(e) => setMaritalStatus(e.value)}
              onBlur={(e) => validateVerify(e)}
              placeholder="Situação Civil"
              options={
                Object.keys(maritalStateList).length !== 0 || maritalStateLoading
                  ? Object.keys(maritalStateList).map((item, index) => {
                      return {
                        value: item,
                        label: maritalStateList[item],
                      };
                    })
                  : [{ label: "Carregando...", value: "" }]
              }
            />
        </Box>
        <Button styling="primary" onClick={_handleVerify} width="296px" mt={25}>
          Próximo
        </Button>
      </Box>
      <Box
        display={visibleModal ? 'flex' : 'none'}
        height="100vh"
        left="0"
        position="fixed"
        top="0"
        styling="row"
        width="100vw"
        zIndex="99999999"
      >
        <Box
          backgroundColor="rgba(0, 0, 0, 0.8)"
          height="100vh"
          onClick={() => { _handleCloseModal() }}
          position="fixed"
          styling="row"
          width="100vw"
        ></Box>
          <Box
            backgroundColor="background.default"
            borderRadius="12px"
            maxWidth="415px"
            padding="2rem"
            position="fixed"
            styling="cloumn"
            width="90%"
            zIndex="2"
          >
            <P mb="30px" styling="base">
              {`Ao prosseguir, você aceita os Termos de Uso da Pessego.com Seguros, `} 
              <Link to="/termos" target="_blank" >
                disponíveis aqui.
              </Link> 
              {` Também, concorda em criar uma conta na Pessego.com. Um e-mail será enviado para a sua caixa de mensagens contendo dados sobre sua conta.`}
            </P>
            <Box
              justifyContent="space-between"
              mt="2rem"
              styling="row"
              width="100%"
            >
              <Button display="block" mr="30px"  margin="0 auto" styling="outline" width="40%" onClick={() => {
                  const rawTelephone = getRawPhone(CellPhoneNumber);
                  onCancel({
                    cpfCnpj: cpfCnpj,
                    dataNascimento: moment(dataNascimento, "DD/MM/YYYY").format("YYYY-MM-DD"),
                    ddd: rawTelephone.substring(0, 2),
                    email: email,
                    nome: nome,
                    sexo: sexo,
                    telefone: rawTelephone.substring(2, rawTelephone.length),
                    situacaoCivil: MaritalStatus
                  });
                  _handleCloseModal();
                }} >
                  Não
              </Button>
              <Button display="block" margin="0 auto"  styling="primary" width="40%" onClick={() => {
                const rawTelephone = getRawPhone(CellPhoneNumber);
                onNext({
                  cpfCnpj: cpfCnpj,
                  dataNascimento: moment(dataNascimento, "DD/MM/YYYY").format("YYYY-MM-DD"),
                  ddd: rawTelephone.substring(0, 2),
                  email: email,
                  nome: nome,
                  sexo: sexo,
                  telefone: rawTelephone.substring(2, rawTelephone.length),
                  situacaoCivil: MaritalStatus
                });
                _handleCloseModal();
                } }>
                  Sim
              </Button>
            </Box>
          </Box>
      </Box>
    </>
  );
};


export default PersonalData;