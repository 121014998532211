import React from "react";
import SmallLoading from "../../SmallLoading";
import { Box } from "../../../components/ds/SubatomicParticles";
import { P } from "../../../components/ds/theme/typography";
import Icon from "../../../components/ds/atoms/Icon";

const defineColorFromTimeStamp = (timeStampQuote) => {
  // tempo restante em milissegundos
  let timeHasPassed = parseFloat(Date.now() - timeStampQuote)
  // tempo restante em dias arredondado
  timeHasPassed = Math.floor(timeHasPassed / 86400000) // 86400000 - um dia em milissegundos
  if (timeHasPassed < 9) {
    let color

    if (timeHasPassed < 4) {
      color = "success.default"
    } else if (timeHasPassed < 7) {
      color = "primary.default"
    } else {
      color = "danger.default"
    }

    return <P color={{ color }} fontSize="12px" styling="error">{`Expira em ${10 - timeHasPassed} dias!`}</P>
  }

  return <P fontSize="12px" styling="error">Expira hoje!</P>
}

const QuotationCardInfo = ({ icon, text, timeStamp, onClick, thirdText, status, width, quoteId, type, loadingId }) => (
  <>
    <Box
      backgroundColor="background.default"
      boxShadow="10px 10px 20px rgba(0, 0, 0, 0.05)"
      borderRadius="12px"
      justifyContent="flex-start"
      mb="15px"
      onClick={() => onClick({ type, quoteId })}
      padding="20px"
      position="relative"
      styling="row"
      width="100%"
    >
      <Icon styling="baseNone">
        {icon}
      </Icon>
      <Box alignItems="flex-start" ml="15px" styling="row" justifyContent="space-between" width="100%">
        <Box alignItems="flex-start" ml="15px" styling="column">
          <P styling="base">{text}</P>
          {defineColorFromTimeStamp(timeStamp)}
          {status &&
            <P styling="regular">{status}</P>
          }
          {thirdText &&
            <P styling="base">{thirdText}</P>
          }
        </Box>
        {loadingId === quoteId && <SmallLoading />}
      </Box>
    </Box>
  </>
);

export default QuotationCardInfo;
