import React from "react";
import { connect } from "react-redux";
import { P } from "../../../components/ds/theme/typography";
import { Box } from "../../../components/ds/SubatomicParticles";
import Icon from "../../../components/ds/atoms/Icon";
import { ReactComponent as IconSpin } from "../../../assets/svg/icon_spin.svg";
import css from "@styled-system/css";

function Loading({ isLoadingQuotationValues, loadingUser, loadingForm, quoteInfoLoading, isBuyingQuotation, show = false }) {
  return (
    <Box
      backgroundColor="rgba(0, 0, 0, 0.8)"
      display={isLoadingQuotationValues || loadingUser || loadingForm || quoteInfoLoading || show ? "flex" : "none"}
      height="100vh"
      left="0"
      position="fixed"
      styling="column"
      top="0"
      width="100vw"
      zIndex="99999999"
    >
      <Box
        height="100%"
        styling="column"
        width="100%"
        css={css({
          backgroundColor: theme => `${theme.colors.background.dark}4d`
        })}
      >
        <Box
          backgroundColor="background.default"
          borderRadius="50%"
          height="50px"
          styling="column"
          position="relative"
          width="50px"
        >
          <Icon styling="base" height="50px" width="50px">
            <IconSpin height="50px" width="50px" />
          </Icon>
        </Box>
        {isLoadingQuotationValues && !isBuyingQuotation && (
          <Box mt="30px" styling="column">
            <P color="text.light" fontSize="26px" mb="15px" styling="regular">Ótimo!</P>
            <P color="text.light" fontSize="20px" styling="base">Aguarde enquanto preparamos sua cotação...</P>
          </Box>
        )}
        {isBuyingQuotation && (
          <Box mt="30px" styling="column">
            <P color="text.light" fontSize="26px" mb="15px" styling="regular">Aguarde.</P>
            <P color="text.light" fontSize="20px" styling="base">Estamos enviando a solicitação do seguro...</P>
          </Box>
        )}
      </Box>
    </Box>
  );
}

const mapStateToProps = state => ({
  loadingUser: state.user.loading,
  loadingForm: state.form.loading,
  quoteInfoLoading: state.user.quoteInfoLoading,
  isLoadingQuotationValues: state.form.isLoadingQuotationValues,
  isBuyingQuotation: state.form.isBuyingQuotation
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Loading);