import React from "react";
import TextMaskInput from "./TextMaskInput";
import { Box } from "../ds/SubatomicParticles";
import { P, Label } from "../ds/theme/typography";

type Props = {
  label: string;
  name: string;
  placeholder: string;
  type: string;
  value: string;
  error: string | boolean | undefined;
  success?: boolean;
  readOnly?: boolean;
  mask?: any;
  icon?: React.ReactNode;
  iconLabel?: React.ReactNode;
  maxLength?: number;
  disabled?: boolean;
  onBlur(e: any): void;
  onChange(e: any): void;
  backgroundColor?: string;
  errorBelow?: boolean;
  pipe?: any;
  textFromRight?: boolean;
};
interface getLabelProps {
  label?: string | boolean;
  placeholder: string;
}
const getLabel = ({ label, placeholder }: getLabelProps) => {
  if (label === false) {
    return null;
  } else if (typeof label === "string") {
    return <Label whiteSpace="normal" styling="base">{label}</Label>;
  } else if (typeof label === "undefined")
    return <Label whiteSpace="normal" styling="base">{placeholder}</Label>;
};

const TextMaskFormInput = ({
  value,
  error,
  mask,
  success,
  icon,
  iconLabel,
  errorBelow,
  textFromRight,
  label,
  placeholder,
  ...rest
}: Props) => {
  return (
    <Box styling="column" width="100%" mb="15px" alignItems="flex-start">
      {getLabel({ label, placeholder })}
      <Box styling="column" width="100%" position="relative">
        <TextMaskInput
          {...rest}
          id={rest.name}
          value={value}
          mask={mask}
          styling="base"
          placeholder={label === undefined ? '' : placeholder}
        />
        {icon && icon}

        {error && errorBelow && (
          <P
            styling="error"
            mb="xxs"
            width="100%"
            textAlign="right"
            data-testid={`${rest.name}Error`}
          >
            {error}
          </P>
        )}
      </Box>
    </Box>
  );
};

export default TextMaskFormInput;
