import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import * as userService from "../../state/context/user/services";
import { IconsMap } from "../../components/iconHome/iconhome";
import { Box, Button } from "../../components/ds/SubatomicParticles";
import { H1, H2, H4, P } from "../../components/ds/theme/typography";
import Link from "../../components/ds/atoms/Link";
import Icon from "../../components/ds/atoms/Icon";
import BgVida from "../../assets/images/bg_vida.png";
import { ReactComponent as Circle } from "../../assets/svg/circle.svg";
import { ReactComponent as DotsRight } from "../../assets/svg/dots_left.svg";
import { ReactComponent as Dotsleft } from "../../assets/svg/dots_right.svg";
import { ReactComponent as PesegoCare } from "../../assets/svg/pessego-care.svg";
import InjectImages from "../Images";
import PreImg from "../../components/PreImg";

function Vida({ history, showModal, user, images }) {
  return (
    <>
      <Box
        gridTemplateColumns="repeat(12,1fr)"
        gridRowGap="1.5rem"
        paddingY={{ mobile: "px", tablet: "0px", desktop: "3rem" }}
        position="relative"
        styling="grid"
      >
        <Box
          alignItems="flex-start"
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "2/span 4",
          }}
          styling="column"
        >
          <H1 styling="base">Um seguro para a sua vida e para quem mais importa para você.</H1>
          <Box
            styling="base"
            height="2px"
            width="4vw"
            mt="2rem"
            backgroundColor="primary.default"
          ></Box>
        </Box>

        <Box
          alignItems="flex-start"
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "2/span 4",
          }}
          gridRow="2"
          styling="column"
        >
          <P lineHeight="1.4" textAlign="justify" styling="base">
            Se engana quem pensa que este seguro tem coberturas
            apenas para amenizar os processos burocráticos em caso de morte. Ele
            garante também a sua tranquilidade em vida em caso de incapacidade
            por doença ou acidente. Feito para que você e sua família permaneçam
            amparados financeiramente quando imprevistos acontecerem.
          </P>
        </Box>

        <Box
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "2/span 4",
          }}
          gridRow="3"
          my="2rem"
          styling="column"
        >
          <Button
            onClick={() => {
              history.push({
                pathname: "/form",
                state: { type: "vida" },
              })
            }}
            styling="primary"
            width="350px"
            height="50px"
          >
            <div style={{ fontSize: 18 }}>Cotar agora seu seguro vida</div>
          </Button>
          {user && (user.telefone || user.telefoneCelular) && (
            <Button
              my="10px"
              onClick={() => showModal({ visible: true, type: "contact" })}
              styling="primary"
              width="295px"
            >
              Quero falar com alguém, me ligue!
            </Button>
          )}
        </Box>

        <Box
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          gridColumn="5/span 8"
          gridRow="2/span 4"
          height="210px"
          mt="150px"
          styling="column"
        >
          <Icon styling="baseNone" height="100%" width="100%">
            <DotsRight width="100%" height="100%" />
          </Icon>
        </Box>

        <Box
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          gridColumn="8/span 4"
          gridRow="1/span 4"
          ml="30px"
          styling="column"
        >
          <img alt="" height="100%" src={BgVida} width="100%" />
        </Box>

        <Box
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          gridColumn="7/span 8"
          gridRow="1/span 4"
          position="absolute"
          styling="column"
          top="50px"
        >
          <Circle />
        </Box>

        <Box
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          gridColumn="10/span 2"
          gridRow="2/span 4"
          position="absolute"
          styling="column"
          top="145px"
          zIndex="-1"
        >
          <Circle />
        </Box>

        <Box
          alignItems="stretch"
          flexWrap="wrap"
          gridColumn="2/span 10"
          gridRow="4"
          justifyContent="space-between"
          margin={{ mobile: "2rem 0", tablet: "2rem 0", desktop: "0 0 2rem" }}
          position="relative"
          styling="row"
        >
          <Box
            backgroundColor="background.default"
            borderRadius="12px"
            boxShadow="10px 10px 28px 1px rgba(0,0,0,.1)"
            justifyContent="flex-start"
            mb={{ mobile: "25px", tablet: "25px", desktop: "0" }}
            padding="20px"
            styling="column"
            width={{ mobile: "100%", tablet: "100%", desktop: "22%" }}
          >
            <H4 mb="24px" styling="base">Indenização por morte</H4>
            <P lineHeight="1.4" styling="base">
              Seus beneficiários receberão o valor do capital segurado de acordo
              com as porcentagens que você estipular no ato da contratação. A
              seguradora também será responsável pelas despesas do funeral e sua
              família não precisará se preocupar.
            </P>
          </Box>

          <Box
            backgroundColor="background.default"
            borderRadius="12px"
            boxShadow="10px 10px 28px 1px rgba(0,0,0,.1)"
            justifyContent="flex-start"
            mb={{ mobile: "25px", tablet: "25px", desktop: "0" }}
            padding="20px"
            styling="column"
            width={{ mobile: "100%", tablet: "100%", desktop: "22%" }}
          >
            <H4 mb="24px" styling="base">Diária por incapacidade temporária</H4>
            <P lineHeight="1.4" styling="base">
              Ficou afastado do trabalho por recomendações médicas por mais de
              10 dias? Seja por acidente ou doença, você pode ser ressarcido
              pelos dias que não conseguiu produzir. Informe sua profissão no
              ato da contratação e aproveite também este benefício.
            </P>
          </Box>

          <Box
            backgroundColor="background.default"
            borderRadius="12px"
            boxShadow="10px 10px 28px 1px rgba(0,0,0,.1)"
            justifyContent="flex-start"
            mb={{ mobile: "25px", tablet: "25px", desktop: "0" }}
            padding="20px"
            styling="column"
            width={{ mobile: "100%", tablet: "100%", desktop: "22%" }}
          >
            <H4 mb="24px" styling="base">Diagnóstico de doenças graves</H4>
            <P lineHeight="1.4" styling="base">
              Em caso de diagnóstico de doenças graves (AVC, câncer, etc), você
              recebe uma porcentagem do total segurado para auxiliar no período
              do tratamento. As informações de aporte para doenças estarão
              sempre descritas na sua apólice. Consulte-a ou ligue na
              pessego.com!
            </P>
          </Box>

          <Box
            backgroundColor="background.default"
            borderRadius="12px"
            boxShadow="10px 10px 28px 1px rgba(0,0,0,.1)"
            justifyContent="flex-start"
            mb={{ mobile: "25px", tablet: "25px", desktop: "0" }}
            padding="20px"
            styling="column"
            width={{ mobile: "100%", tablet: "100%", desktop: "22%" }}
          >
            <H4 mb="24px" styling="base">Invalidez por acidente</H4>
            <P lineHeight="1.4" styling="base">
              Você também estará assegurado em caso de perda, redução ou
              impotência funcional definitiva, parcial e/ou total de membros ou
              órgãos em decorrência de acidente, podendo ser inclusive
              ressarcido de despesas médicas, hospitalares e odontológicas.
            </P>
          </Box>
        </Box>

        <Box
          alignItems="flex-start"
          gridColumn="2/span 10"
          gridRow="5"
          styling="column"
        >
          <H2 styling="base">A pessego.com tranquiliza você e cuida de sua segurança!</H2>
        </Box>

        <Box
          alignItems="flex-start"
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "2/span 6",
          }}
          gridRow="6"
          styling="column"
        >
          <P my="2rem" styling="regular">
            Não sabe exatamente o que procura?
          </P>
          <P styling="base">
            Não tem problema! Nossos consultores são especialistas em seguro e
            vão ajudar você a encontrar exatamente o que você precisa e do jeito
            que você preferir: por telefone, e-mail ou chat.
          </P>
          <P my="2rem" styling="regular">
            Preparamos uma lista de dúvidas mais frequentes pra você.
          </P>
          <P display="block" styling="base">
            Aqui está tudo bem explicadinho, mas se preferir conversar com um de
            nossos promotores é só clicar no botão “
            <Link color="primary.default" to="/contato">
              fale conosco
            </Link>
            ”, ok?
          </P>
        </Box>

        <Box
          backgroundColor="background.default"
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          borderRadius="50%"
          gridColumn="10/span 2"
          gridRow="6"
          height="256px"
          ml="16px"
          styling="column"
          width="256px"
        >
          {images?.generic1?.url ? (
            <PreImg
              src={images.generic1.url}
              style={{
                width: "120px",
                height: "114px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                styling="row"
                width="80%"
                height="80%"
                borderRadius="4px"
                backgroundColor="#ededed"
              />
            </PreImg>
          ) : (
            <PesegoCare height="120px" width="114px" />
          )}
        </Box>

        <Box
          alignItems="flex-start"
          gridColumn="1/span 8"
          gridRow="7"
          height="210px"
          my="30px"
          styling="column"
        >
          <Icon styling="baseNone" height="100%" width="70%">
            <Dotsleft width="100%" height="100%" />
          </Icon>
        </Box>

        <Box gridColumn="2/span 10" gridRow="8" styling="column">
          <H2 mb="2rem" styling="base">Outros seguros</H2>
          <Box
            gridGap="1rem"
            gridTemplateColumns={{
              mobile: "repeat(2,1fr)",
              tablet: "repeat(3,1fr)",
              desktop: "repeat(7,1fr)",
            }}
            styling="grid"
            width="100%"
          >
            <IconsMap history={history} />
          </Box>
        </Box>
      </Box>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = {
  showModal: userService.showModal,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(InjectImages(Vida));
