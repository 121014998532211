import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { compose } from "recompose"
import PaymentTypes from "../util/paymentTypes"
import { showToast } from "../../utils"
import * as formService from "../../state/context/form/services";
import * as userService from "../../state/context/user/services";
import * as Auto from "../form/auto";
import * as Residencia from "../form/residencia";
import { Box } from "../../components/ds/SubatomicParticles";
import { H3, P } from "../../components/ds/theme/typography";

const CotacaoValores = ({ 
    quotes, 
    history, 
    buyInsuranceResidence, 
    buyInsuranceVehicle, 
    showModal, 
    getRoles,
    getRangeOfPayment,
    getMaritalState,
    getGeneralRegistryState,
    roles,
    rangePayments,
    maritalStateList,
    maritalStateLoading,
    generalRegistryList,
    generalRegistryLoading,
  }) => {

  const quote = quotes.find(item => item.quoteId === history.location.state.id)
  const [step, setStep] = useState(1)
  const [dataHabCondutor, setDataHabCondutor] = useState("");
  const [IndividualNumber, setIndividualNumber] = useState("");
  const [IndNumIssuanceDate, setIndNumIssuanceDate] = useState("");
  const [IndNumIssuer, setIndNumIssuer] = useState("");
  const [IndNumIssuerState, setIndNumIssuerState] = useState("");
  const [CellPhoneNumber, setCellPhoneNumber] = useState("");
  const [PoliticallyExposedPerson, setPoliticallyExposedPerson] = useState("");
  const [DriverLicenseNumber, setDriverLicenseNumber] = useState("");
  const [DriverLicenseType, setDriverLicenseType] = useState("");
  const [Is0KM, setIs0KM] = useState("F");
  const [DealershipName, setDealershipName] = useState("");
  const [InvoiceNumber, setInvoiceNumber] = useState("");
  const [RangeMonthlyIncomeCode, setRangeMonthlyIncomeCode] = useState("");
  const [OccupationCode, setOccupationCode] = useState("");
  const [paymentPayload, setPaymentPayload] = useState();
  const [MaritalStatus, setMaritalStatus] = useState("");
  const [Gender, setGender] = useState("");

  const handleSuccessOnPayment = () => {
    showModal({ visible: true, type: "successPaymentQuotation" });
    history.push('minhaconta')
  }

  const handleErrorOnPayment = (dataResponse) => {
    const { errorMessages } = dataResponse;
    if (errorMessages.length !== 0) {
      return showToast("warning", "Alerta", errorMessages[0], 0);
    }
    return showToast("warning", "Alerta", 'Não foi possível concluir a compra, tente novamente ou contate o pessego.com', 0);
  }

  const handlePaymentMethod = (data) => {
    if (isQuoteOfVehicle(quote.type)) {
      buyInsuranceVehicle(data).then(r => {
        if (r && r.status === 200) {
          handleSuccessOnPayment()
        } else {
          handleErrorOnPayment(r.response.data)
        }
      })
    } else {
      buyInsuranceResidence(data).then(r => {
        if (r && r.status === 200) {
          handleSuccessOnPayment()
        } else {
          handleErrorOnPayment(r.response.data)
        }
      })
    }
  }

  const onMountBeneficiaryInfo = async () => {
    try {
      setIs0KM(quote.valueDetails.Is0KM);
      await getMaritalState();
      await getRoles();
      await getRangeOfPayment();
      await getGeneralRegistryState();
    } catch (e) {
      handleErrorOnGetInfo(-1);
    }
  };

  const handleErrorOnGoForwardMsg = () =>
    showToast(
      "warning",
      "Alerta",
      "Não foi possivel carregar os dados. Verifique sua conexão e tente novamente.",
    );

  const handleErrorOnGetInfo = (goBackSteps) => {
    handleErrorOnGoForwardMsg();
    setStep(1);
  };

  const isQuoteOfVehicle = (type) => type === "vehicleQuotes"

  const renderSteps = ()=>{
    switch(step){
      case 1:
        return (
          <Box
            gridColumn={{ mobile: "2/span 10", tablet: "2/span 10", desktop: "4/span 6" }}
            styling="column"
          >
            <H3 mb="30px" styling="light" textAlign="center">{quote.description}</H3>
            <P styling="base">{quote.date}</P>
            <PaymentTypes
              onComplete={data =>{
                setPaymentPayload({ quoteId: quote.quoteId, ...data });
                if(isQuoteOfVehicle(quote.type)){
                  setStep(2);
                } else{
                  setStep(3);
                }
              }}
              values={quote.valueDetails.values}
              hasErrorOnResidence={0}
              onIncomplete={() => { showToast("warning", "Alerta", 'Para prosseguir com a compra, é preciso preencher os campos.') }}
              onBackToHome={() => { }}
              paymentTypes={quote.valueDetails.paymentTypes}
              warningMessages={[]}
              errorMessages={[]}
              isOnFlow={false}
              messages
              statusErrorPaymentQuotation
              quoteId={quote.quoteId}
              paymentContext={isQuoteOfVehicle(quote.type) ? "vehicle" : "residence"}
            />
        </Box>
        )
      case 2:
        return (
            <Auto.AdditionalInformation
                onMountBeneficiaryInfo={onMountBeneficiaryInfo}
                IndividualNumber={IndividualNumber}
                IndNumIssuanceDate={IndNumIssuanceDate}
                IndNumIssuer={IndNumIssuer}
                IndNumIssuerState={IndNumIssuerState}
                OccupationCode={OccupationCode}
                RangeMonthlyIncomeCode={RangeMonthlyIncomeCode}
                DriverLicenseType={DriverLicenseType}
                setDriverLicenseType={setDriverLicenseType}
                roles={roles}
                dataHabCondutor={dataHabCondutor}
                setDataHabCondutor={setDataHabCondutor}
                rangePayments={rangePayments}
                maritalStateList={maritalStateList}
                maritalStateLoading={maritalStateLoading}
                generalRegistryList={generalRegistryList}
                generalRegistryLoading={generalRegistryLoading}
                setRangeMonthlyIncomeCode={setRangeMonthlyIncomeCode}
                setIndividualNumber={setIndividualNumber}
                setIndNumIssuanceDate={setIndNumIssuanceDate}
                setIndNumIssuer={setIndNumIssuer}
                setIndNumIssuerState={setIndNumIssuerState}
                setOccupationCode={setOccupationCode}
                PoliticallyExposedPerson={PoliticallyExposedPerson}
                setPoliticallyExposedPerson={setPoliticallyExposedPerson}
                DriverLicenseNumber={DriverLicenseNumber}
                setDriverLicenseNumber={setDriverLicenseNumber}
                showModal={showModal}
                Is0KM={Is0KM}
                DealershipName={DealershipName}
                setDealershipName={setDealershipName}
                InvoiceNumber={InvoiceNumber}
                setInvoiceNumber={setInvoiceNumber}
                CellPhoneNumber={CellPhoneNumber}
                setCellPhoneNumber={setCellPhoneNumber}
                onComplete={(data) => {
                  const additionalInformationData = {
                    quiver: {
                      dataHabCondutor: data.dataHabCondutor,
                    },
                    PurchaseParameters: {
                      PolicyHolder: {
                        RangeMonthlyIncomeCode: data.RangeMonthlyIncomeCode,
                        OccupationCode: data.OccupationCode,
                        CellPhoneNumber: data.CellPhoneNumber,
                        CellPhoneAreaCode: data.CellPhoneAreaCode,
                        IndNumIssuerState: data.IndNumIssuerState,
                        IndNumIssuer: data.IndNumIssuer,
                        IndNumIssuanceDate: data.IndNumIssuanceDate,
                        IndividualNumber: data.IndividualNumber,
                        DriverLicenseNumber: data.DriverLicenseNumber,
                        DriverLicenseType: data.DriverLicenseType,
                        PoliticallyExposedPerson: data.PoliticallyExposedPerson    
                      }
                    }
                  };
                  
                  handlePaymentMethod({...paymentPayload, ...additionalInformationData})
                }}
                onRedirectError={() => {
                  history.push("minhaconta");
                }}
                onBackToHome={() => {
                  showModal({ visible: true, type: "confirmEndQuotation" });
                }}
              />
        )
        case 3:
          return (
            <Residencia.ResidenceBeneficiaryInfo
              onMountBeneficiaryInfo={onMountBeneficiaryInfo}
              maritalStateList={maritalStateList}
              maritalStateLoading={maritalStateLoading}
              generalRegistryList={generalRegistryList}
              generalRegistryLoading={generalRegistryLoading}
              onComplete={(data) => {
                const additionalInformationData = {
                  PurchaseParameters: {
                    PolicyHolder: {
                      RangeMonthlyIncomeCode: data.RangeMonthlyIncomeCode,
                      OccupationCode: data.OccupationCode,
                      IndNumIssuerState: data.IndNumIssuerState,
                      IndNumIssuer: data.IndNumIssuer,
                      IndNumIssuanceDate: data.IndNumIssuanceDate,
                      IndividualNumber: data.IndividualNumber,
                      PoliticallyExposedPerson: data.PoliticallyExposedPerson,
                      Gender: data.Gender,
                      MaritalStatus: data.MaritalStatus
                    }
                  }
                };
                handlePaymentMethod({...paymentPayload, ...additionalInformationData})
              }}
              IndividualNumber={IndividualNumber}
              setIndividualNumber={setIndividualNumber}
              IndNumIssuanceDate={IndNumIssuanceDate}
              setIndNumIssuanceDate={setIndNumIssuanceDate}
              IndNumIssuer={IndNumIssuer}
              setIndNumIssuer={setIndNumIssuer}
              IndNumIssuerState={IndNumIssuerState}
              setIndNumIssuerState={setIndNumIssuerState}
              MaritalStatus={MaritalStatus}
              setMaritalStatus={setMaritalStatus}
              roles={roles}
              OccupationCode={OccupationCode}
              setOccupationCode={setOccupationCode}
              rangePayments={rangePayments}
              RangeMonthlyIncomeCode={RangeMonthlyIncomeCode}
              setRangeMonthlyIncomeCode={setRangeMonthlyIncomeCode}
              Gender={Gender}
              setGender={setGender}
              PoliticallyExposedPerson={PoliticallyExposedPerson}
              setPoliticallyExposedPerson={setPoliticallyExposedPerson}
            />
          );
      default:
        break;
    }
  }


  return (
    <Box
      gridTemplateColumns="repeat(12,1fr)"
      gridRowGap="1.5rem"
      minHeight="calc(100vh - 266px)"
      position="relative"
      styling="grid"
    >
      {renderSteps()}
    </Box>
  );
};

const mapStateToProps = state => ({
  quotes: state.user.myQuotes,
  maritalStateList: state.form.maritalState.list,
  maritalStateLoading: state.form.maritalState.isLoading,
  generalRegistryList: state.form.generalRegistryState.list,
  generalRegistryLoading: state.form.generalRegistryState.isLoading,
  rangePayments: state.form.rangePayments.list,
  roles: state.form.roles.list,
});

const mapDispatchToProps = {
  showModal: userService.showModal,
  buyInsuranceVehicle: formService.buyInsuranceVehicle,
  buyInsuranceResidence: formService.buyInsuranceResidence,
  getRoles: formService.getRoles,
  getRangeOfPayment: formService.getRangeOfPayment,
  getMaritalState: formService.getMaritalState,
  getGeneralRegistryState: formService.getGeneralRegistryState,
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CotacaoValores);
