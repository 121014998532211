import React, { useEffect } from "react";
import { Box, Button } from "../../../components/ds/SubatomicParticles";
import { H3 } from "../../../components/ds/theme/typography";

function Step1({ onYes, onNo, handleResetLifeInsuranceFields }) {
  useEffect(() => {
    handleResetLifeInsuranceFields()
  },[handleResetLifeInsuranceFields]);

  return (
    <Box
      gridColumn={{
        mobile: "2/span 10",
        tablet: "2/span 10",
        desktop: "4/span 6",
      }}
      styling="column"
    >
      <H3 mb="30px" styling="light">Este seguro é para você?</H3>
      <Box styling="row" width="100%">
        <Button onClick={onNo} styling="primary" width="132px">Não</Button>
        <Button ml="15px" onClick={onYes} styling="primary" width="132px">Sim</Button>
      </Box>
    </Box>
  );
}

export default Step1;
