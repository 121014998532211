import * as actions from "./actions";
import * as repository from "./repository";
import { showToast } from "../../../utils";
import handleError from "../../../utils/handleError";

export const signUserIn = userPass => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch(actions.isLoading(true));
      const response = await repository.signIn(userPass);
      if (response.user) {
        await dispatch(authUser(response.user.uid, response.user.ra));
        dispatch(actions.isLoading(false));
        resolve(response.user);
      } else {
        dispatch(actions.isLoading(false));
        reject(response);
      }
    } catch (err) {
      dispatch(actions.isLoading(false));
      reject(err);
    }
  });
};

export const authUser = (uid, tokenId) => async dispatch => {
  dispatch(actions.isLoading(true));
  return new Promise((resolve, reject) => {
    repository
      .authUser(uid, tokenId)
      .then(data => {
        dispatch(actions.authUser(data));
        sessionStorage.setItem("user", JSON.stringify(data));
        dispatch(actions.isLoading(false));
        resolve()
      })
      .catch(err => {
        dispatch(actions.isLoading(false));
        reject(err)
      });
  })
};

export const userUpdate = newUser => async dispatch => {
  try {
    dispatch(actions.isLoading(true));
    await repository.userUpdate(newUser);
    dispatch(actions.isLoading(false));
  } catch (e) {
    dispatch(actions.isLoading(false));
  }
};

export const logoutUser = () => async dispatch => {
  try {
    dispatch(actions.isLoading(true));
    repository.logout();
    dispatch(actions.logoutUser());
    dispatch(actions.isLoading(false));
  } catch (e) {
    dispatch(actions.isLoading(false));
  }
};

export const createUser = user => async dispatch => {
  dispatch(actions.isLoading(true));
  return new Promise((resolve, reject) => {
    repository
      .create(user)
      .then(r => {
        dispatch(actions.isLoading(false));
        resolve(r);
      })
      .catch(err => {
        dispatch(actions.isLoading(false));
        reject(err);
      });
  });
};

export const resetPassword = email => async dispatch => {
  try {
    dispatch(actions.isLoading(true));
    await repository.resetPassword(email);
    dispatch(actions.isLoading(false));
  } catch (e) {
    dispatch(actions.isLoading(false));
  }
};

export const verifyEmail = email => async dispatch => {
  try {
    dispatch(actions.isLoading(true));
    const data = await repository.verifyEmail(email);
    dispatch(actions.isLoading(false));
    return data;
  } catch (e) {
    dispatch(actions.isLoading(false));
  }
};

export const showToasts = (type, message) => async dispatch => {
  dispatch(actions.showToast({ show: true, type, message }));
  setTimeout(() => {
    dispatch(actions.showToast({ show: false, type: "", message: "" }));
  }, 3000);
};

export const getCEP = cep => async dispatch => {
  try {
    dispatch(actions.isLoading(true));
    const response = await repository.getCEP(cep);

    dispatch(actions.isLoading(false));
    return response;
  } catch (e) {
    dispatch(actions.isLoading(false));
  }
};

export const setLoading = data => async dispatch => {
  dispatch(actions.isLoading(data));
};

export const showModal = data => async dispatch => {
  dispatch(actions.showModal(data));
};

export const getResidences = () => async dispatch => {
  try {
    dispatch(actions.isLoadingResidences(true))
    const response = await repository.getResidences()
    dispatch(actions.successGetResidences(response.data))
    dispatch(actions.isLoadingResidences(false))
  } catch (e) {
    dispatch(actions.isLoadingResidences(false))
    handleError()
  }
}

export const addResidence = (data) => async dispatch => {
  try {
    dispatch(actions.isLoading(true))
    const response = await repository.addResidence(data);
    dispatch(actions.isLoading(false))
    return response;
  } catch (e) {
    dispatch(actions.isLoading(false))
  }
}
export const removeResidence = body => async dispatch => {
  try {
    dispatch(actions.isLoading(true))
    await repository.removeResidence(body);
    dispatch(actions.isLoading(false))
  } catch (e) {
    dispatch(actions.isLoading(false))
  }
}

export const registerVehicles = body => async dispatch => {
  try {
    dispatch(actions.isLoading(true))
    const data = await repository.registerVehicle(body)
    dispatch(actions.isLoading(false))
    return data;
  } catch (e) {
    dispatch(actions.isLoading(false))
  }
}

export const getVehicles = () => async dispatch => {
  try {
    dispatch(actions.isLoadingVehicles(true))
    const response = await repository.getVehicles()
    dispatch(actions.successGetVehicles(response.data))
    dispatch(actions.isLoadingVehicles(false))
  } catch (e) {
    dispatch(actions.isLoadingVehicles(false))
    handleError()
  }
}
export const removeVehicle = body => async dispatch => {
  try {
    dispatch(actions.isLoadingVehicles(true))
    await repository.removeVehicle(body)
    dispatch(actions.isLoadingVehicles(false))
  } catch (e) {
    dispatch(actions.isLoadingVehicles(false))
    handleError()
  }
}
// -----
export const getVehicleBrands = () => async dispatch => {
  try {
    dispatch(actions.isLoading(true))
    const response = await repository.getVehicleBrands()
    dispatch(actions.isLoading(false))
    if (!response.data.length || response.status !== 200) {
      return showToast("warning", "Atenção", "Não foi possivel buscar as marcas do veículo")
    }
    dispatch(actions.successGetBrands(response.data))
  } catch (e) {
    dispatch(actions.isLoading(false))
  }
}

export const getVehiclesFromBrand = id => async dispatch => {
  try {
    dispatch(actions.isLoading(true))
    const response = await repository.getVehiclesFromBrand(id)
    dispatch(actions.isLoading(false))
    if (!response.data.length || response.status !== 200) {
      return showToast("warning", "Atenção", "Não foi possivel buscar os modelos do veículo")
    }
    dispatch(actions.successGetModels(response.data))
  } catch (e) {
    dispatch(actions.isLoading(false))
  }
}

export const getVehicleModelYear = (brandId, vehicleId) => async dispatch => {
  try {
    dispatch(actions.isLoading(true))
    const response = await repository.getVehicleModelYear(brandId, vehicleId)
    dispatch(actions.isLoading(false))
    if (!response.data.length || response.status !== 200) {
      return showToast("warning", "Atenção", "Não foi possivel buscar o ano do veículo")
    }
    dispatch(actions.successGetYearModel(response.data))
  } catch (e) {
    dispatch(actions.isLoading(false))
  }
}

export const getVehicleInfo = (brandId, vehicleId, modelId) => async dispatch => {
  try {
    dispatch(actions.isLoading(true))
    const response = await repository.getVehicleInfo(brandId, vehicleId, modelId)
    dispatch(actions.isLoading(false))
    if (response.status !== 200) {
      return showToast("warning", "Atenção", "Não foi possivel buscar informações sobre o veículo")
    }
    return response.data
  } catch (e) {
    dispatch(actions.isLoading(false))
  }
}

export const verificarCpf = cpf => async dispatch => {
  try {
    dispatch(actions.isLoading(true));
    const data = await repository.verificarCpf(cpf);
    dispatch(actions.isLoading(false));
    return data;
  } catch (e) {
    dispatch(actions.isLoading(false));
    throw e
  }
};

export const editarDados = params => async dispatch => {
  const uid = sessionStorage.getItem("uid")
  const tokenId = sessionStorage.getItem("tokenId")
  try {
    dispatch(actions.isLoading(true))
    const response = await repository.editarDados(params)
    dispatch(authUser(uid, tokenId));
    dispatch(actions.isLoading(false))
    showToast("success", "Sucesso", "Dados alterados com sucesso.")
    return response
  } catch (e) {
    if (e.response && e.response.data && e.response.status === 400) { showToast("warning", "Atenção", e.response.data.error) }
    showToast("warning", "Atenção", "Ocorreu um problema ao atualizar os dados. Tente novamente mais tarde ou entre em contato com o pessego.com.")
    dispatch(actions.isLoading(false))
    return e
  }
}

export const editarCredenciais = params => async dispatch => {
  const uid = sessionStorage.getItem("uid")
  const tokenId = sessionStorage.getItem("tokenId")
  try {
    dispatch(actions.isLoading(true))
    const response = await repository.editarCredenciais(params)
    dispatch(authUser(uid, tokenId));
    dispatch(actions.isLoading(false))
    showToast("success", "Sucesso", "Dados alterados com sucesso.")
    return response
  } catch (e) {
    if (e.response && e.response.data && e.response.status === 400) { showToast("warning", "Atenção", e.response.data.error) }
    showToast("warning", "Atenção", "Ocorreu um problema ao atualizar os dados. Tente novamente mais tarde ou entre em contato com o pessego.com.")
    dispatch(actions.isLoading(false))
    return e
  }
}

// -----

// export const getReferenceTable = period => async dispatch => {
//   try {
//     const response = await repository.getReferenceTable()
//     const reference = response.data.find(item => item.Mes === period.toLowerCase())
//     dispatch(actions.successGetReferenceDate(reference))
//     return reference
//   } catch (e) { }
// }

// export const getConsultarMarcas = params => async dispatch => {
//   try {
//     dispatch(actions.isLoading(true))
//     const response = await repository.getConsultarMarcas(params)
//     dispatch(actions.isLoading(false))
//     if (!response.data.length || response.status !== 200) {
//       return showToast("warning", "Atenção", "Não foi possivel buscar as marcas do veículo")
//     }
//     dispatch(actions.successGetBrands(response.data))
//   } catch (e) {
//     showToast("warning", "Atenção", "Não foi possivel buscar as marcas do veículo")
//     dispatch(actions.isLoading(false))
//   }
// }

// export const getConsultarModelos = params => async dispatch => {
//   try {
//     dispatch(actions.isLoading(true))
//     const response = await repository.getConsultarModelos(params)
//     dispatch(actions.isLoading(false))
//     if ((!response.data.length && !response.data.Modelos) || response.status !== 200) {
//       return showToast("warning", "Atenção", "Não foi possivel buscar os modelos do veículo")
//     }
//     dispatch(actions.successGetModels(response.data.Modelos))
//   } catch (e) {
//     dispatch(actions.isLoading(false))
//   }
// }

// export const getConsultarAnoModelo = params => async dispatch => {
//   try {
//     dispatch(actions.isLoading(true))
//     const response = await repository.getConsultarAnoModelo(params)
//     dispatch(actions.isLoading(false))
//     if (!response.data.length || response.status !== 200) {
//       return showToast("warning", "Atenção", "Não foi possivel buscar o ano do veículo")
//     }
//     dispatch(actions.successGetYearModel(response.data))
//   } catch (e) {
//     dispatch(actions.isLoading(false))
//   }
// }

// export const getConsultarValorComTodosParametros = params => async dispatch => {
//   try {
//     dispatch(actions.isLoading(true))
//     const response = await repository.getConsultarValorComTodosParametros(params)
//     dispatch(actions.isLoading(false))
//     dispatch(actions.successGetSelectedVehicle(response.data))
//   } catch (e) {
//     dispatch(actions.isLoading(false))
//   }
// }


