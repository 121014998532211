import React from "react";
import { Box, Button } from "../../../../components/ds/SubatomicParticles";
import { H3, P } from "../../../../components/ds/theme/typography";

const ModalSuccessPaymentQuotation = ({ redirect, name, onRedirect }) => {
  return (
    <Box
      backgroundColor="background.default"
      borderRadius="12px"
      maxWidth="415px"
      padding="2rem"
      position="fixed"
      styling="cloumn"
      width="100%"
      zIndex="2"
    >
      <H3 styling="light">
        Parabéns {name}, seu seguro foi adquirido!
      </H3>
      <P my="30px" styling="base">Aguarde que um email chegará com as informaçōes sobre sua nova aquisição.</P>
      <Button display="block" margin="0 auto" onClick={onRedirect} styling="primary" width="90%">Ok, obrigado(a)!</Button>
    </Box>
  );
};

export default ModalSuccessPaymentQuotation;
