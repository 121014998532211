import React, { memo, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch, useParams, useLocation } from 'react-router-dom';
import { Formik, Field, FieldProps, Form as FormikForm } from 'formik';
import {
  getTheme, editTheme, createTheme
} from '../../../../state/context/themes/services';
import { getClients } from '../../../../state/context/clients/services';
import ColorPicker from '../../../../components/ColorPicker';
import { Box, Button } from '../../../../components/ds/SubatomicParticles';
import { H3 } from '../../../../components/ds/theme/typography';
import { Schema, initialValues } from './schema';
import Breadcrumbs from '../../../../components/ds/atoms/Breadcrumbs';
import Color from './Color';
import { Props, State, Option } from './types';
import FormSelect from '../../../../components/FormSelect';
import Loading from '../../../../components/SmallLoading';
import ThemesExamples from '../ThemesExamples';

const Form: React.FC<Props> = props => {
  const location = useLocation();
  const { id } = useParams();
  const matchEdit = useRouteMatch('/admin/themes/edit/:id');

  const [dataForm, setDataForm] = useState<any>(initialValues);
  const [contractor, setContractor] = useState<Option>(null)

  const steps = useMemo(() => {
    if (matchEdit) {
      return [
        {
          path: '/admin/themes',
          name: 'Temas',
        },
        {
          path: location.pathname,
          name: 'Editar',
        },
      ];
    }
    return [
      {
        path: '/admin/themes',
        name: 'Temas',
      },
      {
        path: location.pathname,
        name: 'Cadastrar',
      },
    ];
  }, [location, matchEdit]);

  useEffect(() => {
    (async function loadingPage() {
      if (id && matchEdit) {
        const theme = await props.getTheme(id);
        setDataForm(theme);
      } else {
        props.getClients();
      }
    })();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Box
        styling="column"
        width="100%"
        height="100%"
        padding="30px"
        position="relative"
        align-items="flex-start"
      >
        <Breadcrumbs
          styling="base"
          steps={steps}
          backPath="/admin/themes"
        />
        <Box
          styling="column"
          width="100%"
          minHeight="calc(100vh - 108px)"
          overflow="hidden"
          position="relative"
          justifyContent="flex-start"
          pt="30px"
        >
          {!matchEdit && (
            <Box styling="row" width="20%" mb="30px">
              <FormSelect
                styling="base"
                name="select-contractor"
                placeholder="Selecione o Contratante"
                value={contractor}
                onChange={setContractor}
                options={props.clients.map(client => ({ ...client, label: client.name, value: client.domain }))}
              />
            </Box>
          )}
          <Formik
            enableReinitialize
            initialValues={dataForm}
            validationSchema={Schema}
            onSubmit={
              async (values, { setSubmitting }) => {
                if (id && matchEdit) {
                  await props.editTheme(id, values);
                  setSubmitting(false);
                } else {
                  if (contractor && contractor.id) {
                    await props.createTheme(contractor.id, values);
                    setSubmitting(false);
                  }
                }
              }
            }
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <FormikForm translate='no' style={{ height: "100%", width: "100%" }}>
                <Box
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  mb="15px"
                  height="calc(100vh - 190px)"
                  overflowY="scroll"
                  styling="column"
                >
                  <Box
                    styling="grid"
                    gridTemplateColumns="repeat(5, 1fr)"
                    gridGap="30px"
                    minHeight="345px"
                    width="100%"
                  >
                    {values['primary'] && (
                      <Box styling="column" alignItems="flex-start">
                        <H3
                          color="#1B2B37"
                          fontSize="16px"
                          lineHeight="20px"
                          mb="15px"
                          styling="base"
                        >
                          Primary
                    </H3>
                        <Color name="primary" contrast={false} />
                      </Box>
                    )}
                    {values['secondary'] && (
                      <Box styling="column" alignItems="flex-start">
                        <H3
                          color="#1B2B37"
                          fontSize="16px"
                          lineHeight="20px"
                          mb="15px"
                          styling="base"
                        >
                          Secondary
                    </H3>
                        <Color name="secondary" light={false} contrast={false} />
                      </Box>
                    )}
                    {values['success'] && (
                      <Box styling="column" alignItems="flex-start">
                        <H3
                          color="#1B2B37"
                          fontSize="16px"
                          lineHeight="20px"
                          mb="15px"
                          styling="base"
                        >
                          Success
                    </H3>
                        <Color name="success" dark={false} light={false} contrast={false} />
                      </Box>
                    )}
                    {/* {values['info'] && (
                      <Box styling="column" alignItems="flex-start">
                        <H3
                          color="#1B2B37"
                          fontSize="16px"
                          lineHeight="20px"
                          mb="15px"
                          styling="base"
                        >
                          Info
                    </H3>
                        <Color name="info" />
                      </Box>
                    )} */}
                    {/* {values['warning'] && (
                      <Box styling="column" alignItems="flex-start">
                        <H3
                          color="#1B2B37"
                          fontSize="16px"
                          lineHeight="20px"
                          mb="15px"
                          styling="base"
                        >
                          Warning
                    </H3>
                        <Color name="warning" />
                      </Box>
                    )} */}
                    {values['danger'] && (
                      <Box styling="column" alignItems="flex-start">
                        <H3
                          color="#1B2B37"
                          fontSize="16px"
                          lineHeight="20px"
                          mb="15px"
                          styling="base"
                        >
                          Danger
                    </H3>
                        <Color name="danger" dark={false} light={false} contrast={false} />
                      </Box>
                    )}
                    {values['background'] && (
                      <Box styling="column" alignItems="flex-start">
                        <H3
                          color="#1B2B37"
                          fontSize="16px"
                          lineHeight="20px"
                          mb="15px"
                          styling="base"
                        >
                          Background
                    </H3>
                        <Color name="background" />
                      </Box>
                    )}
                    {values['text'] && (
                      <Box styling="column" alignItems="flex-start">
                        <H3
                          color="#1B2B37"
                          fontSize="16px"
                          lineHeight="20px"
                          mb="15px"
                          styling="base"
                        >
                          Text
                          </H3>
                        <Color name="text" />
                      </Box>
                    )}
                    {values['grays'] && (
                      <Box styling="column" alignItems="flex-start">
                      <H3
                        color="#1B2B37"
                        fontSize="16px"
                        lineHeight="20px"
                        mb="15px"
                        styling="base"
                      >
                        Grays
                        </H3>
                      <Color name="grays" contrast={false} />
                    </Box>
                    )}
                    {/* {values['whites'] && (
                      <Box styling="column" alignItems="flex-start">
                        <H3
                          color="#1B2B37"
                          fontSize="16px"
                          lineHeight="20px"
                          mb="15px"
                          styling="base"
                        >
                          Whites
                          </H3>
                        <FieldArray
                          name="whites"
                          render={() => (
                            values.whites.map((index: number) => (
                              <Field key={index} name={`whites.${index}`}>
                                {({ field }: FieldProps) => (
                                  <Box styling="row" mb="10px" >
                                    <H3
                                      color="#1B2B37"
                                      fontSize="12px"
                                      lineHeight="16px"
                                      mr="10px"
                                      styling="base"
                                    >
                                      {index + 1}:
                                      </H3>
                                    <ColorPicker {...field} />
                                  </Box>
                                )}
                              </Field>
                            ))
                          )}
                        />
                      </Box>
                    )} */}
                    {/* {values['primaries'] && (
                      <Box styling="column" alignItems="flex-start">
                        <H3
                          color="#1B2B37"
                          fontSize="16px"
                          lineHeight="20px"
                          mb="15px"
                          styling="base"
                        >
                          Primaries
                          </H3>
                        <FieldArray
                          name="primaries"
                          render={() => (
                            values.primaries.map((index: number) => (
                              <Field key={index} name={`primaries.${index}`}>
                                {({ field }: FieldProps) => (
                                  <Box styling="row" mb="10px" >
                                    <H3
                                      color="#1B2B37"
                                      fontSize="12px"
                                      lineHeight="16px"
                                      mr="10px"
                                      styling="base"
                                    >
                                      {index + 1}:
                                      </H3>
                                    <ColorPicker {...field} />
                                  </Box>
                                )}
                              </Field>
                            ))
                          )}
                        />
                      </Box>
                    )} */}
                    {/* {values['blacks'] && (
                      <Box styling="column" alignItems="flex-start">
                        <H3
                          color="#1B2B37"
                          fontSize="16px"
                          lineHeight="20px"
                          mb="15px"
                          styling="base"
                        >
                          Blacks
                          </H3>
                        <FieldArray
                          name="blacks"
                          render={() => (
                            values.blacks.map((index: number) => (
                              <Field key={index} name={`blacks.${index}`}>
                                {({ field }: FieldProps) => (
                                  <Box styling="row" mb="10px" >
                                    <H3
                                      color="#1B2B37"
                                      fontSize="12px"
                                      lineHeight="16px"
                                      mr="10px"
                                      styling="base"
                                    >
                                      {index + 1}:
                                      </H3>
                                    <ColorPicker {...field} />
                                  </Box>
                                )}
                              </Field>
                            ))
                          )}
                        />
                      </Box>
                    )} */}
                    {/* {values['hovers'] && (
                      <Box styling="column" alignItems="flex-start">
                        <H3
                          color="#1B2B37"
                          fontSize="16px"
                          lineHeight="20px"
                          mb="15px"
                          styling="base"
                        >
                          Hovers
                          </H3>
                        <FieldArray
                          name="hovers"
                          render={() => (
                            values.hovers.map((index: number) => (
                              <Field key={index} name={`hovers.${index}`}>
                                {({ field }: FieldProps) => (
                                  <Box key={index} styling="row" mb="10px" >
                                    <H3
                                      color="#1B2B37"
                                      fontSize="12px"
                                      lineHeight="16px"
                                      mr="10px"
                                      styling="base"
                                    >
                                      {index + 1}:
                                      </H3>
                                    <ColorPicker {...field} />
                                  </Box>
                                )}
                              </Field>
                            ))
                          )}
                        />
                      </Box>
                    )} */}
                    {values['disabled'] && (
                      <Box styling="column" alignItems="flex-start">
                        <H3
                          color="#1B2B37"
                          fontSize="16px"
                          lineHeight="20px"
                          mb="15px"
                          styling="base"
                        >
                          Disabled
                          </H3>
                        <Field name="disabled">
                          {({ field }: FieldProps<string>) => (
                            <Box styling="row" mb="10px" >
                              <H3
                                color="#1B2B37"
                                fontSize="12px"
                                lineHeight="16px"
                                mr="10px"
                                styling="base"
                              >
                                disabled:
                        </H3>
                              <ColorPicker {...field} />
                            </Box>
                          )}
                        </Field>
                      </Box>
                    )}
                  </Box>
                  <ThemesExamples values={values} />
                </Box>
                <Box
                  styling="row"
                  justifyContent="flex-end">
                  <Button
                    display="block"
                    styling={isSubmitting || (!matchEdit && !contractor) ? "disabled" : "outlineAdmin"}
                    type="submit"
                    borderRadius="4px"
                    px="30px"
                    disabled={isSubmitting || (!matchEdit && !contractor)}
                  >
                    Salvar
              </Button>
                </Box>
              </FormikForm>
            )}
          </Formik>
        </Box>
      </Box>
      {props.isLoading && (
        <Box
          styling="column"
          height="100vh"
          width="100%"
          zIndex={99999}
          bg="rgba(240, 241, 242, 0.62)"
          position="absolute"
        >
          <Loading />
        </Box>
      )}
    </>
  )
};

const mapStateToProps = (state: State) => ({
  clients: state.clients.clients,
  isLoading: state.themes.isLoading
});


const mapDispatchToProps = {
  getTheme,
  editTheme,
  createTheme,
  getClients,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(Form as any));