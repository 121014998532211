import * as Yup from 'yup';

export const Schema = Yup.object().shape({
  about1: Yup.object().shape({
    url: Yup.string()
  }),
  contact1: Yup.object().shape({
    url: Yup.string()
  }),
  generic1: Yup.object().shape({
    url: Yup.string()
  }),
  main1: Yup.object().shape({
    url: Yup.string()
  }),
  main2: Yup.object().shape({
    url: Yup.string()
  }),
  logo: Yup.object().shape({
    url: Yup.string()
  }),
  logoFull: Yup.object().shape({
    url: Yup.string()
  }),
});

export const initialValues = {

}