import React from "react";
import { showToast } from "../../../utils";
import { validateVerify } from "../../../utils/validations";
import { fieldNotFilledAlert } from '../../../utils';
import FormInput from "../../../components/FormInput";
import { Box, Button } from "../../../components/ds/SubatomicParticles";
import { H3 } from "../../../components/ds/theme/typography";

export default function Step2({
  onNext,
  senha,
  confirmSenha,
}) {

  const _handleVerify = () => {
    if (senha.value !== "" && confirmSenha.value !== "") {
      if (senha.value.length >= 6) {
        if (senha.value === confirmSenha.value) {
          onNext({ senha: senha.value });
        } else {
          showToast("warning", "Aviso", "Senhas não coincidem");
        }
      } else {
        showToast("warning", "Aviso", "A senha deve possuir no mínimo 6 caracteres");
      }
    } else {
      if (senha.value === "" && confirmSenha.value === "") {
        return showToast("warning", "Aviso", "Verifique os campos para continuar.");
      }
      if (senha.value === "") {
        fieldNotFilledAlert('Senha')
      }
      if (confirmSenha.value === "") {
        fieldNotFilledAlert('Confirmar senha')
      };
    };
  };

  return (
    <Box gridColumn={{ mobile: '2/span 10', tablet: '2/span 10', desktop: '4/span 6' }} styling="column">
      <H3 mb="30px" styling="light">Agora vamos criar uma senha</H3>
      <FormInput
        {...senha}
        data-testid="senha"
        id="senha"
        maxLength={50}
        minLentth={6}
        name="senha"
        onBlur={e => validateVerify(e)}
        placeholder="Senha"
        type="password"
      />
      <FormInput
        {...confirmSenha}
        data-testid="confirmSenha"
        id="confirmSenha"
        maxLength={50}
        minLentth={6}
        name="confirmSenha"
        onBlur={e => validateVerify(e)}
        placeholder="Confirmar Senha"
        type="password"
      />
      <Button display="block" margin="0 auto" onClick={_handleVerify} styling="primary" width="295px">Próximo</Button>
    </Box>
  );
}
