/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import SmallLoading from "../../../components/SmallLoading";
import { ReactComponent as ChevronRight } from "../../../assets/svg/chevron.svg";
import { Box, Button } from "../../../components/ds/SubatomicParticles";
import Card from "../../../components/ds/atoms/Card";
import { H3, P, Span } from "../../../components/ds/theme/typography";

const AutoConfirm = ({
  getVehicles,
  myVehiclesList,
  myVehiclesLoading,

  selectedAuto,
  setSelectedAuto,
  onRegisterAuto,
  removeVehicle,
  onNext,
  onFail,
}) => {
  useEffect(() => {
    getVehicles();
  }, []);

  return (
    <Box
      gridColumn={{
        mobile: "2/span 10",
        tablet: "2/span 10",
        desktop: "4/span 6",
      }}
      styling="column"
      p={"5rem 0"}
    >
      <H3 mb="30px" styling="light">
        Lista de Automóveis
      </H3>

      {myVehiclesList.length === 0 && !myVehiclesLoading && (
        <Box width="100%" display="flex" justifyContent="center">
          <P styling="base">Você ainda não tem nenhum automóvel cadastrado.</P>
        </Box>
      )}
      {myVehiclesLoading && myVehiclesList.length === 0 ? (
        <Box display="flex" justifyContent="center" width="100%">
          <SmallLoading />
        </Box>
      ) : (
          <>
            {myVehiclesList.map((item, i) => (
              <Box
                width="100%"
                display="flex"
                flexDirection="column"
                key={`card${i}`}
              >
                <Card
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" flexDirection="column" onClick={() =>
                    setSelectedAuto(item.vehicleId) || onNext(item.vehicleId)
                  }>
                    <Span className="title"> {item.description}</Span>
                    <P className="description">
                      {item.vehicleData.ManufactureYear}/
                    {item.vehicleData.ModelYear}
                    </P>
                    <P className="description">
                      Placa:{" "}
                      {item.vehicleData.Plate === ""
                        ? "Não informada"
                        : item.vehicleData.Plate}{" "}
                    - Chassi: {item.vehicleData.Chassis}
                    </P>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <ChevronRight style={{ width: 15, height: 15 }} />
                  </Box>
                  <Button onClick={async () => {
                    await removeVehicle({ vehicleId: item.vehicleId })
                    getVehicles();
                  }}
                    style={{
                      width: 120,
                      height: 40,
                      backgroundColor: "white",
                      borderStyle: "solid",
                      borderColor: "#F26522",
                      borderWidth: 2,
                      borderRadius: 20,
                    }}>
                    <div style={{ color: "#F26522", fontWeight: "bold", fontSize: 18 }}>Remover</div>
                  </Button>
                </Card>
              </Box>
            ))}
          </>
        )
      }
      <Button styling="primary" onClick={onRegisterAuto} width="296px" mt={25}>
        Cadastrar novo Automóvel
      </Button>
    </Box >
  );
};

export default AutoConfirm;
