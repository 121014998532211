import * as actions from "./actions";
import * as repository from "./repository";
import handleError from "../../../utils/handleError";
import { showToast } from '../../../utils';

export const getListRaces = (type) => async (dispatch) => {
  try {
    dispatch(actions.isLoading(true));
    const data = await repository.getListRaces(type);
    dispatch(actions.isLoading(false));
    return data;
  } catch (e) {
    dispatch(actions.isLoading(false));
  }
};

export const setPets = (pets) => async (dispatch) => {
  try {
    dispatch(actions.isLoading(true));
    const data = await repository.setPets(pets);
    dispatch(actions.isLoading(false));
    return data;
  } catch (e) {
    dispatch(actions.isLoading(false));
  }
};

export const setResidence = (residence) => async (dispatch) => {
  try {
    dispatch(actions.isLoadingQuotationValues(true));
    const data = await repository.setResidence(residence);
    dispatch(actions.isLoadingQuotationValues(false));
    return data;
  } catch (e) {
    dispatch(actions.isLoadingQuotationValues(false));
    return e;
  }
};

export const getListFormasDeViagem = () => async (dispatch) => {
  try {
    dispatch(actions.isLoading(true));
    const response = await repository.getListFormasDeViagem();
    dispatch(actions.successGetTripTypes(response.data));
    dispatch(actions.isLoading(false));
    return response;
  } catch (e) {
    dispatch(actions.isLoading(false));
    handleError();
  }
};

export const getListContinent = () => async (dispatch) => {
  try {
    dispatch(actions.isLoading(true));
    const response = await repository.getListContinent();
    dispatch(actions.successGetContinents(response.data));
    dispatch(actions.isLoading(false));
    return response;
  } catch (e) {
    dispatch(actions.isLoading(false));
    handleError();
  }
};

export const createViagem = (body) => async (dispatch) => {
  try {
    dispatch(actions.isLoading(true));
    const data = await repository.createViagem(body);
    dispatch(actions.isLoading(false));
    return data;
  } catch (e) {
    dispatch(actions.isLoading(false));
  }
};

export const createVida = (body) => async (dispatch) => {
  try {
    dispatch(actions.isLoading(true));
    const data = await repository.createVida(body);
    dispatch(actions.isLoading(false));
    return data;
  } catch (e) {
    dispatch(actions.isLoading(false));
  }
};
export const createBike = (body) => async (dispatch) => {
  try {
    dispatch(actions.isLoading(true));
    const data = await repository.createBike(body);
    dispatch(actions.isLoading(false));
    return data;
  } catch (e) {
    dispatch(actions.isLoading(false));
  }
};

export const getListTiposPortateis = () => async (dispatch) => {
  try {
    dispatch(actions.isLoading(true));
    const response = await repository.getListTiposPortateis();
    dispatch(actions.successGetPortableTypes(response));
    dispatch(actions.isLoading(false));
    return response;
  } catch (e) {
    dispatch(actions.isLoading(false));
    throw e;
  }
};

export const getListMarcasPortateis = (tipo) => async (dispatch) => {
  try {
    dispatch(actions.isLoading(true));
    const data = await repository.getListMarcasPortateis(tipo);
    dispatch(actions.isLoading(false));
    return data;
  } catch (e) {
    dispatch(actions.isLoading(false));
  }
};

export const getListModelosPortateis = (tipo, marca) => async (dispatch) => {
  try {
    dispatch(actions.isLoading(true));
    const data = await repository.getListModeloPortateis(tipo, marca);
    dispatch(actions.isLoading(false));
    return data;
  } catch (e) {
    dispatch(actions.isLoading(false));
  }
};

export const createPortateis = (body) => async (dispatch) => {
  try {
    dispatch(actions.isLoading(true));
    const data = await repository.createPortateis(body);
    dispatch(actions.isLoading(false));
    return data;
  } catch (e) {
    dispatch(actions.isLoading(false));
  }
};

export const getListMarcasAuto = () => async (dispatch) => {
  try {
    dispatch(actions.isLoading(true));
    const data = await repository.getListMarcasAuto();
    dispatch(actions.isLoading(false));
    return data;
  } catch (e) {
    dispatch(actions.isLoading(false));
  }
};

export const getListModeloAuto = (marca) => async (dispatch) => {
  try {
    dispatch(actions.isLoading(true));
    const data = await repository.getListModeloAuto(marca);
    dispatch(actions.isLoading(false));
    return data;
  } catch (e) {
    dispatch(actions.isLoading(false));
  }
};

export const createAuto = (body) => async (dispatch) => {
  try {
    dispatch(actions.isLoadingQuotationValues(true));
    const data = await repository.createAuto(body);
    dispatch(actions.isLoadingQuotationValues(false));
    return data;
  } catch (e) {
    dispatch(actions.isLoadingQuotationValues(false));
    return e;
  }
};

export const createSinister = (body) => async (dispatch) => {
  try {
    dispatch(actions.isLoading(true));
    const data = await repository.createSinister(body);
    dispatch(actions.isLoading(false));
    return data;
  } catch (error) {
    dispatch(actions.isLoading(false));
    throw error;
  }
};

export const getVehicleQuestionnaireStep1 = (questionnaireTypes) => async (
  dispatch,
) => {
  try {
    dispatch(actions.isLoading(true));
    const data = await repository.getVehicleQuestionnaireStep1(
      questionnaireTypes,
    );
    dispatch(actions.isLoading(false));
    return data;
  } catch (e) {
    dispatch(actions.isLoading(false));
    throw e;
  }
};

export const getVehicleQuestionnaireStep3 = () => async (dispatch) => {
  try {
    dispatch(actions.isLoading(true));
    const response = await repository.getVehicleQuestionnaireStep3();
    dispatch(actions.isLoading(false));
    return response.data;
  } catch (e) {
    dispatch(actions.isLoading(false));
    throw e;
  }
};

export const getVehicleConstants = (type) => async (dispatch) => {
  dispatch(actions.isLoading(true));
  const data = await repository.getVehicleConstants(type);
  dispatch(actions.isLoading(false));
  return data;
};

export const callMe = (obj) => async (dispatch) => {
  try {
    dispatch(actions.isLoading(true));
    const response = await repository.callMePost(obj);
    dispatch(actions.isLoading(false));
    return response;
  } catch (e) {
    dispatch(actions.isLoading(false));
    return e;
  }
};

export const getRoles = () => async (dispatch) => {
  try {
    dispatch(actions.isLoading(true));
    const response = await repository.getResidenceConstants(
      "insuredProfession",
    );
    dispatch(actions.successGetRoles(response.data));
    dispatch(actions.isLoading(false));
  } catch (e) {
    dispatch(actions.isLoading(false));
    throw e;
  }
};

export const getRangeOfPayment = () => async (dispatch) => {
  try {
    dispatch(actions.isLoading(true));
    const response = await repository.getResidenceConstants(
      "insuredIncomeBracket",
    );
    dispatch(actions.successGetRangePayment(response.data));
    dispatch(actions.isLoading(false));
  } catch (e) {
    dispatch(actions.isLoading(false));
    throw e;
  }
};

export const getMaritalState = () => async (dispatch) => {
  try {
    dispatch(actions.isLoading(true));
    const response = await repository.getResidenceConstants("maritalStatus");
    dispatch(actions.successGetMaritalState(response.data));
    dispatch(actions.isLoading(false));
  } catch (e) {
    dispatch(actions.isLoading(false));
    throw e;
  }
};

export const getGeneralRegistryState = () => async (dispatch) => {
  try {
    dispatch(actions.isLoading(true));
    const response = await repository.getResidenceConstants("issuerState");
    dispatch(actions.successGetGeneralRegistryState(response.data));
    dispatch(actions.isLoading(false));
  } catch (e) {
    dispatch(actions.isLoading(false));
    throw e;
  }
};

export const getResidenceCoverage = () => async (dispatch) => {
  try {
    dispatch(actions.isLoadingResidenceCoverages(true));
    const response = await repository.getResidenceCoverages();
    dispatch(actions.successGetResidenceCoverages(response.data));
    dispatch(actions.isLoadingResidenceCoverages(false));
  } catch (e) {
    dispatch(actions.isLoadingResidenceCoverages(false));
    throw e;
  }
};

export const checkToken = () => async (dispatch) => {
  try {
    await repository.getResidenceConstants("maritalStatus");
  } catch (e) {
    if (e && e.response && e.response.status) {
      return e.response.status;
    }
  }
};

export const buyInsuranceResidence = (body) => async (dispatch) => {
  try {
    dispatch(actions.isLoading(true));
    dispatch(actions.isBuyingQuotation(true));
    const response = await repository.buyResidenceInsurance(body);
    dispatch(actions.isBuyingQuotation(false));
    dispatch(actions.isLoading(false));
    return response;
  } catch (e) {
    dispatch(actions.isLoading(false));
    return e;
  }
};

export const buyInsuranceVehicle = (body) => async (dispatch) => {
  try {
    dispatch(actions.isLoading(true));
    dispatch(actions.isBuyingQuotation(true));
    const response = await repository.buyVehicleInsurance(body);
    dispatch(actions.isBuyingQuotation(false));
    dispatch(actions.isLoading(false));
    return response;
  } catch (e) {
    dispatch(actions.isLoading(false));
    return e;
  }
};

export const getAutoInsuranceTypes = () => async (dispatch) => {
  try {
    dispatch(actions.isLoadingAutoInsuranceTypes(true));
    const response = await repository.getAutoInsuranceTypes();
    dispatch(actions.successGetAutoInsuranceTypes(response.data));
    dispatch(actions.isLoadingAutoInsuranceTypes(false));
  } catch (e) {
    dispatch(actions.isLoadingAutoInsuranceTypes(false));
    throw e;
  }
};

export const getVehiclesCoverages = () => async (dispatch) => {
  try {
    dispatch(actions.isLoadingAutoCoverages(true));
    const response = await repository.getVehiclesCoverages();
    dispatch(actions.successGetAutoCoverages(response.data));
    dispatch(actions.isLoadingAutoCoverages(false));
  } catch (e) {
    dispatch(actions.isLoadingAutoCoverages(false));
    throw e;
  }
};

export const newLead = (body) => async (dispatch) => {
  try {
    dispatch(actions.isLoading(true));
    const response = await repository.newLead(body);
    dispatch(actions.isLoading(false));
    return response;
  } catch (e) {
    dispatch(actions.isLoading(false));
  }
};

export const updateLead = (body) => async (dispatch) => {
  try {
    await repository.updateLead(body);
  } catch (e) {
    throw e;
  }
};

export const sendContactMessage = (obj) => async (dispatch) => {
  try {
    dispatch(actions.isLoading(true));
    const response = await repository.sendContactMessage(obj);
    dispatch(actions.isLoading(false));
    return response;
  } catch (e) {
    dispatch(actions.isLoading(false));
    return e;
  }
};

export const getVehicleByPlateOrChassis = (type, value) => async (dispatch) => {
  try {
    dispatch(actions.isLoading(true));
    const response = await repository.getVehicleByPlateOrChassis(type, value);
    dispatch(actions.isLoading(false));
    return response.data;
  } catch (e) {
    dispatch(actions.isLoading(false));
    if (e && e.response && e.response.data && e.response.code === 404) {
      return e.response;
    } else{
      showToast(
        "error",
        "Ops :(",
        "Algo deu errado"
      );
      throw e;
    }
  }
};

export const openVehicleQuotation = (body) => async (dispatch) => {
  try {
    dispatch(actions.isLoading(true));
    dispatch(actions.isBuyingQuotation(true));
    const response = await repository.openVehicleQuotation(body);
    dispatch(actions.isBuyingQuotation(false));
    dispatch(actions.isLoading(false));
    return response;
  } catch (e) {
    dispatch(actions.isBuyingQuotation(false));
    dispatch(actions.isLoading(false));
    return e;
  }
};

export const openResidenceQuotation = (body) => async (dispatch) => {
  try {
    dispatch(actions.isLoadingQuotationValues(true));
    const response = await repository.openResidenceQuotation(body);
    dispatch(actions.isLoadingQuotationValues(false));
    return response;
  } catch (e) {
    dispatch(actions.isLoadingQuotationValues(false));
    return e;
  }
};

export const openGadgetsQuotation = (body) => async (dispatch) => {
  try {
    dispatch(actions.isLoadingQuotationValues(true));
    const response = await repository.openGadgetsQuotation(body);
    dispatch(actions.isLoadingQuotationValues(false));
    return response;
  } catch (e) {
    dispatch(actions.isLoadingQuotationValues(false));
    return e;
  }
};

export const openTravelQuotation = (body) => async (dispatch) => {
  try {
    dispatch(actions.isLoadingQuotationValues(true));
    const response = await repository.openTravelQuotation(body);
    dispatch(actions.isLoadingQuotationValues(false));
    return response;
  } catch (e) {
    dispatch(actions.isLoadingQuotationValues(false));
    return e;
  }
};

export const openBikeQuotation = (body) => async (dispatch) => {
  try {
    dispatch(actions.isLoadingQuotationValues(true));
    const response = await repository.openBikeQuotation(body);
    dispatch(actions.isLoadingQuotationValues(false));
    return response;
  } catch (e) {
    dispatch(actions.isLoadingQuotationValues(false));
    return e;
  }
};

export const openPetQuotation = (body) => async (dispatch) => {
  try {
    dispatch(actions.isLoadingQuotationValues(true));
    const response = await repository.openPetQuotation(body);
    dispatch(actions.isLoadingQuotationValues(false));
    return response;
  } catch (e) {
    dispatch(actions.isLoadingQuotationValues(false));
    return e;
  }
};

export const openLifeQuotation = (body) => async (dispatch) => {
  try {
    dispatch(actions.isLoadingQuotationValues(true));
    const response = await repository.openLifeQuotation(body);
    dispatch(actions.isLoadingQuotationValues(false));
    return response;
  } catch (e) {
    dispatch(actions.isLoadingQuotationValues(false));
    return e;
  }
};
