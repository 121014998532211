/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import moment from "moment";
import {
  validateVerify,
  checkLengthOfDate,
  // getRawPhone,
  autoCorrectedDatePipe
} from "../../../utils/validations";
import { fieldNotFilledAlert, dateMask } from "../../../utils";
import { Box, Button } from "../../../components/ds/SubatomicParticles";
import { H3 } from "../../../components/ds/theme/typography";
import FormSelect from "../../../components/FormSelect";
import FormInput from "../../../components/FormInput";
import TextMaskFormInput from "../../../components/TextMaskFormInput";

export default function ResidenceBeneficiaryInfo({
  onComplete,
  IndividualNumber,
  setIndividualNumber,
  IndNumIssuanceDate,
  setIndNumIssuanceDate,
  IndNumIssuer,
  setIndNumIssuer,
  IndNumIssuerState,
  setIndNumIssuerState,
  MaritalStatus,
  setMaritalStatus,
  OccupationCode,
  setOccupationCode,
  RangeMonthlyIncomeCode,
  setRangeMonthlyIncomeCode,
  Gender,
  setGender,
  roles,
  rangePayments,
  maritalStateList,
  maritalStateLoading,
  generalRegistryList,
  generalRegistryLoading,
  onMountBeneficiaryInfo,
  setPoliticallyExposedPerson,
  PoliticallyExposedPerson
}) {
  const handleVerification = () => {
    return (
      !!IndNumIssuanceDate &&
      checkLengthOfDate(IndNumIssuanceDate) === 8 &&
      IndividualNumber &&
      IndNumIssuer &&
      IndNumIssuerState !== "0" &&
      IndNumIssuerState !== "" &&
      MaritalStatus !== "0" &&
      MaritalStatus &&
      RangeMonthlyIncomeCode &&
      OccupationCode &&
      OccupationCode !== "0" &&
      Gender &&
      PoliticallyExposedPerson
    );
  };

  const _handleIncomplete = () => {
    if (MaritalStatus === "0" || !MaritalStatus)
      fieldNotFilledAlert("Situação Civil");
    if (!Gender) fieldNotFilledAlert("Sexo do segurado");
    if (!IndividualNumber) fieldNotFilledAlert("RG");
    if (!IndNumIssuanceDate || checkLengthOfDate(IndNumIssuanceDate) !== 8)
      fieldNotFilledAlert("Data de expedição");
    if (!IndNumIssuer) fieldNotFilledAlert("Órgão expedidor");
    if (!IndNumIssuerState) fieldNotFilledAlert("UF expedidora");
    if (!OccupationCode || OccupationCode === "0")
      fieldNotFilledAlert("Profissão do segurado");
    if (!RangeMonthlyIncomeCode)
      fieldNotFilledAlert("Faixa de renda do segurado");
    if (!PoliticallyExposedPerson)
      fieldNotFilledAlert("Declaro ser, ou ter relacionamento com Pessoa Politicamente Exposta Estrangeira?");
  };

  const _handleVerify = () => {
    if (handleVerification()) {
      onComplete({
        IndividualNumber,
        IndNumIssuanceDate: moment(IndNumIssuanceDate, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        IndNumIssuer,
        IndNumIssuerState,
        MaritalStatus,
        Gender,
        RangeMonthlyIncomeCode,
        OccupationCode
      });
    } else {
      _handleIncomplete();
    }
  };

  useEffect(() => {
    onMountBeneficiaryInfo();
  }, []);

  return (
    <>
      <Box
        gridColumn={{
          mobile: "2/span 10",
          tablet: "2/span 10",
          desktop: "4/span 6",
        }}
        styling="column"
      >
        <H3 mb="30px" styling="light">Informações do Beneficiário</H3>
        <Box display="flex" width={"100%"} flexDirection="column">

        <FormSelect
            defaultValue={PoliticallyExposedPerson}
            onChange={(e) => setPoliticallyExposedPerson(e.value)}
            onBlur={(e) => validateVerify(e)}
            placeholder="Declaro ser, ou ter relacionamento com Pessoa Politicamente Exposta
            Estrangeira?"
            options={[
                {
                label: "Selecione",
                value: "",
                },
                {
                label: "Não",
                value: "N",
                },
                {
                label: "Sim",
                value: "S",
                },
            ]}
          />
        <Box justifyContent="space-between" styling="row" width="100%">
          <Box styling="row" width="48%">
            <FormSelect
              data-testid="MaritalStatus"
              id="MaritalStatus"
              name="MaritalStatus"
              onBlur={e => validateVerify(e)}
              onChange={(e) => setMaritalStatus(e.value)}
              options={[
                { value: 0, label: 'Selecione' },
                { value: 'S', label: 'Solteiro' },
                { value: 'M', label: 'Casado' },
                { value: 'W', label: 'Viúvo' },
                { value: 'D', label: 'Divorciado' },
              ]}
              placeholder="Estado civil"
              styling="base"
              defaultValue={MaritalStatus}
            />
          </Box>
          <Box styling="row" width="48%">
            <FormSelect
              data-testid="gender"
              id="gender"
              name="gender"
              onBlur={e => validateVerify(e)}
              onChange={(e) => setGender(e.value)}
              options={[
                { value: '', label: 'Selecione' },
                { value: 'F', label: 'Feminino' },
                { value: 'M', label: 'Masculino' }
              ]}
              placeholder="Sexo"
              styling="base"
              defaultValue={Gender}
            />
          </Box>
        </Box>
        <Box justifyContent="space-between" styling="row" width="100%">
          <Box styling="row" width="48%">
            <FormInput
              data-testid="individualNumber"
              id="individualNumber"
              mask="999999999"
              name="individualNumber"
              onBlur={e => validateVerify(e)}
              onChange={e => setIndividualNumber(e.target.value)}
              placeholder="RG"
              type="text"
              value={IndividualNumber}
            />
          </Box>
            <Box styling="row" width="48%">
              <TextMaskFormInput
                data-testid="IndNumIssuanceDate"
                id="IndNumIssuanceDate"
                mask={dateMask}
                name="IndNumIssuanceDate"
                placeholder="Data de expedição"
                onBlur={e => validateVerify(e)}
                onChange={e => setIndNumIssuanceDate(e.target.value)}
                type="text"
                value={IndNumIssuanceDate}
                pipe={date => autoCorrectedDatePipe(date, false)}
              />
            </Box>
          </Box>
          <Box justifyContent="space-between" styling="row" width="100%">
            <Box styling="row" width="48%">
              <FormInput
                data-testid="IndNumIssuer"
                id="IndNumIssuer"
                name="IndNumIssuer"
                onBlur={e => validateVerify(e)}
                onChange={e => setIndNumIssuer(e.target.value)}
                placeholder="Órgão expedidor"
                type="text"
                value={IndNumIssuer}
              />
            </Box>
            <Box styling="row" width="48%">
              <FormSelect
                data-testid="IndNumIssuerState"
                id="IndNumIssuerState"
                name="IndNumIssuerState"
                onBlur={e => validateVerify(e)}
                onChange={(e) => setIndNumIssuerState(e.value)}
                options={
                  Object.keys(generalRegistryList).length !== 0 || generalRegistryLoading
                    ? Object.keys(generalRegistryList).map((item) => ({
                      value: item,
                      label: generalRegistryList[item],
                    }))
                    : [{ value: 0, label: "Carregando" }]
                }
                placeholder="UF expedidora"
                styling="base"
                defaultValue={IndNumIssuerState}
              />
            </Box>
          </Box>
          <Box justifyContent="space-between" styling="row" width="100%">
            <Box styling="row" width="48%">
              <FormSelect
                data-testid="OccupationCode"
                id="OccupationCode"
                name="OccupationCode"
                onBlur={e => validateVerify(e)}
                onChange={(e) => setOccupationCode(e.value)}
                options={
                  Object.keys(roles).length !== 0
                    ? Object.keys(roles).map((item) => ({
                      value: item,
                      label: roles[item],
                    }))
                    : [{ value: 0, label: "Carregando" }]
                }
                placeholder="Profissão do beneficiário"
                styling="base"
                defaultValue={OccupationCode}
              />
            </Box>
            <Box styling="row" width="48%">
              <FormSelect
                data-testid="RangeMonthlyIncomeCode"
                id="RangeMonthlyIncomeCode"
                name="RangeMonthlyIncomeCode"
                onBlur={e => validateVerify(e)}
                onChange={(e) => setRangeMonthlyIncomeCode(e.value)}
                options={
                  Object.keys(rangePayments).length !== 0
                    ? Object.keys(rangePayments).map((item) => ({
                      value: item,
                      label: rangePayments[item],
                    }))
                    : [{ value: 0, label: "Carregando" }]
                }
                placeholder="Faixa de renda do beneficiário"
                styling="base"
                defaultValue={RangeMonthlyIncomeCode}
              />
           </Box>
          </Box>
        </Box>
        <Button
          display="block"
          margin="0 auto"
          onClick={_handleVerify}
          styling="primary"
          type="submit"
          width="195px"
        >
          Concluir
        </Button>
      </Box>
    </>
  );
}
