import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { compose } from "recompose"
import cpfValidator from 'cpf'
import moment from "moment"
import { showToast, dateMask } from "../../utils"
import * as userService from "../../state/context/user/services"
import { withRouter } from "react-router-dom"
import { autoCorrectedDatePipe, validateEmail } from "../../utils/validations"
import { Box, Button } from "../../components/ds/SubatomicParticles";
import { P } from "../../components/ds/theme/typography";
import FormInput from "../../components/FormInput";
import TextMaskFormInput from "../../components/TextMaskFormInput";
import FormSelect from "../../components/FormSelect";

function Conta({
  user,
  onComplete,
  editarDados,
  history,
  showModal
}) {
  const checkDiffBirthdayDate = age => moment().diff(moment(age, "DD/MM/YYYY").format('YYYY-MM-DD'), 'years', false) < 17
  const validateVerify = () => {
    cpfCnpj = cpfCnpj.replace(/[.*+?^${}()|[\]\\]/g, "").replace("-", "")
    if (sexo === "") { showToast("warning", "Alerta", "Campo sexo não preenchido.") }
    if (nome === "") { showToast("warning", "Alerta", "Campo nome não preenchido.") }
    if (!cpfValidator.isValid(cpfCnpj)) { showToast("warning", "Alerta", "CPF incorreto.") }
    if (telefoneSplit === "") {
      showToast("warning", "Alerta", "Campo telefone não preenchido.")
    }
    if (telefoneSplit.length !== 12) {
      showToast("warning", "Alerta", "Campo telefone incorreto.")
    }
    if (checkDiffBirthdayDate(dataNascimento)) {
      return showToast("warning", "Alerta", "Cadastro permitido somente para idades iguais ou superiores a 18 anos.")
    }

    if (cpfCnpj === "") { showToast("warning", "Alerta", "Campo CPF não preenchido.") }
    else {
      if (sexo !== "" &&
        nome !== "" &&
        telefoneSplit !== "" &&
        cpfValidator.isValid(cpfCnpj) &&
        telefoneSplit.length === 12
      ) {
        showModal({
          visible: true,
          type: "editarPerfil",
          nome,
          cpfCnpj,
          telefoneSplit,
          sexo,
          dataNascimento: moment(dataNascimento, "DD/MM/YYYY").format("YYYY-MM-DD")
        })
      }

    }
  }

  const verificarEmail = () => {
    if (!newEmail) showToast("warning", "Alerta", "Campo novo E-email não preenchido.")
    else if (!newEmailConfirmation) showToast("warning", "Alerta", "Campo Confirmação do E-mail não preenchido")
    else if (newEmail !== newEmailConfirmation) showToast("warning", "Alerta", "Novo E-mail e confirmação do E-mail não são iguais.")
    else if (!validateEmail(newEmail)) showToast("warning", "Alerta", "Novo E-mail informado é inválido.")
    else showModal({ visible: true, type: "editarPerfil", newEmail, newEmailConfirmation })
  }

  const verificarSenha = () => {
    if (newPassword.length < 6) { showToast("warning", "Alerta", "Nova senha precisa ter no minimo 6 caracteres.") }
    if (newPassword !== newPasswordConfirmation) { showToast("warning", "Alerta", "Nova senha e confirmação da senha não são iguais.") }
    if (newPassword === "") { showToast("warning", "Alerta", "Campo nova senha não preenchido.") }
    if (newPasswordConfirmation === "") { showToast("warning", "Alerta", "Campo Confirmação da senha não preenchido") }
    else if (newPassword.length >= 6 && newPassword === newPasswordConfirmation && newPassword !== undefined && newPasswordConfirmation !== undefined) { showModal({ visible: true, type: "editarPerfil", newPassword, newPasswordConfirmation }) }
  }

  const toggleEditMode = () => {
    setEditMode(!editMode)
    setEditarrDados(!editarrDados)
  }

  const MudarEstados = () => {
    setEditMode(!editMode)
    setEditarrDados(!editarrDados)
    setDataNascimento("")
  }

  user.nome && sessionStorage.setItem("nome", user.nome)
  user.telefone && sessionStorage.setItem("telefone", user.telefone)
  user.cpfCnpj && sessionStorage.setItem("cpfCnpj", user.cpfCnpj)
  user.dataNascimento && sessionStorage.setItem("dataNascimento", user.dataNascimento)
  user.sexo && sessionStorage.setItem("sexo", user.sexo)
  user.email && sessionStorage.setItem("email", user.email)
  user.ddd && sessionStorage.setItem("ddd", user.ddd)
  const nomeAux = sessionStorage.getItem("nome")
  const telefoneAux = sessionStorage.getItem("telefone")
  const cpfAux = sessionStorage.getItem("cpfCnpj")
  let sexoAux = sessionStorage.getItem("sexo")
  const emailAux = sessionStorage.getItem("email")
  const ddd = sessionStorage.getItem("ddd")
  //quando for chama ela lá embaixo user.nome
  const aux = `${ddd} ${telefoneAux}`
  const [editarrDados, setEditarrDados] = useState(true)
  //Minhas credenciais 
  //Minha Conta
  let [nome, setNome] = useState(nomeAux)
  let [cpfCnpj, setCpfCnpj] = useState(cpfAux)
  const [dataNascimento, setDataNascimento] = useState(moment(user.dataNascimento, "YYYY-MM-DD").format("DD/MM/YYYY"))
  const [sexo, setSexo] = useState(sexoAux)
  const [telefoneSplit, setTelefoneSplit] = useState(aux)
  const [editarCredenciais, setEditarCredenciais] = useState(false)
  const [newPassword, setNewPassword] = useState("")
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("")
  const [newEmail, setNewEmail] = useState("")
  const [newEmailConfirmation, setNewEmailConfirmation] = useState("")
  const [editMode, setEditMode] = useState(false)
  const [meusDados, setMeusDados] = useState(true)
  const [editPassword, setEditPassword] = useState(false)
  const [editEmail, setEditEmail] = useState(false)

  useEffect(() => {
    setNome(nomeAux)
    setCpfCnpj(cpfAux)
    // setDataNascimento(dataAux)
    setSexo(sexoAux)
    setTelefoneSplit(aux)
    setNewEmailConfirmation("")
    setNewEmail("")
    setNewPassword("")
    setNewPasswordConfirmation("")
  }, [editMode, meusDados, editarCredenciais, nomeAux, cpfAux, sexoAux, aux]);
  
  return (
    <>
      <Box
        gridTemplateColumns="repeat(12,1fr)"
        gridRowGap="1.5rem"
        minHeight={{ mobile: "calc(100vh - 215px)", tablet: "calc(100vh - 215px)", desktop: "calc(100vh - 266px)" }}
        position="relative"
        styling="grid"
      >
        <Box
          alignItems="flex-start"
          gridColumn={{ mobile: '2/span 10', tablet: '2/span 10', desktop: '4/span 6' }}
          styling="column"
        >
          <Box styling="row" width="100%">
            <Box styling="row" width="50%">
              <Button
                className={meusDados ? 'active' : ''}
                id='editarDados'
                onClick={() => {
                  setEditarCredenciais(false)
                  setEditPassword(false)
                  setEditEmail(false)
                  setMeusDados(!meusDados)
                }}
                styling="tab"
                width="100%"
              >
                Meus Dados
              </Button>
            </Box>
            <Box styling="row" width="50%">
              <Button
                className={!meusDados ? 'active' : ''}
                id='editarCredenciais'
                onClick={() => {
                  setEditMode(false)
                  setMeusDados(!meusDados)
                }}
                styling="tab"
                width="100%"
              >
                Minhas Credenciais
              </Button>
            </Box>
          </Box>

          <Box alignItems="flex-start" py="25px" styling="column" width="100%">
            {meusDados &&
              <>
                <FormInput
                  disabled={!editMode}
                  id="nome"
                  name="nome"
                  onChange={(e) => setNome(e.target.value)}
                  placeholder="Nome Completo"
                  value={nome}
                />

                <TextMaskFormInput
                  disabled={!editMode}
                  id="dataNascimento"
                  name="dataNascimento"
                  onChange={(e) => setDataNascimento(e.target.value)}
                  onBlur={(e) => validateVerify(e)}
                  mask={dateMask}
                  placeholder="Data Nascimento"
                  pipe={date => autoCorrectedDatePipe(date, false)}
                  value={dataNascimento}
                />

                <FormSelect
                  isDisabled={!editMode}
                  id="sexo"
                  name="sexo"
                  onChange={(e) => setSexo(e.value)}
                  options={[
                    { value: '', label: 'Selecione' },
                    { value: 'F', label: 'Feminino' },
                    { value: 'M', label: 'Masculino' }
                  ]}
                  placeholder="Sexo"
                  styling="base"
                  defaultValue={sexo}
                />

                <FormInput
                  disabled={!editMode}
                  id="cpfCnpj"
                  name="cpfCnpj"
                  onChange={e => setCpfCnpj(e.target.value)}
                  mask="999.999.999-99"
                  placeholder="CPF"
                  value={cpfCnpj}
                />

                <FormInput
                  disabled={!editMode}
                  id="telefoneSplit"
                  name="telefoneSplit"
                  onChange={e => setTelefoneSplit(e.target.value)}
                  mask="(99) 99999-9999"
                  placeholder="Telefone"
                  value={telefoneSplit}
                />

                {!editMode ?
                  <Box styling="row" width="100%">
                    <Button
                      id='editarDados'
                      onClick={() => toggleEditMode()}
                      styling="primary"
                      width="260px"
                    >
                      Editar Dados
                    </Button>
                  </Box>
                  :
                  <Box styling="row" width="100%">
                    <Box styling="row" width="48%">
                      <Button
                        id='btnCancel'
                        onClick={() => MudarEstados()}
                        styling="base"
                        width="100%"
                      >
                        Cancelar
                      </Button>
                    </Box>
                    <Box styling="row" width="48%">
                      <Button
                        id="btnSave"
                        onClick={validateVerify}
                        styling="primary"
                        width="100%"
                      >
                        Salvar edições
                      </Button>
                    </Box>
                  </Box>
                }
              </>
            }

            {!editPassword && !editEmail && !meusDados &&
              <>
                <P styling="base">E-mail</P>
                <P styling="base">{emailAux}</P>
                <P mt="15px" styling="base">Senha</P>
                <P styling="base">**********</P>

                <Box mt="15px" styling="row" width="100%">
                  <Box styling="row" width="48%">
                    <Button
                      id='btnEditEmail'
                      onClick={() => setEditEmail(true)}
                      styling="primary"
                      width="100%"
                    >
                      Editar E-mail
                      </Button>
                  </Box>
                  <Box ml="15px" styling="row" width="48%">
                    <Button
                      id="btnEditPassword"
                      onClick={() => setEditPassword(true)}
                      styling="primary"
                      width="100%"
                    >
                      Editar Senha
                      </Button>
                  </Box>
                </Box>
              </>
            }

            {editPassword && (
              <>
                <P mb="15px" styling="base">Senha</P>
                <FormInput
                  id="newPassword"
                  name="newPassword"
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="Nova Senha"
                  type="password"
                  value={newPassword}
                />
                <FormInput
                  id="newPasswordConfirmation"
                  name="newPasswordConfirmation"
                  onChange={(e) => setNewPasswordConfirmation(e.target.value)}
                  placeholder="Confirmar senha"
                  type="password"
                  value={newPasswordConfirmation}
                />

                <Box mt="15px" styling="row" width="100%">
                  <Box styling="row" width="48%">
                    <Button
                      id='btnCancel'
                      onClick={() => setEditPassword(false)}
                      styling="primary"
                      width="100%"
                    >
                      Cancelar
                      </Button>
                  </Box>
                  <Box ml="15px" styling="row" width="48%">
                    <Button
                      id="btnSaveNewPassword"
                      onClick={() => verificarSenha()}
                      styling="primary"
                      width="100%"
                    >
                      Salvar nova senha
                      </Button>
                  </Box>
                </Box>
              </>
            )}

            {editEmail && (
              <>
                <P mb="15px" styling="base">E-mail</P>
                <FormInput
                  id="newEmail"
                  name="newEmail"
                  onChange={(e) => setNewEmail(e.target.value)}
                  placeholder="Novo E-mail"
                  value={newEmail}
                />
                <FormInput
                  id="newEmailConfirmation"
                  name="newEmailConfirmation"
                  onChange={(e) => setNewEmailConfirmation(e.target.value)}
                  placeholder="Novo E-mail"
                  value={newEmailConfirmation}
                />

                <Box mt="15px" styling="row" width="100%">
                  <Box styling="row" width="48%">
                    <Button
                      id='btnCancel'
                      onClick={() => setEditEmail(false)}
                      styling="primary"
                      width="100%"
                    >
                      Cancelar
                    </Button>
                  </Box>
                  <Box ml="15px" styling="row" width="48%">
                    <Button
                      id="btnSaveNewPassword"
                      onClick={() => verificarEmail()}
                      styling="primary"
                      width="100%"
                    >
                      Salvar novo E-mail
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box >
    </>
  )
}

const mapStateToProps = state => ({
  user: state.user.user
})

const mapDispatchToProps = {
  editarDados: userService.editarDados,
  showModal: userService.showModal
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Conta)
