import React from 'react';
import css from '@styled-system/css';
import { LinkProps } from '../ds/atoms/Link/types';
import Link from '../ds/atoms/Link';

const LinkButton: React.FC<LinkProps> = ({
  children,
  styling,
  onFocus,
  onBlur,
  className,
  ...props
}: LinkProps) => {
  const [focused, set] = React.useState(false);
  const toggleState = () => set(prevState => !prevState);

  return (
    <Link
      {...props}
      className={focused ? `${className ?? ''} focused` : className ?? ''}
      onFocus={(e: React.FocusEvent<HTMLAnchorElement>) => {
        toggleState();
        /* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
        onFocus && onFocus(e);
      }}
      onBlur={(e: React.FocusEvent<HTMLAnchorElement>) => {
        toggleState();
        onBlur && onBlur(e);
      }}
      styling="base"
      css={css({
        display: 'flex',
        alignItems: 'center',
        py: 'xxs',
        px: 'xs',
        color: 'text.dark',
        borderRadius: '50rem',
        outline: 'none',
        svg: {
          path: {
            strokeWidth: 0,
          }
        },
        '&:hover': {
          p: {
            color: 'text.dark',
          }
        },
        '&.active': {
          bg: `${styling}.default`,
          color: 'text.light',
          fontFamily: 'OpenSans Semibold',
        },
        '&.active2': {
          p: {
            color: '#3f51b5',
          },
          svg: {
            path: {
              fill: '#3f51b5',
              stroke: '#3f51b5',
            },
            circle: {
              fill: '#3f51b5',
              stroke: '#3f51b5',
            },
            polygon: {
              fill: '#3f51b5',
              stroke: '#3f51b5',
            }
          },
        },
      })}
    >
      {children}
    </Link>
  );
};

export default LinkButton;
