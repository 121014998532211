import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import { variant, VariantArgs } from 'styled-system';
import { DrawerStyledProps, DrawerProps } from './types';
import { defaults, Box } from '../../SubatomicParticles';
import { useSpring, animated } from 'react-spring';

const variants = variant({
  prop: 'styling',
  variants: {
    base: {
      top: 0,
      flex: '1 0 auto',
      display: 'flex',
      outline: 0,
      zIndex: 1200,
      position: 'fixed',
      overflowY: 'auto',
      flexDirection: 'column',
      bg: '#f0f1f2',
      boxShadow: '0px 0px 20px #fff',
      padding: 'sm'
    },
    get right() {
      return {
        ...this.base,
        left: 'auto',
        right: 0
      }
    },
    get left() {
      return {
        ...this.base,
        right: 'auto',
        left: 0
      }
    },
    get top() {
      return {
        ...this.base,
        right: 0,
        left: 0,
      }
    },
    get bottom() {
      return {
        ...this.top,
        right: 0,
        left: 0,
        bottom: 0,
        top: 'auto'
      }
    }
  },
} as VariantArgs);


const Style: React.FC<DrawerStyledProps> = styled(
  animated.div,
)`
    ${variants}
    ${defaults}
`;

const Drawer: React.FC<DrawerProps> = ({ size, ...props }) => {

  const translate3d = useMemo(() => {
    switch (props.styling) {
      case 'left':
        return `translate3d(-${size}px, 0px, 0px)`;
      case 'right':
        return `translate3d(${size}px, 0px, 0px)`;
      case 'top':
        return `translate3d(0px, -${size}px, 0px)`;
      case 'bottom':
        return `translate3d(0px, ${size}px, 0px)`;
      default:
        return 'translate3d(0px, 0px, 0px)';
    }
  }, [size, props.styling]);

  const animation = useSpring(
    props.open
      ? {
        to: {
          transform: `translate3d(0px, 0px, 0px)`,
        },
        from: {
          transform: translate3d,
        },
      }
      : {
        to: {
          transform: translate3d,
        },
        from: {
          transform: translate3d,
        },
      },
  );

  return (
    <>
      <Style
        {...props}
        height={(props.styling === 'top' || props.styling === 'bottom') ? size : '100%'}
        width={(props.styling === 'right' || props.styling === 'left') ? size : '100%'}
        style={animation}
      >
        {props.children}
      </Style>
      {props.overlay && props.onClose && props.open && (
        <Box styling="overlay" onClick={props.onClose}></Box>
      )}
    </>
  )
};

export default memo(Drawer)
