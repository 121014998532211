import matchSorter, {rankings} from 'match-sorter';
import { FilterValue, IdType, Row } from 'react-table';

export function tobeEqualSelectFilter<T extends object>(rows: Array<Row<T>>, id: IdType<T>, filterValue: FilterValue) {
  return matchSorter(rows, filterValue.value, {
    keys: [{threshold: rankings.EQUAL, key: (row: Row<T>) => row.values[id]}]
  });
};

// Let the table remove the filter if the string is empty
tobeEqualSelectFilter.autoRemove = (val: any) => !val;
