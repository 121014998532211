import React from 'react';
import css from "@styled-system/css";
import { Box, Button } from '../../../components/ds/SubatomicParticles';
import { H1, H2, H3, H4, P } from '../../../components/ds/theme/typography';
import IconHome from "../../../components/iconHome";
import Icon from "../../../components/ds/atoms/Icon";
import FormSelect from '../../../components/FormSelect';
import FormInput from '../../../components/FormInput';
import { ReactComponent as IconVida } from "../../../assets/svg/icon_vida.svg";

interface DispatchProps {
  values: any;
}
type Props = DispatchProps;

const ThemesExamples = ({ values }: Props) => {

  return (
    <Box
      borderTop="1px solid"
      borderColor="secondary.default"
      gridGap="40px"
      gridTemplateColumns="repeat(3, 1fr)"
      mt="40px"
      styling="grid"
      pt="30px"
      width="100%"
    >
      <Box
        gridColumn="1/span 3"
        styling="row"
      >
        <H4 styling="base" color={values.primary.default}>EXEMPLOS:</H4>
      </Box>
      <Box
        alignItems="flex-start"
        justifyContent="flx-start"
        styling="column"
      >
        <H1 styling="base" color={values.secondary.default}>Título primário</H1>
        <H2 styling="base" color={values.secondary.default}>Títuto secundário</H2>
        <H3 styling="base" color={values.primary.default}>Título terciário</H3>
        <H4 styling="base" color={values.primary.default}>Título quaternário</H4>
        <P mt="5px" styling="base" color={values.text.dark}>Exemplo de um texto comum</P>
        <P fontSize="12px" mt="5px" styling="error" color={values.danger.default}>Exemplo mensagem de erro</P>
        <P fontSize="12px" mt="5px" styling="error" color={values.success.default}>Exemplo mensagem de sucesso</P>
      </Box>

      <Box
        alignItems="flex-start"
        justifyContent="flx-start"
        styling="column"
      >
        <FormInput
          labelColor={values.text.dark}
          borderColor={values.text.dark}
          name="exemplo"
          placeholder="Exemplo input"
          type="text"
          value=""
          error=""
        />

        <FormSelect
          name="exemplo-select"
          colors={{
            backgroundDefault: values.background.default,
            disabled: values.disabled,
            primaryDark: values.primary.dark,
            primaryDefault: values.primary.default,
            primaryLight: values.primary.light,
            textDark: values.text.dark,
            textLight: values.text.light,
          }}
          defaultValue=""
          onChange={() => { }}
          onBlur={() => { }}
          placeholder="Exemplo select"
          options={[
            {
              label: "Exemplo 1",
              value: "1",
            },
            {
              label: "Exemplo 2",
              value: "2",
            },
          ]}
          styling="base"
        />

        <Box styling="row" justifyContent="space-between" width="100%">
          <Button
            styling="base"
            type="button"
            color={values.primary.default}
          >
            Exemplo Botão
          </Button>
          <Button
            styling="primary"
            type="button"
            css={css({
              bg: values.primary.default,
              color: values.text.light,
              "&:hover": {
                bg: values.primary.dark,
              },
            })}
          >
            Exemplo Botão
          </Button>
          <Button
            styling="outline"
            type="button"
            css={css({
              borderColor: values.primary.default,
              color: values.primary.default,
              "&:hover": {
                bg: values.primary.dark,
                borderColor: values.primary.dark,
                color: values.text.light,
              },
            })}
          >
            Exemplo Botão
          </Button>
        </Box>
      </Box>

      <Box
        alignItems="flex-start"
        justifyContent="flex-start"
        styling="row"
      >
        <Box styling="row" width="45%">
          <IconHome
            key="seguro-vida"
            text="Seguro Vida"
            onPress={() => { }}
            colors={{
              backgroundDark: values.background.dark,
              backgroundDefault: values.background.default,
              primaryDefault: values.primary.default,
              textDark: values.text.dark,
              textLight: values.text.light,
            }}
          >
            <Icon height="38px" width="38px">
            <IconVida height="38px" width="38px" />
          </Icon>
          </IconHome>
      </Box>
      <Box ml="15px" styling="row" width="45%">
        <IconHome
          className="active"
          key="seguro-vida-2"
          text="Seguro Vida"
          onPress={() => { }}
          colors={{
            backgroundDark: values.background.dark,
            backgroundDefault: values.background.default,
            primaryDefault: values.primary.default,
            textDark: values.text.dark,
            textLight: values.text.light,
          }}
        >
          <Icon height="38px" width="38px">
            <IconVida height="38px" width="38px" />
          </Icon>
        </IconHome>
      </Box>
    </Box>

    </Box >
  );
}

export default ThemesExamples;