import { SET_LOCALES, IS_LOADING, Locale } from './types';

export const setLocales = (locales: Locale[]) => ({
  type: SET_LOCALES,
  locales
});

export const isLoading = (isLoading: boolean) => ({
  type: IS_LOADING,
  isLoading: isLoading,
});