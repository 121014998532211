import React, { memo } from 'react';
import styled from 'styled-components';
import { variant, VariantArgs } from 'styled-system';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { SelectComponents } from 'react-select/src/components';
import { SelectProps, SelectStyledProps } from './index';
import { defaults } from '../../SubatomicParticles';

const variants = variant({
  prop: 'styling',
  variants: {
    base: {
      '.select__input': {
        color: 'secondary.contrast',
      },
      '.select__control': {
        bg: 'transparent',
        border: 'none',
        borderBottom: '1px solid',
        borderColor: 'text.dark',
        borderRadius: '0',
        boxShadow: 'none',
        color: 'text.dark',
        fontFamily: 'OpenSans Regular',
        fontSize: '14px',
        transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '&:hover': {
          borderColor: 'primary.default',
        }
      },
      '.select__menu': {
        zIndex: '999',
        textAlign: 'left',
        borderColor: 'grays.default',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '0px',
        bg: 'background.default',
        marginTop: '4px',
        overflow: 'hidden',
      },
      '.select__menu-list': {
        color: 'secondary.contrast',
        fontFamily: 'OpenSans Regular',
        paddingTop: '0rem',
        paddingBottom: '0rem',
      },
      '.select__indicator': {
        mr: '10px',
        color: 'text.dark',
      },
      '.select__indicator-separator': {
        bg: 'text.dark',
      },
      '.select__single-value': {
        color: 'text.dark',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      },
      '.select__single-value--is-disabled': {
        color: 'disabled'
      },
      '.select__multi-value': {
        bg: 'primary.light',
      },
      '.select__multi-value__label': {
        color: 'text.dark',
        padding: '10px',
      },
      '.select__multi-value__remove': {
        borderRadius: 'sm',
        cursor: 'pointer',
        transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '&:hover': {
          bg: 'background.light',
          color: 'contrast',
          svg: {
            path: {
              color: 'contrast'
            }
          }
        }
      },
      '.select__option': {
        transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        lineHeight: 'normal',
        padding: '15px 20px !important',
        pt: "18px",
        '&:hover': {
          bg: 'primary.default',
          color: 'text.light'
        },
      },
      '.select__option--is-focused': {
        bg: 'hovers.0',
        color: 'text.dark'
      },
      '.select__option--is-selected': {
        bg: 'primary.dark',
        color: 'text.light'
      },
      '.select__value-container': {
        px: '0px',
      },
      '.select__placeholder': {
        color: 'grays.default'
      },
    },
    get outlinePrimary() {
      return {
        ...this.base,
        '.select__control': {
          // @ts-ignore
          ...this.base['.select__control'],
          borderRadius: '0rem',
          border: 'none',
        },
        '.select__control--is-focused': {
          // @ts-ignore
          ...this.base['.select__control--is-focused'],
          boxShadow: 'none'
        },
        '.select__menu': {
          // @ts-ignore
          ...this.base['.select__menu'],
          borderRadius: '.2rem',
          borderColor: (props: any) => `${props.colors.primary}20`,
        }
      }
    },
    get underlined() {
      return {

      }
    }
  }
} as VariantArgs);

const SelectStyle: React.FC<SelectStyledProps> = styled(ReactSelect) <SelectProps>`
  ${variants}
  ${defaults}
  ${props => props.css ?? ''}
`;

const animatedComponents = makeAnimated() as SelectComponents<{ label: string; value: string; }>;

const Select: React.FC<SelectProps> = props => (
  <SelectStyle
    styling={props.styling ?? 'base'}
    classNamePrefix="select"
    components={animatedComponents}
    {...props}
  />
);

export default memo(Select);
