export const SUCCESS_LOGIN = "SUCCESS_LOGIN";
export const SHOW_MODAL = "SHOW_MODAL";
export const LOADING = "LOADING";
export const AUTH_USER = "AUTH_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const TOAST = "TOAST";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const SET_MY_INSURANCES = "SET_MY_INSURANCES";
export const SET_MY_QUOTES = "SET_MY_QUOTES";
export const QUOTES_LOADING = "QUOTES_LOADING";
export const INSURANCE_LOADING = "INSURANCE_LOADING";
export const SET_QUOTE_INFO = "SET_QUOTE_INFO";
export const QUOTE_INFO_LOADING = "SET_INFO_LOADING";

export const SUCCESS_GET_RESIDENCES = "SUCCESS_GET_RESIDENCES"
export const IS_LOADING_RESIDENCES = "user/IS_LOADING_RESIDENCES"

export const SUCCESS_GET_VEHICLES = "SUCCESS_GET_VEHICLES"
export const LOADING_VEHICLES = "user/LOADING_VEHICLES"

export const SUCCESS_GET_REFERENCE_DATE = "user/SUCCESS_GET_REFERENCE_DATE"

export const SUCCESS_GET_BRANDS = "user/SUCCESS_GET_BRANDS"
export const SUCCESS_GET_MODELS = "user/SUCCESS_GET_MODELS"
export const SUCCESS_GET_YEAR_MODEL = "user/SUCCESS_GET_YEAR_MODEL"
export const SUCCESS_GET_VEHICLE = "user/SUCCESS_GET_VEHICLE"