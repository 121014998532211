export type Admin = {
  admin: boolean;
}

export type User = {
  id?: string;
  admin?: boolean;
};

export interface State {
  users: User[];
  isLoading: boolean;
}

export const SET_USERS = "users/SET_USERS";
export const IS_LOADING = "users/IS_LOADING";
