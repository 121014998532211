import { get, post } from "axios";
import headers from '../../../utils/mountFirebaseHeaders';

const { REACT_APP_FIREBASE_FUNCTIONS_URL, REACT_APP_CREDIT_CARD_PAYMENT_SCREEN } = process.env;

const MY_ENSURANCES_SEARCH_GET_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/myInsurancesSearchGET`;
const MY_QUOTES_SEARCH_GET_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/myQuotesSearchGET`;
const INSURANCE_COVERS_SEARCH_GET_URL = (doc) => `${REACT_APP_FIREBASE_FUNCTIONS_URL}/insuranceCoversSearchGET?documento=${doc}&alteracao=0`;
const GET_VALUE_DETAILS_POST_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/fetchDataFromQuotePOST`;
const GET_LIBERTY_PAYMENT_TOKEN_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/providesLibertyPaymentToken`;

export const getInsurances = async () => get(MY_ENSURANCES_SEARCH_GET_URL, { headers: await headers() }).then(({ data }) => data)

export const BuscaCotacoes = async () => get(MY_QUOTES_SEARCH_GET_URL, { headers: await headers() }).then(({ data }) => data)

export const BuscaInfoCotacoes = async (documento) => get(INSURANCE_COVERS_SEARCH_GET_URL(documento), { headers: await headers() }).then(({ data }) => data)

export const getValueDetails = async body => post(GET_VALUE_DETAILS_POST_URL, body, { headers: await headers() })

export const getLibertyPaymentToken = async body => post(GET_LIBERTY_PAYMENT_TOKEN_URL, body, { headers: await headers() })

export const getCreditCardPaymentScreen = token => {
  const form = document.createElement("form");
  form.setAttribute("method", "post");
  form.setAttribute("action", REACT_APP_CREDIT_CARD_PAYMENT_SCREEN);
  const hiddenField = document.createElement("input");
  hiddenField.setAttribute("type", "hidden");
  hiddenField.setAttribute("name", 'Id');
  hiddenField.setAttribute("value", token);
  form.appendChild(hiddenField);
  document.body.appendChild(form);
  form.submit();
}
