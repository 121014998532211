import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import * as userService from "../../state/context/user/services";
import { IconsMap } from "../../components/iconHome/iconhome";
import { Box, Button } from "../../components/ds/SubatomicParticles";
import { H1, H2, H3, P, H4 } from "../../components/ds/theme/typography";
import Link from "../../components/ds/atoms/Link";
import Icon from "../../components/ds/atoms/Icon";
import BgBike from "../../assets/images/bg_bike.png";
import { ReactComponent as Circle } from "../../assets/svg/circle.svg";
import { ReactComponent as DotsRight } from "../../assets/svg/dots_left.svg";
import { ReactComponent as Dotsleft } from "../../assets/svg/dots_right.svg";
import { ReactComponent as PesegoCare } from "../../assets/svg/pessego-care.svg";
import InjectImages from "../Images";
import PreImg from "../../components/PreImg";

function Bike({ history, showModal, user, images }) {
  return (
    <>
      <Box
        gridTemplateColumns="repeat(12,1fr)"
        gridRowGap="1.5rem"
        paddingY={{ mobile: "px", tablet: "0px", desktop: "3rem" }}
        position="relative"
        styling="grid"
      >
        <Box
          alignItems="flex-start"
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "2/span 3",
          }}
          styling="column"
        >
          <H2 styling="base">
            Cuide bem da sua bike com o seguro da Pessego.com. Depois é só
            pedalar e relaxar.
          </H2>
          <Box
            styling="base"
            height="2px"
            width="4vw"
            mt="2rem"
            backgroundColor="primary.default"
          ></Box>
        </Box>

        <Box
          alignItems="flex-start"
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "2/span 3",
          }}
          gridRow="2"
          styling="column"
        >
          <P lineHeight="1.4" textAlign="justify" styling="base">
            O seguro Bike é uma solução que pode ser completa, não só para a
            bicicleta e o ciclista, mas também para terceiros.
          </P>
        </Box>

        <Box
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "2/span 4",
          }}
          gridRow="3"
          my="2rem"
          styling="column"
        >
          <Button
            onClick={() => {
              history.push({
                pathname: "/form",
                state: { type: "bike" },
              })
            }}
            styling="primary"
            width="350px"
            height="50px"
          >
            <div style={{ fontSize: 18 }}>Cotar agora seu seguro bike</div>
          </Button>
          {user && (user.telefone || user.telefoneCelular) && (
            <Button
              my="10px"
              onClick={() =>  showModal({ visible: true, type: "contact" })}
              styling="primary"
              width="295px"
            >
              Quero falar com alguém, me ligue!
            </Button>
          )}
        </Box>

        <Box
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          gridColumn="5/span 8"
          gridRow="2/span 4"
          height="210px"
          mt="150px"
          styling="column"
        >
          <Icon styling="baseNone" height="100%" width="100%">
            <DotsRight width="100%" height="100%" />
          </Icon>
        </Box>

        <Box
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          gridColumn="8/span 4"
          gridRow="1/span 4"
          ml="30px"
          styling="column"
        >
          <img alt="" height="100%" src={BgBike} width="100%" />
        </Box>

        <Box
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          gridColumn="7/span 8"
          gridRow="1/span 4"
          position="absolute"
          styling="column"
          top="50px"
        >
          <Circle />
        </Box>

        <Box
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          gridColumn="10/span 2"
          gridRow="2/span 4"
          position="absolute"
          styling="column"
          top="145px"
          zIndex="-1"
        >
          <Circle />
        </Box>
        <Box
          alignItems="stretch"
          flexWrap="wrap"
          gridColumn="2/span 10"
          gridRow="4"
          justifyContent="space-between"
          margin={{ mobile: "2rem 0", tablet: "2rem 0", desktop: "0 0 2rem" }}
          position="relative"
          styling="row"
        >
          <Box
            backgroundColor="background.default"
            borderRadius="12px"
            boxShadow="10px 10px 28px 1px rgba(0,0,0,.1)"
            justifyContent="flex-start"
            mb={{ mobile: "25px", tablet: "25px", desktop: "0" }}
            padding="20px"
            styling="column"
            width={{ mobile: "100%", tablet: "100%", desktop: "22%" }}
          >
            <H4 mb="24px" styling="base">
              Quais Bikes esse seguro cobre?
            </H4>
            <P alignSelf="flex-start" lineHeight="1.4" styling="base">
              <P>Urbana</P>
              <P>Mountain Bike</P>
              <P>Speed</P>
              <P>Elétrica</P>
              <P>Dobrável</P>
              <P>BMX</P>
              <P>Downhil</P>
              <P>Trial</P>
              <P>Fixa</P>
              <P>Reclinada</P>
              <P>Handbike</P>
              <P mt="15px">PARA BIKES A PARTIR DE RS3000,00</P>
            </P>
          </Box>

          <Box
            backgroundColor="background.default"
            borderRadius="12px"
            boxShadow="10px 10px 28px 1px rgba(0,0,0,.1)"
            justifyContent="flex-start"
            mb={{ mobile: "25px", tablet: "25px", desktop: "0" }}
            padding="20px"
            styling="column"
            width={{ mobile: "100%", tablet: "100%", desktop: "22%" }}
          >
            <H4 mb="24px" styling="base">
              Coberturas básicas
            </H4>
            <P alignSelf="flex-start" lineHeight="1.4" styling="base">
              <P>Danos causados por incêndio</P>
              <P>Queda</P>
              <P>Tentativa de roubo</P>
              <P>
                E, ainda situaçõs que a bicicleta estiver sendo levada por
                veículo transportador
              </P>
            </P>
          </Box>

          <Box
            backgroundColor="background.default"
            borderRadius="12px"
            boxShadow="10px 10px 28px 1px rgba(0,0,0,.1)"
            justifyContent="flex-start"
            mb={{ mobile: "25px", tablet: "25px", desktop: "0" }}
            padding="20px"
            styling="column"
            width={{ mobile: "100%", tablet: "100%", desktop: "22%" }}
          >
            <H4 mb="24px" styling="base">
              Coberturas adicionais
            </H4>
            <P alignSelf="flex-start" lineHeight="1.4" styling="base">
              <P>Subtração da Bike</P>
              <P>Acidentes pessoais individual</P>
              <P>Garantia</P>
              <P>Internacional</P>
              <P>Bike Bagagem</P>
              <P>Responsabilodade Civil</P>
              <P>Danos elétricos</P>
            </P>
          </Box>

          <Box
            backgroundColor="background.default"
            borderRadius="12px"
            boxShadow="10px 10px 28px 1px rgba(0,0,0,.1)"
            justifyContent="flex-start"
            mb={{ mobile: "25px", tablet: "25px", desktop: "0" }}
            padding="20px"
            styling="column"
            width={{ mobile: "100%", tablet: "100%", desktop: "22%" }}
          >
            <H4 mb="24px" styling="base">
              Cote e confira
            </H4>
            <P lineHeight="1.4" styling="base">
              São garantias e serviços pensados para cada modelo e perfil de
              cliente, com contratação simples e rápida, sem burocracia. Faça
              sua cotação agora mesmo, contrate e fique tranquilo para usar sua
              bike da maneira que mais gosta.
            </P>
          </Box>
        </Box>

        <Box
          alignItems="flex-start"
          gridColumn="2/span 10"
          gridRow="5"
          styling="column"
        >
          <H3 fontSize="27px" styling="light">
            A pessego.com tranquiliza você e cuida da sua bike.
          </H3>
        </Box>

        <Box
          alignItems="flex-start"
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "2/span 6",
          }}
          gridRow="6"
          styling="column"
        >
          <P my="2rem" styling="regular">
            Não sabe exatamente o que procura?
          </P>
          <P styling="base">
            Não tem problema! Nossos consultores são especialistas em seguro e
            vão ajudar você a encontrar exatamente o que você precisa e do jeito
            que você preferir: por telefone, e-mail ou chat.
          </P>
          <P my="2rem" styling="regular">
            Preparamos uma lista de dúvidas mais frequentes pra você.
          </P>
          <P display="block" styling="base">
            Aqui está tudo bem explicadinho, mas se preferir conversar com um de
            nossos promotores é só clicar no botão “
            <Link color="primary.default" to="/contato">
              fale conosco
            </Link>
            ”, ok?
          </P>
        </Box>

        <Box
          backgroundColor="background.default"
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          borderRadius="50%"
          gridColumn="10/span 2"
          gridRow="6"
          height="256px"
          ml="16px"
          styling="column"
          width="256px"
        >
          {images?.generic1?.url ? (
            <PreImg
              src={images.generic1.url}
              style={{
                width: "120px",
                height: "114px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                styling="row"
                width="80%"
                height="80%"
                borderRadius="4px"
                backgroundColor="#ededed"
              />
            </PreImg>
          ) : (
            <PesegoCare height="120px" width="114px" />
          )}{" "}
        </Box>

        <Box
          alignItems="flex-start"
          gridColumn="1/span 8"
          gridRow="7"
          height="210px"
          my="30px"
          styling="column"
        >
          <Icon styling="baseNone" height="100%" width="70%">
            <Dotsleft width="70%" height="100%" />
          </Icon>
        </Box>

        <Box gridColumn="2/span 10" gridRow="8" styling="column">
          <H1 fontSize="2rem" mb="2rem" styling="light">
            Outros seguros
          </H1>
          <Box
            gridGap="1rem"
            gridTemplateColumns={{
              mobile: "repeat(2,1fr)",
              tablet: "repeat(3,1fr)",
              desktop: "repeat(7,1fr)",
            }}
            styling="grid"
            width="100%"
          >
            <IconsMap history={history} />
          </Box>
        </Box>
      </Box>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = {
  showModal: userService.showModal,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(InjectImages(Bike));
