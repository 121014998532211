import React, { useState } from "react";
import * as userService from "../../state/context/user/services";
import { showToast } from "../../utils";
import { connect } from "react-redux";
import { validateVerify } from "../../utils/validations";
import { fieldNotFilledAlert } from "../../utils";
import { Box, Button } from "../../components/ds/SubatomicParticles";
import { H3 } from "../../components/ds/theme/typography";
import FormInput from "../../components/FormInput";

function ForgetPassword({ validEmail, resetPassword, history }) {
  const [email, setEmail] = useState("");

  const _handleResetPassword = async () => {
    if (email !== "") {
      await validEmail(email).then((data) => {
        if (data === true) {
          resetPassword(email);
          showToast(
            "info",
            "Informação",
            "Enviamos para você um email para mudar a sua senha.",
          );
          history.push("/");
        } else {
          showToast("error", "Erro", "Email não cadastrado.");
        }
      });
    } else {
      fieldNotFilledAlert("Email");
    }
  };

  return (
    <Box
      styling="grid"
      gridRowGap="1.5rem"
      gridTemplateColumns="repeat(12, 1fr)"
      position="relative"
    >
      <Box
        gridColumn={{
          mobile: "2/span 10",
          tablet: "2/span 10",
          desktop: "4/span 6",
        }}
        justifyContent="flex-start"
        minHeight={{
          mobile: "calc(100vh - 211px)",
          tablet: "calc(100vh - 211px)",
          desktop: "calc(100vh - 266px)",
        }}
        styling="column"
      >
        <H3 mb="30px" styling="light">
          Para solicitar uma nova senha, informe o E-mail da conta.
        </H3>
        <FormInput
          id="email"
          name="email"
          onBlur={(e) => validateVerify(e)}
          onChange={(e) => {
            setEmail(e.target.value.toLowerCase());
          }}
          placeholder="Informe seu Email"
          value={email}
        />
        <Button
          display="block"
          margin="15px auto 0"
          maxWidth="295px"
          onClick={_handleResetPassword}
          styling="primary"
          width="90%"
        >
          Próximo
        </Button>
      </Box>
    </Box>
  );
}

const mapDispatchToProps = {
  resetPassword: userService.resetPassword,
  validEmail: userService.verifyEmail,
};

export default connect(null, mapDispatchToProps)(ForgetPassword);
