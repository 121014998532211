import React from "react";
import TagManager from "react-gtm-module"
import firebase from "firebase";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/lib/integration/react';

import store, { persistor } from './state/store';
import { firebaseConfigDev, firebaseConfigProd } from "./firebase.config";
import Configuration from './containers/Configuration';
import Navigation from "./routes";

import "./App.scss";

firebase.initializeApp(
  process.env.REACT_APP_PESSEGO_ENV === "development"
    ? firebaseConfigDev
    : firebaseConfigProd
);

const tagManagerArgs = {
  gtmId: 'GTM-M3JZJKV'
}

TagManager.initialize(tagManagerArgs)

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Configuration>
        <Navigation />
      </Configuration>
    </PersistGate>
  </Provider>
);

export default App;
