import React from "react";
import {
  validateVerify,
  validInputBorder,
  checkLengthOfDate,
  autoCorrectedDatePipe,
} from "../../../utils/validations";
import { fieldNotFilledAlert, dateMask } from "../../../utils";
import cpfValidator from "cpf";
import moment from "moment";
import FormInput from "../../../components/FormInput";
import FormSelect from "../../../components/FormSelect";
import { Box, Button } from "../../../components/ds/SubatomicParticles";
import { H3 } from "../../../components/ds/theme/typography";
import TextMaskFormInput from "../../../components/TextMaskFormInput";

export default function Step2({
  onNext,
  nome,
  cpf,
  sexo,
  setSexo,
  dataNascimento,
  setDataNascimento,
  onFailBirthDayDate,
  _handleVerificarCPF,
  setCpf,
  onFailName,
}) {
  const _handleVerify = async () => {
    nome.value = nome.value.trim();
    cpf = cpf.replace(/[.*+?^${}()|[\]\\]/g, "").replace("-", "");
    let birthDayDate = dataNascimento
      ? moment(dataNascimento, "DD/MM/YYYY").format("YYYY-MM-DD")
      : "";
    var x = nome.value.split(" ");
    if (checkDiffBirthdayDate(dataNascimento)) {
      return onFailBirthDayDate();
    }
    if (checkIfHasNotNameOrSurname(x)) {
      return onFailName(1);
    }
    if (checkLengthOfSurnameAndName(x)) {
      return onFailName(2);
    }
    if (
      checkIfFieldsAreNotEmpty(nome, cpf, sexo, birthDayDate) &&
      checkLengthOfDate(dataNascimento) === 8 &&
      !checkPersonAge(dataNascimento)
    ) {
      if (cpfValidator.isValid(cpf)) {
        if (await _handleVerificarCPF()) {
          onNext({
            nome: nome.value,
            cpfCnpj: cpf,
            sexo,
            dataNascimento: birthDayDate,
          });
        }
      } else {
        fieldNotFilledAlert("CPF");
      }
    } else {
      if (nome.value === "") {
        fieldNotFilledAlert("Nome");
      }
      if (sexo === "") {
        fieldNotFilledAlert("Sexo");
      }
      if (cpf === "") {
        fieldNotFilledAlert("CPF");
      }
      if (checkPersonAge(dataNascimento)) {
        return onFailBirthDayDate();
      }
      if (
        birthDayDate === "" ||
        checkLengthOfDate(dataNascimento) !== 8 ||
        checkPersonAge(dataNascimento)
      ) {
        fieldNotFilledAlert("Data de Nascimento");
      }
    }
  };

  const checkIfFieldsAreNotEmpty = (nome, cpf, sexo, birthDayDate) =>
    nome.value !== "" && cpf !== "" && sexo !== "" && birthDayDate !== "";
  const checkLengthOfSurnameAndName = (name) =>
    name[0] && name[1] && (name[0].length < 2 || name[1].length < 2);
  const checkIfHasNotNameOrSurname = (name) => !name[0] || !name[1];
  const checkDiffBirthdayDate = (age) =>
    moment().diff(
      moment(age, "DD/MM/YYYY").format("YYYY-MM-DD"),
      "years",
      false,
    ) < 17;
  const checkPersonAge = (date) =>
    date &&
    parseInt(moment(date, "DD/MM/YYYY").format("YYYYMMDD")) >=
      parseInt(moment().format("YYYYMMDD"));

  return (
    <Box
      gridColumn={{
        mobile: "2/span 10",
        tablet: "2/span 10",
        desktop: "4/span 6",
      }}
      styling="column"
    >
      <H3 mb="30px" styling="light">
        Informe seus dados pessoais
      </H3>
      <FormInput
        {...nome}
        data-testid="nome"
        id="nome"
        name="nome"
        placeholder="Nome Completo"
        type="text"
      />
      <FormSelect
        data-testid="select"
        id="sexo"
        name="sexo"
        options={[
          { value: "", label: "Selecione" },
          { value: "F", label: "Feminino" },
          { value: "M", label: "Masculino" },
        ]}
        value={sexo}
        onChange={(e) => {
          validInputBorder("sexo");
          setSexo(e.value);
        }}
        onBlur={(e) => validateVerify(e)}
        placeholder="Sexo"
        styling="base"
      />
      <FormInput
        data-testid="cpf"
        id="cpf"
        mask="999.999.999-99"
        name="cpf"
        onBlur={(e) => {
          validateVerify(e);
        }}
        onChange={(e) => setCpf(e.target.value)}
        placeholder="CPF"
        type="text"
        value={cpf}
      />
      <TextMaskFormInput
        data-testid="dataNascimento"
        id="dataNascimento"
        value={dataNascimento}
        onChange={(e) => setDataNascimento(e.target.value)}
        mask={dateMask}
        pipe={(date) => autoCorrectedDatePipe(date, false)}
        placeholder="Data de nascimento"
        onBlur={(e) => validateVerify(e)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            _handleVerify();
          }
        }}
      />
      <Button
        display="block"
        margin="0 auto"
        onClick={_handleVerify}
        styling="primary"
        width="295px"
      >
        Próximo
      </Button>
    </Box>
  );
}
