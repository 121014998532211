import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Button } from "../../SubatomicParticles";
import styled from "styled-components";
import { VariantArgs, variant } from "styled-system";
import { ImageUploadProps, ImageUploadStyledProps } from "./index";
import ReactAvatarEditor from "react-avatar-editor";
import Slider from "../Slider";
import Icon from "../../../../components/ds/atoms/Icon";
import { ReactComponent as IconPlus } from "../../../../assets/svg/icon_plus.svg";
import { ReactComponent as IconTrash } from "../../../../assets/svg/icon_trash.svg";
import { ReactComponent as IconEdit } from "../../../../assets/svg/edit-3.svg";
import { Image } from "../../SubatomicParticles/Image/Image";

const variants = variant({
  prop: "styling",
  variants: {
    base: {
      border: "1px solid #1B2B37",
      borderRadius: "10px",
      padding: "30px",
      canvas: {
        border: "2px dashed #1B2B37",
        borderRadius: "10px",
      },
      ".imageUpload": {
        border: "2px dashed #1B2B37",
        borderRadius: "4px",
        padding: '5px'
      },
    },
  },
} as VariantArgs);
const ImageUpload: React.FC<ImageUploadProps> = ({
  onDropImage,
  onSave,
  onRemove,
  defaultImage,
  height,
  width,
  id,
  ...props
}) => {
  const [image, setImage] = useState("");
  const [scale, setScale] = useState(1);
  const [showButton, setShowButton] = useState(false);
  const [showDefaultImage, setShowDefaultImage] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setImage(acceptedFiles[0]);
      setShowButton(true);
      if (onDropImage) onDropImage(acceptedFiles[0]);
    },
    [onDropImage],
  );

  useEffect(() => {
    if (!!defaultImage) {
      setShowDefaultImage(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _onSave = () => {
    const canvas = Array.from(document.getElementsByClassName(id)).filter(
      (element: any) => element instanceof HTMLCanvasElement,
    )[0] as HTMLCanvasElement;
    const dataUrl = canvas.toDataURL();
    var blobBin = atob(dataUrl.split(",")[1]);
    var array = [];
    for (var i = 0; i < blobBin.length; i++) {
      array.push(blobBin.charCodeAt(i));
    }
    var file = new Blob([new Uint8Array(array)], { type: "image/png" });

    setShowButton(false);
    if (onSave) onSave({ dataUrl, file });
  };

  const _onRemove = () => {
    setImage("");
    setScale(1);
    if (onRemove) onRemove();
  };

  const _onEdit = () => {
    setShowDefaultImage(false);
    _onRemove();
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <Box width="100%" height="100%">
      <Box {...props} styling="column" width="100%" height="100%" alignItems="flex-end">
        {(!!image || showDefaultImage) && (
          <Box display="flex" justifyContent="flex-end">
            <Button
              styling="base"
              type="button"
              onClick={() => {
                showDefaultImage ? _onEdit() : _onRemove();
              }}
            >
              <Icon styling="admin">
                {showDefaultImage ? (
                  <IconEdit height={20} width={20} />
                ) : (
                    <IconTrash height={20} width={20} />
                  )}
              </Icon>
            </Button>
          </Box>
        )}
        {showDefaultImage ? (
          <Box
            styling="row"
            width="100%"
            height="100%"
          >
            <Image className="imageUpload" src={defaultImage || ""}  maxWidth="100%" maxHeight="100%" />
          </Box>
        ) : (
            <Box
              styling="row"
              width="100%"
              height="100%"
              position="relative"
            >   
              <div {...getRootProps()} >
                <Box styling="column" >
                  {!image && (
                    <Icon
                      position="absolute"
                      css={{
                        cursor: "pointer",
                      }}
                    >
                      <IconPlus height={20} width={20} />
                    </Icon>
                  )}
                  <input {...getInputProps()} disabled={!!image} />

                  <ReactAvatarEditor
                    className={id}
                    scale={scale}
                    image={image}
                    border={0}
                    style={{ height:"auto", 
                             width:"auto", 
                             maxHeight: "280px",
                             maxWidth:"330px", 
                             minWidth:"24px",
                             padding: "5px" }}
                    width={width}
                    height={height}
                  />

                </Box>
              </div>
            </Box>
          )}

      </Box>
      {!!image && showButton && (
        <>
          <Box styling="row">
            <Slider
              styling="base"
              step={0.1}
              min={1}
              max={3}
              value={scale}
              onChange={(e) => setScale(parseFloat(e.target.value))}
              label="Zoom:"
            />
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Button type="button" styling="primary" onClick={_onSave} bg="#3f51b5">
              Selecionar
        </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default styled(ImageUpload) <ImageUploadStyledProps>`
  ${variants}
`;
