import React, { Component } from "react";
import Marcas from "../../components/marcasCarousel";
import Features from "../../components/featuresCarousel";
import * as formActions from "../../state/context/form/actions";
import * as userActions from "../../state/context/user/actions";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import css from "@styled-system/css";
import { Box, Button } from "../../components/ds/SubatomicParticles";
import { H1, P } from "../../components/ds/theme/typography";
import Link from "../../components/ds/atoms/Link";
import Icon from "../../components/ds/atoms/Icon";
import appAndroid from "../../assets/images/googleplay.png";
import appIos from "../../assets/images/appstore.png";
import IconHome from "../../components/iconHome";
import main1 from "../../assets/images/heroHome.png";
import main2 from "../../assets/images/feature1.png";
// import { ReactComponent as Back } from "../../assets/svg/back.svg"; Funcionalidade ainda não liberada pelo gestor
import { ReactComponent as Clients } from "../../assets/svg/icon_clients.svg";
import { ReactComponent as IconAutomovel } from "../../assets/svg/icon_auto.svg";
import { ReactComponent as IconPet } from "../../assets/svg/icon_pet.svg";
import { ReactComponent as IconResidencial } from "../../assets/svg/icon_residencia.svg";
import { ReactComponent as IconViagem } from "../../assets/svg/icon_viagem.svg";
import { ReactComponent as IconVida } from "../../assets/svg/icon_vida.svg";
import { ReactComponent as IconPortateis } from "../../assets/svg/icon_portateis.svg";
import { ReactComponent as IconBike } from "../../assets/svg/icon_bike.svg";
import { ReactComponent as Dotsleft } from "../../assets/svg/dots_right.svg";
import injectLocale from "../Locale";
import injectImages from "../Images/injectImages";
import PreImg from "../../components/PreImg";

const initialStateActiveContent = {
  id: 0,
  title: "",
  contentCopy: "",
  goto: "",
};

class Home extends Component {
  state = {
    width: 0,
    height: 0,
    isActive: "auto",
    titleSeguro: "Seguro Automóvel",
    subtitleSeguro:
      "Garante uma proteção contra perdas e danos ocorridos com seu carro, em caso de acidentes, roubo ou furto, danos materiais ou corporais causados a terceiros e morte/invalidez dos passageiros.",
    paginaSeguro: "automovel",
    activeIconContent: initialStateActiveContent,
    active: false,
    className: "hidden",
    main1: null,
    main2: null,
    escolhaSeguro: false,
    pessoaFisica: true,
    pessoaJuridica: false,
  };

  contentSeguros() {
    const { locale } = this.props;
    return [
      {
        id: 1,
        title: locale.seguro_auto_moto_title,
        contentCopy: locale.seguro_auto_moto_description,
        goto: "/automovel",
        icon: IconAutomovel,
        online: true
      },
      {
        id: 2,
        title: locale.seguro_residencia_title,
        contentCopy: locale.seguro_residencia_description,
        goto: "/residencial",
        icon: IconResidencial,
        online: true
      },
      {
        id: 5,
        title: locale.seguro_pet_title,
        contentCopy: locale.seguro_pet_description,
        contentParagraphCopy: locale.seguro_pet_description_2,
        goto: "/pets",
        icon: IconPet,
        online: true
      },
      {
        id: 3,
        title: locale.seguro_viagem_title,
        contentCopy: locale.seguro_viagem_description,
        goto: "/viagem",
        icon: IconViagem,
      },
      {
        id: 4,
        title: locale.seguro_vida_title,
        contentCopy: locale.seguro_vida_description,
        goto: "/vida",
        icon: IconVida,
      },
      {
        id: 6,
        title: locale.seguro_portateis_title,
        contentCopy: locale.seguro_portateis_description,
        goto: "/portateis",
        icon: IconPortateis,
      },
      {
        id: 7,
        title: locale.seguro_bike_title,
        contentCopy: locale.seguro_bike_description,
        goto: "/bike",
        icon: IconBike,
      },
    ];
  }

  changeActiveContent(item) {
    // if (this.state.activeIconContent.id === item.id) {
    //   return this.setState({
    //     activeIconContent: initialStateActiveContent
    //   })
    // }
    return this.setState({
      activeIconContent:
        this.state.activeIconContent.id === item.id
          ? initialStateActiveContent
          : item,
    });
  }

  getBodyScrollTop() {
    const el = document.scrollingElement || document.documentElement;
    return el.scrollTop;
  }

  handleScroll() {
    if (this.getBodyScrollTop() > 680) {
      this.setState({
        className: "show",
      });
    }
  }

  handleFinishLoadings() {
    this.props.isLoading();
    this.props.isLoadingQuotationValues();
    this.props.isBuyingQuotation();
    this.props.isLoadingUser();
    this.props.quoteInfoLoading();
  }

  componentDidMount() {
    this.handleFinishLoadings();

    window.onscroll = () => this.handleScroll(true);
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  render() {
    const { history, locale } = this.props;
    const IconMap = () => {
      return this.contentSeguros().map((item) => (
        <IconHome
          key={item.id}
          text={item.title}
          online={item.online}
          className={
            this.state.activeIconContent.id === item.id ? "active" : ""
          }
          onPress={() =>
            this.state.width <= 800
              ? history.push(item.goto)
              : this.state.activeIconContent.id === item.id
                ? history.push(item.goto) : this.setState({ activeIconContent: item })
          }
        >
          <item.icon width="38px" />
        </IconHome>
      ));
    };

    return (
      <Box
        styling="grid"
        gridRowGap="1.5rem"
        gridTemplateColumns="repeat(12, 1fr)"
        position="relative"
      >
        <Box
          alignItems="flex-start"
          display={{ mobile: "none", tablet: "flex", desktop: "flex" }}
          height="600px"
          gridColumn="7/12"
          position="absolute"
          styling="row"
          width="100%"
          css={css({
            animation: "drop 4s infinite",
            animationTimingFunction: "ease-out",
            top: 0,
            zIndex: -1,
          })}
        >
          <PreImg
            src={this.props?.images?.main1?.url || main1}
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              styling="row"
              width="50%"
              height="50%"
              mt="-15rem"
              borderRadius="4px"
              backgroundColor="#ededed"
            />
          </PreImg>
        </Box>
        <Box
          alignItems="center"
          gridColumn="1/span 12"
          gridRowGap="1.5rem"
          gridTemplateColumns="repeat(12, 1fr)"
          position="relative"
          styling="grid"
        >
          <Box
            display={{ mobile: "flex", tablet: "none", desktop: "none" }}
            gridColumn="2/span 10"
            mt="2rem"
            styling="column"
          >
            <H1 styling="base" mb="12px" >{locale.contrate_title}</H1>

            <P display="block" fontSize="15px" styling="base" width="100%">
              {locale.contrate_dados_1}
            </P>
            <P display="block" fontSize="15px" styling="base" width="100%">
              {locale.contrate_dados_2}
            </P>
            <P display="block" fontSize="15px" styling="base" width="100%">
              {locale.contrate_dados_3}
            </P>
          </Box>

          <Box
            alignItems="flex-start"
            display={{ mobile: "none", tablet: "flex", desktop: "flex" }}
            styling="column"
            gridColumn="2/span 4"
          >

            <H1 styling="base">{locale.contrate_title}</H1>

            <Box styling="base" display="block" my="16px" alignItems="flex-start">
              <P styling="base" fontSize="19px">
                {locale.contrate_dados_1}
              </P>
              <P styling="base" fontSize="19px">
                {locale.contrate_dados_2}
              </P>
              <P styling="base" fontSize="19px">
                {locale.contrate_dados_3}
              </P>
            </Box>

            <Box
              styling="base"
              height="2px"
              width="15%"
              my="25px"
              backgroundColor="primary.default"
            />
            {this.state.activeIconContent.id === 0 ? (
              <Box styling="column" alignItems="flex-start">
                <P styling="base" mb="15px">
                  {locale.contrate_description}
                </P>
                <Box styling={{ tablet: "column", desktop: "row" }}>
                  <Link target="_blank" to={"//play.google.com/store/apps/details?id=com.pessegoseguros"}>
                    <img
                      className="appIconAndroid"
                      src={appAndroid}
                      alt=""
                      height="50px"
                    />
                  </Link>
                  <Link
                    target="_blank"
                    to={"//apps.apple.com/br/app/pessego-com-seguros/id1515183528"}
                    margin={{ tablet: "10px 0 0", desktop: "0 0 0 10px" }}
                  >
                    <img src={appIos} alt="" height="50px" />
                  </Link>
                </Box>
              </Box>
            ) : (
              <Box alignItems="flex-start" styling="column" >
                <P styling="base" fontSize="20px" mb="35px" style={{ whiteSpace: 'pre-wrap' }}>
                  {this.state.activeIconContent.contentCopy}
                </P>
                { this.state.activeIconContent.contentParagraphCopy &&
                  <P styling="base" fontSize="20px" mb="35px" style={{ whiteSpace: 'pre-wrap' }}>
                    {this.state.activeIconContent.contentParagraphCopy}
                  </P>
                }
                <Button
                  onClick={() => {
                    history.push(this.state.activeIconContent.goto);
                  }}
                  styling="primary"
                  width="295px"
                >
                  Explore e faça sua cotação
                </Button>
              </Box>
            )}
          </Box>

          {this.state.escolhaSeguro && (
            <Box
              gridColumn={{
                mobile: "2/span 10",
                tablet: "7/span 5",
                desktop: "7/span 5",
              }}
              gridGap="40px"
              gridTemplateColumns="1fr 1fr"
              my={{ mobile: "2rem", tablet: "50px", desktop: "50px" }}
              minHeight="340px"
              styling="grid"
            >
              <IconHome
                key="pessoa-fisica"
                text="Pessoa Física"
                onPress={() => {
                  this.setState({
                    pessoaFisica: true,
                    escolhaSeguro: false,
                  });
                }}
              >
                <Icon height="38px" width="38px">
                  <Clients height="38px" width="38px" />
                </Icon>
              </IconHome>

              <IconHome
                key="pessoa-juridica"
                text="Pessoa Jurídica"
                onPress={() => { }}
                height="100px"
              >
                <Icon height="38px" width="38px">
                  <Clients height="38px" width="38px" />
                </Icon>
              </IconHome>
            </Box>
          )}

          {this.state.pessoaFisica && (
            <Box
              styling="grid"
              gridColumn={{
                mobile: "2/span 10",
                tablet: "7/span 5",
                desktop: "7/span 5",
              }}
              gridGap="40px"
              gridTemplateColumns="repeat(3,4fr)"
              my={{ mobile: "2rem", tablet: "50px", desktop: "50px" }}
              position="relative"
            >
              {/* Funcionalidade ainda não liberada pelo gestor */}
              {/* <Box
                left="0"
                onClick={() => {
                  this.setState({
                    pessoaFisica: false,
                    escolhaSeguro: true
                  })
                }}
                position="absolute" 
                styling="row"
                top="-30px"
              >
                <Icon styling="base" height="22px" width="22px"><Back height="22px" width="22px" /></Icon>
              </Box> */}
              <IconMap />
            </Box>
          )}
        </Box>

        <Box
          styling="grid"
          gridColumn="2/span 10"
          gridTemplateColumns="repeat(10, 1fr)"
          position="relative"
        >
          <Box
            alignItems="flex-start"
            display={{ mobile: "none", tablet: "flex", desktop: "flex" }}
            height={{ tablet: "325px", desktop: "425px" }}
            gridColumn="1/span 4"
            left={{ tablet: "-185px", desktop: "-220px" }}
            position="absolute"
            top={{ tablet: "50px", desktop: "-50px" }}
            styling="row"
            width={{ tablet: "500px", desktop: "615px" }}
          >
            <PreImg
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              src={this.props?.images?.main2?.url || main2}
            >
              <Box
                styling="row"
                width="50%"
                height="50%"
                borderRadius="4px"
                backgroundColor="#ededed"
              />
            </PreImg>
          </Box>
          <Box
            id="feature"
            display="block"
            styling="column"
            gridColumn={{
              mobile: "2/span 10",
              tablet: "5/span 7",
              desktop: "5/span 7",
            }}
          >
            <Features animation={this.state.className} />
          </Box>
        </Box>
        <Box
          display={{ mobile: "flex", tablet: "none", desktop: "none" }}
          gridColumn="2/span 10"
          styling="row"
        >
          <Link target="_blank" to={"//play.google.com/store/apps/details?id=com.pessegoseguros"}>
            <img
              className="appIconAndroid"
              src={appAndroid}
              alt=""
              width="80%"
            />
          </Link>
          <Link
            target="_blank"
            to={"//apps.apple.com/br/app/pessego-com-seguros/id1515183528"}
          >
            <img src={appIos} alt="" width="80%" />
          </Link>
        </Box>

        <Box
          display={{ mobile: "none", tablet: "flex", desktop: "flex" }}
          gridColumn="1/span 12"
          justifyContent="flex-start"
          py="3rem"
          styling="row"
        >
          <Icon styling="baseNone" height="100%" width="70%">
            <Dotsleft height="100%" width="100%" />
          </Icon>
        </Box>

        <Box
          gridColumn="2/span 10"
          justifyContent="flex-start"
          position="relative"
          py="2rem"
          styling="row"
        >
          <Marcas />
        </Box>
      </Box >
    );
  }
}

const mapDispatchToProps = {
  isLoading: formActions.isLoading,
  isLoadingQuotationValues: formActions.isLoadingQuotationValues,
  isBuyingQuotation: formActions.isBuyingQuotation,
  isLoadingUser: userActions.isLoading,
  quoteInfoLoading: userActions.quoteInfoLoading,
};

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(injectImages(injectLocale(Home)));
