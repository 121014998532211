import React, { memo, useEffect, useState, useMemo, useCallback } from "react";
import { connect } from "react-redux";
import { useRouteMatch, useParams, useLocation, useHistory } from "react-router-dom";
import {
  Formik,
  Field,
  FieldArray,
  FieldProps,
  FieldMetaProps,
  Form as FormikForm,
} from "formik";

import {
  getClients,
  getClient,
  editClient,
  createClient,
  getSyonetModalityTypes,
  getContractorSyonetParameters,
  addContractorSyonetParameters,
  getContractorQuiverParameters,
  getQuiverModalityTypes,
  addContractorQuiverParameters
} from "../../../../state/context/clients/services";
import FormInput from "../../../../components/FormInput";
import SwitchButton from "../../../../components/ds/atoms/SwitchButton";
import { Table } from '../../../../components/ds/atoms/Table';
import Drawer from '../../../../components/ds/atoms/Drawer';
import { Box, Button } from "../../../../components/ds/SubatomicParticles";
import { H3, P } from "../../../../components/ds/theme/typography";
import { Client, QuiverParam, SyonetParam } from "../../../../state/context/clients/types";
import Breadcrumbs from "../../../../components/ds/atoms/Breadcrumbs";
import Loading from "../../../../components/SmallLoading";
import { Props, State } from "./types";
import { Schema, SchemaDrawer } from "./schema";
import Icon from '../../../../components/ds/atoms/Icon';
import { ReactComponent as IconEdit } from '../../../../assets/svg/edit-3.svg';
import { ReactComponent as IconX } from '../../../../assets/svg/x.svg';
import FormSelect from '../../../../components/FormSelect';

import css from '@styled-system/css';

const Form: React.FC<Props> = (props) => {
  const location = useLocation();
  const { id } = useParams();
  const matchEdit = useRouteMatch("/admin/clients/edit/:id");
  const { push } = useHistory();
  const { getClients, getClient, clients, getContractorSyonetParameters, getSyonetModalityTypes, getContractorQuiverParameters, getQuiverModalityTypes } = props;
  const [dataForm, setDataForm] = useState<any>({
    name: "",
    domain: "",
    config: {
      sac: false,
      pages: [
        {
          sobre: false,
        },
      ],
    },
    syonetModality: [],
    drawerState: false,
    drawerData: [],
    quiverModality: []
  });

  const steps = useMemo(() => {
    if (matchEdit) {
      return [
        {
          path: "/admin/clients",
          name: "Clientes",
        },
        {
          path: location.pathname,
          name: "Editar",
        },
      ];
    }
    return [
      {
        path: "/admin/clients",
        name: "Clientes",
      },
      {
        path: "/admin/clients/create",
        name: "Cadastrar",
      },
    ];
  }, [location, matchEdit]);


  const syonetColumns = [
    {
      Header: 'Modalidade',
      accessor: 'modalidade',
    },
    {
      Header: 'Midia',
      accessor: 'midia',
    },
    {
      Header: 'Grupo Evento',
      accessor: 'grupoEvento',
    },
    {
      Header: 'Tipo Evento',
      accessor: 'tipoEvento',
    },
    {
      Header: 'Ações',
      accessor: 'actions',
      width: 200,
      Cell: (row: any) => (
        <P styling="base" width="100%" textAlign="right">
          {row.row.values.actions}
        </P>
      ),
    },
  ];

  const quiverColumns = [
    {
      Header: 'Modalidade',
      accessor: 'modalidade',
    },
    {
      Header: 'Grupo Hierarquico',
      accessor: 'grupoHierarquico',
    },
    {
      Header: 'Ações',
      accessor: 'actions',
      width: 200,
      Cell: (row: any) => (
        <P styling="base" width="100%" textAlign="right">
          {row.row.values.actions}
        </P>
      ),
    },
  ];

  useEffect(() => {
    getClients();
  }, [getClients]);

  const handleSyonetData = (syonetData: SyonetParam[]) => {
    if(syonetData){
      return syonetData.map(syonetParam => {
        return {
          ...syonetParam,
          modalidade: syonetParam.label,
          actions: (
            <Box styling="row" justifyContent="flex-start">
              <Button
                styling="base"
                type="button"
                onClick={ async () => {
                  handleDrawer(); 
                  handleDrawerData(syonetParam, false);
                }}
              >
                <Icon styling="admin" title="Editar">
                  <IconEdit height={20} width={20} />
                </Icon>
              </Button>
            </Box>
          ),
        }
      });
    } else{
      return [{}]
    }
  }

  const handleQuiverData = (quiverData: QuiverParam[]) => {
    if(quiverData){
      return quiverData.map(quiverParam => {
        return {
          ...quiverParam,
          modalidade: quiverParam.label,
          actions: (
            <Box styling="row" justifyContent="flex-start">
              <Button
                styling="base"
                type="button"
                onClick={ async () => {
                  handleDrawer(); 
                  handleDrawerData(quiverParam, false);
                }}
              >
                <Icon styling="admin" title="Editar">
                  <IconEdit height={20} width={20} />
                </Icon>
              </Button>
            </Box>
          ),
        }
      });
    } else{
      return [{}]
    }
  }
  
  const handleSyonetTypes = (syonetTypes: any[], syonetData: SyonetParam[]) => {
    if(syonetData){
      syonetTypes.map((syonetType, index) => {
        if(syonetData.find((syonetParam)=> (syonetParam.tipo === syonetType.value))){
          syonetTypes.splice(index, 1)
        }
        return true;
      });
    } 
    return syonetTypes
  }

  const handleQuiverTypes = (quiverTypes: any[], quiverData: QuiverParam[]) => {
    if(quiverData){
      quiverTypes = quiverTypes.filter((quiverType, index) => {
        if(quiverData.find((quiverParam)=> (quiverParam.tipo === quiverType.value))){
          return false;
        }
        return true;
      });
    } 
    return quiverTypes
  }

  useEffect(() => {
    (async function loadingPage() {
      if (id && matchEdit) {
        const client = await getClient(id);
        const syonetData = await getContractorSyonetParameters(id);
        const syonetTypes = await getSyonetModalityTypes();
        const quiverData = await getContractorQuiverParameters(id);
        const quiverTypes = await getQuiverModalityTypes();
        client.syonetTypes = handleSyonetTypes(syonetTypes, syonetData) 
        client.syonetModality = handleSyonetData(syonetData); 
        client.quiverTypes = handleQuiverTypes(quiverTypes, quiverData) 
        client.quiverModality = handleQuiverData(quiverData); 
        setDataForm({...client, drawerState: false, drawerData: []});
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const validateDomain = useCallback(
    (value) => {
      const client = clients.find((client) => client.domain === value);
      return client ? "Este dominio já esta em uso" : false;
    },
    [clients],
  );

  const cssCuston = css({
    color: 'secondary.default',
    fontFamily: 'OpenSans Semibold',
    border: 'none',
    fontSize: 2,
    display: 'flex',
    m: 0,
    p: 0,
    '&:hover': {
      color: '#3f51b5',
      svg: {
        path: {
          fill: '#3f51b5',
          stroke: '#3f51b5',
        },
      },
    },
    '& svg': {
      path: {
        stroke: 'secondary.default',
      },
    },
  })

  const getParamProp = (name: string) => {
    switch(name){
      case "label":
        return {order: 1, id: "modalidade", name: "Modalidade"}
      case "grupoEvento":
        return {order: 2, id: "grupoEvento", name: "Grupo Evento"}
      case "tipoEvento":
        return {order: 3, id: "tipoEvento", name: "Tipo Evento"}
      case "midia":
        return {order: 4, id: "midia", name: "Midia"}
      case "grupoHierarquico":
        return {order: 2, id: "grupoHierarquico", name: "Grupo Hierarquico"}
      case "tipo":
        return {id: "tipo", name: "tipo"}
      default:
        return {}
    }
  }

  const handleDrawer = () => setDataForm((data: any) => ({...data, drawerState: !data.drawerState, drawerData: []}));

  const handleDrawerData = async (modalidade: any, novaModalidade: boolean) =>{
    let dawerTipo: any = "";
    let newDrawerData: boolean = false;
    const drawerDataFormatted: any = [];
    if(novaModalidade){
      dawerTipo="new";
      newDrawerData = true;
      modalidade.map((param: any, index: number) => {
        if(!param.Cell){
          drawerDataFormatted[index] = {
            id: param.accessor, 
            name: param.Header,
            value: ''
          }
        }
        return true;
      })
    } else {
      Object.entries(modalidade).map((param) => {
        const modalidadeProps = getParamProp(param[0]);
        if(modalidadeProps.order){
          drawerDataFormatted[modalidadeProps.order] = {
            id: modalidadeProps.id, 
            name: modalidadeProps.name,
            value: param[1]
          }
        }
        dawerTipo = modalidadeProps.id === "tipo" ? param[1] : dawerTipo 
        return true;
      });
    }
    setDataForm((data: any) => ({...data, drawerData: drawerDataFormatted, drawerId: dawerTipo, newDrawerData: newDrawerData}));
    return true;
  }

  const getParameterDrawer = (id : string) => dataForm.drawerData.find((parameter: any)=>parameter? parameter.id === id : false)

  const saveFormDrawer = async () => {
    if(getParameterDrawer('grupoHierarquico')){
      await props.addContractorQuiverParameters(id, getParameterDrawer('grupoHierarquico').value, dataForm.drawerId)
      const quiverData = await getContractorQuiverParameters(id);
      const quiverModality = handleQuiverData(quiverData); 
      let quiverTypes = await getQuiverModalityTypes();
      quiverTypes = handleQuiverTypes(dataForm.quiverTypes, quiverData) 
      setDataForm((data: any) => {
        data.quiverModality = quiverModality;
        data.quiverTypes = quiverTypes;
        return data;
      });

    } else {
      await props.addContractorSyonetParameters(id, getParameterDrawer('grupoEvento').value, getParameterDrawer("tipoEvento").value, getParameterDrawer('midia').value, dataForm.drawerId)
      const syonetData = await getContractorSyonetParameters(id);
      const syonetModality = handleSyonetData(syonetData); 
      let syonetTypes = await getSyonetModalityTypes();
      syonetTypes = handleSyonetTypes(dataForm.syonetTypes, syonetData) 
      setDataForm((data: any) => {
        data.syonetModality = syonetModality;
        data.syonetTypes = syonetTypes;
        return data;
      });
    }
    handleDrawer()
  }
  

  return (
    <>
      <Box
        styling="column"
        width="100%"
        height="100%"
        padding="30px"
        position="relative"
      >
        <Breadcrumbs styling="base" steps={steps} backPath="/admin/clients" />
        <Box
          styling="column"
          width="100%"
          minHeight="calc(100vh - 108px)"
          position="relative"
          justifyContent="flex-start"
          pt="30px"
        >
          <Formik
            enableReinitialize
            initialValues={dataForm}
            validationSchema={Schema}
            onSubmit={async (values, { setSubmitting }) => {
              delete values.syonetModality
              delete values.quiverModality
              delete values.drawerState
              delete values.drawerData
              delete values.syonetTypes
              delete values.quiverTypes
              if (id && matchEdit) {
                await props.editClient(id, values);
                setSubmitting(false);
              } else {
                await props.createClient(values);
                setSubmitting(false);
                push('/admin/clients');
              }
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <FormikForm translate='no' style={{ height: "100%", width: "100%", overflowY:"scroll"}}>
                <Box
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  mb="15px"
                  minHeight="calc(100% - 52px)"
                  styling="column"
                >
                  <Box
                    styling="grid"
                    gridTemplateColumns="1fr 1fr"
                    gridGap="50px"
                    width="100%"
                  >
                    <Field name="name">
                      {({
                        field,
                        meta,
                      }: FieldProps & FieldMetaProps<string>) => (
                          <FormInput
                            {...field}
                            name="name"
                            placeholder="Cliente"
                            type="text"
                            error={meta.touched && meta.error && meta.error}
                          />
                        )}
                    </Field>
                    <Field
                      name="domain"
                      validate={!matchEdit && validateDomain}
                    >
                      {({
                        field,
                        meta,
                      }: FieldProps & FieldMetaProps<string>) => (
                          <FormInput
                            {...field}
                            name="domain"
                            placeholder="Domínio"
                            type="text"
                            disabled={!!matchEdit}
                            error={meta.touched && meta.error && meta.error}
                          />
                        )}
                    </Field>
                  </Box>
                  <Box
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    mt="30px"
                    styling="column"
                    width="100%"
                  >
                    <H3
                      color="#1B2B37"
                      fontSize="16px"
                      lineHeight="20px"
                      mb="15px"
                      styling="base"
                    >
                      CONFIGURAÇÕES
                    </H3>
                    <Field name="config.sac">
                      {({ field }: FieldProps) => (
                        <SwitchButton
                          {...field}
                          id="sac"
                          label="Ativar SAC"
                          mt="10px"
                          onChange={(value) => {
                            setFieldValue("config.sac", value);
                          }}
                        />
                      )}
                    </Field>
                    <H3
                      color="#1B2B37"
                      fontSize="16px"
                      lineHeight="20px"
                      my="15px"
                      styling="base"
                    >
                      PÁGINAS
                    </H3>
                    <FieldArray
                      name="config.pages"
                      render={() =>
                        values.config.pages.map(
                          (page: Client, index: number) => {
                            const namePage = Object.keys(page)[0];
                            return (
                              <Field key={page} name={`config.pages.${index}`}>
                                {({ field }: FieldProps) => (
                                  <SwitchButton
                                    {...field}
                                    id={`config.pages.${index}`}
                                    label={`Ativar página ${namePage}`}
                                    mt="10px"
                                    value={field.value[namePage]}
                                    onChange={(value) =>
                                      setFieldValue(`config.pages.${index}`, {
                                        [namePage]: value,
                                      })
                                    }
                                  />
                                )}
                              </Field>
                            );
                          },
                        )
                      }
                    />
                  </Box>
                  <Box
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    mt="30px"
                    styling="column"
                    width="100%"
                  >
                    <H3
                      color="#1B2B37"
                      fontSize="16px"
                      lineHeight="20px"
                      mb="15px"
                      styling="base"
                      bottom="0"
                      left="0"
                      right="0"
                      height="0.5em"
                    >
                      SYONET
                      <span style={{paddingRight: "10px"}} ></span>
                    </H3>
                    <Box
                      width="100%"
                    >
                      <Table<any>
                          name="syonetParams"
                          styling="base"
                          data={dataForm.syonetModality}
                          columns={syonetColumns}
                          showFilter={false}
                          hiddenPagination={true}
                        />
                    </Box>
                    <Box styling="row" alignSelf="center" paddingTop="4px">
                      <Button
                        styling="outlineAdmin"
                        type="button"
                        disabled={isSubmitting || !matchEdit} 
                        onClick={ async () => {
                          handleDrawer();
                          handleDrawerData(syonetColumns, true);
                        }}
                      >
                        Nova Modalidade
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    mt="30px"
                    styling="column"
                    width="100%"
                  >
                    <H3
                      color="#1B2B37"
                      fontSize="16px"
                      lineHeight="20px"
                      mb="15px"
                      styling="base"
                      bottom="0"
                      left="0"
                      right="0"
                      height="0.5em"
                    >
                      QUIVER
                      <span style={{paddingRight: "10px"}} ></span>
                    </H3>
                    <Box
                      width="100%"
                    >
                      <Table<any>
                          name="syonetParams"
                          styling="base"
                          data={dataForm.quiverModality}
                          columns={quiverColumns}
                          showFilter={false}
                          hiddenPagination={true}
                        />
                    </Box>
                    <Box styling="row" alignSelf="center" paddingTop="4px">
                      <Button
                        styling="outlineAdmin"
                        type="button"
                        disabled={isSubmitting || !matchEdit} 
                        onClick={ async () => {
                          handleDrawer();
                          handleDrawerData(quiverColumns, true);
                        }}
                      >
                        Nova Modalidade
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Box styling="row" justifyContent="flex-end">
                  <Button
                    styling="outlineAdmin"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Salvar
                  </Button>
                </Box>
              </FormikForm>
            )}
          </Formik>
        </Box>
      </Box>
      <Drawer zIndex={99999} styling="right" open={dataForm.drawerState} size={450} onClose={handleDrawer} overlay>
        <Formik
            enableReinitialize
            initialValues={dataForm}
            validationSchema={SchemaDrawer}
            onSubmit={() => {}}
          >
            {() => (
            <FormikForm translate='no' style={{ height: "100%", width: "100%", overflowY:"scroll"}}>
              <Box styling="column" height="100%" justifyContent="space-between">
                <Box styling="column" width="100%" justifyContent="space-between">
                  <Box styling="row" width="100%" justifyContent="space-between" mb="20px">
                    <Button
                      type="button"
                      styling="underlined"
                      css={cssCuston}
                      onClick={handleDrawer}
                    >
                      <Icon styling="admin" title="Fechar">
                        <IconX />
                      </Icon>
                    </Button>
                  </Box>
                  {dataForm.drawerData && dataForm.drawerData
                  .map((data: any, index: number) =>(
                      <Field
                        name={data.name}
                        validate={!matchEdit}
                      >
                        {({
                          field,
                          meta,
                        }: FieldProps & FieldMetaProps<string>) => 
                        data.id === "modalidade" ?  (
                          <FormSelect
                            {...field}
                            styling="base"
                            key={index}
                            name={data.name}
                            placeholder={data.name}
                            id={data.id}
                            options={dataForm.newDrawerData? (getParameterDrawer('grupoHierarquico')? dataForm.quiverTypes : dataForm.syonetTypes) : [{value: dataForm.drawerId, label: data.value}]}
                            defaultValue={dataForm.newDrawerData? '' : dataForm.drawerId}
                            onChange={e => {
                              dataForm.drawerId = e.value;
                              setDataForm((data: any) => ({...data}));
                            }}
                        />
                      ) : 
                      (
                        <FormInput
                          {...field}
                          key={index}
                          name={data.name}
                          placeholder={data.name}
                          value={data.value}
                          id={data.id}
                          type={data.id==="grupoHierarquico"? "number" : "text"}
                          disabled={false}
                          error={meta.touched && meta.error && meta.error}
                          onChange={e => {
                            dataForm.drawerData[index].value = e.target.value;
                            setDataForm((data: any) => ({...data}));
                          }}
                        />
                      )
                    }
                  </Field>
                  ))}
                </Box>

                <Box styling="row" width="100%" justifyContent="space-between" mt="20px" mb="5px">
                  <Button type="button" styling="outlineAdmin" onClick={handleDrawer} >
                    Cancelar
                  </Button>
                  <Button type="button" styling="outlineAdmin" onClick={saveFormDrawer} >
                    Salvar
                  </Button>
                </Box>
              </Box>
            </FormikForm>
          )}
        </Formik>
      </Drawer>
      {props.isLoading && (
        <Box   
          styling="column"
          height="100vh"
          width="100%"
          zIndex={99999}
          bg="rgba(240, 241, 242, 0.62)"
          position="absolute"
        >
          <Loading />
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state: State) => ({
  clients: state.clients.clients,
  isLoading: state.clients.isLoading,
});

const mapDispatchToProps = {
  getClients,
  getClient,
  editClient,
  createClient,
  getSyonetModalityTypes,
  getContractorSyonetParameters,
  addContractorSyonetParameters,
  getContractorQuiverParameters,
  getQuiverModalityTypes,
  addContractorQuiverParameters,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(Form as any));
