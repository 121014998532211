export type Theme = {
  id?: string;
  dark: boolean;
  primary: {
    default: string;
    dark: string;
    light: string;
    contrast: string;
  },
  secondary: {
    default: string;
    dark: string;
    light: string;
    contrast: string;
  },
  danger: {
    default: string;
    dark: string;
    light: string;
    contrast: string;
  },
  success: {
    default: string;
    dark: string;
    light: string;
    contrast: string;
  },
  warning: {
    default: string;
    dark: string;
    light: string;
    contrast: string;
  },
  info: {
    default: string;
    dark: string;
    light: string;
    contrast: string;
  },
  disabled: string;
  background: {
    default: string;
    dark: string;
  },
  text: {
    light: string;
    dark: string;
  },
  primaries: string[];
  grays: {
    default: string;
    dark: string;
    light: string;
    contrast: string;
  };
  whites: string[];
  blacks: string[];
  hovers: string[];
  label: string;
};

export interface State {
  themes: Theme[];
  isLoading: boolean;
}

export const SET_THEMES = "theme/SET_CLIENTS";
export const IS_LOADING = "theme/IS_LOADING";
