/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Box, Button } from "../../../components/ds/SubatomicParticles";
import { H3, P } from "../../../components/ds/theme/typography";
import SmallLoading from "../../../components/SmallLoading";

export default function AddressConfirm({
  onNextMyHouse,
  onNextOtherHouse,
  userResidences,
  removeResidence,
  setSelectedAddress,
  userResidencesLoading,
  getResidences,
  titleEmpty,
  type = "residence",
}) {
  useEffect(() => {
    getResidences();
  }, []);

  const handleNewResidence = () => onNextOtherHouse("createMyResidence");

  return (
    <>
      <Box
        gridColumn={{
          mobile: "2/span 10",
          tablet: "2/span 10",
          desktop: "4/span 6",
        }}
        styling="column"
      >
        <H3 mb="30px" styling="light">
          {type === "auto" ? titleEmpty : "Lista de Residências"}
        </H3>
        {userResidences.length === 0 && !userResidencesLoading && (
          <Box styling="row" width="100%">
            <P styling="base" textAlign="center" width="100%">
              Você ainda não tem nenhuma residência cadastrada.
            </P>
          </Box>
        )}

        {userResidencesLoading && userResidences.length === 0 ? (
          <Box styling="row" width="100%">
            <SmallLoading />
          </Box>
        ) : (
            <Box styling="column" width="100%">
              {userResidences.map((item, i) => (
                <Box
                  key={`d${i}`}
                  backgroundColor="background.default"
                  borderRadius="12px"
                  boxShadow="10px 10px 28px 1px rgba(0,0,0,.1)"
                  alignItems="center"

                  mb="15px"
                  padding="20px"
                  styling="row"
                  width="100%"
                >
                  <Box onClick={() =>
                    setSelectedAddress(item.residenceId) ||
                    onNextMyHouse(item.residenceId)
                  }
                    alignItems="flex-start"
                    styling="column"
                    width="calc(100% - 30px)"
                  >
                    <P fontSize="18px" styling="base">
                      {item.StreetType} {item.StreetName} {item.StreetNumber}
                    </P>
                    <P styling="base">{item.StreetExtraInformation}</P>
                    <P styling="base">
                      {item.CityName}, {item.StateCode} - CEP {item.ZipCode}
                    </P>
                  </Box>
                  <Button onClick={async () => {
                    await removeResidence({ residenceId: item.residenceId })
                    getResidences();
                  }}
                    style={{
                      width: 120,
                      height: 40,
                      backgroundColor: "white",
                      borderStyle: "solid",
                      borderColor: "#F26522",
                      borderWidth: 2,
                      borderRadius: 20,
                    }}>
                    <div style={{ color: "#F26522", fontWeight: "bold", fontSize: 18 }}>Remover</div>
                  </Button>
                </Box>
              ))}
            </Box>
          )}
        {userResidencesLoading && userResidences.length !== 0 && (
          <Box styling="row" width="100%">
            <SmallLoading />
          </Box>
        )}
        <Button
          display="block"
          margin="15px auto 0"
          onClick={handleNewResidence}
          styling="primary"
          width="225px"
        >
          {userResidences.length === 0 && !userResidencesLoading
            ? "Cadastrar residência"
            : "Cadastrar nova residência"}
        </Button>
      </Box>
    </>
  );
}
