import { Dispatch } from 'redux';
import moment from 'moment';
import { setClient, setTheme, setLocale, isLoading, setImages } from './actions';
import { getClient as getClientContext } from '../clients/services';
import { getTheme as getThemeContext } from '../themes/services';
import { getImage as getImagesContext } from '../images/services';
import { getLocale as getLocaleContext } from '../locales/services';
import localeDefault from '../../../assets/locale/default';

import { Client } from '../clients/types';
import { Theme } from '../themes/types';
import { Images } from '../images/types';
import { Locale } from '../locales/types';

export const getConfig = (contractor: string) => async (dispatch: Dispatch, getState: any) => {
  const { config: { clients, themes, locales, images } } = getState();
  try {
    if (
      !clients[contractor] || moment().isSameOrAfter(moment(clients[contractor].cache.expire))) {
      dispatch(isLoading(true));
      const client = await dispatch(getClientContext(contractor) as any) as Client;
      const now = moment();
      dispatch(
        setClient({
          [contractor]: {
            ...client,
            cache: {
              cratedAt: now.toDate().toISOString(),
              expire: now
                .add(6, 'hours')
                .toDate()
                .toISOString(),
            }
          }
        })
      );
      dispatch(isLoading(false));
    }
    if (!themes[contractor] || moment().isSameOrAfter(moment(themes[contractor].cache.expire))) {
      dispatch(isLoading(true));
      const theme = await dispatch(getThemeContext(contractor) as any) as Theme;
      const now = moment();
      dispatch(
        setTheme({
          [contractor]: {
            ...theme,
            cache: {
              cratedAt: now.toDate().toISOString(),
              expire: now
                .add(6, 'hours')
                .toDate()
                .toISOString(),
            }
          }
        })
      );
      dispatch(isLoading(false));

    }
    if (!images[contractor] || moment().isSameOrAfter(moment(images[contractor].cache.expire))) {
      dispatch(isLoading(true));
      const images = await dispatch(getImagesContext(contractor) as any) as Images;
      const now = moment();
      dispatch(
        setImages({
          [contractor]: {
            ...images,
            cache: {
              cratedAt: now.toDate().toISOString(),
              expire: now
                .add(6, 'hours')
                .toDate()
                .toISOString(),
            }
          }
        })
      );
      dispatch(isLoading(false));
    }
    if (!locales[contractor] || moment().isSameOrAfter(moment(locales[contractor].cache.expire))) {
      dispatch(isLoading(true));
      const locale = await dispatch(getLocaleContext(contractor) as any) as Locale;
      const now = moment();
      dispatch(
        setLocale({
          [contractor]: {
            ...localeDefault,
            ...locale,
            cache: {
              cratedAt: now.toDate().toISOString(),
              expire: now
                .add(6, 'hours')
                .toDate()
                .toISOString(),
            }
          }
        } as any)
      );
      dispatch(isLoading(false));
    }
  } catch (error) {
    dispatch(isLoading(false));
  };
};
