import React from 'react';
import { Box } from "../../components/ds/SubatomicParticles";
import Icon from "../../components/ds/atoms/Icon";
import { ReactComponent as IconSpin } from "../../assets/svg/icon_spin.svg";

const SmallLoading = () => (
  <Box height="50px" position="relative" styling="row" width="50px">
    <Icon styling="base" height="50px" width="50px">
      <IconSpin height="50px" width="50px" />
    </Icon>
  </Box>
)

export default SmallLoading