import React, { memo, useEffect, useCallback } from "react";
import { ThemeProvider } from "styled-components";
import { connect } from "react-redux";
import kebabCase from "lodash/kebabCase";

import * as theme from "../../components/ds/theme";
import Loading from "../../components/SmallLoading";
import { Box } from "../../components/ds/SubatomicParticles";
import themeDefault from "../../assets/themes/default";
import { getConfig } from "../../state/context/config/services";
import { setContractor } from "../../state/context/config/actions";
import { getClient } from "../../state/context/config/selector";
import { Props, State } from "./types";

const Provider: React.FC<Props> = (props) => {
  const { themes, client, getConfig, setContractor } = props;
  const getContractorName = useCallback(() => {
    let contractor: string =
      process.env.REACT_APP_CUSTOM_CONTRACTOR ?? "pessego-com";
    
    if (process.env.REACT_APP_PESSEGO_ENV === "production") {
      contractor = kebabCase(
        window.location.origin
          .replace(/^(http|https):\/\//, "")
      );
    }
    return contractor;
  }, []);

  const contractor = getContractorName();
  const themeContractor =
    themes && themes[contractor] ? themes[contractor] : {};

  useEffect(() => {
    setContractor(contractor);
  }, [setContractor, contractor, props.client]);

  useEffect(() => {
    getConfig(contractor);
  }, [getConfig, contractor]);

  useEffect(() => {
    if (client?.config?.sac) {
      const callScript = document.createElement("script");
      callScript.setAttribute(
        "src",
        "https://assets.callnow.com.br/VkQS7o.js",
      );
      document.head.appendChild(callScript);
    }
  }, [client])

  return (
    <ThemeProvider
      theme={{
        ...theme.default,
        colors: {
          ...theme.default.colors,
          ...themeDefault,
          ...themeContractor,
        },
      }}
    >
      {props.isLoading ? (
        <Box styling="column" height="100vh">
          <Loading />
        </Box>
      ) : (
          props.children
        )}
    </ThemeProvider>
  );
};

const mapStateToProps = (state: State) => ({
  themes: state.config.themes,
  isLoading: state.config.isLoading,
  client: getClient(state),
});

const mapDispatchToProps = {
  getConfig,
  setContractor,
  getClient,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(Provider as any));
