import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { State, Props } from './types';
import { getLocale } from '../../state/context/config/selector';

const InjectLocale = <P extends Props>(Component: React.FC<P>) => (props: any) => (
    <Component {...props} />
);

const mapState = (state: State) => ({
    locale: getLocale(state),
});

export default compose(
    connect(
        mapState,
    ),
    InjectLocale,
);
