import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { TableInstance } from 'react-table';
import { variant, VariantArgs } from 'styled-system';
import { P } from '../../theme/typography';
import { defaults, DefaultStyleProps, Box, Button } from '../../SubatomicParticles';
import { ReactComponent as IconLeft } from './assets/arrow-left-circle.svg';
import { ReactComponent as IconRight } from './assets/arrow-right-circle.svg';

import styled from 'styled-components';
import Select from '../Select';
import css from '@styled-system/css';
import Icon from '../Icon';

type TablePaginationProps = {
  styling?: 'base';
};

const tableType = variant
  ({
    prop: 'styling',
    variants: {
      base: {
        // borderTop: '1px solid',
        // borderColor: 'bg',
      },
    }
  } as VariantArgs);

const TablePaginationStyle: React.FC<TablePaginationProps & DefaultStyleProps> = styled.div`
    ${tableType}
    ${defaults}
`;

const rowsPerPageOptions = [
  { value: '5', label: '5' },
  { value: '10', label: '10' },
  { value: '25', label: '25' },
  { value: '50', label: '50' }
];

export function TablePagination<T extends object>({
  instance,
  ...props
}: PropsWithChildren<{ instance: TableInstance<T>, styling: 'base' }>): React.ReactElement | null {

  const {
    state: { pageIndex, pageSize, rowCount = instance.rows.length },
    nextPage,
    previousPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
    pageOptions
  } = instance;

  const onChangeRowsPerPage = useCallback(
    e => {
      setPageSize(Number(e.value))
    },
    [setPageSize]
  );

  const rowPerPageDefaultSelected = useMemo(() =>
    rowsPerPageOptions.find(
      rowPerPageOptions => Number(rowPerPageOptions.value) === pageSize
    ), [pageSize]);

  return (
    <TablePaginationStyle styling={props.styling ?? "base"}>
      <Box styling="row" p={0} justifyContent="space-between">
        <Box styling="row">
          <P color="#3f51b5" styling="base" fontSize="1">Total: </P>
          <P styling="baseBold" color="#3f51b5" fontSize="1">&nbsp;{rowCount}&nbsp;</P>
          <P styling="base" color="#3f51b5" fontSize="1">itens </P>
        </Box>
        <Box styling="row">
          <Button
            styling="underlined"
            type="button"
            m={0}
            mr="xl"
            p={0}
            pt="5px"
            onClick={() => {canPreviousPage && previousPage()}}
          >
            <Icon styling={canPreviousPage ? "adminButton" : "disabled"} title={canPreviousPage ? 'Página anterior' : ''}>
              <IconLeft height={20} width={20} fill="none" />
            </Icon>
          </Button>
          <P color="#1B2B37" styling="base" fontSize="1">Página {pageIndex + 1} de</P>
          <P color="#1B2B37" styling="base" fontSize="1">&nbsp;{pageOptions.length ? pageOptions.length : 1}</P>
          <Button
            styling="underlined"
            type="button"
            m={0} ml="xl"
            p={0} pt="5px"
            onClick={() => {canNextPage && nextPage()}}
          >
            <Icon styling={canNextPage ? "adminButton" : "disabled"} title={canNextPage ? 'Próxima página' : ''}>
              <IconRight height={20} width={20} fill="none" stroke="red" />
            </Icon>
          </Button>
        </Box>
        <Box styling="row">
          <P color="#3f51b5" styling="base" fontSize="1">Ítens por página:</P>
          <Select
            styling="outlinePrimary"
            options={rowsPerPageOptions}
            placeholder=''
            width="80px"
            css={css({
              '.select__control': {
                bg: 'transparent',
                border: 'none',
                height: '50px',
                fontSize: '1',
              },
              '.select__indicator': {
                margin: 0,
              },
              '.select__indicator-separator': {
                bg: 'transparent',
              },
              ".select__menu": {
                top: "auto",
                bottom: "0"
              }
            })}
            defaultValue={rowPerPageDefaultSelected}
            onChange={onChangeRowsPerPage}
          />
        </Box>
      </Box>
    </TablePaginationStyle >
  );
};
