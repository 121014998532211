import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import {
  login as pentagramaLogin,
  getDropdownData,
  sendLead,
} from "../../state/context/parceiros/repository";
import { showToast } from "../../utils";
import Loading from "../util/loading";
import { Login } from "./login";
import { ReferralForm } from "./form";
import { IconsMap } from "../../components/iconHome/iconhome";
import { Box } from "../../components/ds/SubatomicParticles";
import { H1 } from "../../components/ds/theme/typography";
import Icon from "../../components/ds/atoms/Icon";
import { ReactComponent as Dotsleft } from "../../assets/svg/dots_right.svg";

function Parceiros({ history }) {
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [dropdownData, setDropdownData] = useState({});

  const submitLogin = async (fields) => {
    try {
      setLoading(true);
      const token = (
        await pentagramaLogin({
          username: fields.email,
          password: fields.password,
        })
      ).token;
      const dropdownDataResponse = await getDropdownData(token);
      setDropdownData(dropdownDataResponse);
      setToken(token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToast(
        "error",
        "Ops!",
        "Ocorreu um erro ao fazer o login. Verifique o seu usuário e sua senha ou entre em contato com o pessego.com",
      );
    }
  };

  const submitForm = async ({
    indicador,
    telefone,
    empresa,
    setor,
    funcao,
    indicacaoVenda: indicacao_venda,
    proposta,
    clienteNome: cliente_nome,
    clienteEmail: cliente_email,
    clienteCelular: cliente_celular,
    clienteFixo: cliente_fixo,
    vencimentoSeguro: vencimento_seguro,
    clienteVeiculo: cliente_veiculo,
    observacao,
  }) => {
    try {
      setLoading(true);
      await sendLead(token, {
        indicador,
        telefone: telefone.replace(/[^0-9 ]/g, ""),
        empresa,
        setor,
        funcao,
        indicacao_venda,
        proposta,
        cliente_nome,
        cliente_email,
        cliente_celular: cliente_celular.replace(/[^0-9 ]/g, ""),
        cliente_fixo: cliente_fixo.replace(/[^0-9 ]/g, ""),
        vencimento_seguro,
        cliente_veiculo,
        observacao,
      });
      setLoading(false);
      showToast(
        "success",
        "Tudo certo!",
        "Sua indicação foi enviada com sucesso",
      );
      history.push("/");
    } catch (error) {
      setLoading(false);
      showToast(
        "error",
        "Ops!",
        "Ocorreu um erro ao enviar o formulário. Verifique os dados e se o problema persistir, entre em contato com o pessego.com",
      );
    }
  };

  return (
    <>
      <Box
        gridTemplateColumns="repeat(12,1fr)"
        gridRowGap="1.5rem"
        position="relative"
        styling="grid"
      >
        {token === "" || !token ? (
          <Login submitLogin={submitLogin} />
        ) : (
          <ReferralForm submit={submitForm} dropdownData={dropdownData} />
        )}
        <Box
          alignItems="flex-start"
          gridColumn="1/span 8"
          gridRow="8"
          height="210px"
          my="30px"
          styling="column"
        >
          <Icon styling="baseNone" height="100%" width="70%">
          <Dotsleft width="100%" height="100%" />
          </Icon>
        </Box>
        <Box gridColumn="2/span 10" gridRow="9" styling="column">
          <H1 fontSize="2rem" mb="2rem" styling="light">
            Outros seguros
          </H1>
          <Box
            gridGap="1rem"
            gridTemplateColumns={{
              mobile: "repeat(2,1fr)",
              tablet: "repeat(3,1fr)",
              desktop: "repeat(7,1fr)",
            }}
            styling="grid"
            width="100%"
          >
            <IconsMap history={history} />
          </Box>
        </Box>
      </Box>
      <Loading show={loading} />
    </>
  );
}

export default compose(withRouter)(Parceiros);
