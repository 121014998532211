import React from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";

import Main from "../containers/main";
import PrivateRouter from "../containers/util/privateRouter";
import Router from "../containers/util/Router";

import Home from "../containers/home";
import Register from "../containers/register";
import ForgetPassword from "../containers/forgetPassword";
import MinhaConta from "../containers/minhaconta";
import Verify from "../containers/verify";
import Suporte from "../containers/suporte";
import Form from "../containers/form";
import Automovel from "../containers/automovel";
import Residencial from "../containers/residencial";
import Viagem from "../containers/viagem";
import Vida from "../containers/vida";
import Bike from "../containers/bike";
import Pets from "../containers/pets";
import Portateis from "../containers/portateis";
import Sinistro from "../containers/sinistro";
import infoMySecures from "../containers/infoMySecures";
import CotacaoValores from "../containers/CotacaoValores";
import InfoMyQuotes from "../containers/infoMyQuotes";
import Sobre from "../containers/sobre";
import Contato from "../containers/contato";
import ScrollToTop from "../components/ScrollToTop";
import Parceiros from "../containers/parceiros";
import PagamentoCartao from "../containers/pagamentoCartao";
import Conta from "../containers/Conta";
import Admin from "../containers/Admin";
import ClientsList from "../containers/Admin/Clients/List";
import ClientsForm from "../containers/Admin/Clients/Form";
import ThemesList from "../containers/Admin/Themes/List";
import ThemesForm from "../containers/Admin/Themes/Form";
import ImagesForm from "../containers/Admin/Images/Form";
import ImagesList from "../containers/Admin/Images/List";
import LocaleList from "../containers/Admin/Locale/List";
import LocaleForm from "../containers/Admin/Locale/Form";
import UsersList from "../containers/Admin/Users";
import Cotacoes from "../containers/cotacoes";
import Termos from "../containers/termos";
import IFrame from "../components/iFrame/iFrame";
import iFrameAuto from "../components/iFrame/iFrameAuto";

const Navigation = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Router path="/admin">
          <Admin>
            <PrivateRouter
              exact
              path="/admin/clients"
              component={ClientsList}
            />
            <PrivateRouter
              exact
              path="/admin/clients/create"
              component={ClientsForm}
            />
            <PrivateRouter
              exact
              path="/admin/clients/edit/:id"
              component={ClientsForm}
            />
            <PrivateRouter exact path="/admin/themes" component={ThemesList} />
            <PrivateRouter
              exact
              path="/admin/themes/create"
              component={ThemesForm}
            />
            <PrivateRouter
              exact
              path="/admin/themes/edit/:id"
              component={ThemesForm}
            />
            <PrivateRouter exact path="/admin/locales" component={LocaleList} />
            <PrivateRouter
              exact
              path="/admin/locales/create"
              component={LocaleForm}
            />
            <PrivateRouter
              exact
              path="/admin/locales/edit/:id"
              component={LocaleForm}
            />
            <PrivateRouter exact path="/admin/users" component={UsersList} />
            <PrivateRouter exact path="/admin/images" component={ImagesList} />
            <PrivateRouter
              exact
              path="/admin/images/edicao/:id"
              component={ImagesForm}
            />
          </Admin>
        </Router>
        <ScrollToTop>
          <Main>
            <Switch>
              <Router exact path="/" component={Home} />
              <Router exact path="/seguros" component={Home} />
              <Router exact path="/parceiros" component={Parceiros} />
              <Router exact path="/cadastrar" component={Register} />
              <Router exact path="/verificacao" component={Verify} />
              <Router exact path="/esqueci-senha" component={ForgetPassword} />
              <Router exact path="/cotacoes" component={Cotacoes} />
              <Router exact path="/form" component={Form} />
            
              <Router
                exact
                path="/pagamento/cartao/credito/:token"
                component={PagamentoCartao}
              />
              <PrivateRouter exact path="/minhaconta" component={MinhaConta} />
              <PrivateRouter exact path="/info" component={infoMySecures} />
              <PrivateRouter exact path="/sinistro" component={Sinistro} />
              <PrivateRouter
                exact
                path="/cotacao-valores"
                component={CotacaoValores}
              />
              <PrivateRouter
                exact
                path="/info-cotacoes"
                component={InfoMyQuotes}
              />
              <Router exact path="/automovel" component={Automovel} />
              <Router exact path="/iframe" component={IFrame} />
              <Router exact path="/iframe-auto" component={iFrameAuto} />
              <Router exact path="/residencial" component={Residencial} />
              <Router exact path="/viagem" component={Viagem} />
              <Router exact path="/vida" component={Vida} />
              <Router exact path="/pets" component={Pets} />
              <Router exact path="/portateis" component={Portateis} />
              <Router exact path="/suporte" component={Suporte} />
              <Router exact path="/sobre" component={Sobre} />
              <Router exact path="/contato" component={Contato} />
              <Router exact path="/conta" component={Conta} />
              <Router exact path="/bike" component={Bike} />
              <Router exact path="/termos" component={Termos} />
              <Redirect to="/" />
            </Switch>
          </Main>
        </ScrollToTop>
      </Switch>
    </BrowserRouter>
  );
};

export default Navigation;
