import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import * as quotationService from '../../state/context/cotacao/services';
import { showToast } from "../../state/context/user/actions";
import QuotationCardInfo from "../../components/shared/QuotationCardInfo";
import { returnIconToQuotation } from "../../utils";
import { Box } from "../../components/ds/SubatomicParticles";
import { H3 } from "../../components/ds/theme/typography";

const Cotacoes = ({ quotes, getValueDetails, history, quotesLoading }) => {
  const [idSearching, setIdSearching] = useState()
  const getValueDetail = (data) => {
    setIdSearching(data.quoteId)
    getValueDetails(data).then(r => {
      if (r.status === 200) {
        history.push('/cotacao-valores', { id: data.quoteId })
        setIdSearching()
      } else {
        showToast('erro', 'Erro Interno', 'Não foi possivel obter os dados da cotaçāo.')
        setIdSearching()
      }
    }).catch(e => {
      setIdSearching()
    })
  }

  return (
    <Box
      gridTemplateColumns="repeat(12,1fr)"
      gridRowGap="1.5rem"
      minHeight="calc(100vh - 236px)"
      styling="grid"
    >
      <Box gridColumn={{ mobile: '2/span 10', tablet: '2/span 10', desktop: '4/span 6' }} styling="column">
        <H3 mb="30px" styling="light">Cotações</H3>
        {quotes.map(e => (
          <QuotationCardInfo
            key={e.quoteId}
            width="80%"
            icon={returnIconToQuotation(e.type)}
            text={e.description || e.descriptions}
            timeStamp={e.timeStamp}
            onClick={getValueDetail}
            type={e.type}
            quoteId={e.quoteId}
            loadingId={idSearching}
          />
        ))}
      </Box>
    </Box>
  );
};

const mapStateToProps = state => ({
  quotes: state.user.myQuotes,
});

const mapDispatchToProps = {
  getValueDetails: quotationService.getValueDetails
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Cotacoes);
