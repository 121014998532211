import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import * as userService from "../../state/context/user/services";
import { IconsMap } from "../../components/iconHome/iconhome";
import { Box, Button } from "../../components/ds/SubatomicParticles";
import { H1, H2, H4, P } from "../../components/ds/theme/typography";
import Link from "../../components/ds/atoms/Link";
import Icon from "../../components/ds/atoms/Icon";
import BgPets from "../../assets/images/bg_pets.png";
import { ReactComponent as Circle } from "../../assets/svg/circle.svg";
import { ReactComponent as DotsRight } from "../../assets/svg/dots_left.svg";
import { ReactComponent as Dotsleft } from "../../assets/svg/dots_right.svg";
import { ReactComponent as PesegoCare } from "../../assets/svg/pessego-care.svg";
import InjectImages from "../Images";
import PreImg from "../../components/PreImg";

function Pets({ history, showModal, user, images }) {
  return (
    <>
      <Box
        gridTemplateColumns="repeat(12,1fr)"
        gridRowGap="1.5rem"
        paddingY={{ mobile: "px", tablet: "0px", desktop: "3rem" }}
        position="relative"
        styling="grid"
      >
        <Box
          alignItems="flex-start"
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "2/span 4",
          }}
          styling="column"
        >
          <H1 styling="base">Cuide do seu pet, previna doenças graves e fique tranquilo.</H1>
          <Box
            styling="base"
            height="2px"
            width="4vw"
            mt="2rem"
            backgroundColor="primary.default"
          ></Box>
        </Box>

        <Box
          alignItems="flex-start"
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "2/span 4",
          }}
          gridRow="2"
          styling="column"
        >
          <P lineHeight="1.4" textAlign="justify" styling="base">
            Cachorros e gatos também precisam de cuidados especiais e,
            inclusive, periódicos. Ter um Seguro Pet é uma excelente decisão
            para ficar tranquilo com a saúde, garantir vacinas e até reduzir
            custos com consultas e emergências cirúrgicas necessárias por motivo
            de doença ou acidentes domésticos.
          </P>
          {/* <P lineHeight="1.4" textAlign="justify" styling="base" marginTop="8px">
            Contratação disponível nas capitais: Belo Horizonte, Rio de Janeiro e São Paulo.
          </P> */}
        </Box>

        <Box
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "2/span 4",
          }}
          gridRow="3"
          my="2rem"
          styling="column"
        >
          <Button
            onClick={() => {
              history.push({
                pathname: "/iframe",
                state: { type: "pets" },
              })
            }}
            styling="primary"
            width="350px"
            height="50px"
          >
            <div style={{ fontSize: 18 }}>Cotar agora seu seguro pet</div>
          </Button>
          {user && (user.telefone || user.telefoneCelular) && (
            <Button
              my="10px"
              onClick={() => showModal({ visible: true, type: "contact" })}
              styling="primary"
              width="295px"
            >
              Quero falar com alguém, me ligue!
            </Button>
          )}
        </Box>

        <Box
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          gridColumn="5/span 8"
          gridRow="2/span 4"
          height="210px"
          mt="150px"
          styling="column"
        >
          <Icon styling="baseNone" height="100%" width="100%">
            <DotsRight width="100%" height="100%" />
          </Icon>
        </Box>

        <Box
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          gridColumn="8/span 4"
          gridRow="1/span 4"
          ml="30px"
          styling="column"
        >
          <img alt="" height="100%" src={BgPets} width="100%" />
        </Box>

        <Box
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          gridColumn="7/span 8"
          gridRow="1/span 4"
          position="absolute"
          styling="column"
          top="50px"
        >
          <Circle />
        </Box>

        <Box
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          gridColumn="10/span 2"
          gridRow="2/span 4"
          position="absolute"
          styling="column"
          top="145px"
          zIndex="-1"
        >
          <Circle />
        </Box>

        <Box
          alignItems="stretch"
          flexWrap="wrap"
          gridColumn="2/span 10"
          gridRow="4"
          justifyContent="space-between"
          margin={{ mobile: "2rem 0", tablet: "2rem 0", desktop: "0 0 2rem" }}
          position="relative"
          styling="row"
        >
          <Box
            backgroundColor="background.default"
            borderRadius="12px"
            boxShadow="10px 10px 28px 1px rgba(0,0,0,.1)"
            justifyContent="flex-start"
            mb={{ mobile: "25px", tablet: "25px", desktop: "0" }}
            padding="20px"
            styling="column"
            width={{ mobile: "100%", tablet: "100%", desktop: "22%" }}
          >
            <H4 mb="24px" styling="base">Garantia de vacinas em dia</H4>
            <P lineHeight="1.4" styling="base">
              Manter as vacinas em dia é um ato de amor com o seu animalzinho de
              estimação, já que esta é a melhor forma de preveni-lo contra
              doenças infecciosas. Conte com a pessego.com e garanta que ele
              esteja livre dos males dos vírus.
            </P>
          </Box>

          <Box
            backgroundColor="background.default"
            borderRadius="12px"
            boxShadow="10px 10px 28px 1px rgba(0,0,0,.1)"
            justifyContent="flex-start"
            mb={{ mobile: "25px", tablet: "25px", desktop: "0" }}
            padding="20px"
            styling="column"
            width={{ mobile: "100%", tablet: "100%", desktop: "22%" }}
          >
            <H4 mb="24px" styling="base">Emergências cirúrgicas</H4>
            <P lineHeight="1.4" styling="base">
              Um dos custos mais altos com os pets são as cirurgias
              emergenciais. Com esta cobertura seu animalzinho terá garantia da
              atenção de profissionais especializados e você estará livre dos
              custos fora da sua programação financeira.
            </P>
          </Box>

          <Box
            backgroundColor="background.default"
            borderRadius="12px"
            boxShadow="10px 10px 28px 1px rgba(0,0,0,.1)"
            justifyContent="flex-start"
            mb={{ mobile: "25px", tablet: "25px", desktop: "0" }}
            padding="20px"
            styling="column"
            width={{ mobile: "100%", tablet: "100%", desktop: "22%" }}
          >
            <H4 mb="24px" styling="base">Consulta com especialistas</H4>
            <P lineHeight="1.4" styling="base">
              Oftalmologia, extração de dentes, cáries, dermatologia,
              odontologia, obstetrícia, pré-natal, cirurgias de pequeno, médio e
              grande porte e até parto cirúrgico ou parto normal. Todos estes
              serviços à disposição do seu pet!
            </P>
          </Box>

          <Box
            backgroundColor="background.default"
            borderRadius="12px"
            boxShadow="10px 10px 28px 1px rgba(0,0,0,.1)"
            justifyContent="flex-start"
            mb={{ mobile: "25px", tablet: "25px", desktop: "0" }}
            padding="20px"
            styling="column"
            width={{ mobile: "100%", tablet: "100%", desktop: "22%" }}
          >
            <H4 mb="24px" styling="base">Descontos exclusivos</H4>
            <P lineHeight="1.4" styling="base">
              Algumas seguradoras oferecem descontos e condições exclusivas em
              banho e tosa, medicamentos, fisioterapia e até terapias
              comportamentais. Informe ao promotor pessego.com quais são as
              coberturas de seu interesse e faça uso de todos os seus
              benefícios.
            </P>
          </Box>
        </Box>

        <Box
          alignItems="flex-start"
          gridColumn="2/span 10"
          gridRow="5"
          styling="column"
        >
          <H2 styling="base">A pessego.com tranquiliza você e cuida do seu pet!</H2>
        </Box>

        <Box
          alignItems="flex-start"
          gridColumn={{
            mobile: "2/span 10",
            tablet: "2/span 10",
            desktop: "2/span 6",
          }}
          gridRow="6"
          styling="column"
        >
          <P my="2rem" styling="regular">
            Não sabe exatamente o que procura?
          </P>
          <P styling="base">
            Não tem problema! Nossos consultores são especialistas em seguro e
            vão ajudar você a encontrar exatamente o que você precisa e do jeito
            que você preferir: por telefone, e-mail ou chat.
          </P>
          <P my="2rem" styling="regular">
            Preparamos uma lista de dúvidas mais frequentes pra você.
          </P>
          <P display="block" styling="base">
            Aqui está tudo bem explicadinho, mas se preferir conversar com um de
            nossos promotores é só clicar no botão “
            <Link color="primary.default" to="/contato">
              fale conosco
            </Link>
            ”, ok?
          </P>
        </Box>

        <Box
          backgroundColor="background.default"
          display={{ mobile: "none", tablet: "none", desktop: "flex" }}
          borderRadius="50%"
          gridColumn="10/span 2"
          gridRow="6"
          height="256px"
          ml="16px"
          styling="column"
          width="256px"
        >
          {images?.generic1?.url ? (
            <PreImg
              src={images.generic1.url}
              style={{
                width: "120px",
                height: "114px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                styling="row"
                width="80%"
                height="80%"
                borderRadius="4px"
                backgroundColor="#ededed"
              />
            </PreImg>
          ) : (
            <PesegoCare height="120px" width="114px" />
          )}
        </Box>

        <Box
          alignItems="flex-start"
          gridColumn="1/span 8"
          gridRow="7"
          height="210px"
          my="30px"
          styling="column"
        >
          <Icon styling="baseNone" height="100%" width="100%">
            <Dotsleft width="100%" height="100%" />
          </Icon>
        </Box>

        <Box gridColumn="2/span 10" gridRow="8" styling="column">
          <H2 mb="2rem" styling="base">Outros seguros</H2>
          <Box
            gridGap="1rem"
            gridTemplateColumns={{
              mobile: "repeat(2,1fr)",
              tablet: "repeat(3,1fr)",
              desktop: "repeat(7,1fr)",
            }}
            styling="grid"
            width="100%"
          >
            <IconsMap history={history} />
          </Box>
        </Box>
      </Box>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = {
  showModal: userService.showModal,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(InjectImages(Pets));
