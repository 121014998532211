import React from "react";
import { ReactComponent as LogoWhite } from "../../assets/svg/logo_pessego_white.svg";
import { Box } from "../ds/SubatomicParticles";
import { P } from "../ds/theme/typography";
import pjson from '../../../package.json';

import styles from "./style.module.css"

const Footer = () => {
  return (
    <Box
      backgroundColor="secondary.default"
      mt="2rem"
      py="2rem"
      styling="row"
      justifyContent="space-around"
    >
      <Box
        styling="column"
      >
        <div className={styles.razaoSocialContainer}>
          <LogoWhite />
          <P className={styles.razaoSocialText} color="text.light" mt="10px" styling="base">CORRETORA DE SEGUROS LTDA </P>
        </div>
        <P color="text.light" mt="10px" styling="base">70.946.470/0001-28 </P>
        <P color="text.light" mt="10px" styling="base">{pjson.version}</P>
      </Box>
      <Box
        styling="column"
      >
        <P color="text.light" mt="10px" styling="base">
          <a
            href="codigo_de_conduta_etica_carbel_auto_group.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link_footer}
          >Código de Conduta Ética</a>
        </P>
        <P color="text.light" mt="10px" styling="base">
          <a href="https://www.contatoseguro.com.br/carbel" target="_blank" rel="noopener noreferrer" className={styles.link_footer}>Canal de Denúncias</a>
        </P>
      </Box>
    </Box>
  );
};

export default Footer;