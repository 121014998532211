import {
  SET_CLIENT,
  SET_THEME,
  SET_LOCALE,
  SET_CONTRACTOR,
  IS_LOADING,
  SET_IMAGES,
  SET_CACHE,
  Themes,
  Images,
  Clients,
  Locales,
  Cache
} from './types';

export const setContractor = (contractor: string) => ({
  type: SET_CONTRACTOR,
  contractor
});

export const setClient = (client: Clients) => ({
  type: SET_CLIENT,
  client
});

export const setTheme = (theme: Themes) => ({
  type: SET_THEME,
  theme
});

export const setLocale = (locale: Locales) => ({
  type: SET_LOCALE,
  locale
});

export const setImages = (images: Images) => ({
  type: SET_IMAGES,
  images
});

export const setCache = (cache: Cache) => ({
  type: SET_CACHE,
  cache
})

export const isLoading = (isLoading: boolean) => ({
  type: IS_LOADING,
  isLoading: isLoading,
});