import matchSorter from 'match-sorter';
import { FilterValue, IdType, Row } from 'react-table';

export function fuzzySelectFilter<T extends object>(rows: Array<Row<T>>, id: IdType<T>, filterValue: FilterValue) {
  return matchSorter(rows, filterValue.value, {
    keys: [(row: Row<T>) => row.values[id]]
  });
};

fuzzySelectFilter.autoRemove = (val: any) => !val;
