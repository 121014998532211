import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Box, Button } from "../../../../components/ds/SubatomicParticles";
import { H3 } from "../../../../components/ds/theme/typography";
import FormInput from "../../../../components/FormInput";
import Icon from "../../../../components/ds/atoms/Icon";
import { ReactComponent as IconEyeOpen } from "../../../../assets/svg/eyeOpen.svg";
import { ReactComponent as IconEyeClosed } from "../../../../assets/svg/eyeClosed.svg";

const ModalLogin = ({ callBackData, callBackRedirectModal, callBackError }) => {
  const [showPassword, setShowPassword] = useState(false);
  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("E-mail está no formato incorreto")
      .required("Preencha o e-mail."),
    password: Yup.string().required("Preencha a senha."),
  });

  const _handleEnter = (values) => {
    if (values.email !== "" && values.password !== "") {
      callBackData({ email: values.email, password: values.password });
    } else {
      callBackError();
    }
  };

  return (
    <>
      <Box
        backgroundColor="background.default"
        borderRadius="12px"
        maxWidth="415px"
        padding="2rem"
        position="fixed"
        styling="column"
        width="90%"
        zIndex="2"
      >
        <H3
          color="primary.default"
          fontSize="22px"
          mb="2rem"
          styling="light"
          width="100%"
        >
          Entrar
        </H3>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={_handleEnter}
          render={({ errors, touched }) => (
            <Form translate="no" style={{ width: "100%" }}>
              <Field name="email">
                {({ field }) => (
                  <FormInput
                    {...field}
                    value={field.value.toLowerCase()}
                    data-testid="email"
                    name="email"
                    placeholder="Digite seu email"
                    type="text"
                    error={touched.email && errors.email}
                  />
                )}
              </Field>
              <Field name="password">
                {({ field }) => (
                  <Box display={"block"}>
                    <FormInput
                      {...field}
                      data-testid="password"
                      name="password"
                      placeholder="Digite sua senha"
                      type={showPassword ? "text" : "password"}
                      error={touched.password && errors.password}
                      maxLength={50}
                    />
                    {/* <Box onClick={() => setShowPassword(!showPassword)}> */}
                    <Icon
                      styling="base"
                      onClick={() => setShowPassword(!showPassword)}
                      style={{
                        position: "absolute",
                        top: "175px",
                        right: "40px",
                      }}
                    >
                      {showPassword ? (
                        <IconEyeOpen height={20} width={20} />
                      ) : (
                        <IconEyeClosed height={20} width={20} />
                      )}
                    </Icon>
                  </Box>
                  // </Box>
                )}
              </Field>
              <Button
                display="block"
                type="submit"
                margin="3rem auto 0"
                styling="primary"
                width="90%"
              >
                Entrar
              </Button>
            </Form>
          )}
        />
        <Box
          justifyContent="space-between"
          mt="2rem"
          styling="row"
          width="100%"
        >
          <Button
            onClick={() => callBackRedirectModal("esqueci-senha")}
            type="submit"
            styling="base"
            width="295px"
          >
            Esqueci a senha
          </Button>
          <Button
            onClick={() => callBackRedirectModal("cadastrar")}
            type="submit"
            styling="base"
            width="295px"
          >
            Cadastrar
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ModalLogin;
