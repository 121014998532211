import React from "react";
import { Box, Button } from "../../../components/ds/SubatomicParticles";
import { H3, P, Span } from "../../../components/ds/theme/typography";
import Link from '../../../components/ds/atoms/Link';

export default function Step1({ onNext, onReject }) {
  return (
    <>
      <Box gridColumn={{ mobile: '2/span 10', tablet: '2/span 10', desktop: '4/span 6' }} styling="column">
      <H3 mb="30px" styling="light">Bem vindo a pessego.com, seu app de seguros.</H3>
        <P fontSize="20px" lineHeight="26px" mb="30px" styling="regular">
          Antes de criar sua conta é importante que você leia e aceite os Termos de Uso e Privacidade.
        </P>
        <P display="block" styling="base" textAlign="justify">
          Esta é a política de privacidade do aplicativo da <Span fontFamily="Opensans Regular">Pessego.com</Span>, de titularidade da <Span fontFamily="Opensans Regular">Bonsucesso Corretora de Seguros Ltda.,</Span> inscrita no CNPJ 70.946.470/0001-28, extensiva a todas as funcionalidades e recursos interativos oferecidos no aplicativo <Span fontFamily="Opensans Regular" fontStyle="italic">“Pessego.com”</Span>, salvo quando houver indicação ao contrário. <Span fontFamily="Opensans Regular">Aqui na Pessego.com a privacidade de nossos usuários/clientes é fundamental, motivo pela qual preparamos esta política para explicar como é feita a coleta de dados, utilização e proteção de suas informações pessoais e não pessoais a partir do acesso ao aplicativo e/ou interações via e-mail.</Span> A aceitação desta Política é requisito necessário e indispensável para a utilização desse aplicativo e das demais plataformas administradas pela <Span fontFamily="Opensans Regular" fontStyle="italic">Pessego.com</Span>. E neste ato, o usuário/cliente ao "<Span fontFamily="Opensans Regular">CLICAR</Span>" no ícone "<Span fontFamily="Opensans Regular">ACEITO</Span>" <Span fontFamily="Opensans Regular">adere e concorda com todos os termos e condições expostas na referida Política de forma livre e espontânea.</Span>
        </P>

        <P display="block " mt="15px" styling="base" textAlign="justify">
          <Span fontFamily="Opensans Regular"><u>1) Da coleta de dados</u></Span> Neste contexto, registra-se que no aplicativo da empresa acima mencionada as informações são coletadas da seguinte maneira:
          <P display="block" mt="15px" pl="40px" styling="base" textAlign="justify">
            <Span fontFamily="Opensans Regular" fontSize="25px" left="-15px" mr="-8px" position="relative" top="4px">&#8226;</Span>Os dados fornecidos no site são coletadas para identificação pessoal do usuário/cliente, através do preenchimento de formulários para download , onde é fornecido nome, telefone, e-mail, empresa em que trabalha, cargo e dados para cotação de seguros escolhidos.
          </P>
          <P display="block" pl="40px" styling="base" textAlign="justify">
            <Span fontFamily="Opensans Regular" fontSize="25px" left="-15px" mr="-8px" position="relative" top="4px">&#8226;</Span>Eventualmente, alguns dados podem ser solicitados por meio de contato direto efetivado pela equipe da <Span fontFamily="Opensans Regular" fontStyle="italic">Pessego.com</Span> com os usuários/clientes via e-mail, telefone e/ou aplicativo;
          </P>
          <P display="block" mb="15px" pl="40px" styling="base" textAlign="justify">
            <Span fontFamily="Opensans Regular" fontSize="25px" left="-15px" mr="-8px" position="relative" top="4px">&#8226;</Span><u>Dados de navegação no site:</u> Ao visitar nosso site é inserido um ‘cookie’ no seu navegador, por meio do software Google Analytics para identificar quantas vezes o usuário/cliente retorna ao nosso endereço eletrônico. A partir daí são coletadas, anonimamente, dados, como endereço de IP, localização geográfica, fonte de referência, tipo de navegador, duração da visita e páginas visitadas.
          </P>
        </P>

        <P display="block" styling="base" textAlign="justify">
          <Span fontFamily="Opensans Regular"><u>2) Do uso dos dados Pessoais</u></Span> A aceitação da presente Política autoriza que a <Span fontFamily="Opensans Regular" fontStyle="italic">Pessego.com</Span> utilize os dados pessoais do usuário/cliente para diferentes finalidades, tais como: 
          <P display="block" mt="15px" pl="40px" styling="base" textAlign="justify">
            <Span fontFamily="Opensans Regular" fontSize="25px" left="-15px" mr="-8px" position="relative" top="4px">&#8226;</Span>O e-mail é utilizado para a operação de envio de material e/ou informação requisitada pelo usuário/cliente no preenchimento do formulário e também para envio de Newsletters;
          </P>
          <P display="block" pl="40px" styling="base" textAlign="justify">
            <Span fontFamily="Opensans Regular" fontSize="25px" left="-15px" mr="-8px" position="relative" top="4px">&#8226;</Span>Por fim, o e-mail será utilizado ainda para comunicar lançamentos de novos produtos e serviços da <Span fontFamily="Opensans Regular" fontStyle="italic">Pessego.com</Span>;
          </P>
          <P display="block" pl="40px" styling="base" textAlign="justify">
            <Span fontFamily="Opensans Regular" fontSize="25px" left="-15px" mr="-8px" position="relative" top="4px">&#8226;</Span>No entanto, o usuário/cliente poderá cancelar a autorização a qualquer momento, mediante envio de e-mail e/ou comunicação no aplicativo da empresa detentora dos dados;
          </P>
          <P display="block" pl="40px" styling="base" textAlign="justify">
            <Span fontFamily="Opensans Regular" fontSize="25px" left="-15px" mr="-8px" position="relative" top="4px">&#8226;</Span>Os dados de download poderão ser divulgados como pesquisas e estatísticas, não sendo neste caso revelados abertamente nenhuma informação pessoal do usuário/cliente, a menos que haja autorização expressa;
          </P>
          <P display="block" mb="15px" pl="40px" styling="base" textAlign="justify">
            <Span fontFamily="Opensans Regular" fontSize="25px" left="-15px" mr="-8px" position="relative" top="4px">&#8226;</Span>A equipe da <Span fontFamily="Opensans Regular" fontStyle="italic">Pessego.com</Span> poderá entrar em contato via e-mail e/ou telefone para fazer pesquisas e/ou apresentar produtos e serviços.
          </P>
        </P>

        <P display="block" styling="base" textAlign="justify">
          <Span fontFamily="Opensans Regular"><u>3) Do acesso aos dados Pessoais</u></Span> neste contexto, registra-se que no aplicativo da empresa acima mencionada as informações são coletadas da seguinte maneira:
          <P display="block" mt="15px" pl="40px" styling="base" textAlign="justify">
            <Span fontFamily="Opensans Regular" fontSize="25px" left="-15px" mr="-8px" position="relative" top="4px">&#8226;</Span>Os dados pessoais dos usuários/clientes serão acessadas pela equipe da <Span fontFamily="Opensans Regular" fontStyle="italic">Pessego.com</Span>.
          </P>
          <P display="block" pl="40px" styling="base" textAlign="justify">
            <Span fontFamily="Opensans Regular" fontSize="25px" left="-15px" mr="-8px" position="relative" top="4px">&#8226;</Span>Eventualmente, caso a inserção dos dados do usuário/cliente se dê por ações criadas em parcerias, os parceiros explicitamente identificados no aplicativo também terão acesso às informações;
          </P>
          <P display="block" mb="15px" pl="40px" styling="base" textAlign="justify">
            <Span fontFamily="Opensans Regular" fontSize="25px" left="-15px" mr="-8px" position="relative" top="4px">&#8226;</Span>A <Span fontFamily="Opensans Regular" fontStyle="italic">Pessego.com</Span> compromete-se a não vender, alugar ou repassar os dados pessoais de seus usuários/clientes para terceiros não autorizados, exceto nos casos que as informações forem requeridas por Órgão Público competente e/ou judicialmente;
          </P>
        </P>

        <P display="block" styling="base" textAlign="justify">
          <Span fontFamily="Opensans Regular"><u>4) Do compartilhamento de conteúdo nas redes sociais</u></Span>
          <P display="block" mt="15px" pl="40px" styling="base" textAlign="justify">
            <Span fontFamily="Opensans Regular" fontSize="25px" left="-15px" mr="-8px" position="relative" top="4px">&#8226;</Span>Ao clicar nos botões de compartilhamento de conteúdo nas mídias sociais disponíveis em nosso aplicativo e páginas, o usuário/cliente estará publicando o conteúdo por meio de seu perfil na rede selecionada, não tendo, portanto a <Span fontFamily="Opensans Regular" fontStyle="italic">Pessego.com</Span> qualquer acesso ao login e senha dos usuários/clientes nas redes sociais;
          </P>
          <P display="block" mb="15px" pl="40px" styling="base" textAlign="justify">
            <Span fontFamily="Opensans Regular" fontSize="25px" left="-15px" mr="-8px" position="relative" top="4px">&#8226;</Span>Igualmente, a <Span fontFamily="Opensans Regular" fontStyle="italic">Pessego.com</Span> não pública conteúdos em nome do usuário.
          </P>
        </P>

        <P display="block" styling="base" textAlign="justify">
          <Span fontFamily="Opensans Regular"><u>5) Do Google Analytics</u></Span>
          <P display="block" mt="15px" pl="40px" styling="base" textAlign="justify">
            <Span fontFamily="Opensans Regular" fontSize="25px" left="-15px" mr="-8px" position="relative" top="4px">&#8226;</Span>A <Span fontFamily="Opensans Regular" fontStyle="italic">Pessego.com</Span> utiliza o Google Analytics para acompanharem as métricas do tráfego de usuários que acessam seus sites e/ou aplicativos, sendo o principal objetivo a obtenção de estatísticas sobre seus visitantes para melhoria dos conteúdos e ofertas de produtos e serviços.
          </P>
          <P display="block" mb="15px" pl="40px" styling="base" textAlign="justify">
            <Span fontFamily="Opensans Regular" fontSize="25px" left="-15px" mr="-8px" position="relative" top="4px">&#8226;</Span>O Google Analytics, por sua vez utiliza cookies com a finalidade de avaliar o uso do website, através da compilação de relatórios sobre a atividades do website e/ou aplicativo. No entanto, vale esclarecer que o Google poderá transferir estas informações à terceiros, sempre que exigido por lei.
          </P>
        </P>

        <P display="block" styling="base" textAlign="justify">
          <Span fontFamily="Opensans Regular"><u>6) Do Google Remarketing</u></Span>
          <P display="block" mt="15px" pl="40px" styling="base" textAlign="justify">
            <Span fontFamily="Opensans Regular" fontSize="25px" left="-15px" mr="-8px" position="relative" top="4px">&#8226;</Span>A <Span fontFamily="Opensans Regular" fontStyle="italic">Pessego.com</Span> utiliza remarketing para anunciar online.
          </P>
          <P display="block" pl="40px" styling="base" textAlign="justify">
            <Span fontFamily="Opensans Regular" fontSize="25px" left="-15px" mr="-8px" position="relative" top="4px">&#8226;</Span>Desta forma, os fornecedores de terceiros, incluindo o Google, usam cookies primários (como o cookie do Google Analytics) e cookies de terceiros (como o cookie DoubleClick) em conjunto para informar, otimizar e servir anúncios baseados em visitas anteriores do usuário/cliente para o site.
          </P>
          <P display="block" mb="15px" pl="40px" styling="base" textAlign="justify">
            <Span fontFamily="Opensans Regular" fontSize="25px" left="-15px" mr="-8px" position="relative" top="4px">&#8226;</Span>Contudo, vale registrar que diversos fornecedores de terceiros, inclusive o Google, compram espaços de publicidade em sites da Internet e dessa forma, a <Span fontFamily="Opensans Regular" fontStyle="italic">Pessego.com</Span> poderá contratar o Google para exibir seus anúncios nesses espaços, bem como para identificarem as visitas realizadas em seus sites e aplicativos, através da utilização de cookies.
          </P>
        </P>

        <P display="block" styling="base" textAlign="justify">
          <Span fontFamily="Opensans Regular"><u>7) Da Recusa de cookies</u></Span>
          <P display="block" my="15px" pl="40px" styling="base" textAlign="justify">
            <Span fontFamily="Opensans Regular" fontSize="25px" left="-15px" mr="-8px" position="relative" top="4px">&#8226;</Span>O usuário/cliente poderá recusar o uso de cookies e para tanto deve selecionar as definições apropriadas no seu navegador, bem como desativar anúncios com base em interesses no Google e também com relação a toda a Web neste link: <Link to="https://tools.google.com/dlpage/gaoptout/">https://tools.google.com/dlpage/gaoptout/</Link>.
          </P>
        </P>

        <P display="block" styling="base" textAlign="justify">
          <Span fontFamily="Opensans Regular"><u>8) Da Segurança da Informação</u></Span>
          <P display="block" mt="15px" pl="40px" styling="base" textAlign="justify">
            <Span fontFamily="Opensans Regular" fontSize="25px" left="-15px" mr="-8px" position="relative" top="4px">&#8226;</Span>A <Span fontFamily="Opensans Regular" fontStyle="italic">Pessego.com</Span> assegura a segurança das informações pessoais de identificação de seus usuários/clientes fornecidas em seu site e aplicativo, no entanto, por mais que seu sistema seja seguro, nenhum sistema de segurança é completamente imune aos ataques de terceiros como "hackers" ou "crackers". Sendo assim, a <Span fontFamily="Opensans Regular" fontStyle="italic">Pessego.com</Span> não tem como garantir segurança absoluta de seu banco de dados e nem pode afiançar que as informações pessoais de identificação que o usuário/cliente forneceu não possam ser interceptadas quando a transmissão é feita pela Internet e por este motivo a <Span fontFamily="Opensans Regular" fontStyle="italic">Pessego.com</Span> não responderá por prejuízos que possam advir do vazamento das informações pessoais por violação ou quebra das barreiras de segurança de internet por terceiros como "hackers" ou "crackers".
          </P>
          <P display="block" pl="40px" styling="base" textAlign="justify">
            <Span fontFamily="Opensans Regular" fontSize="25px" left="-15px" mr="-8px" position="relative" top="4px">&#8226;</Span>Assim, a <Span fontFamily="Opensans Regular" fontStyle="italic">Pessego.com</Span> recomenda que usuário/cliente não revele sua senha de acesso a terceiros, bem como orienta que utilize senhas complexas e as altere imediatamente se, por qualquer motivo, o usuário/cliente tiver as fornecido para terceiros.
          </P>
          <P display="block" mb="15px" pl="40px" styling="base" textAlign="justify">
            <Span fontFamily="Opensans Regular" fontSize="25px" left="-15px" mr="-8px" position="relative" top="4px">&#8226;</Span>E, ainda, informa que a <Span fontFamily="Opensans Regular" fontStyle="italic">Pessego.com</Span> não solicita senha, dados pessoais, arquivos anexados, entre outros por e-mail, não indicando, assim, a abertura de possíveis e-mails que conste seu nome com tais solicitações.
          </P>
        </P>

        <P display="block" styling="base" textAlign="justify">
          <Span fontFamily="Opensans Regular"><u>9) Da alteração da Política de Privacidade e Segurança</u></Span>
          <P display="block" mt="15px" pl="40px" styling="base" textAlign="justify">
            <Span fontFamily="Opensans Regular" fontSize="25px" left="-15px" mr="-8px" position="relative" top="4px">&#8226;</Span>A <Span fontFamily="Opensans Regular" fontStyle="italic">Pessego.com</Span> reserva-se ao direito de alterar a presente política de privacidade a qualquer momento, sem notificação prévia, visando sua constante melhoria, com o objetivo de adaptá-la as alterações legislativas e/ou aquelas relativas as práticas comerciais.
          </P>
          <P display="block" mb="15px" pl="40px" styling="base" textAlign="justify">
            <Span fontFamily="Opensans Regular" fontSize="25px" left="-15px" mr="-8px" position="relative" top="4px">&#8226;</Span>Portanto, ao utilizar funcionalidades do site/aplicativo ou interagir com ele, o usuário/cliente concorda e aceita os termos da política de privacidade vigente na data de acesso.
          </P>
        </P>

        <P display="block" styling="base" textAlign="justify">
          <Span fontFamily="Opensans Regular"><u>10) Do Canal de Comunicação do Usuário/Cliente</u></Span>
          <P display="block" my="15px" pl="40px" styling="base" textAlign="justify">
            <Span fontFamily="Opensans Regular" fontSize="25px" left="-15px" mr="-8px" position="relative" top="4px">&#8226;</Span>Caso o usuário/cliente tenha alguma dúvida com relação à aceitação da presente política de privacidade e segurança e/ou sobre as práticas adotas nos sites e aplicativo da <Span fontFamily="Opensans Regular">Pessego.com, favor entrar em contato com a nossa equipe através dos e-mails contato@pessego.com e/ou telefone 0800-941-4980.</Span>
          </P>
        </P>
        <Button onClick={() => onNext()} styling="primary" width="295px">Li e aceito as políticas e termos</Button>
        <Button onClick={onReject} styling="base" width="295px">Não aceito as políticas e termos</Button>
      </Box>
    </>
  );
}
