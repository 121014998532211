import React from "react";
import { Box, Button } from "../../../../components/ds/SubatomicParticles";
import { H3 } from "../../../../components/ds/theme/typography";

const ModalConfirmEndQuotation = ({ redirect, name, onRedirect }) => (
  <Box
    backgroundColor="background.default"
    borderRadius="12px"
    maxWidth="415px"
    padding="2rem"
    position="fixed"
    styling="cloumn"
    width="90%"
    zIndex="2"
  >
    <H3 mb="30px" styling="light">
      {`${name.split(" ")[0]} ${name.split(" ")[1] || ""}, você pode prosseguir com a compra acessando o menu Cotações.`}
    </H3>
    <Button display="block" margin="0 auto" onClick={onRedirect} styling="primary" width="90%">Ok, irei continuar depois!</Button>
  </Box>
);

export default ModalConfirmEndQuotation;
