import React, {useState, useEffect} from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import * as userService from "../../state/context/user/services";
import InjectImages from "../../containers/Images";
import kebabCase from "lodash/kebabCase";

const IFrame = ({ history, showModal, user, images, location }) => {
  const [jwt, setJwt]  = useState("")
  const [uid, setUid] = useState("")
  const [refreshToken, setRefreshToken] = useState("")

  useEffect(() => {
    setJwt(sessionStorage.getItem('tokenId'))
    setUid(sessionStorage.getItem('uid'));
    setRefreshToken(sessionStorage.getItem('refreshToken'));
  }, []);

  const type = location?.state?.type || 'residencial'
  const [sutHubHeight, setSutHubHeight] = useState( "200")

  window.addEventListener("message", (event) => {
    // console.log("addEventListener event.data", event.data)
    if (event.data.type === "bodyHeight") {
      setSutHubHeight(event.data.value)
    }
    if (event.data.type === "userDataLogin") {
      if (event.data.value?.uid !== uid) {
            sessionStorage.setItem('tokenId', event.data.value.jwt)
            sessionStorage.setItem('uid', event.data.value.uid)
          }
    }
    if (event.data.type === "redirect") {
      event.data.value && history.push(event.data.value)
    }
    if (event.data.type === "scrollToTop") {
      window.scrollTo( 0, 0 );
    }
     return;
 }, false);


  const getContractorName = () => {
    let contractor =
      process.env.REACT_APP_CUSTOM_CONTRACTOR ?? "pessego-com";
    
    if (process.env.REACT_APP_PESSEGO_ENV === "production") {
      contractor = kebabCase(
        window.location.origin
          .replace(/^(http|https):\/\//, "")
      );
    }
    return contractor;
  };

  const contractor = getContractorName();
  // let url = "https://demo.suthubservice.com/4f7ba38b-9d15-4957-bc09-48ce6efbc79a"
  // const url_residencial = `https://demo.suthubservice.com/4f7ba38b-9d15-4957-bc09-48ce6efbc79a/seguro-residencial/imovel`
  // const url_pets = `https://demo.suthubservice.com/4f7ba38b-9d15-4957-bc09-48ce6efbc79a/seguro-pet/pet`

  let url = "https://d3lyqda7irpnwg.cloudfront.net"
  const url_residencial = `https://d3lyqda7irpnwg.cloudfront.net/seguro-residencial/imovel`
  const url_pets = `https://d3lyqda7irpnwg.cloudfront.net/seguro-pet/pet`

  switch (type) {
    case "residencial" :
      url = url_residencial;      
      break;
    case "pets":
      url = url_pets;      
      break;
  
    default:
      break;
  }

  return (
    <>
     <iframe 
      src={`${url}/?company_id=${contractor}&user_id=${uid}&jwt=${jwt}&type=${type}&refresh_token=${refreshToken}`}
      overflow="hidden" 
      name="iframe_a" 
      height={sutHubHeight + "px" || "1000px"}
      width="100%" 
      title="Iframe"
      frameborder="0"
      scrolling="no" 
     >       
     </iframe>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = {
  showModal: userService.showModal,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(InjectImages(IFrame));
