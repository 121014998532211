/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable */
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Box, Button } from "../ds/SubatomicParticles";
import { P } from "../../components/ds/theme/typography";
import Link from "../ds/atoms/Link";
import Icon from "../ds/atoms/Icon";

import { ReactComponent as LogoCompleta } from "../../assets/svg/logo_pessego_orange.svg";
import { ReactComponent as Logo } from "../../assets/svg/logo_fruta.svg";
import { ReactComponent as Back } from "../../assets/svg/back.svg";
import { ReactComponent as IconMenu } from "../../assets/svg/icon_menu.svg";
import { getClient } from "../../state/context/config/selector";
import injectImages from "../../containers/Images/injectImages";
import PreImg from "../PreImg";

const menuList = (props) => {
  const pages = props.client.config ? props.client.config.pages : [];
  const pageSobre = pages.find((page) => page.sobre);
  const menuEntries = [
    {
      displayName: "Início",
      route: "/",
      subMenu: [],
    },
    pageSobre
      ? {
          displayName: "Sobre a pessego.com",
          route: "/sobre",
          subMenu: [],
        }
      : false,
    {
      displayName: "Seguros",
      route: "/seguros",
      subMenu: [],
    },
    {
      displayName: "Suporte",
      route: "/suporte",
      subMenu: [],
    },
    {
      displayName: "Fale Conosco",
      route: "/contato",
      subMenu: [],
    },
    {
      displayName: "Parceiros",
      route: "/parceiros",
      subMenu: [],
    },
  ];
  return menuEntries
    .filter((menu) => menu)
    .map((item) => (
      <Box
        styling="row"
        px="15px"
        py={{ mobile: "10px", tablet: "10px", desktop: "0px" }}
        key={item.displayName}
      >
        <Link
          className={props.location.pathname === item.route ? "active" : ""}
          fontSize={{ mobile: "17px", tablet: "17px", desktop: "14px" }}
          to={item.route}
          onClick={props.handleCloseMenu}
          styling="base"
        >
          {item.displayName}
        </Link>
      </Box>
    ));
};

const menuListLogged = (props) => {
  const pages = props.client.config ? props.client.config.pages : [];
  const pageSobre = pages.find((page) => page.sobre);
  const menuEntriesLogged = [
    {
      displayName: "Início",
      route: "/minhaconta",
      subMenu: [],
    },
    pageSobre
      ? {
          displayName: "Sobre a pessego.com",
          route: "/sobre",
          subMenu: [],
        }
      : false,
    {
      displayName: "Suporte",
      route: "/suporte",
      subMenu: [],
    },
    {
      displayName: "Fale Conosco",
      route: "/contato",
      subMenu: [],
    },
    {
      displayName: "Minha Conta",
      route: "/conta",
      subMenu: [],
    },
  ];

  return menuEntriesLogged
    .filter((menu) => menu)
    .map((item) => (
      <Box
        styling="row"
        px="15px"
        py={{ mobile: "10px", tablet: "10px", desktop: "0px" }}
        key={item.displayName}
      >
        <Link
          className={props.location.pathname === item.route ? "active" : ""}
          fontSize={{ mobile: "17px", tablet: "17px", desktop: "14px" }}
          to={item.route}
          onClick={props.handleCloseMenu}
          styling="base"
        >
          {item.displayName}
        </Link>
      </Box>
    ));
};

const Header = ({
  handleOpenModal,
  handleSair,
  history,
  location,
  user,
  client,
  images,
}) => {
  const [logged, setLogged] = useState(true);
  const [myint, setMyint] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleCloseMenu = () => setIsMenuOpen(false);
  
  useEffect(() => {
    const setint = setInterval(() => { 
      setMyint(myint + 1);    
      if (sessionStorage.getItem("uid")) {        
        setLogged(true);
      } else {
        setLogged(false);
      }
    }, 1000);
    return () => clearInterval(setint);

      // sessionStorage.getItem("uid") ? setLogged(true) : setLogged(false);
  },[myint]);

  useEffect(() => {
    handleCloseMenu();
  }, [location.pathname]);

  const renderNaoLogado = () => {
    return (
      <Box
        styling="row"
        borderBottom="1px solid #e1e1e1"
        flexWrap="wrap"
        mb={
          window.location.pathname == "/iframe" ||  window.location.pathname == "/iframe-auto" ? {
          mobile: "0px", tablet: "0px", desktop: "0px"} : { mobile: "0px", tablet: "60px", desktop: "60px"}
        }
        px="3rem"
        py="1rem"
        justifyContent="space-between"
      >
        {location.pathname === "/" ? (
          images?.logoFull?.url ? (
            <PreImg
              src={images.logoFull.url}
              style={{
                width: "170px",
                height: "32px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                styling="row"
                width="90%"
                height="90%"
                borderRadius="4px"
                backgroundColor="#ededed"
              />
            </PreImg>
          ) : (
            <LogoCompleta />
          )
        ) : (
          <Box styling="row">
            <Box styling="row" mr="10px">
              <Icon styling="base">
                <Back
                  onClick={() => {
                    history.goBack();
                  }}
                />
              </Icon>
            </Box>
            {images?.logo?.url ? (
              <PreImg
                src={images.logo.url}
                style={{
                  width: "24px",
                  height: "24px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  styling="row"
                  width="100%"
                  height="100%"
                  borderRadius="4px"
                  backgroundColor="#ededed"
                />
              </PreImg>
            ) : (
              <Logo />
            )}
          </Box>
        )}
        <Box
          styling="row"
          display={{ mobile: "block", tablet: "block", desktop: "none" }}
        >
          <Button
            styling="base"
            type="button"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <IconMenu height="25px" width="25px" />
          </Button>
        </Box>
        <Box
          display={{
            mobile: isMenuOpen ? "flex" : "none",
            tablet: isMenuOpen ? "flex" : "none",
            desktop: "flex",
          }}
          opacity={{
            mobile: isMenuOpen ? "1" : "0",
            tablet: isMenuOpen ? "1" : "0",
            desktop: "1",
          }}
          styling={{ mobile: "column", tablet: "column", desktop: "row" }}
          width={{ mobile: "100%", tablet: "100%", desktop: "auto" }}
        >
          {menuList({ location, handleCloseMenu, client })}
          <Button
            styling="outline"
            type="button"
            ml="15px"
            onClick={handleOpenModal}
            width="115px"
          >
            Entrar
          </Button>
        </Box>
      </Box>
    );
  };

  const renderLogado = () => {
    return (
      <Box
        styling="row"
        borderBottom="1px solid #e1e1e1"
        flexWrap="wrap"
        mb={
          window.location.pathname == "/iframe" ||  window.location.pathname == "/iframe-auto" ? {
          mobile: "0px", tablet: "0px", desktop: "0px"} : { mobile: "0px", tablet: "60px", desktop: "60px"}
        }
        px="3rem"
        py="1rem"
        justifyContent="space-between"
      >
        <Box styling="row">
          {location.pathname !== "/minhaconta" &&
          location.pathname !== "/vida" &&
          location.pathname !== "/automovel" &&
          location.pathname !== "/residencial" &&
          location.pathname !== "/viagem" &&
          location.pathname !== "/pets" &&
          location.pathname !== "/portateis" &&
          location.pathname !== "/bike" ? (
            <Icon styling="base">
              <Back
                onClick={() => {
                  history.goBack();
                }}
              />
            </Icon>
          ) : (
            location.pathname !== "/minhaconta" && (
              <Icon styling="base">
                <Back
                  onClick={() => {
                    history.push("/minhaconta");
                  }}
                />
              </Icon>
            )
          )}
          <Box ml="15px" styling="row">
            {images?.logo?.url ? (
              <PreImg
                src={images.logo.url}
                style={{
                  width: "24px",
                  height: "24px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  styling="row"
                  width="100%"
                  height="100%"
                  borderRadius="4px"
                  backgroundColor="#ededed"
                />
              </PreImg>
            ) : (
              <Logo />
            )}
            {(user.nome || sessionStorage.getItem("nome")) && (
              <P styling="base" fontSize="20px" ml="22.55px">
                {` Olá, ${
                  sessionStorage.getItem("nome")
                    ? sessionStorage.getItem("nome").split(" ")[0]
                    : user.nome.split(" ")[0]
                }
                 `}
              </P>
            )}
          </Box>
        </Box>
        <Box styling="row" display={{ tablet: "block", desktop: "none" }}>
          <Button
            styling="base"
            type="button"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {!logged ? "" : <IconMenu height="25px" width="25px" />}
          </Button>
        </Box>
        <Box
          display={{
            mobile: isMenuOpen ? "flex" : "none",
            tablet: isMenuOpen ? "flex" : "none",
            desktop: "flex",
          }}
          opacity={{
            mobile: isMenuOpen ? "1" : "0",
            tablet: isMenuOpen ? "1" : "0",
            desktop: "1",
          }}
          styling={{ mobile: "column", tablet: "column", desktop: "row" }}
          width={{ mobile: "100%", tablet: "100%", desktop: "auto" }}
        >
          {menuListLogged({ location, handleCloseMenu, client })}
          <Button
            styling="outline"
            type="button"
            ml="15px"
            onClick={handleSair}
            width="115px"
          >
            Sair
          </Button>
        </Box>
      </Box>
    );
  };

  return !logged
    ? location.pathname === "/cadastrar" || location.pathname === "/form"
      ? null
      : renderNaoLogado()
    : location.pathname === "/form" ||
      location.pathname === "/info" ||
      location.pathname === "/sinistro"
    ? null
    : renderLogado();
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  client: getClient(state),
});

const mapDispatchToProps = {};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(injectImages(Header));
