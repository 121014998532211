import React from "react";
import { Box, Button } from "../../../components/ds/SubatomicParticles";
import { H3 } from "../../../components/ds/theme/typography";

function Step3({ onYes, onNo, modelo = "" }) {
  return (
    <>
      <Box
        gridColumn={{
          mobile: "2/span 10",
          tablet: "2/span 10",
          desktop: "4/span 6",
        }}
        styling="column"
        pb={"30rem"}
      >
        <H3 mb="30px" styling="light">
          O {modelo} possui acessórios?
        </H3>
        <Box display="flex" width={"100%"} justifyContent="center">
          <Box pr="1.5rem">
            <Button styling="primary" onClick={onNo} width="148px" mt={25}>
              Não
            </Button>
          </Box>
          <Box pl="1.5rem">
            <Button styling="primary" onClick={onYes} width="148px" mt={25}>
              Sim
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Step3;
