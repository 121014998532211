import { FilterValue, IdType, Row } from 'react-table';

export function dateBetweenFilter<T extends object>(rows: Array<Row<T>>, id: IdType<T>, filterValue: FilterValue) {
  let filtered: any[] = [];

  const startDate = filterValue[0] ? new Date(filterValue[0]) : new Date(0);
  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);
  startDate.setMilliseconds(0);
  const endDate = filterValue[1] ? new Date(filterValue[1]) : new Date();
  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);
  endDate.setMilliseconds(999);

  rows.forEach((row) => {
    const rowdate = new Date(row.values[id]);
    
    if(rowdate > startDate && rowdate < endDate){
      filtered.push(row);
    }
  })
  return filtered;
};

dateBetweenFilter.autoRemove = (val: any) => !val;
