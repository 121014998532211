import { connect } from 'react-redux';
import { getClient } from '../../../state/context/config/selector';
import { Props, State, Permissions } from './types';

export const check = (pathname: string, permissions: Permissions) => {
  switch (true) {
    case pathname.includes('/admin'):
      if (permissions.user.admin) {
        return true;
      }
      return false;
    case pathname.includes('/sobre'): {
      const pages = permissions.client.config?.pages ?? [];
      const page = pages.find(page => page.sobre);
      if (page) {
        return true;
      }
      return false;
    }
    default:
      return true
  }
};

const Can = (props: Props) => {
  const no = props.no || (() => '');
  return check(props.pathname, { user: props.user, client: props.client }) ? props.yes() : no();
};

const mapState = (state: State) => ({
  user: state.user.user,
  client: getClient(state),
});

const mapDispatch = {};

export { Can };
export default connect(mapState, mapDispatch)(Can);
