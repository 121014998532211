export type Image = {
  id: string
  blob?: Blob,
  height?: number;
  width?: number;
  description?: string;
  url?: string;
  image?: HTMLImageElement;
};
export type ImageObject = {
  [key: string]: Image;
};
export type Images = {
  about1?: Image;
  contact1?: Image;
  generic1?: Image;
  main1?: Image;
  main2?: Image;
  logo?: Image;
};
export interface State {
  images: Image[];
  isLoading: boolean;
}

export const SET_IMAGES = "image/SET_IMAGES";
export const IS_LOADING = "image/IS_LOADING";
