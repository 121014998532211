import { SET_CLIENTS, IS_LOADING, Client } from './types';

export const setClients = (clients: Client[]) => ({
  type: SET_CLIENTS,
  clients
});

export const isLoading = (isLoading: boolean) => ({
  type: IS_LOADING,
  isLoading: isLoading,
});
