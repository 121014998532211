import { Client } from "../clients/types";
import { Theme } from "../themes/types";
import { Locale } from "../locales/types";
import { Images as ImagesType } from "../images/types";

export type Cache = {
  cratedAt: string;
  expire: string;
};

export type Clients = {
  [key: string]: Client & {
    cache?: Cache
  };
};

export type Themes = {
  [key: string]: Theme & {
    cache?: Cache
  };
};

export type Locales = {
  [key: string]: Locale & {
    cache?: Cache
  };
};

export type Images = {
  [key: string]: ImagesType & {
    cache?: Cache
  };
};



export interface State {
  contractor: string;
  clients: Clients;
  themes: Themes;
  locales: Locale;
  images: Images;
  isLoading: boolean;
}

export const SET_CONTRACTOR = "config/SET_CONTRACTOR";
export const SET_IMAGES = "config/SET_IMAGES";
export const SET_CLIENT = "config/SET_CLIENT";
export const SET_THEME = "config/SET_THEME";
export const SET_LOCALE = "config/SET_LOCALE";
export const SET_CACHE = "config/SET_CACHE";
export const IS_LOADING = "config/IS_LOADING";
