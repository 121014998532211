import firebase from "firebase";
import { User, Admin } from "./types";

export const getUsers = () =>
  firebase
    .firestore()
    .collection('users')
    .get()
    .then(function (querySnapshot) {
      const docs: User[] = [];
      querySnapshot.forEach(function (doc) {
        docs.push({ ...doc.data() as User, id: doc.id });
      });
      return docs;
    });


export const setAdmin = (userId: string, admin: Admin) =>
  firebase
    .firestore()
    .collection('users')
    .doc(userId)
    .update(admin);