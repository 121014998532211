import React, { useState, useEffect } from "react";
import * as userService from "../../state/context/user/services";
import { showToast } from "../../utils";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import { RegisterMyLocation } from "../form/residencia";
import { Step1, Step2, Step3, Step4 } from "./steps";
import { useFormInput } from "../../utils";
import { Box } from "../../components/ds/SubatomicParticles";
import Icon from '../../components/ds/atoms/Icon';
import { ReactComponent as LogoCompleta } from "../../assets/svg/logo_pessego_orange.svg";
import { ReactComponent as Logo } from "../../assets/svg/logo_fruta.svg";
import { ReactComponent as Back } from "../../assets/svg/back.svg";
import InjectImages from "../Images";

function Register({
  history,
  createUser,
  getCEP,
  signUserIn,
  authUser,
  validEmail,
  verificarCpf,
  images,
}) {
  const [step, setStep] = useState(1);
  const [user, setUser] = useState({});
  const [endereco, setEndereco] = useState({});
  // Fields
  // Personal information step
  const nome = useFormInput("", "Nome completo");
  const [cpf, setCpf] = useState("");
  const [sexo, setSexo] = useState("");
  // const [dataNascimento, setDataNascimento] = useState(new Date())
  const [dataNascimento, setDataNascimento] = useState("");
  // Contact step
  const email = useFormInput("", "Email", "email");
  const telefone = useFormInput("", "Telefone", "text");
  // Password step
  const senha = useFormInput("", "Senha", "password");
  const confirmSenha = useFormInput("", "Confirmar senha", "password");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const renderHeaderCustom = () => {
    const renderHeaderStep1 = () => {
      return (
        <Box
          styling="row"
          borderBottom="1px solid #e1e1e1"
          px="3rem"
          py="1rem"
          justifyContent="flex-start"
          width="100%"
        >
          {images?.logoFull?.url ? (
            <img src={images.logoFull.url} width={170} height={32} alt="" />
          ) : (
              <LogoCompleta />
            )}
        </Box>
      );
    };

    const renderHeaderStepOther = () => {
      return (
        <Box
          styling="row"
          borderBottom="1px solid #e1e1e1"
          px="3rem"
          py="1rem"
          justifyContent="flex-start"
          width="100%"
        >
          <Icon styling="base">
            <Back
              onClick={() => {
                setStep(step - 1);
              }}
            />
          </Icon>
          <Box styling="row" ml="15px">
            {images?.logo?.url ? (
              <img src={images.logo.url} width={24} height={24} alt="" />
            ) : (
                <Logo />
              )}
          </Box>
        </Box>
      );
    };

    return step !== 1 ? renderHeaderStepOther() : renderHeaderStep1();
  };

  const _handleVerificarCPF = () => {
    return verificarCpf(cpf.replace(/\.|-/g, ""))
      .then((r) => {
        if (!r.userCpf) {
          showToast("warning", "Alerta", "CPF já existente.");
          setCpf("");
          return false;
        }
        return true;
      })
      .catch((e) => {
        showToast(
          "warning",
          "Alerta",
          "Ocorreu um problema ao validar o CPF. Verifique suas informações e tente novamente.",
        );
        return false;
      });
  };

  const renderSteps = () => {
    const _handleGetCep = async (cep) => {
      await getCEP(cep).then((r) => {
        setEndereco({
          ...r,
        });
      });
    };

    const _handleChangeStep = (count = 1) => {
      setStep(step + count);
    };

    const _handleRedirectHome = () => {
      history.push("/");
    };

    const _handleConcatRegister = (values) => {
      setUser({ ...user, ...values });
      _handleChangeStep();
    };

    const handleCheckEmail = (values) => {
      validEmail(email.value).then((data) => {
        if (data) {
          showToast("warning", "Alerta", "Email já cadastrado.");
        } else {
          _handleConcatRegister(values);
        }
      });
    };

    const handleRedirectLogin = () => {
      signUserIn({ email: email.value, password: senha.value })
        .then((user) => {
          sessionStorage.setItem("uid", user.uid);
          sessionStorage.setItem("tokenId", user.ma);
          sessionStorage.setItem("refreshToken", user.refreshToken);
          authUser(user.uid, user.ma);
          showToast("success", "Sucesso !", "Seja bem vindo!");
          history.push("/minhaconta");
        })
        .catch(() => {
          showToast("error", "Erro", "Erro ao fazer login! Tente novamente.");
        });
    };

    const handleCompleteRegister = (data) => {
      createUser({ ...user, ...data })
        .then(() => {
          handleRedirectLogin();
        })
        .catch((e) => {
          showToast(
            "warning",
            "Alerta",
            "Ocorreu um erro ao concluir o cadastro. Verifique sua conexão e tente novamente.",
          );
        });
    };

    const _handleCompleteEndereco = (data) => {
      const residence = {
        ZipCode: data.cep,
        StreetType: data.tipo,
        StreetName: data.logradouro,
        StreetNumber: data.numero,
        StreetExtraInformation: data.complemento,
        DistrictName: data.bairro,
        CityName: data.cidade,
        StateCode: data.estado,
      };

      createUser({ ...user, residence })
        .then(() => {
          handleRedirectLogin();
        })
        .catch((e) => {
          showToast("warning", "Alerta", "Email já em uso!");
        });
    };

    switch (step) {
      case 1:
        return (
          <Step1 onNext={_handleChangeStep} onReject={_handleRedirectHome} />
        );

      case 2:
        return (
          <Step2
            onNext={_handleConcatRegister}
            nome={nome}
            cpf={cpf}
            setCpf={setCpf}
            _handleVerificarCPF={_handleVerificarCPF}
            sexo={sexo}
            setSexo={setSexo}
            dataNascimento={dataNascimento}
            setDataNascimento={setDataNascimento}
            onFailBirthDayDate={() =>
              showToast(
                "warning",
                "Alerta",
                "Cadastro permitido somente para idades iguais ou superiores a 18 anos.",
              )
            }
            onFailName={(option) => {
              if (option === 1) {
                showToast(
                  "warning",
                  "Alerta",
                  "É preciso preencher nome e sobrenome.",
                );
              } else {
                showToast(
                  "warning",
                  "Alerta",
                  "Nome e sobrenome precisam ter no mínimo 2 caracteres cada.",
                );
              }
            }}
          />
        );

      case 3:
        return (
          <Step3 onNext={handleCheckEmail} email={email} telefone={telefone} />
        );

      case 4:
        return (
          <Step4
            onNext={(data) => handleCompleteRegister(data)}
            senha={senha}
            confirmSenha={confirmSenha}
          />
        );

      case 5:
        return (
          <RegisterMyLocation
            getEndereco={_handleGetCep}
            endereco={endereco}
            onNext={_handleCompleteEndereco}
            setEndereco={setEndereco}
          />
        );

      default:
        break;
    }
  };

  return (
    <Box
      alignItems="flex-start"
      minHeight="calc(100vh - 140px)"
      justifyContent="flex-start"
      styling="column"
      width="100%"
    >
      {renderHeaderCustom()}
      <Box
        gridRowGap="1.5rem"
        gridTemplateColumns="repeat(12, 1fr)"
        minHeight="calc(100vh - 202px)"
        pt="30px"
        position="relative"
        styling="grid"
        width="100%"
      >
        {renderSteps()}
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  createUser: userService.createUser,
  getCEP: userService.getCEP,
  signUserIn: userService.signUserIn,
  authUser: userService.authUser,
  verificarCpf: userService.verificarCpf,
  validEmail: userService.verifyEmail,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(InjectImages(Register));
