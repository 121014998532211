import React, { PropsWithChildren, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { variant, VariantArgs } from 'styled-system';
import classnames from 'classnames';
import {
  useTable,
  useFilters,
  usePagination,
  useSortBy,
  useRowSelect,
  useColumnOrder,
  FilterProps,
} from 'react-table';
import { TableProps, TableStyledProps } from './index';
import { TablePagination } from './TablePagination';
import { TableFilter } from './TableFilters';
import { useLocalStorage } from './utils/useLocalStorage';
import { useDebounce } from './utils/useDebounce';
import { fuzzyTextFilter, tobeEqualFilter, tobeEqualKeyFilter, fuzzyTextKeyFilter, dateBetweenFilter, tobeEqualSelectFilter, fuzzySelectFilter } from './filters';
import Checkbox from '../Checkbox';
import Input from '../Input';
import { defaults, Button, Box } from '../../SubatomicParticles';
import { Label, P } from '../../theme/typography';
import { ReactComponent as IconUp } from './assets/arrow-up.svg';

const variants = variant({
  prop: 'styling',
  variants: {
    base: {
      transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      borderRadius: '4px',
      fontFamily: 'OpenSans Regular',
      textAlign: 'center',
      color: 'text.dark',
      width: '100%',
      position: 'inherit',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      '.table': {
        borderSpacing: 0,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        borderCollapse: 'separate',
        verticalAlign: 'middle',
        height: 'calc(100% - 83px)',
        overflow: 'hidden',
        '.thead': {
          display: 'table-header-group',
          bg: 'transparent',
          '.tr': {
            color: 'inherit',
            display: 'flex',
            outline: 0,
            verticalAlign: 'middle',
            '.th': {
              color: '#3f51b5',
            },
            '&:last-child': {
              '.td': {
                borderBottom: 0,
              },
            },
          },
        },
        '.tbody': {
          bg: '#fff',
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'column',
          overflowX: 'auto',
          height: 'calc(100% - 50px)',
          width: '100%',
          scrollSnapType: 'y mandatory',
          '.tr': {
            display: 'flex',
            transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            height: '50px',
            minHeight: '50px',
            scrollSnapAlign: 'start',
            '&:hover': {
              bg: 'hovers.0',
            },
          },
        },
        '.th, .td': {
          margin: 0,
          borderBottom: '1px solid',
          borderColor: '#f0f1f2',
          display: 'flex',
          color: '#1B2B37',
          alignItems: 'center',
          padding: '8px 16px',
          fontSize: '1',
          textAlign: 'left',
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
          overflow: 'hidden',
          width: '100%',
          '&:last-child': {
            borderRight: 0,
          },
        },
        '.td': {
          display: 'block',
          height: '50px',
          lineHeight: '45px',
          padding: '0 16px',
          textOverflow: 'ellipsis',
          verticalAlign: 'inherit',
          whiteSpace: 'nowrap',
          'label, p': {
            fontSize: '0.85rem',
          },
          '& > p': {
            '& > div': {
              height: '50px',
              '& > a': {
                lineHeight: 'normal',
              },
            },
          },
        },
        '.icon-sort': {
          transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          svg: {
            path: {
              stroke: 'secondary.default',
            },
            line: {
              stroke: 'secondary.default',
            },
          },
          '&.down': {
            transform: 'rotate(180deg) scale(1)',
          },
          '&.up': {
            transform: 'rotate(0deg) scale(1)',
          },
          '&.none': {
            transform: 'rotate(0deg) scale(0)',
          },
        },
      },
    },
  },
} as VariantArgs);

const Style: React.FC<TableStyledProps> = styled.div`
  ${variants}
  ${defaults}
`;

function DefaultColumnFilter<T extends object>({
  column: { id, index, filterValue, setFilter, render, parent },
}: FilterProps<T>) {
  const [value, setValue] = React.useState(filterValue || '');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  // ensure that reset loads the new value
  useEffect(() => {
    setValue(filterValue || '');
  }, [filterValue]);

  const firstIndex = !(parent && parent.index);

  return (
    <Box styling="column" width="100%" mb="15px">
      <Label styling="base" mb="xxs" color="text.dark">
        {render('Header')}
      </Label>
      <Input
        name={id}
        value={value}
        autoFocus={index === 0 && firstIndex}
        styling="base"
        mask=""
        onChange={handleChange}
        onBlur={(e: any) => {
          setFilter(e.target.value || undefined);
        }}
      />
    </Box>
  );
}

const filterTypes = {
  fuzzyText: fuzzyTextFilter,
  fuzzyTextKey: fuzzyTextKeyFilter,
  fuzzyTextSelect: fuzzySelectFilter,
  tobeEqual: tobeEqualFilter,
  tobeEqualKey: tobeEqualKeyFilter,
  tobeEqualSelect: tobeEqualSelectFilter,
  dateBetween: dateBetweenFilter,
  // numeric: numericTextFilter
};
const defaultColumn = {
  Filter: DefaultColumnFilter,
} as any;

export function Table<T extends object>(
  props: PropsWithChildren<TableProps<T>> & TableStyledProps
): React.ReactElement {
  const [initialState, setInitialState] = useLocalStorage(
    `tableState:${props.name}`,
    {}
  );
  const hooks = useMemo(
    () =>
      [
        useColumnOrder,
        useFilters,
        // useBlockLayout,
        useSortBy,
        !props.hiddenPagination?  usePagination : false,
        props.isMulti ? useRowSelect : false,
      ].filter(hook => hook) as any,
    [props.isMulti] // eslint-disable-line react-hooks/exhaustive-deps
  ); 

  const instance = useTable<T>(
    {
      ...props,
      filterTypes,
      defaultColumn,
      initialState
    },
    ...hooks,
    hooks => {
      if (props.isMulti) {
        hooks.visibleColumns.push((columns: any) => [
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <Checkbox
                styling="base"
                // @todo ver com o rodolfo
                style={{ padding: 0 }}
                name={getToggleAllRowsSelectedProps.name}
                id={getToggleAllRowsSelectedProps.name}
                {...getToggleAllRowsSelectedProps()}
              />
            ),
            Cell: ({ row }: any) => (
              <Checkbox
                styling="base"
                style={{ padding: 0 }}
                name={row.id}
                id={row.id}
                {...row.getToggleRowSelectedProps()}
              />
            ),
          },
          ...columns,
        ]);
      }
    }
  );

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    page,
    prepareRow,
    state,
    rows,
  } = instance;

  const debouncedState = useDebounce(state, 500);

  useEffect(() => {
    // Para voltar a persitir o filtro apenas descomente as linhas abaixo
    const {
      sortBy,
      // filters,
      pageSize,
      hiddenColumns
    } = debouncedState;
    const val = {
      sortBy,
      // filters: props.resetFilterWhenChangeView ? [] : filters,
      pageSize,
      hiddenColumns: hiddenColumns ? props.hiddenColumns : hiddenColumns,
    };
    setInitialState(val);
  }, [setInitialState, debouncedState]);// eslint-disable-line react-hooks/exhaustive-deps

  const handleWidthColumns = useCallback(
    width => {
      return width === 150 ? '100%' : width
    }, [])

  const renderTablePagination = () =>{
    if(!props.hiddenPagination) {
      return (
      <TablePagination<T>
        instance={instance}
        styling={props.styling ?? 'base'}
      />)
    }
  }

  return (
    <Style styling={props.styling ?? 'base'}>
    <>
      <div className="table" {...getTableProps()}>
        <div className="thead">
          {headerGroups.map(headerGroup => (
            <div className="tr" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <div
                  className="th"
                  {...column.getHeaderProps(column.getSortByToggleProps({ title: column.Header }))}
                  style={{ width: handleWidthColumns(column.width) }}
                >
                  {column.render('Header')}
                  {/* Render the columns filter UI */}
                  <Button
                    styling="underlined"
                    type="button"
                    m={0}
                    mx="xxs"
                    p={0}
                    border="none"
                    className={classnames({
                      'icon-sort': true,
                      down: column.isSortedDesc,
                      up: !column.isSortedDesc,
                      none: !column.isSorted,
                    })}
                  >
                    <IconUp height={10} width={10} fill="none" />
                  </Button>
                  <div>{column.canFilter ?? null}</div>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="tbody" {...getTableBodyProps()}>
        { page ? ( page.length ? (
            page.map(row => {
              prepareRow(row);
              return (
                <div className="tr" {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <div
                        className="td"
                        style={{ width: handleWidthColumns(cell.column.width) }}
                        {...cell.getCellProps()}
                        title={typeof cell.value === 'string' ? cell.value : ''}
                      >
                        {cell.render('Cell')}
                      </div>
                    );
                  })}
                </div>
              );
            })
          ) : props.isLoading ? (
            <P styling="base" m="sm">Carregando dados...</P>
          ) : (<P styling="base" m="sm" data-testid="emptyMessage">{props.emptyMessage || 'Não há itens a serem exibidos.'}</P>)
        ) : 
        (rows.length ? (
          rows.map(row => {
            prepareRow(row);
            return (
              <div className="tr" {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <div
                        className="td"
                        style={{ width: handleWidthColumns(cell.column.width) }}
                        {...cell.getCellProps()}
                        title={typeof cell.value === 'string' ? cell.value : ''}
                      >
                        {cell.render('Cell')}
                      </div>
                    );
                  })}
                </div>
            );
          })
        ) : props.isLoading ? (
          <P styling="base" m="sm">Carregando dados...</P>
        ) : (<P styling="base" m="sm" data-testid="emptyMessage">{props.emptyMessage || 'Não há itens a serem exibidos.'}</P>)
      )
      }
        </div>
      </div>
      {renderTablePagination()}
      <TableFilter<T>
        instance={instance}
        styling={props.styling ?? 'base'}
        open={props.showFilter}
        onClose={props.onCloseFilter}
        countFilter={props.countFilter}
      />
    </>
  </Style>
  );
}
