import firebase from "firebase";
import axios, { get, post, put } from 'axios';
import headers from '../../../utils/mountFirebaseHeaders';

const { REACT_APP_FIREBASE_FUNCTIONS_URL } = process.env;

const ACCOUNT_INFORMATION_GET_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/accountInformationGET`;
const USER_REGISTER_POST = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/userRegisterPOST`;
const EMAIL_EXISTS_GET_URL = email => `${REACT_APP_FIREBASE_FUNCTIONS_URL}/emailExistsGET?email=${email}`;
const SEARCH_ADDRESS_BY_ZIPCODE_GET_URL = cep => `${REACT_APP_FIREBASE_FUNCTIONS_URL}/searchAddressByZipCodeGET?zipcode=${cep}`;
const USER_UPDATE_REGISTER_PUT_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/userUpdateRegisterPUT`;
const USER_GET_RESIDENCES_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/residencesGET`;
const REMOVE_RESIDENCE_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/removeResidence`;
const USER_ADD_RESIDENCE_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/saveUserResidencePOST`;
const REGISTER_VEHICLE_URL_POST = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/registerVehiclePOST`;
const GET_VEHICLE_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/userVehiclesGET`;
const REMOVE_VEHICLE_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/removeVehicle`;
export const CPF_EXISTS_GET_URL = cpf => `${REACT_APP_FIREBASE_FUNCTIONS_URL}/cpfExistsGet?cpf=${cpf}`;
const POST_UPDATE_USER_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/updateUserRegister`;
const POST_UPDATE_USER_CREDENTIALS_URL = `${REACT_APP_FIREBASE_FUNCTIONS_URL}/updateUserCredentials`;

export const signIn = async obj => {
  const { email, password } = obj;
  return firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(data => data)
    .catch(function (error) {
      return error;
    });
};

export const authUser = async () => get(ACCOUNT_INFORMATION_GET_URL, { headers: await headers() }).then(({ data }) => data)

export const getResidences = async () => get(USER_GET_RESIDENCES_URL, { headers: await headers() })
export const removeResidence = async body => post(REMOVE_RESIDENCE_URL, body, { headers: await headers() })
export const addResidence = async body => post(USER_ADD_RESIDENCE_URL, body, { headers: await headers() })

export const logout = async () => {
  try {
    firebase.auth().signOut()
  } catch (error) {
    console.log("ERROR -> CATCH", error);
  }
};

export const create = async body => post(USER_REGISTER_POST, body, { headers: await headers() });

export const resetPassword = async emailAddress => {
  firebase.auth().sendPasswordResetEmail(emailAddress)
    .then(() => true)
    .catch(() => false);
}

export const verificarCpf = async cpf => get(CPF_EXISTS_GET_URL(cpf)).then(({ data }) => data)

export const verifyEmail = async emailAddress => get(EMAIL_EXISTS_GET_URL(emailAddress)).then(({ data }) => data)

export const getCEP = async cep => get(SEARCH_ADDRESS_BY_ZIPCODE_GET_URL(cep)).then(({ data }) => data)

export const userUpdate = async body => put(USER_UPDATE_REGISTER_PUT_URL, body, { headers: await headers() })

export const registerVehicle = async body => post(REGISTER_VEHICLE_URL_POST, body, { headers: await headers() });

export const getVehicles = async () => get(GET_VEHICLE_URL, { headers: await headers() })

export const removeVehicle = async body => post(REMOVE_VEHICLE_URL, body, { headers: await headers() })

const apiClientVehicle = axios.create({
  baseURL: 'https://fipeapi.appspot.com/api/',
  timeout: 3000,
  headers: { 'Content-Type': 'application/json' }
});

export const getVehicleBrands = () => apiClientVehicle.get('1/carros/marcas.json')
export const getVehiclesFromBrand = id => apiClientVehicle.get(`1/carros/veiculos/${id}.json`)
export const getVehicleModelYear = (brandId, vehicleId) => apiClientVehicle.get(`1/carros/veiculo/${brandId}/${vehicleId}.json`)
export const getVehicleInfo = (brandId, vehicleId, modelId) => apiClientVehicle.get(`1/carros/veiculo/${brandId}/${vehicleId}/${modelId}.json`)

export const editarDados = async params => post(POST_UPDATE_USER_URL, params, { headers: await headers() });
export const editarCredenciais = async params => post(POST_UPDATE_USER_CREDENTIALS_URL, params, { headers: await headers() });



// const vehiclesAxiosInstance = axios.create({
//   baseURL: 'https://veiculos.fipe.org.br/api/veiculos/',
//   timeout: 3000,
//   headers: {
//     Host: 'veiculos.fipe.org.br',
//     Referer: 'http://veiculos.fipe.org.br',
//     'Content-Type': 'application/json'
//   }
// });

// export const getReferenceTable = () => vehiclesAxiosInstance.post('ConsultarTabelaDeReferencia');

// export const getParamBrands = param => {
//   const data = new FormData();
//   data.append('codigoTabelaReferencia', param.codigoTabelaReferencia);
//   data.append('codigoTipoVeiculo', param.codigoTipoVeiculo);

//   return data
// }

// export const getParamModels = params => {
//   const { codigoTabelaReferencia, codigoTipoVeiculo, codigoMarca } = params
//   const data = new FormData();
//   data.append('codigoTabelaReferencia', codigoTabelaReferencia);
//   data.append('codigoTipoVeiculo', codigoTipoVeiculo);
//   data.append('codigoMarca', codigoMarca);

//   return data
// }

// export const getParamYearModels = params => {
//   const { codigoTabelaReferencia, codigoTipoVeiculo, codigoMarca, codigoModelo } = params
//   const data = new FormData();
//   data.append('codigoTabelaReferencia', codigoTabelaReferencia);
//   data.append('codigoTipoVeiculo', codigoTipoVeiculo);
//   data.append('codigoMarca', codigoMarca);
//   data.append('codigoModelo', codigoModelo);

//   return data
// }

// export const getParamsToSend = params => {
//   const {
//     codigoTabelaReferencia,
//     codigoTipoVeiculo,
//     codigoMarca,
//     ano,
//     codigoTipoCombustivel,
//     anoModelo,
//     codigoModelo,
//     tipoConsulta,
//   } = params
//   const data = new FormData();
//   data.append("codigoTabelaReferencia", codigoTabelaReferencia)
//   data.append("codigoTipoVeiculo", codigoTipoVeiculo)
//   data.append("codigoMarca", codigoMarca)
//   data.append("ano", ano)
//   data.append("codigoTipoCombustivel", codigoTipoCombustivel)
//   data.append("anoModelo", anoModelo)
//   data.append("codigoModelo", codigoModelo)
//   data.append("tipoConsulta", tipoConsulta)

//   return data
// }

// export const getConsultarMarcas = param => vehiclesAxiosInstance.post('ConsultarMarcas', getParamBrands(param))

// export const getConsultarModelos = params => vehiclesAxiosInstance.post('ConsultarModelos', getParamModels(params))

// export const getConsultarAnoModelo = params => vehiclesAxiosInstance.post('ConsultarAnoModelo', getParamYearModels(params))

// export const getConsultarValorComTodosParametros = params => vehiclesAxiosInstance.post('ConsultarValorComTodosParametros', getParamsToSend(params))